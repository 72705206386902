import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import MainContainer from "features/containers/MainContainer/MainContainer";
import IPage from "interfaces/page";
import { selectUsers } from "../usersSlice";
import { UserIdParam } from "models/Users";
import {
  getUser,
  selectUser,
  setUser,
  selectLoading,
  deleteUser,
  selectDeleted,
  resetDeleted,
  selectInvalidated,
  resetAfterEditUser,
} from "./showUserSlice";
import { setLastViewedUser } from "../usersSlice";
import EditUser from "./EditUser";
import ConfirmModal from "features/confirmModal/ConfirmModal";
import UserData from "./components/UserData";
import UserStatistics from "./components/ShowUserStatistics";
import CompletedInstallations from "./components/CompletedInstallations";
import { selectUserIsAdmin } from "features/auth/authSlice";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { selectShowUser } from "./showUserSlice";
import { ToastContainer, toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";

const ShowUser: React.FunctionComponent<IPage> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<UserIdParam>();
  const showUser = useAppSelector(selectShowUser);
  const shownUser = useAppSelector(selectUser);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);
  const allUsers = useAppSelector(selectUsers);
  const userLoading = useAppSelector(selectLoading);
  const userDeleted = useAppSelector(selectDeleted);
  const userInvalidated = useAppSelector(selectInvalidated);
  const location = useLocation();
  const query = useQuery();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deletePending, setDeletePending] = useState<boolean>(false);
  const userWasEdited = showUser.userWasEdited;

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const closeModal = () => {
    query.delete("edit");
    history.replace({
      search: query.toString(),
    });
    setEditMode(false);
  };
  const openModal = () => {
    query.append("edit", "true");
    history.replace({
      search: query.toString(),
    });
    setEditMode(true);
    dispatch(resetAfterEditUser());
  };

  useEffect(() => {
    if (query.get("edit")) {
      setEditMode(true);
    }
    if (!shownUser || shownUser.id !== id) {
      loadUser();
    }
  }, []);

  const loadUser = () => {
    let user;

    if (allUsers && allUsers?.length > 0) {
      // Lookup user in store
      user = allUsers.find((user) => {
        return user.id === id;
      });

      if (user) {
        dispatch(setUser(user));
      } else {
        // Fetch user
        dispatch(getUser(id));
      }
    } else {
      // Fetch user
      dispatch(getUser(id));
    }
  };

  useEffect(() => {
    if (userDeleted) {
      // Reset deleted state
      dispatch(resetDeleted());

      // Redirect back to users page
      history.push("/users");
    }
  }, [userDeleted]);

  useEffect(() => {
    // Check if invalidated
    if (userInvalidated && !userLoading) {
      closeModal();
      dispatch(getUser(id));
    }
  }, [userInvalidated]);

  // Saving the userid in users.lastViewedUser
  useEffect(() => {
    if (shownUser) {
      dispatch(setLastViewedUser(shownUser.id));
    }
  }, [shownUser]);

  const dismissConfirmModal = () => {
    setDeletePending(false);
  };

  const confirmDelete = () => {
    dispatch(deleteUser(id));
    setDeletePending(false);
  };

  // Toastify
  useEffect(() => {
    if (userWasEdited) {
      if (showUser.error === null) {
        toast.success(`Användaruppgifterna har redigerats!`, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        toast.error(`${showUser.error?.message}`);
      }
    }
  }, [userWasEdited]);

  // Cleanup when component unmount
  useEffect(() => {
    if (userWasEdited) {
      return () => {
        dispatch(resetAfterEditUser());
      };
    }
  }, [userWasEdited]);

  return (
    <>
      <ToastContainer position="top-center" />

      <MainContainer
        title={`${props.name} | ${shownUser?.userName} ${
          shownUser?.state === "PENDING" ? " (väntar på godkännande)" : ""
        }`}
      >
        <Tooltip arrow title={<>Alla användare</>}>
          <div className="d-flex mb-4" style={{ height: 20, width: 85 }}>
            <Link to={`/users`} style={{ textDecoration: "none" }}>
              <ArrowBackIcon />
              Tillbaka
            </Link>
          </div>
        </Tooltip>

        <Row>
          <Col xs={12} lg={6}>
            <UserData
              shownUser={shownUser}
              openModal={openModal}
              setDeletePending={setDeletePending}
            />
          </Col>

          {!userIsAdmin && <CompletedInstallations />}
          {userIsAdmin && (
            <>
              <Col xs={12} lg={6}>
                <UserStatistics />
              </Col>

              <CompletedInstallations />
            </>
          )}
        </Row>

        {deletePending && (
          <ConfirmModal
            isLoading={false}
            handleClose={dismissConfirmModal}
            handleConfirm={confirmDelete}
            show={true}
            title={"Ta bort"}
            message="Är du säker på att du vill ta bort den här användaren?"
            confirmLabel="Ja, ta bort"
            dismissLabel="Avbryt"
          />
        )}
        {editMode && !userLoading && (
          <EditUser show={editMode} handleClose={closeModal} />
        )}
      </MainContainer>
    </>
  );
};

export default ShowUser;
