import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styles from "./mainNavigation.module.scss";
import "./mainNavigation.module.scss";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { Link } from "react-router-dom";

const MainNavItem = ({
  to,
  eventKey,
  label,
  Icon,
}: {
  to: string;
  eventKey: number;
  label: string;
  Icon:
    | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
      })
    | undefined;
}) => {
  return (
    <>
      <LinkContainer
        active={false}
        activeClassName={styles.active}
        exact={false}
        to={to}
        eventKey={eventKey}
        className={`${styles.navItem} px-0`}
      >
        <Nav.Link
          to={to}
          className={`w-100 ${styles.navItem}`}
          eventKey={to}
          as={Link}
        >
          {Icon && <Icon className="mx-3" />}
          {label}
        </Nav.Link>
      </LinkContainer>
    </>
  );
};

export default MainNavItem;
