import { ReactElement, ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./mainContainer.module.scss";

interface MainConTainerProps {
  children: ReactNode;
  title: string;
  edit?: ReactNode;
}

const MainContainer: React.FunctionComponent<MainConTainerProps> = (
  props
): ReactElement => {
  const { title, children, edit } = props;
  return (
    <div className={styles.mainContainerWrapper}>
      <Row>
        <div className="d-flex justify-content-start">
          <h1 className="ms-3">{title}</h1>
          {edit}
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <div className={styles.mainContainer}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default MainContainer;
