import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import signUpreducer from "../features/signUp/signUpSlice";
import resetPasswordReducer from "../features/resetPassword/resetPasswordSlice";
import usersReducer from "../pages/users/usersSlice";
import companiesReducer from "../pages/companies/companiesSlice";
import showUserReducer from "../pages/users/showUser/showUserSlice";
import addUserReducer from "pages/users/addNewUser/addNewUserSlice";
import showCompanyReducer from "pages/companies/showCompany/showCompanySlice";
import addCompanyReducer from "pages/companies/addNewCompany/addNewCompanySlice";
import timeManagementReducer from "pages/timeManagement/timeManagementSlice";
import ordersReducer from "pages/orders/ordersSlice";
import viewOrderReducer from "pages/orders/viewOrder/viewOrderSlice";
import viewChecklistReducer from "pages/orders/viewChecklist/viewChecklistSlice";
import productsReducer from "pages/orders/viewChecklist/components/products/productsSlice";
import ratingReducer from "pages/orders/viewChecklist/components/rating/ratingSlice";
import statisticsReducer from "pages/dashboard/statisticsSlice";
import editFunctionCheckSlice from "pages/orders/viewChecklist/components/edit-components/editFunctionCheckSlice";
import editCustomerSlice from "pages/orders/viewOrder/components/editCustomerInfo/editCustomerSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    signUp: signUpreducer,
    resetPassword: resetPasswordReducer,
    users: usersReducer,
    companies: companiesReducer,
    showUser: showUserReducer,
    showCompany: showCompanyReducer,
    addCompany: addCompanyReducer,
    addUser: addUserReducer,
    timeManagement: timeManagementReducer,
    orders: ordersReducer,
    viewOrder: viewOrderReducer,
    viewChecklist: viewChecklistReducer,
    products: productsReducer,
    rating: ratingReducer,
    statistics: statisticsReducer,
    editCustomerData: editCustomerSlice,
    editFunctionCheck: editFunctionCheckSlice,
  },

  // Enable Redux devtools in development only
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
