import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectChecklist, deleteChecklist } from "../viewChecklistSlice";
import { Button, Tooltip } from "@mui/material";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";
import { useHistory } from "react-router-dom";

const DeleteChecklist = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const checklist = useAppSelector(selectChecklist);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteChecklist = () => {
    if (checklist?.id) {
      dispatch(deleteChecklist(checklist?.id));
      setShowModal(false);
      history.push(`/orders/view/${checklist?.partOfOrder}`);
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Vill du radera denna checklista?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-center my-3">
            OBS! Denna åtgärd kan inte ångras. Om du raderar{" "}
            {checklist?.roomName} försvinner all data kopplad till checklistan.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="contained" className="me-2" onClick={handleClose}>
            Avbryt
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteChecklist}
          >
            Ja, radera denna checklista
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="ms-2">
        <Tooltip arrow title="Radera checklistan">
          <ClearIcon onClick={handleClick} className={iconStyles.clearIcon} />
        </Tooltip>
      </div>
    </>
  );
};

export default DeleteChecklist;
