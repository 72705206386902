import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { Order } from "models/Order";
import { initialState, GetOrdersResponse } from "models/Orders";

export type FilterOrdersParams = {
  page?: number;
  pageSize?: number;
  filterText: string | null;
  fromDate: string | null;
  toDate: string | null;
  filterByReferenceOrder: boolean | null;
  filterOnlyCustomerFollowUpNotDone?: boolean | null;
  filterByOrderStatus: string | null;
  filterNotRatedChecklists: boolean | null;
  sort: { sortBy: string; sortType: string } | null;
};

const filterParamsAreEqual = (
  currentFilters: FilterOrdersParams,
  newFilters: FilterOrdersParams
): boolean => {
  const orderedA = Object.keys(currentFilters)
    .sort()
    .reduce((obj, key) => {
      obj[key] = currentFilters[key];
      return obj;
    }, {});

  const orderedB = Object.keys(newFilters)
    .sort()
    .reduce((obj, key) => {
      obj[key] = newFilters[key];
      return obj;
    }, {});

  let areEqual = JSON.stringify(orderedA) === JSON.stringify(orderedB);

  return areEqual;
};

export const getOrders = createAsyncThunk<
  GetOrdersResponse | null,
  FilterOrdersParams,
  { rejectValue: GenericError; state: RootState }
>("orders/getOrders", async (params, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const filterParams: FilterOrdersParams = {
    page: state.orders.lastPageLoad || 0,
    pageSize: 20,
    fromDate: params.fromDate ? params.fromDate : null,
    toDate: params.toDate ? params.toDate : null,
    filterText: params.filterText ? params.filterText : "",
    filterNotRatedChecklists: params.filterNotRatedChecklists,
    filterByReferenceOrder: params.filterByReferenceOrder!,
    filterByOrderStatus: params.filterByOrderStatus,
    filterOnlyCustomerFollowUpNotDone: params.filterOnlyCustomerFollowUpNotDone
      ? params.filterOnlyCustomerFollowUpNotDone
      : false,
    sort: params.sort ? params.sort : null,
  };

  // Check if fetching new page or with new params
  if (
    state.orders.currentFilters &&
    state.orders.pageCount &&
    state.orders.lastPageLoad !== null
  ) {
    if (filterParamsAreEqual(state.orders.currentFilters, filterParams)) {
      // Check if all pages have been loaded
      if (state.orders.lastPageLoad === state.orders.pageCount! - 1) {
        thunkApi.fulfillWithValue(null);
      }
      // Increment last loaded page
      filterParams.page = state.orders.lastPageLoad + 1;
    } else {
      // Reset page
      filterParams.page = 0;
    }
  }

  // Set currentFilters
  dispatch(setCurrentFilters(filterParams));

  const response = await axios
    .post<GetOrdersResponse>(`${BE_URL}/order/page`, filterParams, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta ordrar",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const getOrdersFilteredByDate = createAsyncThunk<
  Order[],
  { fromDate: string; toDate: string },
  { rejectValue: GenericError; state: RootState }
>("orders/filterOrdersByDate", async (params, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<Order[]>(`${BE_URL}/order/filter`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta ordrar",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as Order[];
});

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsInvalidated: (state) => {
      state.isInvalidated = true;
    },
    resetState: (state) => {
      state = initialState;
    },
    setCurrentFilters: (
      state,
      action: PayloadAction<FilterOrdersParams | null>
    ) => {
      state.currentFilters = action.payload;
    },
    setLastViewedOrder: (state, action: PayloadAction<number | null>) => {
      state.lastViewedOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;

        if (action.payload) {
          if (action.payload.page === 0) {
            state.orders = action.payload.orders;
          } else {
            state.orders = [...state.orders!, ...action.payload.orders];
          }

          state.pageCount = action.payload.pageCount;
          state.lastPageLoad = action.payload.page;
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const {
  setIsLoading,
  setIsInvalidated,
  setCurrentFilters,
  setLastViewedOrder,
} = ordersSlice.actions;

export const selectPreviousOrder = (state: RootState) =>
  state.orders.lastViewedOrder;
export const selectOrders = (state: RootState) => state.orders.orders;
export const selectError = (state: RootState) => state.orders.error;
export const selectLoading = (state: RootState) => state.orders.isLoading;
export const selectLoaded = (state: RootState) => state.orders.isLoaded;
export const selectIsInvalidated = (state: RootState) =>
  state.orders.isInvalidated;
export const selectCurrentPage = (state: RootState) =>
  state.orders.lastPageLoad;
export const selectHasMorePages = (state: RootState) => {
  if (state.orders.pageCount !== null && state.orders.lastPageLoad !== null) {
    return state.orders.lastPageLoad < state.orders.pageCount - 1;
  }
  return true;
};
export const selectCurrentFilters = (state: RootState) =>
  state.orders.currentFilters;

export default ordersSlice.reducer;
