import { useEffect } from "react";
import styles from "./map.module.scss";
import { Loader } from "@googlemaps/js-api-loader";

const SearchMap = ({ props: companies }) => {
  useEffect(() => {
    if (companies) {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"],
      });

      loader.load().then(() => {
        function initAutocomplete() {
          const sweden = { lat: 63.005848452901844, lng: 15.393478406049903 };
          const geocoder = new window.google.maps.Geocoder();

          // Initialize map
          const map = new window.google.maps.Map(
            document.getElementById("map"),
            {
              center: sweden,
              zoom: 5,
              mapTypeId: "roadmap",
              streetViewControl: false,
              mapTypeControl: false,
            }
          );

          // Map through companies, geocode them, add a marker and infoWindow for each company
          companies.forEach((company) => {
            const address = company.geoAddress;
            geocoder.geocode({ address }, (results, status) => {
              if (status === "OK") {
                const marker = new window.google.maps.Marker({
                  position: results[0].geometry.location,
                  map,
                  title: company.name,
                });

                const contentString = `
                <div className=${styles.contentInfoWindow} >
                  <h5>${company.namn}</h5>
                  <span>${company.address} <br> ${company.postnr}, ${
                  company.postaddress
                }.</span>
                  <br><br>
                  <p><strong>Timpris:</strong> ${
                    company.rate !== null
                      ? company.rate + " kr/h"
                      : "Info saknas"
                  } </p>

                  <p><strong>Kompetens:</strong> ${
                    company.competence || "Info saknas"
                  } </p>
                  <p><strong>Kommentar:</strong> ${
                    company.comment || "Info saknas"
                  } </p>
                </div>`;

                const infoWindow = new window.google.maps.InfoWindow({
                  content: contentString,
                  ariaLabel: company.name,
                });

                marker.addListener("mouseover", () => {
                  infoWindow.open({
                    anchor: marker,
                    map,
                  });
                });

                marker.addListener("mouseout", () => {
                  infoWindow.close();
                });
              } else {
                console.log(`Geocode failed: ${status}`);
              }
            });
          });

          // Input and searchbox
          const input = document.getElementById("pac-input");
          const searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
          });

          searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length === 0) {
              return;
            }

            const bounds = new window.google.maps.LatLngBounds();
            places.forEach((place) => {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }

              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          });
        }

        initAutocomplete();
      });
    }
  }, [companies]);

  return (
    <>
      <div id="map" className={styles.map}></div>
      <input
        id="pac-input"
        className={styles.searchBox}
        type="text"
        placeholder="Sök efter stad eller område"
      ></input>
    </>
  );
};

export default SearchMap;
