import React, { useEffect } from "react";
import { Button, Tooltip } from "@mui/material";
import { useAppSelector, useAppDispatch } from "app/hooks";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import iconStyles from "utilities/styles/iconStyles.module.scss";
import { Form, Modal, FloatingLabel, Spinner, Alert } from "react-bootstrap";
import { useForm } from "hooks/useForm";
import { useState } from "react";
import { selectOrder, selectLoading } from "../../viewOrderSlice";
import {
  selectIsLoading,
  selectProgressState,
  EditCustomerDetailsState,
  editCustomerDetails,
  EditCustomerDetails,
} from "./editCustomerSlice";
import { ToastContainer, toast } from "react-toastify";

export interface EditCustomerFormFields {
  name: string;
  address: string;
  postAddress: string;
  postalCode: string;
}

const EditCustomerData = ({ show, setShow }) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectOrder);
  const loading = useAppSelector(selectLoading);
  const progressState = useAppSelector(selectProgressState);

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = () => {
    setShow(true);
  };

  useEffect(() => {
    console.log("Use effect listening for change in progressstate");
    if (progressState === EditCustomerDetailsState.success) {
      handleClose();
      toast.success("Kunduppgifterna har redigerats!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
    if (progressState === EditCustomerDetailsState.fail) {
      toast.error("Något gick fel, försök igen", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  }, [progressState]);

  const {
    handleSubmit,
    handleChange,
    data: customerData,
    errors,
    clearFormData,
  } = useForm<EditCustomerFormFields>({
    validations: {
      name: {
        custom: {
          isValid: (value) => {
            return value.length > 0;
          },
          message: "Ange kundens namn",
        },
      },
      address: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange adress",
        },
      },
      postAddress: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ort",
        },
      },
      postalCode: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange postnummer",
        },
      },
    },
    initialValues: {
      name: order?.companyName,
      address: order?.address,
      postAddress: order?.postaddress,
      postalCode: order?.postnr,
    },
    onSubmit: () => {
      console.log("Hello submit");
      dispatch(editCustomerDetails(customerData));
    },
  });

  return (
    <div>
      <ToastContainer position="top-center" />
      <Tooltip arrow title={"Redigera kunduppgifter"}>
        <EditOutlinedIcon
          className={iconStyles.editIcon}
          onClick={handleClick}
        />
      </Tooltip>

      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Redigera kunduppgifter</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <FloatingLabel label="Kund" className="mb-3">
              <Form.Control
                type="text"
                onChange={handleChange("name" || "")}
                className={`form-control mb-3 ${errors.name && "is-invalid"}`}
                value={customerData.name}
              />
            </FloatingLabel>
            <FloatingLabel label="Adress" className="mb-3">
              <Form.Control
                type="text"
                onChange={handleChange("address" || "")}
                className={`form-control mb-3 ${
                  errors.address && "is-invalid"
                }`}
                value={customerData.address}
              />
            </FloatingLabel>
            <FloatingLabel label="Postnummer" className="mb-3">
              <Form.Control
                type="text"
                onChange={handleChange("postalCode" || "")}
                className={`form-control mb-3 ${
                  errors.postalCode && "is-invalid"
                }`}
                value={customerData.postalCode}
              />
            </FloatingLabel>
            <FloatingLabel label="Ort" className="mb-3">
              <Form.Control
                type="text"
                onChange={handleChange("postAddress" || "")}
                className={`form-control mb-3 ${
                  errors.postAddress && "is-invalid"
                }`}
                value={customerData.postAddress}
              />
            </FloatingLabel>
            {Object.keys(errors).length > 0 && (
              <Alert variant="danger">
                <ul className="mb-0">
                  {Object.keys(errors).map((field) => {
                    const errorValue = errors[field];

                    return (
                      <li className="m-0" key={field}>
                        {errorValue}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="error"
              variant="contained"
              className="me-2"
              onClick={handleClose}
            >
              Avbryt
            </Button>
            <Button variant="contained" type="submit">
              {progressState === EditCustomerDetailsState.loading ? (
                <Spinner size={"sm"} animation={"border"} />
              ) : (
                "Spara"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default EditCustomerData;
