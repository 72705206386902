import { useAppSelector } from "app/hooks";
import { selectChecklist } from "../viewChecklistSlice";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";

const CreatedBy = ({ nameHidden }) => {
  const checklist = useAppSelector(selectChecklist);

  return (
    <div className={containerStyles.userDataContainer}>
      <p className={textStyles.orderInfoTitle}>Skapad:</p>

      <p className={`mt-3 mb-0 ${textStyles.smallText}`}>
        {checklist?.createdAt.slice(0, 16)}

        {!nameHidden && (
          <>
            <span className={textStyles.smallText}>{" av "}</span>
            <Tooltip arrow title="Gå till användarprofilen">
              <Link to={`/users/view/${checklist?.creator.id}`}>
                <span className={textStyles.smallText}>
                  {checklist?.creator.userName}
                </span>
              </Link>
            </Tooltip>
          </>
        )}
      </p>
    </div>
  );
};

export default CreatedBy;
