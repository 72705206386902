import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const FilterCustomerFollowUp = ({ filterCustomerFollowUpNotDone, setFilterCustomerFollowUpNotDone }) => {

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={filterCustomerFollowUpNotDone}
            onChange={() =>
              setFilterCustomerFollowUpNotDone(!filterCustomerFollowUpNotDone)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Telemarketing ej gjord"
      />
    </div>
  );
};

export default FilterCustomerFollowUp;
