import React from "react";
import { setPayrollStatus } from "../timeManagementSlice";
import { Form, FloatingLabel, Spinner } from "react-bootstrap";
import { truncateDecimals } from "utilities/Conversions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectUser,
  selectUserIsAdmin,
  selectUserIsPayrollUser,
} from "features/auth/authSlice";
import { Button, FormControlLabel, Switch } from "@mui/material";
import styles from "../timeManagement.module.scss";

const Details = (props) => {
  const {
    filterWithinRange,
    timeEntry,
    intervalOverTimeCalculations,
    overTimeCalculations,
    isApprovedByPayRoll,
    statusUpdating,
    handleSubmit,
    setApprovedByPayroll,
    handleChange,
    data,
    errors,
    submitType,
    setSubmitType,
  } = props;

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isPayrollUser = useAppSelector(selectUserIsPayrollUser);
  const isAdmin = useAppSelector(selectUserIsAdmin);

  const togglePayrollStatus = () => {
    dispatch(
      setPayrollStatus({ id: timeEntry.id, processed: !isApprovedByPayRoll })
    );

    setApprovedByPayroll(!isApprovedByPayRoll);
  };

  function isController(): boolean {
    return user?.id === timeEntry.controllerId;
  }

  return (
    <>
      {filterWithinRange && (
        <>
          {timeEntry.type === 5 && (
            <div className={styles.overTimeWithinInterval}>
              <p className="fw-bold mb-2">Arbetstimmar inom valt intervall</p>

              <div className="d-flex justify-content-between">
                <p>Totalt</p>
                <p>{` ${
                  intervalOverTimeCalculations &&
                  truncateDecimals(
                    intervalOverTimeCalculations[0] +
                      intervalOverTimeCalculations[1]
                  )
                }h`}</p>
              </div>

              <div className="d-flex justify-content-between">
                <p>50%</p>

                <p>
                  {` ${
                    intervalOverTimeCalculations &&
                    truncateDecimals(intervalOverTimeCalculations[0])
                  }h`}
                </p>
              </div>

              <div className="d-flex justify-content-between">
                <p>100%</p>
                <p>
                  {` ${
                    intervalOverTimeCalculations &&
                    truncateDecimals(intervalOverTimeCalculations[1])
                  }h`}
                </p>
              </div>
            </div>
          )}
        </>
      )}

      {timeEntry.type === 5 && (
        <div className={styles.overTimeDetails}>
          <div>
            <p className="fw-bold">Övertid totalt</p>
            <p>{`${
              overTimeCalculations &&
              truncateDecimals(
                overTimeCalculations[0] + overTimeCalculations[1]
              )
            }h`}</p>
          </div>
          <div>
            <p className="fw-bold">Övertid 50%</p>
            <p>{`${
              overTimeCalculations ? overTimeCalculations[0].toFixed(2) : ""
            }h`}</p>
          </div>
          <div>
            <p className="fw-bold">Övertid 100%</p>
            <p>{`${
              overTimeCalculations ? overTimeCalculations[1].toFixed(2) : ""
            }h`}</p>
          </div>
        </div>
      )}

      <Form onSubmit={handleSubmit}>
        {isPayrollUser && (
          <FormControlLabel
            control={
              <Switch
                checked={isApprovedByPayRoll}
                onChange={togglePayrollStatus}
              />
            }
            label="Behandlad av ekonomi"
          />
        )}

        {!isController() && isAdmin && user?.id !== timeEntry.userId && (
          <p className={styles.danger}>
            Du är inte attesterare för den här användaren, men kan som admin
            ändra status ändå.
          </p>
        )}

        {(isController() || isAdmin) && user?.id !== timeEntry.userId && timeEntry.state === "PENDING" &&  (
          <div>
            <FloatingLabel controlId="floatingSelect" label="Kommentar">
              <Form.Control
                defaultValue={data.adminComment}
                type="text"
                placeholder="Kommentar"
                onChange={handleChange("adminComment" || "")}
                className={`form-control ${
                  errors.adminComment && "is-invalid"
                }`}
              />
            </FloatingLabel>

            <div className="d-flex justify-content-center my-4">
              <Button
                onClick={() => setSubmitType("REJECT")}
                type="submit"
                color="error"
                variant="contained"
                className="mx-2"
              >
                {statusUpdating && submitType === "REJECT" ? (
                  <Spinner size="sm" animation={"border"} />
                ) : (
                  "Neka"
                )}
              </Button>

              <Button
                onClick={() => setSubmitType("APPROVE")}
                type="submit"
                variant="contained"
                className="mx-2"
              >
                {statusUpdating && submitType === "APPROVE" ? (
                  <Spinner size="sm" animation={"border"} />
                ) : (
                  "Godkänn"
                )}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </>
  );
};

export default Details;
