import axios from "axios";
import { RootState } from "app/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BE_URL } from "config/api";
import { AbsentUser, User } from "features/auth/types/user";
import { GenericError } from "interfaces/errorTypes";
import {
  InstallerChecklistStatistics,
  InstallerStatisticsMinimum,
  TopFiveInstallersRequest,
} from "models/InstallerChecklistStatistics";

interface UsersState {
  users: User[] | null;
  isLoading: boolean;
  isLoaded: boolean;
  isInvalidated: boolean;
  error: string | null;
  statistics: InstallerChecklistStatistics[];
  topFiveInstallers: InstallerStatisticsMinimum[];
  statisticsLoading: boolean;
  lastViewedUser: string | null;
  todaysAbsentees: AbsentUser[] | null;
}

const initialState: UsersState = {
  users: null,
  isLoading: false,
  isLoaded: false,
  isInvalidated: false,
  error: null,
  statistics: [],
  topFiveInstallers: [],
  lastViewedUser: null,
  todaysAbsentees: null,
  statisticsLoading: false,
};

export const getAllUsers = createAsyncThunk<
  User[],
  void,
  { rejectValue: GenericError; state: RootState }
>("users/getAll", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<User[]>(`${BE_URL}/user/list`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      const onlyActiveUsers = res.data.filter((user) => {
        return user.state !== "INACTIVE";
      });
      return onlyActiveUsers;
    })
    .catch((e) => {
      console.log("Server res:  ", e.response.data);
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Koden du angav var fel.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as User[];
});

export const getInstallerChecklistStatistics = createAsyncThunk<
  InstallerChecklistStatistics[],
  string,
  { rejectValue: GenericError; state: RootState }
>("users/getInstallerChecklistStatistics", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<InstallerChecklistStatistics[]>(
      `${BE_URL}/statistics/installer-checklist-statistics`,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Ett okänt fel uppstod",
      });
    });

  return response;
});

export const getTodaysAbsentees = createAsyncThunk<
  AbsentUser[],
  string,
  { rejectValue: GenericError; state: RootState }
>("users/todays-absentees", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<AbsentUser[]>(`${BE_URL}/statistics/todays-absentees`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Ett okänt fel uppstod",
      });
    });

  return response;
});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsInvalidated: (state) => {
      state.isInvalidated = true;
    },
    setLastViewedUser: (state, action: PayloadAction<string | null>) => {
      state.lastViewedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.users = action.payload;
      })

      // get statistics
      .addCase(getInstallerChecklistStatistics.pending, (state) => {
        state.statisticsLoading = true;
      })
      .addCase(getInstallerChecklistStatistics.rejected, (state) => {
        state.statisticsLoading = false;
      })
      .addCase(getInstallerChecklistStatistics.fulfilled, (state, action) => {
        state.statisticsLoading = false;
        state.statistics = action.payload;
      })

      //get todays absentees
      .addCase(getTodaysAbsentees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTodaysAbsentees.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTodaysAbsentees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.todaysAbsentees = action.payload;
      });
  },
});

export const { setIsLoading, setIsInvalidated, setLastViewedUser } =
  usersSlice.actions;

export const selectLastViewedUser = (state: RootState) =>
  state.users.lastViewedUser;
export const selectUsersState = (state: RootState) => state.users;
export const selectUsers = (state: RootState) => state.users.users;
export const selectError = (state: RootState) => state.users.error;
export const selectLoading = (state: RootState) => state.users.isLoading;
export const selectStatisticsLoading = (state: RootState) =>
  state.users.statisticsLoading;
export const selectLoaded = (state: RootState) => state.users.isLoaded;
export const selectisInvalidated = (state: RootState) =>
  state.users.isInvalidated;
export const selectStatistics = (state: RootState) => state.users.statistics;
export const selectTodaysAbsentees = (state: RootState) =>
  state.users.todaysAbsentees;

export default usersSlice.reducer;
