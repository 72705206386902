import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Link, useHistory } from "react-router-dom";
import {
  InstallerChecklistStatistics,
  InstallerStatisticsMinimum,
  TopFiveInstallersRequest,
} from "models/InstallerChecklistStatistics";
import { Rating } from "@mui/material";
import {
  selectStatisticsLoading,
  selectStatistics,
} from "pages/users/usersSlice";
import { BeatLoader } from "react-spinners";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import styles from "../../styles.module.scss";
import {
  getTopFiveInstallers,
  selectTopFiveInStallers,
  selectTopFiveInStallersLoaded,
  selectTopFiveInStallersLoading,
  setTopFiveLoaded,
} from "pages/dashboard/statisticsSlice";
import { useForm } from "hooks/useForm";

const TopInstallers = () => {
  const statistics = useAppSelector(selectTopFiveInStallers);
  const isLoading = useAppSelector(selectTopFiveInStallersLoading);
  const isLoaded = useAppSelector(selectTopFiveInStallersLoaded);
  const dispatch = useAppDispatch();

  const history = useHistory();
  const [sortedUsers, setSortedUsers] =
    useState<InstallerChecklistStatistics[]>();

  // Filter values
  const [selectedInstallerTypeFilter, setSelectedInstallerTypeFilter] =
    useState<InstallerTypeFilter>(InstallerTypeFilter.ALL);

  const [selectedTimePeriodFilter, setSelectedTimePeriodFilters] = useState(
    TimePeriodFilters.FROM_START
  );
  const [onlyApprovedFilter, setOnlyApprovedFilter] = useState<boolean>(true);
  const [initialFiltersLoaded, setInititalFiltersLoaded] =
    useState<boolean>(false);
  const [initialFetchComplete, setInitialDataFetchComplete] = useState(false);

  // Handle filter selections
  const { data: topFiveInstallersFilters } = useForm<TopFiveInstallersRequest>({
    initialValues: {
      timePeriod: TimePeriodFilters[TimePeriodFilters.FROM_START],
      installerType: InstallerTypeFilter[InstallerTypeFilter.ALL],
      onlyIncludeApprovedChecklists: true,
    },
  });

  useEffect(() => {
    fetchData();
    console.log("topFiveInstallers useEffect");
    if (isLoaded === false) {
    }
  }, []);

  // useEffect(() => {
  //   if (isLoaded && initialFetchComplete) {
  //     console.log("Data invalidated");
  //     dispatch(setTopFiveLoaded(false));
  //   }
  // }, [
  //   onlyApprovedFilter,
  //   selectedTimePeriodFilter,
  //   selectedInstallerTypeFilter,
  // ]);

  const fetchData = () => {
    // const requestBody: TopFiveInstallersRequest = {
    //   timePeriod: TimePeriodFilters[selectedTimePeriodFilter],
    //   installerType: InstallerTypeFilter[selectedInstallerTypeFilter],
    //   onlyIncludeApprovedChecklists: onlyApprovedFilter,
    // };
    // console.log(`timeperiod: ${TimePeriodFilters[requestBody.timePeriod]}`);
    console.log(
      `fetching data approved only: ${topFiveInstallersFilters.onlyIncludeApprovedChecklists}`
    );
    dispatch(getTopFiveInstallers(topFiveInstallersFilters));
  };

  function filtersChanged() {
    console.log("Filters changed");
    fetchData();
  }

  function installerTypeSelected(installerType: InstallerTypeFilter) {
    if (
      InstallerTypeFilter[installerType].toString() !==
      topFiveInstallersFilters.installerType
    ) {
      topFiveInstallersFilters.installerType =
        InstallerTypeFilter[installerType];
      fetchData();
    }
  }

  function timePeriodSelected(timePeriod: TimePeriodFilters) {
    if (
      TimePeriodFilters[timePeriod].toString() !==
      topFiveInstallersFilters.timePeriod
    ) {
      topFiveInstallersFilters.timePeriod = TimePeriodFilters[timePeriod];
      fetchData();
    }
  }

  function onlyIncludeApprovedSelected(value: boolean) {
    if (topFiveInstallersFilters.onlyIncludeApprovedChecklists !== value) {
      topFiveInstallersFilters.onlyIncludeApprovedChecklists = value;
      fetchData();
    }
  }

  return (
    <Container className={`w-100 fluid ${styles.modalContainer}`}>
      <>
        {/* Filter row */}
        <Row className="mb-2 mt-4">
          <Col
            xs={"auto"}
            className="d-flex justify-content-center align-items-center flex-direction-column"
          >
            <h5 className="m-0 fw-bold text-center">Topp 5</h5>
          </Col>
          <Col xs={"auto"}>
            <Form onChange={filtersChanged}>
              <DropdownButton
                variant="info"
                id="dropdown-basic-button"
                className=""
                title={getInstallerTypeFilterString(
                  InstallerTypeFilter[topFiveInstallersFilters.installerType]
                )}
                //onChange={(e) => installerTypeSelected()}
              >
                <Dropdown.Item
                  onClick={
                    () => installerTypeSelected(InstallerTypeFilter.ALL)
                    //setSelectedInstallerTypeFilter(InstallerTypeFilter.ALL)
                  }
                >
                  {getInstallerTypeFilterString(InstallerTypeFilter.ALL)}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    installerTypeSelected(
                      InstallerTypeFilter.INTERNAL_INSTALLER
                    )
                  }
                >
                  {getInstallerTypeFilterString(
                    InstallerTypeFilter.INTERNAL_INSTALLER
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    installerTypeSelected(
                      InstallerTypeFilter.EXTERNAL_INSTALLER
                    )
                  }
                >
                  {getInstallerTypeFilterString(
                    InstallerTypeFilter.EXTERNAL_INSTALLER
                  )}
                </Dropdown.Item>
              </DropdownButton>
            </Form>
          </Col>
          <Col>
            <DropdownButton
              variant="info"
              id="dropdown-basic-button"
              title={getTimePeriodFiltersTimePeriodFiltersString(
                TimePeriodFilters[topFiveInstallersFilters.timePeriod]
              )}
              // onChange={filtersChanged}
            >
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.FROM_START)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.FROM_START
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_WEEK)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_WEEK
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_MONTH)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_MONTH
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_YEAR)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_YEAR
                )}
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        <Row className={"mb-4"}>
          <Col>
            <Form.Switch
              checked={topFiveInstallersFilters.onlyIncludeApprovedChecklists} // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Endast godkända checklistor"
              onChange={(e) => onlyIncludeApprovedSelected(e.target.checked)}
              // onChange={filtersChanged}
            />
          </Col>
        </Row>
        {/* Header */}
        <Row className="mb-2">
          <Col xs={1}></Col>
          <Col xs={4}>
            <h6 className={"fw-bold"}>Installatör</h6>
          </Col>
          <Col xs={3}>
            <h6 className={"fw-bold"}>Checklistor</h6>
          </Col>
          <Col xs={4}>
            <h6 className={"fw-bold"}>Betyg</h6>
          </Col>
        </Row>
        {/* Installer rows */}
        {isLoading && (
          <div className="d-flex flex-column align-items-center justify-content-center my-5">
            <BeatLoader color="#5f5f5f" className="mb-3" />
          </div>
        )}

        {!isLoading && isLoaded && statistics.length > 0 && (
          <>
            <Row className="">
              {statistics?.map((user, index) => {
                return (
                  <Row className="my-1 mx-0 px-0" key={index}>
                    <Col xs={1}>{`${index + 1}.`}</Col>
                    <Col xs={4}>{user.userName}</Col>
                    <Col xs={3}>{user.numberOfCompletedChecklists}</Col>
                    <Col xs={4} className="d-flex justify-content-end">
                      {user.averageRating > 0 ? (
                        <div className="d-flex">
                          <span
                            className="me-2 fw-bold"
                            style={{ position: "relative", top: 2 }}
                          >
                            {user.averageRating.toFixed(2)}
                          </span>
                          <Rating
                            value={user.averageRating}
                            precision={0.1}
                            readOnly
                          />
                        </div>
                      ) : (
                        <p className="mb-0">Inget betyg</p>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Row>
            <Row className="my-3">
              <Col
                xs={{ span: 3, offset: 9 }}
                className="d-flex justify-content-end"
              >
                <Link to="/installer-statistics">Visa alla</Link>
              </Col>
            </Row>
          </>
        )}

        {!isLoading && isLoaded && statistics.length < 0 && (
          <p>Ej tillgänglig</p>
        )}
      </>
    </Container>
  );
};

export enum InstallerTypeFilter {
  ALL,
  INTERNAL_INSTALLER,
  EXTERNAL_INSTALLER,
}

export function getInstallerTypeFilterString(
  value: InstallerTypeFilter
): string {
  switch (value) {
    case InstallerTypeFilter.ALL:
      return "Alla installatörer";
    case InstallerTypeFilter.INTERNAL_INSTALLER:
      return "Interna installatörer";
    case InstallerTypeFilter.EXTERNAL_INSTALLER:
      return "Externa installatörer";
  }
}

export function getTimePeriodFiltersTimePeriodFiltersString(
  value: TimePeriodFilters
): string {
  switch (value) {
    case TimePeriodFilters.FROM_START:
      return "Från början";
    case TimePeriodFilters.PAST_MONTH:
      return "Senaste månaden";
    case TimePeriodFilters.PAST_WEEK:
      return "Senaste veckan";
    case TimePeriodFilters.PAST_YEAR:
      return "Senaste året";
  }
}

export enum TimePeriodFilters {
  FROM_START,
  PAST_WEEK,
  PAST_MONTH,
  PAST_YEAR,
}
export default TopInstallers;
