import React, { useState } from "react";
import { useAppSelector } from "app/hooks";
import { selectOrder } from "../../viewOrderSlice";
import { Checklist } from "models/Checklist";
import GenerateExcel from "./GenerateExcel";
import { Modal } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import { Button, FormControlLabel, FormGroup } from "@mui/material";

const GenerateExcelModal = () => {
  const order = useAppSelector(selectOrder);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const checklists = order?.checklists;
  const [checklistsToDownload, setChecklistsToDownload] = useState<Checklist[]>(
    checklists || []
  );
  const [includeAllChecklists, setIncludeAllChecklists] =
    useState<boolean>(true);

  // Populating array of selected checklists
  const checklistIsSelected = (id: Checklist): boolean => {
    if (!checklistsToDownload) return false;
    const isSelected = checklistsToDownload?.some((selection) => {
      return id === selection;
    });
    return isSelected;
  };

  const toggleChecklistSelection = (id: Checklist): void => {
    if (!checklistsToDownload) return;

    if (includeAllChecklists) {
      // If "Alla" checkbox is checked, uncheck all individual checkboxes
      setChecklistsToDownload([]);
    } else {
      let index = checklistsToDownload.indexOf(id);
      if (index > -1) {
        // Remove from array
        let arrayCopy = [...checklistsToDownload];
        arrayCopy.splice(index, 1);
        setChecklistsToDownload(arrayCopy);
      } else {
        // Add to array
        setChecklistsToDownload([...checklistsToDownload, id]);
      }
    }
  };

  const handleIncludeAllChecklists = (value: boolean) => {
    setIncludeAllChecklists(value);

    if (value) {
      // If "Alla" checkbox is checked, include all checklists
      setChecklistsToDownload(checklists || []);
    } else {
      // If "Alla" checkbox is unchecked, deselect all checklists
      setChecklistsToDownload([]);
    }
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="contained"
        className="mb-5"
        onClick={() => setShowModal(true)}
      >
        Sammanställ produkter
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Exportera produktsammanställning</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex flex-column">
          <div className="mb-3">
            <p className="mb-2 fw-bold">
              Kryssa i checklistor som ska inkluderas
            </p>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeAllChecklists}
                    onChange={() =>
                      handleIncludeAllChecklists(!includeAllChecklists)
                    }
                  />
                }
                label={"Alla"}
              />
            </FormGroup>
          </div>

          {!includeAllChecklists && (
            <FormGroup>
              {checklists?.map((checklist) => {
                return (
                  <FormControlLabel
                    key={checklist.id}
                    label={checklist.roomName}
                    control={
                      <Checkbox
                        checked={checklistIsSelected(checklist)}
                        onChange={() => toggleChecklistSelection(checklist)}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          )}
        </Modal.Body>

        <Modal.Footer>
          <GenerateExcel
            order={order}
            checklistsToDownload={checklistsToDownload}
            handleClose={handleClose}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GenerateExcelModal;
