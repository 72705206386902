import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { PhotoComment } from "models/PhotoComment";
import {
  deletePhotoComment,
  selectChecklist,
  viewChecklist,
} from "pages/orders/viewChecklist/viewChecklistSlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import WriteCommentModal from "./modals/WriteCommentModal";
import EditCommentModal from "./modals/EditCommentModal";
import Comment from "./Comment";
import { Tooltip } from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import iconStyles from "../../../utilities/styles/iconStyles.module.scss";
import styles from "../slideShow.module.scss";

const Comments = ({ currentSlide, currentVerification }) => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const viewChecklistState = useAppSelector(viewChecklist);
  const checklist = useAppSelector(selectChecklist);
  const [writeComment, setWriteComment] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<PhotoComment | null>(
    null
  );
  const [editComment, setEditComment] = useState<boolean>(false);
  const [comments, setComments] = useState<PhotoComment[]>();
  const [confirmDeleteComment, setConfirmDeleteComment] =
    useState<boolean>(false);

  const reduxComments =
    viewChecklistState.checklist?.verification[currentSlide]?.comments;

  // Setting the comments for the current verification from redux state.
  useEffect(() => {
    setComments(reduxComments);
  }, [currentVerification, checklist, viewChecklistState.isLoading]);

  const handleDeleteComment = (id: string | undefined) => {
    if (id) {
      dispatch(deletePhotoComment(id));
      setConfirmDeleteComment(false);
    }
  };

  const handleEditComment = (comment) => {
    setSelectedComment(comment);
    setEditComment(true);
  };

  const handleCloseModal = () => {
    setWriteComment(false);
    setEditComment(false);
  };

  return (
    <div className={styles.comments}>
      <div className="">
        <div className="d-flex justify-content-between mx-2 pb-1">
          <span className="fw-bold">
            {comments?.length === 0 ? "Inga kommentarer" : ""}
            {comments?.length && comments.length > 0
              ? `Kommentarer (${comments?.length} st)`
              : ""}
          </span>

          {isAdmin || isUserAdmin ? (
            <Tooltip arrow title="Lägg till ny kommentar">
              <AddCommentIcon
                className={iconStyles.commentIcon}
                onClick={() => {
                  setWriteComment(true);
                }}
              />
            </Tooltip>
          ) : null}
        </div>

        {comments && comments.length > 0 && (
          <div className="mt-3">
            {comments?.map((comment, index) => {
              return (
                <div key={index}>
                  <Comment
                    comment={comment}
                    handleDeleteComment={handleDeleteComment}
                    confirmDeleteComment={confirmDeleteComment}
                    index={index}
                    setConfirmDeleteComment={setConfirmDeleteComment}
                    handleEditComment={handleEditComment}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      {writeComment && (
        <WriteCommentModal
          currentVerification={currentVerification}
          handleCloseModal={handleCloseModal}
        />
      )}

      {editComment && (
        <EditCommentModal
          selectedComment={selectedComment}
          currentVerification={currentVerification}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Comments;
