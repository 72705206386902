import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CenterContainer } from "../../features/containers/centerContainer/CenterContainer";
import { registerUser, selectStatus } from "features/signUp/signUpSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { SignUpData } from "interfaces/formTypes";
import { useForm } from "hooks/useForm";
import { selectError } from "features/signUp/signUpSlice";
import SignUpSuccess from "./SignUpSuccess";
import { Alert, FloatingLabel, Form, Spinner } from "react-bootstrap";
import textStyles from "../../utilities/styles/textStyles.module.scss";

const SignUpForm: React.FunctionComponent = (props) => {
  const dispatch = useAppDispatch();
  const signUpDone = useAppSelector(selectStatus) === "done";
  const signUpLoading = useAppSelector(selectStatus) === "loading";
  const serverErrors = useAppSelector(selectError);
  const [sameEmailForAppleId, setSameEmailForAppleId] = useState<boolean>(true);

  // Using form hook for validation
  const {
    handleSubmit,
    handleChange,
    data: userData,
    errors,
  } = useForm<SignUpData>({
    validations: {
      signUpCode: {
        required: {
          value: true,
          message: "Ange en registreringskod",
        },
      },
      userName: {
        custom: {
          isValid: (value) => value.length > 2,
          message: "Ange ditt för- och efternam",
        },
      },
      email: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig e-postadress",
        },
      },
      phone: {
        custom: {
          isValid: (value) => parseInt(value, 10) > 17,
          message: "Ange ett giltigt telefonnummer",
        },
      },
      password: {
        required: {
          value: true,
          message: "Ange ett lösenord",
        },
      },
      confirmPassword: {
        custom: {
          isValid: (value) => {
            return value === userData.password;
          },
          message: "Lösenorden matchar inte",
        },
        required: {
          value: true,
          message: "Bekräfta lösenordet",
        },
      },
      appleId: {
        custom: {
          isValid: (value) => {
            return true;
          },
          message: "Ange ett Apple-ID",
        },
      },
      requestedCompanyName: {
        custom: {
          isValid: (value) => {
            return value !== "";
          },
          message: "Ange företaget du tillhör",
        },
      },
    },
    initialValues: {
      signUpCode: "",
      userName: "",
      email: "",
      phone: 0,
      password: "",
      confirmPassword: "",
      appleId: null,
      requestedCompanyName: "",
    },
    onSubmit: () => {
      dispatch(registerUser(userData));
    },
  });

  return (
    <CenterContainer>
      {signUpDone ? (
        <SignUpSuccess />
      ) : (
        <>
          <form onSubmit={handleSubmit} noValidate>
            <h1 className="h3 mb-4 fw-normal text-center">Skapa konto</h1>

            <FloatingLabel
              controlId="floatingSelect"
              label="Registreringskod"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Registreringskod"
                onChange={handleChange("signUpCode" || "")}
                className={`form-control mb-3 ${
                  errors.signUpCode && "is-invalid"
                }`}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="För- och efternamn"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="För- och efternamn"
                onChange={handleChange("userName" || "")}
                className={`form-control ${errors.userName && "is-invalid"}`}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="E-post"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="E-post"
                onChange={handleChange("email" || "")}
                className={`form-control ${errors.email && "is-invalid"}`}
              />
            </FloatingLabel>
            <Form.Check
              type={"checkbox"}
              id={`useDifferentAppleId`}
              label={`E-posten är samma som mitt Apple-ID`}
              onChange={() => {
                setSameEmailForAppleId(!sameEmailForAppleId);
              }}
              checked={sameEmailForAppleId}
              className="my-3"
            />
            {!sameEmailForAppleId && (
              <FloatingLabel
                controlId="floatingSelect"
                label="AppleId"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Apple-ID"
                  onChange={handleChange("appleId" || "")}
                  className={`form-control ${errors.appleId && "is-invalid"}`}
                />
              </FloatingLabel>
            )}

            <FloatingLabel
              controlId="floatingSelect"
              label="Telefon"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Telefon"
                onChange={handleChange("phone" || "")}
                className={`form-control ${errors.phone && "is-invalid"}`}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="Lösenord"
              className="mb-3"
            >
              <Form.Control
                type="password"
                placeholder="Lösenord"
                onChange={handleChange("password" || "")}
                className={`form-control ${errors.password && "is-invalid"}`}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="Bekräfta lösenord"
              className="mb-3"
            >
              <Form.Control
                type="password"
                placeholder="Bekräfta lösenord"
                onChange={handleChange("confirmPassword" || "")}
                className={`form-control ${
                  errors.confirmPassword && "is-invalid"
                }`}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="Företag"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Företag"
                onChange={handleChange("requestedCompanyName" || "")}
                className={`form-control ${
                  errors.requestedCompanyName && "is-invalid"
                }`}
              />
            </FloatingLabel>

            {/* Display validation errors if present */}
            {Object.keys(errors).length > 0 && (
              <Alert variant={"danger"}>
                <ul className="mb-0">
                  {Object.values(errors).map((error, index) => {
                    return (
                      <li className="m-0" key={index}>
                        {error}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
            {/* Display server errors if present */}
            {serverErrors && (
              <Alert variant={"danger"}>
                <p className="m-0">{serverErrors.message}</p>
              </Alert>
            )}

            <div className="d-flex align-items-center justify-content-between my-3">
              <button className="w-80 btn btn-md btn-primary" type="submit">
                {signUpLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Skapa konto"
                )}
              </button>
              <Link to="/reset" className={textStyles.link}>
                Glömt lösenord?
              </Link>
            </div>
            <Link to="/login" className={textStyles.link}>
              Har du redan ett konto? Logga in
            </Link>
          </form>
        </>
      )}
    </CenterContainer>
  );
};

export default SignUpForm;
