import { useAppSelector } from "app/hooks";
import { selectLoaded, selectOrder } from "../viewOrderSlice";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import styles from "../viewOrder.module.scss";
import { selectUserIsAdmin } from "features/auth/authSlice";
import { useState } from "react";
import EditCustomerData from "./editCustomerInfo/EditCustomerData";

const CustomerSummary = () => {
  const order = useAppSelector(selectOrder);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const orderLoaded = useAppSelector(selectLoaded);
  const [editModalShowing, setEditModealShowing] = useState<boolean>(false);

  return (
    <div className={styles.customerSummary}>
      <div className={textStyles.customerSummaryContainer}>
        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <p>Kund</p>
            {isAdmin && orderLoaded && (
              <EditCustomerData
                show={editModalShowing}
                setShow={setEditModealShowing}
              />
            )}
          </div>
          {order?.companyName && (
            <p className="fw-light">{order?.companyName}</p>
          )}
        </div>

        <div className="mb-4">
          <p>Adress</p>
          {order?.address ? (
            <div>
              <p className="fw-light">{order?.address} </p>
              <p className="fw-light">
                {order?.postnr}, {order?.postaddress}
              </p>
            </div>
          ) : (
            <span>Ingen adress sparad</span>
          )}
        </div>

        <p>
          Kundkod: <span>Test {order?.companyCode}</span>
        </p>
        <p>
          Kundtyp: <span> {order?.customerType}</span>
        </p>
        <p>
          Subkundtyp: <span>{order?.subCustomerType}</span>
        </p>
        <p>
          Avtal: <span>{order?.customerContract}</span>
        </p>
        <p>
          Kundordernummer: <span>{order?.customerOrderNumber}</span>
        </p>
      </div>
    </div>
  );
};

export default CustomerSummary;
