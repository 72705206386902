import { useAppSelector } from "app/hooks";
import { selectLoaded, selectUser } from "features/auth/authSlice";
import { UserRole } from "features/auth/types/user";
import PendingApproval from "pages/pendingApproval/PendingApproval";
import VerifyEmail from "pages/verifyEmail/VerifyEmail";
import { Redirect } from "react-router";

export interface IAuthRouteProps {
  notApprovedComponent?: React.ReactNode;
  authorizedRoles?: UserRole[];
}

const PendingVerificationRoute: React.FunctionComponent<IAuthRouteProps> = (
  props
) => {
  const { children, authorizedRoles } = props;
  const authIsLoaded = useAppSelector(selectLoaded);
  const user = useAppSelector(selectUser);
  const userIsUnVerified = user?.state === "CREATED";
  const userIsNotApproved = user?.state === "PENDING";

  const hasPermission = (): boolean => {
    if (!authorizedRoles) {
      return true;
    } else {
      return authorizedRoles.includes(user?.role ? user?.role : UserRole.NONE);
    }
  };

  if (authIsLoaded) {
    if (!user) {
      return <Redirect to="/login" />;
    } else if (userIsUnVerified) {
      //return <Redirect to="/verify" />;
      return <VerifyEmail name={""} />;
    } else if (userIsNotApproved) {
      //return <Redirect to="/pendingapproval" />;
      return <PendingApproval name={""} />;
    } else {
      return <Redirect to="/" />;
    }

    // else if (hasPermission()) {
    //   return <>{children}</>;
    // }
  } else {
    return <></>;
  }
};

export default PendingVerificationRoute;
