import React from "react";
import Comments from "./components/Comments";
import ImageFeatures from "./components/ImageFeatures";
import VideoFeatures from "./components/VideoFeatures";
import styles from "./slideShow.module.scss";
import CreatedBy from "./components/CreatedBy";

const Features = ({
  currentSlide,
  currentVerification,
  setCurrentSlide,
  handleSlideshowModalClose,
  nameHidden,
}) => {
  return (
    <div className={styles.featuresSection}>
      <CreatedBy
        currentVerification={currentVerification}
        nameHidden={nameHidden}
      />

      <ImageFeatures
        currentVerification={currentVerification}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        handleSlideshowModalClose={handleSlideshowModalClose}
      />

      <VideoFeatures
        currentVerification={currentVerification}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        handleSlideshowModalClose={handleSlideshowModalClose}
      />

      <Comments
        currentSlide={currentSlide}
        currentVerification={currentVerification}
      />
    </div>
  );
};

export default Features;
