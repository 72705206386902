import Login from "pages/login/Login";
import ResetPassword from "pages/resetPassword/ResetPassword";
import IRoute from "../interfaces/route";
import SignUp from "pages/signUp/SignUp";
import Users from "pages/users/Users";
import Orders from "pages/orders/Orders";
import VerifyEmail from "pages/verifyEmail/VerifyEmail";
import AddNewUserModal from "pages/users/addNewUser/AddNewUserModal";
import TimeManagement from "pages/timeManagement/TimeManagement";
import Companies from "pages/companies/Companies";
import ShowUser from "pages/users/showUser/ShowUser";
import ShowCompany from "pages/companies/showCompany/ShowCompany";
import ViewOrder from "pages/orders/viewOrder/ViewOrder";
import ViewChecklist from "pages/orders/viewChecklist/ViewChecklist";
import PendingApproval from "pages/pendingApproval/PendingApproval";
import Profile from "pages/profile/Profile";
import Dashboard from "pages/dashboard/Dashboard";
import { UserRole } from "features/auth/types/user";
// Nav item icons
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import GroupIcon from "@mui/icons-material/Group";
import StoreIcon from "@mui/icons-material/Store";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import InstallerStatistics from "pages/installerStatistics/InstallerStatistics";

const allRutes: IRoute[] = [
  {
    path: "/login",
    name: "Logga in",
    component: Login,
    exact: true,
    private: false,
    showInMainNav: false,
  },
  {
    path: "/reset",
    name: "Återställ lösenord",
    component: ResetPassword,
    exact: true,
    private: false,
    showInMainNav: false,
  },
  {
    path: "/signup",
    name: "Skapa konto",
    component: SignUp,
    exact: true,
    private: false,
    showInMainNav: false,
  },
  {
    path: "/verify",
    name: "Verifiera e-post",
    component: VerifyEmail,
    exact: true,
    private: true,
    type: "pendingVerification",
    showInMainNav: false,
  },
  {
    path: "/pendingapproval",
    name: "En admin måste godkänna dig",
    component: PendingApproval,
    exact: true,
    private: true,
    type: "pendingApproval",
    showInMainNav: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
    private: true,
    showInMainNav: true,
    navItemIcon: DashboardIcon,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.INTERNAL_INSTALLER,
      UserRole.EXTERNAL_INSTALLER,
      UserRole.EMPLOYEE,
      UserRole.PAYROLL,
      UserRole.USER_ADMIN,
      UserRole.NONE,
      UserRole.SALES,
    ],
  },
  {
    path: "/orders",
    name: "Ordrar",
    component: Orders,
    exact: true,
    private: true,
    navItemIcon: PlaylistAddCheckIcon,
    showInMainNav: true,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.USER_ADMIN,
      UserRole.EMPLOYEE,
      UserRole.INTERNAL_INSTALLER,
      UserRole.SALES,
    ],
  },
  {
    path: "/users",
    name: "Användare",
    component: Users,
    exact: true,
    private: true,
    roleAccess: "ADMIN",
    navItemIcon: GroupIcon,
    showInMainNav: true,
    authorizedRoles: [UserRole.ADMIN, UserRole.USER_ADMIN],
  },
  {
    path: "/companies",
    name: "Företag",
    component: Companies,
    exact: true,
    private: true,
    roleAccess: "ADMIN",
    navItemIcon: StoreIcon,
    showInMainNav: true,
    authorizedRoles: [UserRole.ADMIN, UserRole.USER_ADMIN],
  },
  {
    path: "/reports",
    name: "Frånvaro/Övertid",
    component: TimeManagement,
    exact: true,
    private: true,
    navItemIcon: AccessTimeIcon,
    showInMainNav: true,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.USER_ADMIN,
      UserRole.EMPLOYEE,
      UserRole.INTERNAL_INSTALLER,
      UserRole.PAYROLL,
      UserRole.SALES,
    ],
  },
  {
    path: "/installer-statistics",
    name: "Statistik",
    component: InstallerStatistics,
    exact: true,
    private: true,
    navItemIcon: BarChartIcon,
    showInMainNav: true,
    authorizedRoles: [UserRole.ADMIN, UserRole.USER_ADMIN],
  },
  {
    path: "/orders/view/:id",
    name: "Visa order",
    component: ViewOrder,
    exact: true,
    private: true,
    navItemIcon: PlaylistAddCheckIcon,
    showInMainNav: false,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.USER_ADMIN,
      UserRole.EMPLOYEE,
      UserRole.INTERNAL_INSTALLER,
      UserRole.SALES,
    ],
  },
  {
    path: "/orders/view/:id/checklist/:checklistId",
    name: "Visa checklista",
    component: ViewChecklist,
    exact: true,
    private: true,
    navItemIcon: PlaylistAddCheckIcon,
    showInMainNav: false,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.USER_ADMIN,
      UserRole.EMPLOYEE,
      UserRole.INTERNAL_INSTALLER,
      UserRole.SALES,
    ],
  },
  {
    path: "/users/add",
    name: "Lägg till en ny användare",
    component: AddNewUserModal,
    exact: true,
    private: true,
    roleAccess: "ADMIN",
    showInMainNav: false,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.USER_ADMIN,
      UserRole.EMPLOYEE,
      UserRole.INTERNAL_INSTALLER,
      UserRole.SALES,
    ],
  },
  {
    path: "/users/view/:id",
    name: "Användare",
    component: ShowUser,
    exact: false,
    private: true,
    roleAccess: "ADMIN",
    showInMainNav: false,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.USER_ADMIN,
      UserRole.EMPLOYEE,
      UserRole.INTERNAL_INSTALLER,
      UserRole.SALES,
    ],
  },
  {
    path: "/companies/view/:id",
    name: "Företag",
    component: ShowCompany,
    exact: false,
    private: true,
    roleAccess: "ADMIN",
    showInMainNav: false,
    authorizedRoles: [
      UserRole.ADMIN,
      UserRole.USER_ADMIN,
      UserRole.EMPLOYEE,
      UserRole.INTERNAL_INSTALLER,
      UserRole.SALES,
    ],
  },
  {
    path: "/profile",
    name: "Profil",
    component: Profile,
    showInMainNav: false,
    exact: true,
    private: true,
  },
];

const routes: IRoute[] = [...allRutes];

export default routes;
