import React, { FC } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import iconStyles from "utilities/styles/iconStyles.module.scss";

interface RatingInformationProps {
  onClick: React.MouseEventHandler;
}

const RatingInformation: FC<RatingInformationProps> = (props) => {
  return (
    <div className="d-flex justify-content-between mb-3">
      <p className="fw-bold">Betyg</p>

      <Tooltip
        arrow
        title={
          <>
            <p className="fw-bold fs-6 mb-1">Betygsättning av checklistan</p>
            <p className="mb-4">
              Betygsätt alla moment med 1-5 där 1 är sämsta och 5 innebär bästa
              möjliga betyg.
            </p>
            <p className="mb-2">
              <span className="fw-bold">Installation: </span> Hur väl är
              installationen genomförd?
            </p>
            <p className="mb-2">
              <span className="fw-bold">Bilder: </span> Hur väl har
              installationen dokumenterats med bilder och film?
            </p>
            <p className="mb-2">
              <span className="fw-bold">Kommentarer: </span> Har relevanta
              kommenter skrivits till installationen?
            </p>
            <p className="mb-2">
              <span className="fw-bold">Genomsnitt: </span>
              Genomsnittsbetyget räknas ut automatiskt baserat på de satta
              betygen och blir ett övrgripande betyg för checklistan.
            </p>
            <p className="mt-4">
              <span className="fw-bold">OBS!</span> Endast admins &
              användaradmins har möjlighet att sätta betyg.
            </p>
          </>
        }
      >
        <EditOutlinedIcon
          onClick={props.onClick}
          className={iconStyles.editIcon}
        />
        {/* <HelpOutlineOutlinedIcon
          sx={{
            color: "#161616",
            opacity: 0.4,
            position: "relative",
            left: 8,
          }} 
        />*/}
      </Tooltip>
    </div>
  );
};

export default RatingInformation;
