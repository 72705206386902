import { Form, Modal, FloatingLabel, Row, Col } from "react-bootstrap";
import Errors from "./Errors";
import { Button } from "@mui/material";

const FormComponent = (props) => {
  const { handleSubmit, handleChange, errors, handleClose, serverError } =
    props;

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Skapa nytt företag</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <FloatingLabel
            controlId="floatingSelect"
            label="Leverantörskod"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Leverantörskod"
              onChange={handleChange("uecode" || "")}
              className={`${errors.uecode && "is-invalid"}`}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingSelect"
            label="Företagsnamn"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Företagsnamn"
              onChange={handleChange("namn" || "")}
              className={`${errors.namn && "is-invalid"}`}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingSelect"
            label="Orgnummer"
            className="mb-3"
          >
            <Form.Control
              type="number"
              placeholder="Orgnummer"
              onChange={handleChange("orgnr" || "")}
              className={`${errors.orgnr && "is-invalid"}`}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingSelect"
            label="Telefonnummer"
            className="mb-3"
          >
            <Form.Control
              type="number"
              placeholder="Telefon"
              onChange={handleChange("phone" || "")}
              className={`${errors.phone && "is-invalid"}`}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingSelect"
            label="E-post"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="Orgnummer"
              onChange={handleChange("mail" || "")}
              className={`${errors.mail && "is-invalid"}`}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingSelect"
            label="Adress"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Adress"
              onChange={handleChange("address" || "")}
              className={`${errors.address && "is-invalid"}`}
            />
          </FloatingLabel>

          <Row>
            <Col sm={6} md={8}>
              <FloatingLabel
                controlId="floatingSelect"
                label="Postnummer"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Postnummer"
                  onChange={handleChange("postnr" || "")}
                  className={`${errors.postnr && "is-invalid"}`}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingSelect"
                label="Ort"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Ort"
                  onChange={handleChange("postaddress" || "")}
                  className={`${errors.postaddress && "is-invalid"}`}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <FloatingLabel
            controlId="floatingSelect"
            label="Timpris"
            className="mb-3"
          >
            <Form.Control
              type="number"
              placeholder="Timpris"
              onChange={handleChange("rate" || "")}
              className={`${errors.rate && "is-invalid"}`}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingSelect"
            label="Kommentar"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Kommentar"
              onChange={handleChange("comment" || "")}
              className={`${errors.comment && "is-invalid"}`}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingSelect"
            label="Kompetens"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Kompetens"
              onChange={handleChange("competence" || "")}
              className={`${errors.competence && "is-invalid"}`}
            />
          </FloatingLabel>
        </>
        <Errors errors={errors} serverError={serverError} />
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="contained" className="me-2">Skapa företag</Button>
        <Button variant="contained" color="error" onClick={() => handleClose()}>
          Avbryt
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default FormComponent;
