import axios from "axios";
import { RootState } from "app/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { Rating, SetRating } from "models/Rating";
import { AdminComment } from "models/AdminComment";

interface RatingState {
  ratingLoading: boolean;
  error: GenericError | undefined | null;
  setRatingError: GenericError | undefined | null;
  ratings: Rating;
  commentWasSubmitted: boolean;
  ratingWasSubmitted: boolean;
}

const initialState: RatingState = {
  ratingLoading: false,
  error: null,
  setRatingError: null,
  ratings: {
    checklistId: undefined,
    photoAndVideoDocumentation: null,
    comments: null,
    installation: null,
    average: null,
    adminCommentNew: {
      checklistId: undefined,
      comment: "",
      createdAt: "",
      createdBy: {
        userId: "",
        userName: "",
      },
    },
  },
  commentWasSubmitted: false,
  ratingWasSubmitted: false,
};

export const setRating = createAsyncThunk<
  SetRating,
  SetRating,
  { rejectValue: GenericError; state: RootState }
>("rating/setRating", async (rating, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  dispatch(updateRatings(rating));
  console.log("set rating action");
  const response = await axios
    .post<SetRating>(`${BE_URL}/checklist/set-rating`, rating, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      res.data = rating;
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "An unknown error has occurred",
        status: e.response.status,
      });
    });

  return response;
});

export const setAdminComment = createAsyncThunk<
  AdminComment,
  AdminComment,
  { rejectValue: GenericError; state: RootState }
>("rating/setAdminComment", async (adminComment, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<AdminComment>(`${BE_URL}/checklist/set-admin-comment`, adminComment, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "An unknown error occurred",
        status: e.response.status,
      });
    });

  return response;
});

export const ratingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {
    // Populate ratings in ratingsState with ratings from viewChecklistState
    updateRatingsFromChecklist: (state, action) => {
      state.ratings = action.payload;
      state.commentWasSubmitted = false;
    },
    resetRatings: () => {
      console.log("Reset rating reducer called");
      //state.commentWasSubmitted = false;
      return initialState;
    },
    updateRatings: (state, action: PayloadAction<SetRating>) => {
      console.log(`Set new rating value: ${action.payload.installation}`);
      state.ratings.installation = action.payload.installation;
      state.ratings.comments = action.payload.comments;
      state.ratings.photoAndVideoDocumentation =
        action.payload.photoAndVideoDocumentation;
    },
    resetRatingWasSubmitted: (state) => {
      state.ratingWasSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // set rating
      .addCase(setRating.pending, (state) => {
        state.ratingLoading = true;
      })
      .addCase(setRating.rejected, (state, action) => {
        state.ratingLoading = false;
        state.setRatingError = action.payload;
      })
      .addCase(setRating.fulfilled, (state, action) => {
        state.ratingLoading = false;
        state.ratings.comments = action.payload.comments;
        state.ratings.installation = action.payload.installation;
        state.ratingWasSubmitted = true;
        state.ratings.photoAndVideoDocumentation =
          action.payload.photoAndVideoDocumentation;
      })

      // set adminComment
      .addCase(setAdminComment.pending, (state) => {
        state.ratingLoading = true;
      })
      .addCase(setAdminComment.fulfilled, (state, action) => {
        state.ratingLoading = false;
        state.commentWasSubmitted = true;
        state.ratings.adminCommentNew = action.payload;
      })
      .addCase(setAdminComment.rejected, (state, action) => {
        state.ratingLoading = false;
        state.error = action.payload;
        state.commentWasSubmitted = true; // Submit not successful but update state for toastify function
      });
  },
});

export const {
  updateRatingsFromChecklist,
  resetRatings,
  updateRatings,
  resetRatingWasSubmitted,
} = ratingSlice.actions;
export const selectRatingState = (state: RootState) => state.rating;
export default ratingSlice.reducer;
