import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectChecklist,
  setIsInvalidatedChecklist,
} from "../viewChecklistSlice";
import { Tooltip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";

const ChecklistNavigation = () => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const allChecklists = checklist?.allChecklists;
  const currentChecklistId = checklist?.id;
  const totalNumberOfChecklists = allChecklists?.length;
  const ordernr = checklist?.partOfOrder;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [prevIndex, setPrevIndex] = useState<number>(0);
  const [nextIndex, setNextIndex] = useState<number>(0);
  const [prevId, setPrevId] = useState("");
  const [nextId, setNextId] = useState("");

  useEffect(() => {
    // set current index
    if (allChecklists) {
      const index = allChecklists.findIndex((id) => id === currentChecklistId);
      setCurrentIndex(index);
    }

    // set previous and next index
    setPrevIndex(currentIndex - 1);
    setNextIndex(currentIndex + 1);

    // set previous and next id (url)
    if (allChecklists) {
      setPrevId(allChecklists[prevIndex]);
      setNextId(allChecklists[nextIndex]);
    }
  });

  // mark checklist as invalidated (will force update of MainContainer data)
  const handleClick = () => {
    dispatch(setIsInvalidatedChecklist());
  };

  if (totalNumberOfChecklists === 1) {
    return <></>;
  } else
    return (
      <div className="d-flex flex-column">
        <div className={iconStyles.arrows}>
          {currentIndex === 0 ? (
            <>
              <ArrowBackIcon className={iconStyles.disabled} />
            </>
          ) : (
            <>
              <Tooltip arrow title={<>Föregående checklista</>}>
                <Link to={`/orders/view/${ordernr}/checklist/${prevId}`}>
                  <ArrowBackIcon onClick={handleClick} />
                </Link>
              </Tooltip>
            </>
          )}

          <span>|</span>

          {nextIndex === totalNumberOfChecklists ? (
            <>
              <ArrowForwardIcon className={iconStyles.disabled} />
            </>
          ) : (
            <Tooltip arrow title={<>Nästa checklista</>}>
              <Link to={`/orders/view/${ordernr}/checklist/${nextId}`}>
                <ArrowForwardIcon onClick={handleClick} />
              </Link>
            </Tooltip>
          )}
        </div>

        {currentIndex !== undefined && (
          <p className="text-center">
            Visar checklista {currentIndex + 1} av {totalNumberOfChecklists}
          </p>
        )}
      </div>
    );
};

export default ChecklistNavigation;
