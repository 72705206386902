import format from "date-fns/format";
import { OrderStatus } from "models/Order";

export const getUpdatedAtFormat = (date: string | null): string => {
  if (date !== null) {
    // eslint-disable-next-line no-useless-escape
    try {
      const dateString = format(new Date(date), "yyyy-MM-dd");
      const timeString = format(new Date(date), "HH:mm");
      return dateString + " " + timeString;
    } catch (e) {
      return "";
    }
  } else {
    return "";
  }
};

export enum FilterOrderStatus {
  ALL = "ALL",
  PENDING = "PENDING",
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
}

export enum SortType {
  NONE = "NONE",
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortBy {
  NONE = "NONE",
  ORDER_NUMBER = "ORDER_NUMBER",
  COMPANY = "COMPANY",
  ORDER_STATUS = "ORDER_STATUS",
  ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED",
  LAST_UPDATE = "LAST_UPDATE",
}

export const getOrderStatusString = (orderStatus: OrderStatus): string => {
  switch (Number(OrderStatus[orderStatus])) {
    case 4:
      return "4";
    case 3:
      return "4";
    case 2:
      return "X";
    case 1:
      return "2";
    case 0:
      return "Okänd";
    default:
      return "Okänd";
  }
};

export const getOrderStatusLongString = (orderStatus: OrderStatus): string => {
  switch (Number(OrderStatus[orderStatus])) {
    case 4:
      return "4";
    case 3:
      return "Slutförd (Status 4)";
    case 2:
      return "Ej slutförd (Status X)";
    case 1:
      return "Pågående (Status 2)";
    case 0:
      return "Okänd";
    default:
      return "Okänd";
  }
};
