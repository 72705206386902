import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";
import textStyles from "../../../../../utilities/styles/textStyles.module.scss";
import styles from "../../../viewChecklist/checklist.module.scss";

const InternalComment = (props) => {
  const {
    comment,
    edit,
    setDeleteIndex,
    setConfirmDelete,
    deleteIndex,
    index,
    confirmDelete,
    handleDelete,
    editIndex,
    setEdit,
    setEditIndex,
    handleChange,
    confirmChange,
    commentBeingEdited,
    nameHidden,
  } = props;

  return (
    <div className={styles.editCommentWrapper}>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-center w-100 flex-column me-3">
          {edit && editIndex === index ? (
            <Form.Control
              key={index}
              type="text"
              value={comment.message}
              onChange={(e) => {
                handleChange(e.target.value, comment.id, index);
              }}
            />
          ) : (
            <>
              <p>{comment.message}</p>
              <p className={`${textStyles.smallText} mb-0`}>
                {comment.postedAt.substring(0, 16)}
                {!nameHidden && (
                  <>
                    {" av "}
                    <Tooltip arrow title="Gå till användarprofilen">
                      <Link to={`/users/view/${comment.poster.id}`}>
                        <span className={textStyles.smallText}>
                          {comment.poster.userName}
                        </span>
                      </Link>
                    </Tooltip>
                  </>
                )}
              </p>
            </>
          )}
        </div>

        {edit && editIndex === index ? (
          <div className="d-flex flex-column justify-content-between">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setEdit(false);
              }}
            >
              Avbryt
            </Button>

            <Button
              disabled={commentBeingEdited === ""}
              variant="contained"
              className="mt-2"
              onClick={() => {
                confirmChange(comment.id);
              }}
            >
              Spara
            </Button>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-between">
            <Tooltip arrow title="Radera kommentar">
              <ClearIcon
                className={iconStyles.clearIcon}
                onClick={() => {
                  setDeleteIndex(index);
                  setConfirmDelete(true);
                }}
              />
            </Tooltip>

            <Tooltip arrow title="Redigera kommentar">
              <EditIcon
                className={iconStyles.editIconOrange}
                onClick={() => {
                  setEdit(true);
                  setEditIndex(index);
                }}
              />
            </Tooltip>
          </div>
        )}

        {/* CONFIRM DELETE */}
        {deleteIndex === index && confirmDelete && (
          <div className={styles.confirmDelete}>
            <p className="fw-bold">
              Är du säker på att du vill radera kommentaren?
            </p>

            <div>
              <Button
                color="error"
                variant="contained"
                onClick={() => handleDelete(comment.id)}
              >
                Radera
              </Button>
              <Button
                variant="contained"
                onClick={() => setConfirmDelete(false)}
              >
                Ångra
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InternalComment;
