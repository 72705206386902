import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios, { AxiosResponse } from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { Checklist } from "models/Checklist";
import { MediaEntity } from "models/MediaEntity";
import { Signature } from "models/Signature";
import { MediaThumbnail } from "models/MediaThumbnail";
import { Order } from "models/Order";
import { setAllImagesLoaded } from "../viewChecklist/viewChecklistSlice";
import { editCustomerDetails } from "./components/editCustomerInfo/editCustomerSlice";

export enum LoadingState {
  IDLE,
  LOADING,
  SUCCESS,
  FAIL,
}

interface ViewOrderState {
  order: Order | null;
  isLoading: boolean;
  isLoaded: boolean;
  addexternalLoading: boolean;
  deleteExternalLoading: boolean;
  externalUserDeleted: boolean;
  isInvalidated: boolean;
  error: GenericError | undefined | null;
  mediaEntities: MediaEntity[];
  signatureData: Signature[];
  signaturesLoading: boolean;
  mediaUrlsThumb: MediaThumbnail[] | null;
  thumbnailsLoading: boolean;
  fullMediaLoading: boolean;
  orderStatusUpdating: boolean;
  orderReportLoading: boolean;
  orderReportLoaded: boolean;
  reportToken: string | null;
  fullMediaLoaded: boolean;
  PDFisLoading: boolean;
  lastViewedChecklist: string | null;
  syncToPyramidState: LoadingState;
}

const initialState: ViewOrderState = {
  order: null,
  isLoading: false,
  addexternalLoading: false,
  deleteExternalLoading: false,
  externalUserDeleted: false,
  isLoaded: false,
  isInvalidated: false,
  error: null,
  mediaEntities: [],
  signatureData: [],
  signaturesLoading: false,
  mediaUrlsThumb: null,
  thumbnailsLoading: false,
  fullMediaLoading: false,
  orderStatusUpdating: false,
  orderReportLoading: false,
  orderReportLoaded: false,
  reportToken: null,
  fullMediaLoaded: false,
  PDFisLoading: false,
  lastViewedChecklist: null,
  syncToPyramidState: LoadingState.IDLE,
};

export const getOrder = createAsyncThunk<
  Order,
  string,
  { rejectValue: GenericError; state: RootState }
>("viewOrder/getOrder", async (orderId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<Order>(`${BE_URL}/order/${orderId}`, {
      data: {},
      headers: {
        token: state.auth.token || "",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Ordernumret existerar inte.",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as Order;
});

export const toggleIsReferenceOrder = createAsyncThunk<
  { orderId: string; isReferenceOrder: boolean },
  { orderId: string; isReferenceOrder: boolean },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/toggleIsReferenceOrder", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const dispatch = thunkApi.dispatch;

  const response = await axios
    .post<{ orderId: string; isReferenceOrder: boolean }>(
      `${BE_URL}/order/setasreferenceorder`,
      params,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then((res) => {
      return params;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta sätta status på order",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });

  // Invalidate current orderdata
  dispatch(setIsInvalidated);
  return response;
});

export const setFollowUpDone = createAsyncThunk<
  { orderId: string; followUpDone: boolean },
  { orderId: string; followUpDone: boolean },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/setFollowUpDone", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const dispatch = thunkApi.dispatch;

  const response = await axios
    .post<{ orderId: string; followUpDone: boolean }>(
      `${BE_URL}/order/setfollowupdone`,
      params,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then((res) => {
      return params;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta sätta status på order",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });

  // Invalidate current orderdata
  dispatch(setIsInvalidated);
  return response;
});

export const addExternalUser = createAsyncThunk<
  string,
  { userId: string; orderId: string },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/addExternalUser", async (paramsObject, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = paramsObject;

  const response = await axios
    .post<string>(`${BE_URL}/order/assign`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta order.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

export const addExternalCompany = createAsyncThunk<
  string,
  { companyId: string; orderId: string },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/addExternalCompany", async (paramsObject, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = paramsObject;

  const response = await axios
    .post<string>(`${BE_URL}/order/assigncompany`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta order.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

export const removeExternalCompany = createAsyncThunk<
  string,
  { companyId: string; orderId: string },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/removeExternalCompany", async (paramsObject, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = paramsObject;

  const response = await axios
    .delete<string>(`${BE_URL}/order/assigncompany`, {
      data: body,
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta order.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

export const removeExternalUser = createAsyncThunk<
  string,
  { userId: string; orderId: string },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/removeExternalUser", async (paramsObject, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = paramsObject;

  const response = await axios
    .delete<string>(`${BE_URL}/order/assign`, {
      data: body,
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta order.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

// For exporting images to PDF
export const fetchAllImages = createAsyncThunk<
  MediaEntity[],
  Checklist[],
  { rejectValue: GenericError; state: RootState }
>("viewOrder/fetchAllImages", async (checklists, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();
  const allRequests: Promise<AxiosResponse<Blob>>[] = [];
  const returnResponses: MediaEntity[] = [];

  checklists.forEach((checklist) => {
    checklist.verification.forEach((item) => {
      if (item.type === 1 && item.excludedFromPdf === false) {
        const id = item.id;
        let request = axios.get<Blob>(`${BE_URL}/bin/${id}`, {
          responseType: "blob",
          headers: {
            token: state.auth.token || "",
          },
          data: {
            checklistId: checklist.id,
          },
        });

        allRequests.push(request);
      }
    });
  });

  const allResponses = await axios
    .all(allRequests)
    .then(
      axios.spread((...responses) => {
        responses.forEach((res) => {
          const i = res.config.url?.lastIndexOf("/");
          let id;
          if (i) {
            id = res.config.url?.substring(i + 1);
          }

          const mediaType = res.data.type;
          const localImageUrl = window.URL.createObjectURL(
            new Blob([res.data])
          );
          const returnObj = {
            id: id,
            imgUrl: localImageUrl,
            mediaType: mediaType,
            checklist: JSON.parse(res.config.data).checklistId || null,
            isLoading: false,
            compressedImage: undefined,
          };

          returnResponses.push(returnObj);
        });

        thunkApi.dispatch(setAllImagesLoaded(true));
        return returnResponses;
      })
    )
    .catch((e) => {
      console.log("error: ", e);

      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta filer.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Något annat gick fel.",
        });
      }
    });
  return allResponses;
});

export const fetchThumbnailMedia = createAsyncThunk<
  MediaThumbnail[],
  Checklist,
  { rejectValue: GenericError; state: RootState }
>("viewOrder/fetchThumbnailMedia", async (checklist, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const mediaIds: string[] = [];
  const allRequests: Promise<AxiosResponse<Blob>>[] = [];
  const returnResponses: { imgUrl: string; id: string; mediaType: string }[] =
    [];

  checklist.verification.forEach((item) => {
    // console.log("item: ", item);
    const id = item.id;
    let request = axios.get<Blob>(`${BE_URL}/bin/thumb/${id}`, {
      responseType: "blob",
      headers: {
        token: state.auth.token || "",
      },
    });

    allRequests.push(request);
  });

  const allResponses = await axios
    .all(allRequests)
    .then(
      axios.spread((...responses) => {
        responses.forEach((res) => {
          const i = res.config.url?.lastIndexOf("/");
          let id;
          if (i) {
            id = res.config.url?.substring(i + 1);
          }

          const mediaType = res.data.type;
          const localImageUrl = window.URL.createObjectURL(
            new Blob([res.data])
          );
          const returnObj = {
            id: id || "",
            imgUrl: localImageUrl || "",
            mediaType: mediaType || "",
          };
          returnResponses.push(returnObj);
        });

        return returnResponses;
      })
    )
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta thumbnails.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta thumbnails.",
        });
      }
    });
  return allResponses;
});

export const fetchFullMedia = createAsyncThunk<
  MediaEntity,
  string,
  { rejectValue: GenericError; state: RootState }
>("viewOrder/fetchFullMedia", async (mediaId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  let request = axios.get<Blob>(`${BE_URL}/bin/${mediaId}`, {
    responseType: "blob",
  });

  // Add pending mediaEntity to media
  const pendingEntity: MediaEntity = {
    id: mediaId,
    isLoading: true,
    imgUrl: "",
    mediaType: "",
    compressedImage: undefined,
  };
  dispatch(addPendingMediaEntity(pendingEntity));

  const response = request
    .then((res) => {
      const mediaType = res.data.type;
      const localImageUrl = window.URL.createObjectURL(
        new Blob([res.data], { type: mediaType })
      );

      const returnObj = {
        id: mediaId,
        imgUrl: localImageUrl,
        mediaType: mediaType,
        isLoading: false,
        compressedImage: undefined,
      };

      return returnObj;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta fil.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Något gick fel.",
        });
      }
    });
  return response;
});

// Get signature image and save in Redux
export const getSignatureImage = createAsyncThunk<
  Signature,
  string,
  { rejectValue: GenericError; state: RootState }
>("viewOrder/getSignatureImage", async (customerApprovalId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  let request = axios.get<Blob>(
    `${BE_URL}/approve/customersignature/${customerApprovalId}`,
    {
      responseType: "blob",
      headers: {
        token: state.auth.token || "",
      },
    }
  );

  const response = request
    .then((res) => {
      const mediaType = res.data.type;
      const localImageUrl = window.URL.createObjectURL(
        new Blob([res.data], { type: mediaType })
      );

      const signature: Signature = {
        imgUrl: localImageUrl,
        id: customerApprovalId,
      };

      return signature as Signature;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta fil.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta fil.",
        });
      }
    });
  return response;
});

export const updateOrderStatus = createAsyncThunk<
  string,
  { orderId: string; state: string; adminComment: string },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/updateOrderStatus", async (data, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<string>(`${BE_URL}/order/admin/approve`, data, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Ordernumret existerar inte.",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const generateOrderReport = createAsyncThunk<
  { reportToken: string },
  { orderId: string; recpEmail: string; checklistIds: string[] },
  { rejectValue: GenericError; state: RootState }
>("viewOrder/generateOrderReport", async (data, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<{ reportToken: string }>(`${BE_URL}/order/report`, data, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Ordernumret existerar inte.",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const syncToPyramid = createAsyncThunk<
  { reportToken: string },
  string,
  { rejectValue: GenericError; state: RootState }
>("viewOrder/syncToPyramid", async (orderId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<{ reportToken: string }>(
      `${BE_URL}/pyramid/sync-to-pyramid/${orderId}`,
      null,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404 || e.response.status === 500) {
        return thunkApi.rejectWithValue({
          message: "Ordern kunde inte synkas till pyramid",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const viewOrderSlice = createSlice({
  name: "viewOrder",
  initialState,
  reducers: {
    addMediaEntity: (state, action: PayloadAction<MediaEntity>) => {
      state.mediaEntities.push(action.payload);
      state.isInvalidated = true;
    },
    deleteMediaEntity: (state, action: PayloadAction<string>) => {
      const mediaEntities = state.mediaEntities.filter((mediaEntity) => {
        return mediaEntity.id !== action.payload;
      });
      state.mediaEntities = mediaEntities;
      const thumbnails = state.mediaUrlsThumb?.filter((mediaEntity) => {
        return mediaEntity.id !== action.payload;
      });

      if (thumbnails) {
        state.mediaUrlsThumb = thumbnails;
      }
      state.isInvalidated = true;
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsInvalidated: (state) => {
      state.isInvalidated = true;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetExternalUserDeleted: (state) => {
      state.externalUserDeleted = false;
    },
    resetThumbnailUrls: (state) => {
      state.mediaUrlsThumb = null;
    },
    resetFullMediaUrls: (state) => {
      state.mediaEntities = [];
    },
    resetOrderReportDone: (state) => {
      state.orderReportLoaded = false;
    },
    addPendingMediaEntity: (state, action: PayloadAction<MediaEntity>) => {
      let entityExists = state.mediaEntities.some((entity) => {
        return entity.id === action.payload.id;
      });
      if (!entityExists) {
        state.mediaEntities.push(action.payload);
      }
    },
    setPDFisLoading: (state) => {
      state.PDFisLoading = false;
    },
    setLastViewedChecklist: (state, action: PayloadAction<string | null>) => {
      state.lastViewedChecklist = action.payload;
    },
    resetPyramidSyncLoadingState: (state) => {
      state.syncToPyramidState = LoadingState.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.order = action.payload;
        state.isInvalidated = false;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(addExternalCompany.pending, (state) => {
        state.error = null;
        state.addexternalLoading = true;
      })
      .addCase(addExternalCompany.fulfilled, (state, action) => {
        state.error = null;
        state.isInvalidated = true;
        state.addexternalLoading = false;
      })
      .addCase(addExternalCompany.rejected, (state, action) => {
        state.error = null;
        state.isInvalidated = true;
        state.addexternalLoading = false;
      })
      .addCase(removeExternalCompany.pending, (state) => {
        state.error = null;
        state.deleteExternalLoading = true;
      })
      .addCase(removeExternalCompany.fulfilled, (state, action) => {
        state.error = null;
        state.deleteExternalLoading = false;
        state.externalUserDeleted = true;
        state.isInvalidated = true;
      })
      .addCase(removeExternalCompany.rejected, (state, action) => {
        state.error = null;
        state.deleteExternalLoading = false;
        state.externalUserDeleted = true;
      })
      .addCase(fetchFullMedia.pending, (state) => {
        state.fullMediaLoading = true;
      })
      .addCase(fetchFullMedia.fulfilled, (state, action) => {
        state.fullMediaLoading = false;

        let existingEntityIndex = state.mediaEntities.findIndex((entity) => {
          return entity.id === action.payload.id;
        });
        if (existingEntityIndex < 0) {
          state.mediaEntities.push(action.payload);
        } else {
          state.mediaEntities[existingEntityIndex] = action.payload;
        }
      })
      .addCase(fetchFullMedia.rejected, (state, action) => {
        state.error = action.payload || null;
        state.fullMediaLoading = false;
      })
      .addCase(getSignatureImage.pending, (state, action) => {
        state.signaturesLoading = true;
      })
      .addCase(getSignatureImage.fulfilled, (state, action) => {
        state.signaturesLoading = false;
        state.signatureData.push(action.payload);
      })
      .addCase(getSignatureImage.rejected, (state, action) => {
        state.error = action.payload || null;
      })
      .addCase(fetchThumbnailMedia.pending, (state) => {
        state.thumbnailsLoading = true;
      })
      .addCase(fetchThumbnailMedia.fulfilled, (state, action) => {
        state.mediaUrlsThumb = action.payload;
        state.thumbnailsLoading = false;
      })
      .addCase(fetchThumbnailMedia.rejected, (state, action) => {
        state.error = action.payload || null;
      })

      .addCase(fetchAllImages.rejected, (state, action) => {
        state.error = action.payload || null;
        state.fullMediaLoaded = true;
        state.PDFisLoading = false;
      })
      .addCase(fetchAllImages.pending, (state, action) => {
        state.fullMediaLoaded = false;
        state.PDFisLoading = true;
      })
      .addCase(fetchAllImages.fulfilled, (state, action) => {
        state.fullMediaLoaded = true;
        state.mediaEntities = action.payload;
        state.PDFisLoading = false;
      })

      .addCase(updateOrderStatus.pending, (state) => {
        state.orderStatusUpdating = true;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.orderStatusUpdating = false;
        state.isInvalidated = true;
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.error = action.payload || null;
        state.orderStatusUpdating = false;
      })
      .addCase(generateOrderReport.pending, (state) => {
        state.orderReportLoading = true;
      })
      .addCase(generateOrderReport.fulfilled, (state, action) => {
        state.orderReportLoading = false;
        state.orderReportLoaded = true;
        state.reportToken = action.payload.reportToken;
      })
      .addCase(generateOrderReport.rejected, (state, action) => {
        state.error = action.payload || null;
        state.orderReportLoading = false;
      })
      .addCase(toggleIsReferenceOrder.pending, (state) => {})
      .addCase(toggleIsReferenceOrder.fulfilled, (state, action) => {
        if (state.order) {
          state.order.referenceOrder = action.payload.isReferenceOrder;
        }
      })
      .addCase(toggleIsReferenceOrder.rejected, (state) => {})
      .addCase(setFollowUpDone.fulfilled, (state, action) => {
        if (state.order) {
          state.order.customerFollowUp = action.payload.followUpDone;
        }
      })
      .addCase(editCustomerDetails.fulfilled, (state, action) => {
        const newCustomerDetails = action.payload;

        if (state.order) {
          if (newCustomerDetails.address) {
            state.order.address = newCustomerDetails.address;
          }
          if (newCustomerDetails.name) {
            state.order.companyName = newCustomerDetails.name;
          }
          if (newCustomerDetails.postAddress) {
            state.order.postaddress = newCustomerDetails.postAddress;
          }
          if (newCustomerDetails.postalCode) {
            state.order.postnr = newCustomerDetails.postalCode;
          }
        }
      })
      .addCase(syncToPyramid.pending, (state) => {
        state.syncToPyramidState = LoadingState.LOADING;
      })
      .addCase(syncToPyramid.fulfilled, (state, action) => {
        state.syncToPyramidState = LoadingState.SUCCESS;
      })
      .addCase(syncToPyramid.rejected, (state, action) => {
        state.syncToPyramidState = LoadingState.FAIL;
      });
  },
});

export const {
  setIsLoading,
  setIsInvalidated,
  setOrder,
  resetError,
  resetExternalUserDeleted,
  resetThumbnailUrls,
  resetFullMediaUrls,
  resetOrderReportDone,
  addPendingMediaEntity,
  addMediaEntity,
  deleteMediaEntity,
  setLastViewedChecklist,
  resetPyramidSyncLoadingState,
} = viewOrderSlice.actions;

export const selectLastViewedChecklist = (state: RootState) =>
  state.viewOrder.lastViewedChecklist;
export const selectOrder = (state: RootState) => state.viewOrder.order;
export const selectViewOrder = (state: RootState) => state.viewOrder;
export const selectMediaEntities = (state: RootState) =>
  state.viewOrder.mediaEntities;
export const selectError = (state: RootState) => state.viewOrder.error;
export const selectLoading = (state: RootState) => state.viewOrder.isLoading;
export const selectDeleteExternalLoading = (state: RootState) =>
  state.viewOrder.deleteExternalLoading;
export const selectAddExternalLoading = (state: RootState) =>
  state.viewOrder.addexternalLoading;
export const selectExternalUserDeleted = (state: RootState) =>
  state.viewOrder.externalUserDeleted;
export const selectLoaded = (state: RootState) => state.viewOrder.isLoaded;
export const selectIsInvalidated = (state: RootState) =>
  state.viewOrder.isInvalidated;
export const selectImageUrls = (state: RootState) =>
  state.viewOrder.mediaEntities;
export const selectImageThumbUrls = (state: RootState) =>
  state.viewOrder.mediaUrlsThumb;
export const selectSignature = (state: RootState) =>
  state.viewOrder.signatureData;
export const selectSignaturesLoading = (state: RootState) =>
  state.viewOrder.signaturesLoading;
export const selectThumbnailsLoading = (state: RootState) =>
  state.viewOrder.thumbnailsLoading;
export const selectFullImagesLoading = (state: RootState) =>
  state.viewOrder.fullMediaLoading;
export const selectOrderUpdateStatusLoading = (state: RootState) =>
  state.viewOrder.orderStatusUpdating;
export const selectGenerateReportLoading = (state: RootState) =>
  state.viewOrder.orderReportLoading;
export const selectGenerateReportDone = (state: RootState) =>
  state.viewOrder.orderReportLoaded;
export const selectReportToken = (state: RootState) =>
  state.viewOrder.reportToken;
export const selectFullMediaLoaded = (state: RootState) =>
  state.viewOrder.fullMediaLoaded;
export const selectPDFisLoading = (state: RootState) =>
  state.viewOrder.PDFisLoading;
export const selectPyramidSyncState = (state: RootState) =>
  state.viewOrder.syncToPyramidState;

export default viewOrderSlice.reducer;
