import { ReactElement, ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./centerContainer.module.scss";

export const CenterContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  return (
    <Row className="justify-content-center">
      <Col md={10} lg={6} xl={5}>
        <div className={styles.centerContainer}>{children}</div>
      </Col>
    </Row>
  );
};
