import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  logOut,
  selectError,
  selectLoginStatus,
  verifyEmail,
} from "features/auth/authSlice";
import ConfirmModal from "features/confirmModal/ConfirmModal";
import { CenterContainer } from "features/containers/centerContainer/CenterContainer";
import { useForm } from "hooks/useForm";
import { VerifyEmailData } from "interfaces/formTypes";
import { Alert, Spinner } from "react-bootstrap";
import { Button } from "@mui/material";

const VerifyEmailForm: React.FunctionComponent = (props) => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector(selectError);
  const isLoading = useAppSelector(selectLoginStatus) === "loading";
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // Using form hook for validation
  const {
    handleSubmit,
    handleChange,
    data: verifyData,
    errors,
  } = useForm<VerifyEmailData>({
    validations: {
      verificationCode: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange en kod",
        },
      },
    },
    initialValues: {
      verificationCode: "",
    },
    onSubmit: () => {
      dispatch(verifyEmail(verifyData));
    },
  });

  return (
    <CenterContainer>
      <main className="form-signin">
        <form className="" onSubmit={handleSubmit} noValidate>

          <h1 className="h3 mb-3 fw-normal text-center">Verifiera e-post</h1>
          <p className="text-center">
            För att komma åt ditt konto behöver du först verifiera din e-post.
            Ange koden du fått skickad till din e-post.
          </p>

          <div className="form-floating mt-2">
            <input
              value={verifyData.verificationCode}
              onChange={handleChange("verificationCode" || "")}
              type="text"
              className={`form-control mb-3 ${
                errors.verificationCode && "is-invalid"
              }`}
              id="floatingInput"
              placeholder="Verifieringskod"
            />
            <label htmlFor="floatingInput">Verifieringskod</label>
          </div>

          <div className="my-3">
            {
              // Show validation errors if present
              Object.keys(errors).length > 0 && (
                <Alert variant={"danger"}>
                  <ul className="mb-0">
                    {Object.values(errors).map((error, index) => {
                      return (
                        <li className="m-0" key={index}>
                          {error}
                        </li>
                      );
                    })}
                  </ul>
                </Alert>
              )
            }
            {
              // Show server errors if present
              loginError && (
                <Alert variant={"danger"}>
                  <p className="m-0">{loginError.message}</p>
                </Alert>
              )
            }
          </div>
          <div className="d-flex justify-content-center">
            <Button
              variant="contained"
              color="error"
              className="me-2"
              onClick={() => setShowConfirmModal(true)}
            >
              Logga ut
            </Button>

            <Button
              type="submit"
              variant="contained"
            >
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm"></Spinner>
              ) : (
                "Verifiera"
              )}
            </Button>

          </div>
        </form>
      </main>

      <ConfirmModal
        handleClose={() => {
          setShowConfirmModal(false);
        }}
        handleConfirm={() => {
          dispatch(logOut());
        }}
        show={showConfirmModal}
        title={"Logga ut"}
        message={"Är du säker på att du vill logga ut?"}
        confirmLabel={"Logga ut mig"}
        dismissLabel={"Avbryt"}
        isLoading={false}
      />
    </CenterContainer>
  );
};

export default VerifyEmailForm;
