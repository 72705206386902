import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm } from "hooks/useForm";
import { Company } from "models/Company";
import {
  addCompany,
  selectSuccess,
  setInitialState,
  selectError,
} from "./addNewCompanySlice";
import { Modal } from "react-bootstrap";
import FormComponent from "./FormComponent";
import { ToastContainer, toast } from "react-toastify";

interface AddNewCompanyModalProps {
  handleClose: () => void;
  show: boolean;
}

const AddNewCompanyModal: React.FunctionComponent<AddNewCompanyModalProps> = (
  props
) => {
  const { handleClose, show } = props;
  const dispatch = useAppDispatch();
  const successful = useAppSelector(selectSuccess);
  const serverError = useAppSelector(selectError);

  const {
    handleSubmit,
    handleChange,
    data: companyData,
    errors,
    clearFormData,
  } = useForm<Company>({
    validations: {
      namn: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett namn.",
        },
      },
      orgnr: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett organisationsnummer.",
        },
      },
      phone: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett telefonnummer.",
        },
      },
      mail: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig e-postadress.",
        },
      },
      address: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange en adress.",
        },
      },
      postnr: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange postnummer.",
        },
      },
      postaddress: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ort.",
        },
      },
      // OPTIONAL
      // rate: {
      //   custom: {
      //     isValid: (value) =>
      //       value === "" || (!isNaN(Number(value)) && Number(value) >= 0),
      //     message: "Ange ett giltigt timpris.",
      //   },
      // },
      // competence: {
      //   custom: {
      //     isValid: (value) => !value || value.length > 0,
      //     message: "Ange kompetens.",
      //   },
      // },
      // comment: {
      //   custom: {
      //     isValid: (value) => !value || value.length > 0,
      //     message: "Skriv en kommentar.",
      //   },
      // },
    },
    initialValues: {
      uecode: "",
      mail: "",
      namn: "",
      phone: "",
      postaddress: "",
      address: "",
      orgnr: "",
      postnr: "",
      rate: undefined,
      competence: "",
      comment: "",
    },
    onSubmit: () => {
      dispatch(addCompany(companyData));
    },
  });

  useEffect(() => {
    dispatch(setInitialState());
  }, []);

  useEffect(() => {
    if (successful) {
      handleClose();
      dispatch(setInitialState());
      clearFormData();

      toast.success(`Företaget har lagts till!`, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  }, [successful]);

  return (
    <>
      <ToastContainer position="top-center" />
      <Modal size="lg" show={show} onHide={handleClose} animation={true}>
        <FormComponent
          handleSubmit={handleSubmit}
          successful={successful}
          handleChange={handleChange}
          errors={errors}
          serverError={serverError}
          handleClose={handleClose}
        />
      </Modal>
    </>
  );
};

export default AddNewCompanyModal;
