import React from "react";
import { UserRole } from "features/auth/types/user";
import { Form, Modal, Spinner, FloatingLabel } from "react-bootstrap";
import { Button } from "@mui/material";

const EditProfileForm = (props) => {
  const {
    handleSubmit,
    companiesLoading,
    handleChange,
    errors,
    userData,
    userToEdit,
    adminUsers,
    companies,
    handleClose,
    editInProgress,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <>
          {companiesLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation={"border"} />
            </div>
          ) : (
            <>
              <FloatingLabel
                controlId="floatingSelect"
                label="Namn"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Telefon"
                  onChange={handleChange("userName" || "")}
                  className={`form-control ${errors.userName && "is-invalid"}`}
                  value={userData.userName}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingSelect"
                label="E-post"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="E-post"
                  onChange={handleChange("email" || "")}
                  className={`form-control ${errors.email && "is-invalid"}`}
                  value={userData.email}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingSelect"
                label="Telefonnummer"
                className="mb-3"
              >
                <Form.Control
                  type="phone"
                  placeholder="Telefon"
                  onChange={handleChange("phone" || "")}
                  className={`form-control ${errors.phone && "is-invalid"}`}
                  value={userData.phone}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingSelect"
                label="Anställningsnummer"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Anställningsnummer"
                  onChange={handleChange("anstnrHogia" || "")}
                  className={`form-control ${
                    errors.anstnrHogia && "is-invalid"
                  }`}
                  value={userData.anstnrHogia ? userData.anstnrHogia : ""}
                />
              </FloatingLabel>
              {userData.role === UserRole.EXTERNAL_INSTALLER && (
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Företag"
                  className="mb-3"
                >
                  <Form.Select
                    className={`${errors.companyId && "is-invalid"}`}
                    aria-label="Floating label"
                    onChange={handleChange("companyId" || "")}
                    value={userData.companyId ? userData.companyId : ""}
                  >
                    <option
                      defaultValue={
                        userToEdit?.companyId ? userToEdit.companyId : ""
                      }
                    >
                      Välj ett företag
                    </option>
                    {companies?.map((company) => {
                      return (
                        <option
                          key={company.id}
                          value={company.id}
                          selected={
                            company.id === userToEdit?.companyId && true
                          }
                        >
                          {company.namn}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              )}

              <FloatingLabel
                controlId="floatingSelect"
                label="Roll"
                className="mb-3"
              >
                <Form.Select
                  disabled
                  aria-label="Floating label"
                  value={userData.role ? userData.role : ""}
                  onChange={handleChange("role" || "")}
                >
                  <option>Välj användartyp</option>
                  <option value="ADMIN">Admin</option>
                  <option value="INTERNAL_INSTALLER">Intern installatör</option>
                  <option value="EXTERNAL_INSTALLER">Extern installatör</option>
                  <option value="EMPLOYEE">Medarbetare</option>
                  <option value="PAYROLL">Ekonomi</option>
                  <option value="SALES">Säljare</option>
                </Form.Select>
              </FloatingLabel>

              {userToEdit?.role !== UserRole.EXTERNAL_INSTALLER && (
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Attesterare frånvaro/övertid"
                  className="mb-3"
                >
                  <Form.Select
                    aria-label="Floating label"
                    onChange={handleChange("controllerId" || "")}
                    value={userData.controllerId ? userData.controllerId : ""}
                  >
                    <option>Ingen attesterare</option>

                    {adminUsers.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.userName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              )}

              {userData.role === UserRole.EXTERNAL_INSTALLER && (
                <div>
                  <FloatingLabel
                    controlId="floatingSelect"
                    label="Företag"
                    className="mb-3"
                  >
                    <Form.Select
                      className={`${errors.companyId && "is-invalid"}`}
                      aria-label="Floating label"
                      onChange={handleChange("companyId" || "")}
                      value={userData.companyId ? userData.companyId : ""}
                    >
                      <option>Välj ett företag</option>
                      {companies?.map((company, index) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.namn}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </div>
              )}
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="error"
          variant="contained"
          onClick={handleClose}
          className="me-2"
        >
          Avbryt
        </Button>
        <Button type="submit" variant="contained">
          {editInProgress ? (
            <Spinner size={"sm"} animation={"border"} />
          ) : (
            "Spara"
          )}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default EditProfileForm;
