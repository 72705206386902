import React from "react";
import { Link } from "react-router-dom";
import textStyles from "../../utilities/styles/textStyles.module.scss";

const SignUpSuccess: React.FunctionComponent = (props) => {
  return (
    <div>
      <h2 className="text-center my-4">Klart!</h2>
      <p>Ditt konto har nu skapats och du kan logga in.</p>
      <Link to="/login" className={textStyles.link}>
        Logga in
      </Link>
    </div>
  );
};

export default SignUpSuccess;
