import React from "react";
import { useAppSelector } from "app/hooks";
import { selectUserIsPayrollUser } from "features/auth/authSlice";
import {
  TimeEntryType,
  selectPayrollStatusLoading,
} from "../timeManagementSlice";
import { Container, Row, Spinner } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSummary } from "@mui/material";
import StatusComponent from "./StatusComponent";
import styles from "../timeManagement.module.scss";

const TimeEntryItemSummary = ({
  timeEntry,
  filterWithinRange,
  hoursTotal,
  intervalHoursTotal,
}) => {
  const isPayrollUser = useAppSelector(selectUserIsPayrollUser);
  const payrollStatusUpdating = useAppSelector(selectPayrollStatusLoading);

  return (
    <AccordionSummary
      aria-controls={`${timeEntry.id}-content`}
      id={`${timeEntry.id}-header`}
      expandIcon={<ExpandMoreIcon />}
      className={styles.customAccordionSummary}
    >
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between mb-3 mt-2">
          <div className="d-flex flex-column">
            {isPayrollUser && timeEntry.userHogiaNumber ? (
              <p className="fw-bold">{`${timeEntry.userName}(${timeEntry.userHogiaNumber})`}</p>
            ) : (
              <div className="d-flex">
                <p className="fw-bold">
                  {`${timeEntry.userName} - ${TimeEntryType[timeEntry.type]} `}
                </p>

                {timeEntry.type === 5 && (
                  <p className="ms-1 fw-bold">
                    {timeEntry.orderId !== null
                      ? `på ordernummer ${timeEntry.orderId}`
                      : "(ordernummer saknas)"}
                  </p>
                )}
              </div>
            )}
            <StatusComponent timeEntry={timeEntry} />
          </div>

          <div className="d-flex flex-column">
            <p className={styles.createdAt}>Skapad: {timeEntry.createdAt}</p>

            {payrollStatusUpdating.some((id) => {
              return id === timeEntry.id;
            }) && <Spinner size="sm" animation={"border"} />}

            {timeEntry.processed ? (
              <p className={`${styles.success} fw-bold`}>
                Behandlad av ekonomi
              </p>
            ) : (
              <p className={`${styles.danger} fw-bold`}>
                Ej behandlad av ekonomi
              </p>
            )}
          </div>
        </div>

        <Row className="gx-0 gy-2 w-100">
          <div className="d-flex">
            <div className={styles.timeFromToTotal}>
              <div className="d-flex justify-content-between mb-1">
                <p>Från: </p>
                <p>{timeEntry.from}</p>
              </div>

              <div className="d-flex justify-content-between">
                <p>Till: </p>
                <p>{timeEntry.to}</p>
              </div>

              {timeEntry.type !== 5 && (
                <div className="d-flex justify-content-between mt-3 mb-1">
                  <p>Arbetstimmar totalt:</p>
                  <p>{`${hoursTotal.toFixed(2)}h`}</p>
                </div>
              )}

              {/* Arbetstimmar nom valt intervall */}
              {filterWithinRange && timeEntry.type !== 5 && (
                <div className="d-flex justify-content-between">
                  <p className="fw-bold">Inom valt intervall: </p>

                  <p className="fw-bold">{`${intervalHoursTotal.toFixed(
                    2
                  )}h`}</p>
                </div>
              )}
            </div>

            {timeEntry.message ? (
              <div className="ms-2">
                <p className="fw-bold mb-0">Kommentar:</p>
                <p>{timeEntry.message}</p>
              </div>
            ) : (
              <p className="ms-2">Kommentar saknas</p>
            )}
          </div>
        </Row>
      </Container>
    </AccordionSummary>
  );
};

export default TimeEntryItemSummary;
