import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectLoading,
  selectOrder,
  toggleIsReferenceOrder,
  setFollowUpDone,
} from "../../viewOrderSlice";
import { selectUserIsAdmin } from "features/auth/authSlice";
import { Form } from "react-bootstrap";
import GeneratePDF from "./GeneratePDF";
import GenerateExcelModal from "./GenerateExcelModal";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { BeatLoader } from "react-spinners";
import { Tooltip } from "@mui/material";
import containerStyles from "../../../../../utilities/styles/containerStyles.module.scss";
import textStyles from "../../../../../utilities/styles/textStyles.module.scss";

const ReportSection = () => {
  const dispatch = useAppDispatch();
  const orderLoading = useAppSelector(selectLoading);
  const order = useAppSelector(selectOrder);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const [isReferenceOrder, setIsReferenceOrder] = useState<boolean>(
    order?.referenceOrder || false
  );
  const [telemarketingDone, setTelemarketingDone] = useState<boolean>(
    order?.customerFollowUp || false
  );

  const toggleIsReference = () => {
    if (order) {
      // Dispatch action
      dispatch(
        toggleIsReferenceOrder({
          orderId: order?.ordernr,
          isReferenceOrder: !isReferenceOrder,
        })
      );

      // Update state
      setIsReferenceOrder(!isReferenceOrder);
    }
  };

  const toggleTelemarketingDone = () => {
    if (order) {
      dispatch(
        setFollowUpDone({
          orderId: order?.ordernr,
          followUpDone: !telemarketingDone,
        })
      );
    }
    setTelemarketingDone(!telemarketingDone);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="d-flex">
          <div className={containerStyles.reportSection}>
            {orderLoading ? (
              <div className="d-flex flex-column align-items-center justify-content-center m-5">
                <BeatLoader color="#5f5f5f" className="mb-3" />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <p className={textStyles.orderInfoTitle}>
                    Exportera produkter till Excel
                  </p>

                  <Tooltip
                    className="ms-4"
                    arrow
                    title={
                      <>
                        <p className="fw-bold mb-1">Produktsammanställning</p>
                        <p className="mb-4">
                          En sammanställning över alla produkter som tillhör
                          ordern i Excel-format (.xlsx). Inkludera alla, eller
                          välj specifika checklistor.
                        </p>

                        <p className="fw-bold mb-1">Installationsbevis</p>
                        <p className="mb-0">
                          Ett PDF-dokument med info & bilder att lämna till kund
                          efter installation. Inkludera alla, eller välj
                          specifika checklistor.
                        </p>
                      </>
                    }
                  >
                    <HelpOutlineOutlinedIcon
                      sx={{
                        color: "#161616",
                        opacity: 0.4,
                      }}
                    />
                  </Tooltip>
                </div>
                <GenerateExcelModal />

                <p className={textStyles.orderInfoTitle}>Installationsbevis</p>

                {order && <GeneratePDF order={order} />}

                {isAdmin && (
                  <div className="mt-4">
                    <Form.Check
                      type={"checkbox"}
                      id={"referenceCaseCheckbox"}
                      label={"Markera order som referensobjekt"}
                      checked={isReferenceOrder}
                      onChange={toggleIsReference}
                    />

                    <Form.Check
                      type={"checkbox"}
                      id={"telemarketingDone"}
                      label={"Telemarketing gjord på order"}
                      checked={telemarketingDone}
                      onChange={toggleTelemarketingDone}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportSection;
