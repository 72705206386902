import React from "react";
import { Nav } from "react-bootstrap";
import styles from "./mainNavigation.module.scss";
import "./mainNavigation.module.scss";

import LogoutIcon from "@mui/icons-material/Logout";

interface LogoutLinkProps {
  onClick: () => void;
}
const LogoutLink: React.FunctionComponent<LogoutLinkProps> = (props) => {
  const { onClick } = props;
  return (
    <>
      <Nav.Link
        onClick={onClick}
        className={`w-100  mt-3 ${styles.navItem} d-flex justify-content center`}
      >
        <LogoutIcon className="ms-2 me-3" />
        Logga ut
      </Nav.Link>
    </>
  );
};

export default LogoutLink;
