import React, { ReactElement, ReactNode, useState } from "react";
import IPage from "interfaces/page";
import AuthHeader from "features/authHeader/AuthHeader";
import ResetPasswordForm from "features/resetPassword/ResetPasswordForm";
import { useAppSelector } from "app/hooks";
import { selectStatus } from "features/resetPassword/resetPasswordSlice";
import EnterNewPassword from "features/resetPassword/EnterNewPassword";
import ResetComplete from "features/resetPassword/ResetComplete";
import { Container } from "react-bootstrap";

const ResetPassword: React.FunctionComponent<IPage> = (props) => {
  const resetStatus = useAppSelector(selectStatus);

  return (
    <Container className="p-0">
      <AuthHeader />
      {resetStatus === "idle" && <ResetPasswordForm />}
      {resetStatus === "emailSent" && <EnterNewPassword />}
      {resetStatus === "done" && <ResetComplete />}
    </Container>
  );
};

export default ResetPassword;
