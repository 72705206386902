import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useHistory } from "react-router-dom";
import { Order } from "models/Order";
import { getUpdatedAtFormat, getOrderStatusString } from "utilities/orders";
import { selectPreviousOrder, setLastViewedOrder } from "../ordersSlice";
import { SortBy, SortType } from "utilities/orders";

import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import tableStyles from "../../../utilities/styles/tableStyle.module.scss";

const OrdersTableMui = ({ orders, setSort }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const hasPreviousOrder = useAppSelector(selectPreviousOrder);
  const [sortedOrders, setSortedOrders] = useState<Order[]>([]);
  const [sortOrder, setSortOrder] = useState({
    column: "",
    direction: "",
  });

  // Scroll to last visited order
  useEffect(() => {
    if (orders) {
      scrollToVisitedOrder();
    }
  }, []);

  const scrollToVisitedOrder = () => {
    if (hasPreviousOrder) {
      setTimeout(() => {
        var container = document.getElementById("scrollContainer");
        if (container) {
          container.scrollTo(0, hasPreviousOrder);
        }
      }, 10);

      // console.log("scrolling to Z137496");

      // let element = document.getElementById("Z137496");
      // element?.scrollIntoView({
      //   behavior: "auto",
      //   block: "center",
      //   inline: "nearest",
      // });
    }
  };

  useEffect(() => {
    if (orders) {
      setSortedOrders(orders);
    }
  }, [orders]);

  const labels = [
    "Ordernr",
    "Företag",
    "Orderstatus",
    "Orderstatus ändrad",
    "Order ändrad",
  ];

  const cellWidths = ["20%", "30%", "10%", "20%", "20%"];

  const handleSort = (label) => {
    let direction = "asc";
    if (sortOrder.column === label && sortOrder.direction === "asc") {
      direction = "desc";
    }

    // Update the local state with the new sorting order.
    setSortOrder({ column: label, direction });

    const convertSortBy = (label) => {
      switch (label) {
        case "Ordernr":
          return SortBy.ORDER_NUMBER;
        case "Företag":
          return SortBy.COMPANY;
        case "Orderstatus":
          return SortBy.ORDER_STATUS;
        case "Orderstatus ändrad":
          return SortBy.ORDER_STATUS_UPDATED;
        case "Order ändrad":
          return SortBy.LAST_UPDATE;
        default:
          return SortBy.NONE;
      }
    };

    const convertSortType = (direction) => {
      switch (direction) {
        case "asc":
          return SortType.ASC;
        case "desc":
          return SortType.DESC;
        default:
          return SortType.NONE;
      }
    };

    // Convert the sorting values into the correct format
    const sortBy = convertSortBy(label);
    const sortType = convertSortType(direction);

    // Update the sort state in parent component (that will be sent in the request)
    setSort({
      sortBy: sortBy,
      sortType: sortType,
    });
  };

  useEffect(() => {
    if (orders) {
      let sortedList = [...orders];

      if (sortOrder.column === "Ordernr") {
        sortedList.sort((a, b) =>
          (a.ordernr ?? "").localeCompare(b.ordernr ?? "")
        );
      } else if (sortOrder.column === "Företag") {
        sortedList.sort((a, b) =>
          (a.companyName ?? "").localeCompare(b.companyName ?? "")
        );
      } else if (sortOrder.column === "Orderstatus") {
        sortedList.sort((a, b) =>
          (a.orderStatus ?? "").localeCompare(b.orderStatus ?? "")
        );
      } else if (sortOrder.column === "Orderstatus ändrad") {
        sortedList.sort((a, b) =>
          (a.lastStatusUpdate ?? "").localeCompare(b.lastStatusUpdate ?? "")
        );
      } else if (sortOrder.column === "Order ändrad") {
        sortedList.sort((a, b) =>
          (a.updatedAt ?? "").localeCompare(b.updatedAt ?? "")
        );
      }

      if (sortOrder.direction === "desc") {
        sortedList.reverse();
      }

      setSortedOrders(sortedList);
    }
  }, [sortOrder, orders]);

  const handleClick = (ordernr) => {
    var rowPosition = document.getElementById("scrollContainer")?.scrollTop;
    console.log(`clicked on ${rowPosition}`);
    dispatch(setLastViewedOrder(rowPosition ?? 0));

    const orderLink = `/orders/view/${ordernr}`;
    history.push(orderLink);
  };

  return (
    <>
      <div className={tableStyles.ordersTable}>
        <TableContainer sx={{ minWidth: 1000 }}>
          <Table size="small" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow sx={{ bgcolor: "rgba(220, 220, 220, 0.5)" }}>
                {labels.map((label, index) => (
                  <TableCell
                    key={index}
                    sx={{ width: cellWidths[index] }}
                    align={index < 2 ? "left" : "center"}
                  >
                    <TableSortLabel
                      className="fw-bold py-2"
                      onClick={() => handleSort(label)}
                      active={sortOrder.column === label}
                      direction={
                        sortOrder.column === label
                          ? sortOrder.direction === "asc"
                            ? "desc"
                            : "asc"
                          : undefined
                      }
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                "& tr:nth-of-type(2n)": {
                  backgroundColor: "grey.100",
                },
              }}
            >
              {sortedOrders.length > 0 ? (
                sortedOrders.map((order, index) => {
                  const isLastRow = index === sortedOrders.length - 1;
                  return (
                    <TableRow
                      key={index}
                      id={order.ordernr}
                      hover={true}
                      onClick={() => handleClick(order.ordernr)}
                      className={isLastRow ? tableStyles.lastRow : ""}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="left">{order.ordernr}</TableCell>
                      <TableCell align="left">{order.companyName}</TableCell>
                      <TableCell align="center">
                        {getOrderStatusString(order.orderStatus)}
                      </TableCell>
                      <TableCell align="center">
                        {getUpdatedAtFormat(order.lastStatusUpdate)}
                      </TableCell>
                      <TableCell align="center">
                        {getUpdatedAtFormat(order.updatedAt)}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow className={tableStyles.lastRow}>
                  <TableCell colSpan={labels.length}>
                    <p className="mb-0">Din sökning gav inget resultat</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default OrdersTableMui;
