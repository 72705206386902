import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  addExtra,
  deleteExtra,
  editExtra,
  selectChecklist,
  viewChecklist,
  resetExtras,
} from "../../viewChecklistSlice";
import { Comment } from "models/Comment";
import { Form, Modal } from "react-bootstrap";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ToastContainer, toast } from "react-toastify";
import { Button, Tooltip } from "@mui/material";
import Extra from "./Extra";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";

const EditExtras = ({ nameHidden }) => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const [extraString, setExtraString] = useState<string>("");
  const [newExtraString, setNewExtraString] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updatedExtras, setUpdatedExtras] = useState<Comment[]>([]);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const additionalExtras = checklist?.additionalExtras;
  const viewChecklistState = useAppSelector(viewChecklist);

  useEffect(() => {
    if (additionalExtras) {
      setUpdatedExtras(additionalExtras);
    }
  }, [additionalExtras]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDelete = (extraId: string) => {
    setConfirmDelete(false);
    dispatch(deleteExtra(extraId));

    setTimeout(() => {
      dispatch(resetExtras());
    }, 3000);
  };

  const addExtraItem = () => {
    if (checklist) {
      const requestBody = {
        message: newExtraString,
        listId: checklist.id,
      };

      dispatch(addExtra(requestBody));

      setTimeout(() => {
        dispatch(resetExtras());
      }, 3000);
    }
    setNewExtraString("");
  };

  const confirmChange = (commentId: string) => {
    if (checklist) {
      const requestBody = {
        message: extraString,
        commentId: commentId,
      };

      dispatch(editExtra(requestBody));

      setTimeout(() => {
        dispatch(resetExtras());
      }, 3000);

      setEdit(false);
    }
  };

  const handleChange = (message: string, extraId: string, index: number) => {
    setEdit(true);
    setEditIndex(index);
    let tempState = updatedExtras.map((extra) => {
      if (extra.id === extraId) {
        let tempExtra = { ...extra, message: message };

        return tempExtra;
      }
      return extra;
    });

    setExtraString(message);
    setUpdatedExtras(tempState);
  };

  const notifyAdd = () => {
    if (viewChecklistState.error === null) {
      toast.success("Tillägget har lagts till!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när tillägget skulle sparas.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const notifyEdit = () => {
    if (viewChecklistState.error === null) {
      toast.success("Tillägget har redigeras!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när tillägget skulle redigeras.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const notifyDelete = () => {
    if (viewChecklistState.error === null) {
      toast.success("Tillägget har tagits bort!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när tillägget skulle raderas.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (viewChecklistState.extraWasAdded) {
      notifyAdd();
    }

    if (viewChecklistState.extraWasDeleted) {
      notifyDelete();
    }

    if (viewChecklistState.extraWasEdited) {
      notifyEdit();
    }
  }, [
    viewChecklistState.extraWasAdded,
    viewChecklistState.extraWasDeleted,
    viewChecklistState.extraWasEdited,
  ]);

  return (
    <>
      <ToastContainer position="top-center" />

      <Tooltip arrow title={<>Lägg till eller ta bort tillägg</>}>
        <EditOutlinedIcon
          onClick={handleClick}
          className={iconStyles.editIcon}
        />
      </Tooltip>

      <Modal show={showModal} onHide={handleClose} size="xl">
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Redigera tillägg</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {updatedExtras &&
              updatedExtras.map((extra, index) => (
                <Extra
                  extra={extra}
                  key={index}
                  index={index}
                  handleChange={handleChange}
                  deleteIndex={deleteIndex}
                  handleDelete={handleDelete}
                  confirmDelete={confirmDelete}
                  setConfirmDelete={setConfirmDelete}
                  editIndex={editIndex}
                  edit={edit}
                  confirmChange={confirmChange}
                  setDeleteIndex={setDeleteIndex}
                  setEdit={setEdit}
                  setEditIndex={setEditIndex}
                  extraString={extraString}
                  nameHidden={nameHidden}
                />
              ))}
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
              <Form.Control
                type="text"
                placeholder="Nytt tillägg"
                onChange={(e) => {
                  setNewExtraString(e.target.value);
                }}
                value={newExtraString}
                className="me-2"
              />
              {newExtraString.length > 0 ? (
                <Button
                  variant="contained"
                  onClick={addExtraItem}
                  className="ms-2"
                  type="submit"
                >
                  Spara
                </Button>
              ) : (
                <Button disabled variant="contained">
                  Spara
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditExtras;
