import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const FilterByReferenceOrders = ({ filterReferenceOrders, setFilterReferenceOrders }) => {

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={filterReferenceOrders}
            onChange={() =>
              setFilterReferenceOrders(!filterReferenceOrders)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Endast referensobjekt"
      />
    </div>
  );
};

export default FilterByReferenceOrders;
