import React from "react";
import * as ExcelJS from "exceljs";
import { logoBase64 } from "./logo";
import { Button } from "@mui/material";

const GenerateExcel = (props) => {
  const { checklistsToDownload, order, handleClose } = props;
  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Produkter");

  // Add logo to workbook
  const image = workbook.addImage({
    base64: logoBase64,
    extension: "png",
  });

  // Place out logo in worksheet
  worksheet.addImage(image, {
    tl: { col: 0.99, row: 0.9 },
    ext: { width: 90, height: 110 },
    hyperlinks: {
      hyperlink: "https://avs.se/",
      tooltip: "https://avs.se/",
    },
  });

  // Add a top cell/header
  worksheet.getRow(1).font = { bold: true, size: 20 };
  worksheet.getRow(1).height = 80;
  worksheet.mergeCells(1, 1, 1, 10);
  const topCell = worksheet.getCell(1, 1);
  topCell.alignment = {
    horizontal: "center",
    vertical: "middle",
  };
  topCell.value = `Sammanställning över produkter tillhörande order ${order.ordernr}`;
  topCell.border = { bottom: { style: "thin" }, right: { style: "thin" } };
  topCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ffececec" },
  };

  // Add the second row with headings
  const headings = [
    "Tillhör checklista",
    "Serienummer",
    "Fabrikat",
    "Modell",
    "MAC-address",
    "Nätverksuttag",
    "Övrigt/Firmware-version",
    "Stöldmärkning",
    "MAC-address Wi-Fi",
    "IP-address (om fast)",
  ];
  worksheet.addRow(headings).font = { bold: true, size: 16 };
  worksheet.getRow(2).height = 30;
  worksheet.getRow(2).eachCell((cell, colNumber) => {
    cell.border = { right: { style: "thin" }, bottom: { style: "thin" } };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffececec" },
    };
    cell.alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    cell.value = headings[colNumber - 1]; // the -1 is because the colNumber is 1-based
  });

  // Style columns (width)
  worksheet.columns = [
    { key: "col1", width: 30 },
    { key: "col2", width: 30 },
    { key: "col3", width: 30 },
    { key: "col4", width: 30 },
    { key: "col5", width: 30 },
    { key: "col6", width: 30 },
    { key: "col7", width: 30 },
    { key: "col8", width: 30 },
    { key: "col9", width: 30 },
    { key: "col10", width: 30 },
  ];

  const handleGenerateExcel = async () => {
    // Add the products data to the worksheet
    checklistsToDownload.forEach((checklist) => {
      checklist.serialEntities.forEach((product) => {
        worksheet.addRow([
          checklist.roomName, // Tillhör checklista
          product.serialNumber, // Serienummer
          product.maker, // Fabrikat
          product.model, // Modell
          product.macAddress, // MAC-address
          product.networkSocket, // Nätverksuttag
          product.other, // Firmware version/Övrigt
          product.theftProtection, // Stöldmärkning
          product.macAddressWifi, // MAC-address Wi-Fi
          product.ipAddress, // IP-address (om fast)
        ]);
      });
    });

    // Save the workbook to a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Produktsammanställning ordernr ${order?.ordernr}.xlsx`; // File name
      a.click();
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        className="me-2"
        onClick={handleClose}
      >
        Avbryt
      </Button>

      {checklistsToDownload.length === 0 ? (
        <Button variant="contained" disabled>Ladda ner fil</Button>
      ) : (
        <Button variant="contained" type="submit" onClick={handleGenerateExcel}>
          Ladda ner fil
        </Button>
      )}
    </>
  );
};

export default GenerateExcel;
