import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectChecklist } from "pages/orders/viewChecklist/viewChecklistSlice";
import {
  fetchFullMedia,
  selectImageUrls,
} from "pages/orders/viewOrder/viewOrderSlice";
import { Player, Video, DefaultUi } from "@vime/react";
import "@vime/core/themes/default.css";
import ImageItem from "./components/ImageItem";
import { BeatLoader } from "react-spinners";
import { Carousel } from "react-bootstrap";
import styles from "./slideShow.module.scss";

const SlideShow = ({ initialSlideIndex, onSlideChange }) => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const mediaEntities = useAppSelector(selectImageUrls);
  const [currentSlide, setCurrentSlide] = useState<number>(initialSlideIndex);

  useEffect(() => {
    let mediaId = checklist?.verification[currentSlide]?.id;
    let media = mediaEntities.find((mediaEntity) => mediaEntity.id === mediaId);

    if (!media && mediaId) {
      dispatch(fetchFullMedia(mediaId));
    }
  }, [checklist, currentSlide, mediaEntities]);

  const setSlideIndex = (selectedIndex) => {
    setCurrentSlide(selectedIndex);
    onSlideChange(selectedIndex); // Update currentSlide in parent component SlideShowModal
  };

  return (
    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
      <div className="slideshow">
        <Carousel
          variant="dark"
          activeIndex={currentSlide}
          onSelect={setSlideIndex}
          interval={null}
        >
          {checklist?.verification.map((verification) => {
            // Find mediaUrl by id
            const mediaUrl = mediaEntities?.find((url) => {
              return verification.id === url.id;
            });

            // VIDEO
            if (mediaUrl && !mediaUrl.isLoading) {
              switch (mediaUrl.mediaType) {
                case "video/mp4":
                  return (
                    <Carousel.Item
                      key={verification.id}
                      className={styles.carouselItem}
                    >
                      <div className={styles.videoPlayer}>
                        <Player theme="dark">
                          <Video crossOrigin="">
                            <source
                              data-src={mediaUrl.imgUrl}
                              type={mediaUrl.mediaType}
                            />
                          </Video>
                          <DefaultUi />
                        </Player>
                      </div>
                    </Carousel.Item>
                  );

                // IMAGE
                default:
                  return (
                    <Carousel.Item
                      key={verification.id}
                      className={styles.carouselItem}
                    >
                      <ImageItem mediaUrl={mediaUrl} />
                    </Carousel.Item>
                  );
              }
            } else {
              // Loading
              return (
                <Carousel.Item
                  key={verification.id}
                  className={`${styles.carouselItem}`}
                >
                  <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    <BeatLoader color="#5f5f5f" />
                  </div>
                </Carousel.Item>
              );
            }
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default SlideShow;
