import {
  addDays,
  addWeeks,
  compareAsc,
  differenceInHours,
  differenceInMinutes,
  isAfter,
  isBefore,
  isEqual,
  isFriday,
  isSameDay,
  isWeekend,
  isWithinInterval,
  nextFriday,
  nextMonday,
  nextSaturday,
  nextThursday,
  previousFriday,
  previousSaturday,
  startOfDay,
  subDays,
} from "date-fns";

export function minutesToTimeString(totalMinutes: number): string {
  if (totalMinutes < 60) {
    return `${totalMinutes} min`;
  } else {
    return `${Math.floor(totalMinutes / 60)}:${totalMinutes % 60} h`;
  }
}

export const convertMinutesToHours = (minutes: number): number => {
  let fullHours = Math.floor(minutes / 60);
  let fractions = (minutes - fullHours * 60) / 60;
  return fullHours + fractions;
};

export const workingHoursBetweenDates = (startDate, endDate) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  // Store minutes worked
  var minutesWorked = 0;

  // Validate input
  if (endDate < startDate) {
    return 0;
  }

  // Loop from your Start to End dates (by hour)
  var current = startDate;

  // Define work range
  const workHoursStart = 8;
  const lunchHoursStart = 12;
  const lunchHoursEnd = 13;
  const workHoursEnd = 17;

  // Loop while currentDate is less than end Date (by minutes)
  while (current <= endDate) {
    // Is the current time within a work day (and if it occurs on a weekend or not)
    if (
      current.getHours() >= workHoursStart &&
      current.getHours() < workHoursEnd &&
      current.getDay() !== 0 &&
      current.getDay() !== 6
    ) {
      if (
        current.getHours() >= lunchHoursStart &&
        current.getHours() < lunchHoursEnd
      ) {
        minutesWorked = minutesWorked - 1;
      }
      minutesWorked++;
    }

    // Increment current time
    current.setTime(current.getTime() + 1000 * 60);
  }

  // Return the number of hours
  return minutesWorked / 60;
};

export const calculateWorkHours = (from: Date, to: Date): number => {
  const workHoursStart = 8;
  const lunchHoursStart = 12;
  const lunchHoursEnd = 13;
  const workHoursEnd = 17;
  const holidays = getAllHolidays().sort(compareAsc);
  let workingHoursTotal = 0;

  // Validate input
  if (isBefore(to, from) || isEqual(to, from)) {
    return 0;
  }

  // Calculate work hours
  for (
    let currentDate = from;
    isBefore(currentDate, to) || isSameDay(currentDate, to);
    currentDate = addDays(startOfDay(currentDate), 1)
  ) {
    // Check weekend
    if (isWeekend(currentDate)) continue;

    // Check hoiliday
    let isHoliday = holidays.some((date) => {
      return isSameDay(date, currentDate);
    });
    if (isHoliday) {
      continue;
    }

    // Sum total hours
    let startWorkHour = currentDate.getHours();
    let endWorkHour = 17;
    let endWorkHourDate = new Date(currentDate);

    // If last day
    if (isSameDay(currentDate, to)) {
      endWorkHour = to.getHours();
      endWorkHourDate.setMinutes(to.getMinutes());
    } else {
      endWorkHourDate.setMinutes(0);
    }
    // Check for full day
    if (startWorkHour <= workHoursStart && endWorkHour >= workHoursEnd) {
      // Add 8 hours to total
      workingHoursTotal += 8;
    } else if (startWorkHour <= workHoursEnd && endWorkHour <= workHoursEnd) {
      if (
        currentDate.getHours() < workHoursStart &&
        to.getHours() > workHoursStart
      ) {
        currentDate.setHours(workHoursStart);
      }
      endWorkHourDate.setHours(endWorkHour);

      let total = differenceInMinutes(endWorkHourDate, currentDate);

      if (convertMinutesToHours(total) > 8) total = 8 * 60;

      // Check if overlaping lunch
      let lunchMinutes = 0;
      let lunchStart = new Date(currentDate);
      lunchStart.setHours(lunchHoursStart);
      lunchStart.setMinutes(0);
      lunchStart.setSeconds(0);
      let lunchEnd = new Date(currentDate);
      lunchEnd.setHours(lunchHoursEnd);
      lunchEnd.setMinutes(0);
      lunchEnd.setSeconds(0);

      if (isAfter(to, lunchStart)) {
        if (
          isBefore(currentDate, lunchStart) ||
          (isEqual(currentDate, lunchStart) && isAfter(to, lunchEnd)) ||
          isEqual(to, lunchEnd)
        ) {
          // Add one hour lunch
          lunchMinutes += 60;
        } else if (
          (isAfter(currentDate, lunchStart) &&
            isBefore(currentDate, lunchEnd) &&
            isAfter(to, lunchEnd)) ||
          isEqual(to, lunchEnd)
        ) {
          // Add difference between currentDate and lunchEnd
          lunchMinutes += differenceInMinutes(lunchEnd, currentDate);
        } else if (
          (isBefore(currentDate, lunchStart) && isBefore(to, lunchEnd)) ||
          isEqual(currentDate, lunchStart)
        ) {
          lunchMinutes += differenceInMinutes(to, lunchStart);
        }
      }

      // Deduct lunch minutes
      total = total - lunchMinutes;

      workingHoursTotal += convertMinutesToHours(total);
    }
  }

  return workingHoursTotal;
};

export const getAllHolidays = (): Date[] => {
  // Fixed holidays
  const currentYear = new Date().getFullYear();
  const newYearsDay = new Date(currentYear, 0, 1);
  const epiphany = new Date(currentYear, 0, 6);
  const walpurgis = new Date(currentYear, 3, 30);
  const laborDay = new Date(currentYear, 4, 1);
  const nationalDay = new Date(currentYear, 5, 6);
  const christmasEve = new Date(currentYear, 11, 24);
  const christmasDay = new Date(currentYear, 11, 25);
  const boxingDay = new Date(currentYear, 11, 26);
  const newYearsEve = new Date(currentYear, 11, 31);

  const fixedHolidays: Date[] = [
    newYearsDay,
    epiphany,
    walpurgis,
    laborDay,
    nationalDay,
    christmasEve,
    christmasDay,
    boxingDay,
    newYearsEve,
  ];

  // Unfixed holidays
  // Easter
  const easterSunday = calculateEasterSunday();
  const goodFriday = previousFriday(easterSunday);
  const easterSaturday = previousSaturday(easterSunday);
  const easterMonday = nextMonday(easterSunday);

  // The rest
  const ascensionDay = nextThursday(addWeeks(easterSaturday, 5));
  const pentecost = addWeeks(easterSunday, 7);
  let midsummer = new Date(currentYear, 5, 19);
  const midsummerIsOn19 = isFriday(new Date(currentYear, 5, 19));

  if (!midsummerIsOn19) {
    midsummer = nextFriday(midsummer);
  }

  const midsummerDay = nextSaturday(midsummer);
  const allSaintsDay = nextSaturday(new Date(currentYear, 9, 30));

  const unfixedHolidays: Date[] = [
    easterSunday,
    goodFriday,
    easterSaturday,
    easterMonday,
    ascensionDay,
    pentecost,
    midsummer,
    midsummerDay,
    allSaintsDay,
  ];

  return [...fixedHolidays, ...unfixedHolidays];
};
export const calculateEasterSunday = (): Date => {
  // Algorithm for easter
  const year = new Date().getFullYear();
  const M = 24;
  const N = 5;

  let a = year % 19;
  let b = year % 4;
  let c = year % 7;

  let d = (19 * a + M) % 30;
  let e = (2 * b + 4 * c + 6 * d + N) % 7;
  let f = 22 + d + e;

  let easterSunday;
  let date;
  if (f <= 31) {
    easterSunday = f;
    date = new Date(year, 2, easterSunday);
  } else {
    easterSunday = f - 31;
    date = new Date(year, 3, easterSunday);
  }
  return date;
};

export const calculateOvertimeHours = (from: Date, to: Date): number => {
  const total = differenceInHours(new Date(to), new Date(from));
  const workHours = calculateWorkHours(from, to);
  return total - workHours;
};

/**
 * Calculate the amount of overtime hours within the hours for 50% and 100% compensation.
 *
 * @param from Range start date
 * @param to Range end date
 * @returns Array where index 0 is the 50% value and 1 is the 100% value
 */
export const calculateOvertimePercentages = (
  from: Date,
  to: Date
): number[] => {
  const WORK_HOURS_START = 8;
  const WORK_HOURS_END = 17;
  const ONE_HUNDRED_PERCENT_START = 20;

  const holidays = getAllHolidays().sort(compareAsc);
  // let workingHoursTotal = 0;
  let workingHoursFifty = 0;
  let workingHoursHundred = 0;

  // Validate input
  if (isBefore(to, from)) {
    return [];
  }

  // Calculate overtime hours
  for (
    let currentDate = from;
    isBefore(currentDate, to) || isSameDay(currentDate, to);
    currentDate = addDays(startOfDay(currentDate), 1)
  ) {
    if (isBefore(currentDate, from)) {
    }
    // Check holiday
    let isHoliday = holidays.some((date) => {
      return isSameDay(date, currentDate);
    });

    // Determine if currentDate is the last day
    let currentDateEnd;
    if (isSameDay(currentDate, to)) {
      currentDateEnd = to;
    } else {
      currentDateEnd = startOfDay(addDays(currentDate, 1));
    }

    const currentDateWorkEnd = new Date(currentDate);
    currentDateWorkEnd.setHours(WORK_HOURS_END);
    currentDateWorkEnd.setMinutes(0);
    currentDateWorkEnd.setSeconds(0);

    // Check if currentDate is on a weekend or a holiday
    if (isWeekend(currentDate) || isHoliday) {
      // Count 100% hours for current day, then continue
      let minutesOnWeekend = differenceInMinutes(currentDateEnd, currentDate);
      workingHoursHundred += convertMinutesToHours(minutesOnWeekend);
      continue;
    }

    // Count overtime hours BEFORE regular hours
    if (currentDate.getHours() < WORK_HOURS_START) {
      let minutesBeforeRegularHours;
      let workHoursStartDate = new Date(currentDate);
      workHoursStartDate.setHours(WORK_HOURS_START);
      workHoursStartDate.setMinutes(0);

      if (WORK_HOURS_START <= to.getHours()) {
        // Count from currentDate to workHoursStart
        minutesBeforeRegularHours = differenceInMinutes(
          workHoursStartDate,
          currentDate
        );
      } else {
        minutesBeforeRegularHours = differenceInMinutes(to, currentDate);
      }

      // Is the previous day a weekend or holiday? Then count 100%, else count 50%
      let holidayPreviousDay = holidays.some((date) => {
        return isSameDay(date, subDays(currentDate, 1));
      });

      if (isWeekend(subDays(currentDate, 1)) || holidayPreviousDay) {
        // Count 100%
        workingHoursHundred += convertMinutesToHours(minutesBeforeRegularHours);
      } else {
        // Count 50%
        workingHoursFifty += convertMinutesToHours(minutesBeforeRegularHours);
      }
    }

    if (isAfter(currentDateEnd, currentDateWorkEnd)) {
      if (currentDate.getHours() <= WORK_HOURS_END) {
        currentDate.setHours(WORK_HOURS_END);
        currentDate.setMinutes(0);
      }

      let nextDayIsHoliday = holidays.some((date) => {
        return isSameDay(date, addDays(currentDate, 1));
      });

      if (!nextDayIsHoliday && !isWeekend(addDays(currentDate, 1))) {
        workingHoursFifty += convertMinutesToHours(
          differenceInMinutes(currentDateEnd, currentDate)
        );
      } else {
        let hundredPercentHoursStartDate = new Date(currentDate);
        hundredPercentHoursStartDate.setHours(ONE_HUNDRED_PERCENT_START);
        hundredPercentHoursStartDate.setMinutes(0);

        if (isBefore(currentDate, hundredPercentHoursStartDate)) {
          if (isBefore(currentDateEnd, hundredPercentHoursStartDate)) {
            workingHoursFifty += convertMinutesToHours(
              differenceInMinutes(currentDateEnd, currentDate)
            );
            continue;
          } else {
            workingHoursFifty += convertMinutesToHours(
              differenceInMinutes(hundredPercentHoursStartDate, currentDate)
            );

            workingHoursHundred += convertMinutesToHours(
              differenceInMinutes(currentDateEnd, hundredPercentHoursStartDate)
            );
          }
        } else {
          workingHoursFifty += convertMinutesToHours(
            differenceInMinutes(currentDateEnd, currentDate)
          );
        }
      }
    }
  }

  return [workingHoursFifty, workingHoursHundred];
};

export const calculateTimePeriod = (
  fromDate: Date,
  toDate: Date,
  intervalStartDate: Date,
  intervalEndDate: Date
): Date[] | null => {
  const fromDateWithin = isWithinInterval(fromDate, {
    start: intervalStartDate,
    end: intervalEndDate,
  });
  const toDateWithin = isWithinInterval(toDate, {
    start: intervalStartDate,
    end: intervalEndDate,
  });

  // If from and to are within range, return null
  if (fromDateWithin && toDateWithin) {
    return [fromDate, toDate];
  }

  // Check if fromDate is within interval
  if (fromDateWithin) {
    return [fromDate, intervalEndDate];
  }

  // Check if toDate is within interval
  if (toDateWithin) {
    return [intervalStartDate, toDate];
  }

  return [new Date()];
};

export const truncateDecimals = (number: number): string => {
  number = Math.floor(number * 100) / 100;
  // Check if integer
  if (number % 1 === 0) {
    return number.toString();
  }
  // else keep decimals
  return number.toFixed(2);
};
