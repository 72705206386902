import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectExternalUserLoading,
  getExternalInstallers,
  selectExternalUsers,
  addUser,
} from "./showCompanySlice";
import { Company } from "models/Company";
import { SimpleUser } from "models/SimpleUser";
import { Modal, Form, FloatingLabel, Spinner } from "react-bootstrap";
import Add from "@mui/icons-material/Add";
import { Button } from "@mui/material";

export interface IModalProps {
  handleClose: () => void;
  show: boolean;
  company: Company | null;
}

const AddInstaller: React.FunctionComponent<IModalProps> = (props) => {
  const { show, handleClose, company } = props;
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>("");
  const externalInstallers = useAppSelector(selectExternalUsers);
  const [results, setResults] = useState<SimpleUser[]>([]);
  const addUserLoading = useAppSelector(selectExternalUserLoading);
  const [currentIdLoading, setCurrentIdLoading] = useState<string | null>(null);

  // Initial loading of users
  useEffect(() => {
    dispatch(getExternalInstallers());
  }, []);

  const addUserToCompany = (userId: string) => {
    const body = {
      userId: userId,
      companyId: company?.id ? company?.id : "",
    };

    setCurrentIdLoading(userId);
    dispatch(addUser(body));
  };

  const searchUsers = (query) => {
    setQuery(query);
    if (query.length > 1) {
      const filtered =
        externalInstallers?.filter((user) => {
          return user.userName.toLowerCase().includes(query.toLowerCase());
        }) || [];
      setResults(filtered);
    } else {
      setResults([]);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Lägg till användare</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form className="mt-4">
            <FloatingLabel
              controlId="floatingSelect"
              label="Användare"
              className=""
            >
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="E-post"
                onChange={(e) => searchUsers(e.target.value)}
              />
            </FloatingLabel>
          </Form>

          <div className="mt-4">
            {query.length > 1 && results.length < 1 ? (
              <p className="mb-0">Inga resultat</p>
            ) : (
              results.map((result) => {
                let alreadyAdded = company?.users?.some((addedInstaller) => {
                  return addedInstaller.userId === result.userId;
                });
                return (
                  <div
                    className="my-3 d-flex justify-content-between align-items-center"
                    key={result.userId}
                  >
                    <p className="m-0">{result.userName}</p>

                    {alreadyAdded ? (
                      <Button variant="contained" disabled>
                        Tillagd
                      </Button>
                    ) : addUserLoading && currentIdLoading === result.userId ? (
                      <Button variant="contained">
                        <Spinner size="sm" animation={"border"} />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => addUserToCompany(result.userId)}
                        variant="contained"
                      >
                        <Add />
                      </Button>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="error" onClick={handleClose}>
            Avbryt
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddInstaller;
