import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import {
  selectUserIsAdmin,
  selectUser,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { Button, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import iconStyles from "../../../utilities/styles/iconStyles.module.scss";
import styles from "../slideShow.module.scss";

const Comment = ({
  comment,
  handleDeleteComment,
  confirmDeleteComment,
  index,
  setConfirmDeleteComment,
  handleEditComment,
}) => {
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const loggedInUser = useAppSelector(selectUser);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);

  const [deleteCommentIndex, setDeleteCommentIndex] = useState<number | null>(
    null
  );

  return (
    <div className={styles.comment}>
      <div className="d-flex flex-column justify-content-between me-4">
        <p className="me-2">{comment.text}</p>
        <div className={styles.commentBy}>
          <p>
            {comment.createdAt.substring(0, 16)} av{" "}
            <Tooltip arrow title="Gå till användarprofilen">
              <Link
                to={`/users/view/${comment.author.userId}`}
                className={styles.link}
              >
                {comment.author.userName}
              </Link>
            </Tooltip>
          </p>
        </div>
      </div>

      {deleteCommentIndex === index && confirmDeleteComment ? (
        <div className={styles.confirmDeleteComment}>
          <Button
            onClick={() => setConfirmDeleteComment(false)}
            variant="contained"
            size="small"
            className="me-2"
          >
            Behåll
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => handleDeleteComment(comment.id)}
          >
            Radera
          </Button>
        </div>
      ) : (
        <>
          {isAdmin || isUserAdmin ? (
            <div className={styles.commentIconsWrapper}>
              <Tooltip arrow title="Radera kommentar" placement="left">
                <ClearIcon
                  className={iconStyles.clearIcon}
                  onClick={() => {
                    setDeleteCommentIndex(index);
                    setConfirmDeleteComment(true);
                  }}
                />
              </Tooltip>

              {loggedInUser?.userName === comment.author.userName && (
                <Tooltip arrow title="Redigera" placement="left">
                  <EditIcon
                    className={iconStyles.editIconOrange}
                    onClick={() => handleEditComment(comment)}
                  />
                </Tooltip>
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Comment;
