import React, { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { CreatePhotoComment } from "models/PhotoComment";
import { addPhotoComment } from "pages/orders/viewChecklist/viewChecklistSlice";
import { Button } from "@mui/material";
import { Form } from "react-bootstrap";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";
import styles from "../../slideShow.module.scss";

const WriteCommentModal = ({ currentVerification, handleCloseModal }) => {
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState<string>("");

  const submitComment = (e) => {
    e.preventDefault();
    const requestBody: CreatePhotoComment = {
      mediaId: currentVerification.id,
      comment: comment,
    };
    dispatch(addPhotoComment(requestBody));

    handleCloseModal();
    setComment("");
  };

  let mediaType = "bilden";
  if (currentVerification.type === 2) {
    mediaType = "filmen";
  }

  return (
    <div className={containerStyles.overlay}>
      <div className={styles.writeComment}>
        <Form onSubmit={submitComment}>
          <p className="fw-bold text-dark">Kommentera {mediaType}</p>
          <Form.Control
            type="text"
            autoFocus
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={comment || ""}
            className="my-3"
          />

          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
              className="me-2"
            >
              Avbryt
            </Button>
            <Button disabled={comment === ""} type="submit" variant="contained">
              Spara
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WriteCommentModal;
