import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectShowUser, getUserStatistics } from "../showUserSlice";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import { BeatLoader } from "react-spinners";
import Rating from "@mui/material/Rating";
import { Row } from "react-bootstrap";

const ShowUserStatistics = () => {
  const shownUser = useAppSelector(selectShowUser);
  const userStatistics = shownUser?.userStatistics;
  const userId = shownUser.user?.id;
  const isLoading = shownUser.statisticsLoading;
  const dispatch = useAppDispatch();
  const [isInstaller, setIsInstaller] = useState(false);

  // Check if shownUser is installer and set variable
  useEffect(() => {
    if (
      shownUser?.user?.role === "EXTERNAL_INSTALLER" ||
      shownUser?.user?.role === "INTERNAL_INSTALLER"
    )
      setIsInstaller(true);
    else setIsInstaller(false);
  });

  // Get user statistics on first load
  useEffect(() => {
    if (userId && isInstaller) {
      dispatch(getUserStatistics(userId));
    }
  }, [userId, isInstaller]);

  if (!isInstaller) return null;
  else
    return (
      <div>
        <DataContainer>
          {isLoading ? (
            <div className="d-flex flex-column align-items-center justify-content-center my-5">
              <BeatLoader color="#5f5f5f" className="mb-3" />
              <p>Hämtar statistik...</p>
            </div>
          ) : (
            <>
              <div className="flex-column w-100">
                <Row className={"mb-2"}>
                  <h5 className="fw-bold">Genomsnittsbetyg</h5>
                </Row>

                <>
                  <div>
                    <p className="fw-bold mb-0">Genomsnittligt betyg på</p>
                    <p className="mb-0">
                      Installationer:{" "}
                      {userStatistics?.averageInstallationRating !== null &&
                      userStatistics?.averageInstallationRating !== 0
                        ? userStatistics?.averageInstallationRating
                        : "-"}
                    </p>
                    <p className="mb-0">
                      Kommentarer:{" "}
                      {userStatistics?.averageCommentRating !== null &&
                      userStatistics?.averageCommentRating !== 0
                        ? userStatistics?.averageCommentRating
                        : "-"}
                    </p>
                    <p className="mb-0">
                      Foto:{" "}
                      {userStatistics?.averagePhotoRating !== null &&
                      userStatistics?.averagePhotoRating !== 0
                        ? userStatistics?.averagePhotoRating
                        : "-"}
                    </p>
                  </div>

                  <div className="mt-4 fw-bold">
                    {userStatistics?.averageRating !== null &&
                    userStatistics?.averageRating !== 0 ? (
                      <>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Genomsnitt:</span>
                          <Rating
                            className=""
                            readOnly
                            precision={0.1}
                            value={userStatistics?.averageRating}
                          />
                          <span className="ms-2 mt-1">
                            ({userStatistics?.averageRating})
                          </span>
                        </div>
                      </>
                    ) : (
                      <>Totalt översiktsbetyg saknas</>
                    )}
                  </div>
                </>
              </div>
            </>
          )}
        </DataContainer>
      </div>
    );
};

export default ShowUserStatistics;
