import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FilterOrderStatus } from "utilities/orders";

const FilterByStatus = ({ filterByOrderStatus, setFilterByOrderStatus }) => {

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilterBy: void
  ) => {
    setFilterByOrderStatus(newFilterBy);
  };

  return (
    <div className="mb-5">
      <p className="mb-0">Orderstatus</p>
      <ToggleButtonGroup
        color="primary"
        value={filterByOrderStatus}
        exclusive
        onChange={handleChange}
        aria-label="Filtrera på orderstatus"
      >
        <ToggleButton value={FilterOrderStatus.ALL} sx={{ width: 70 }}>
          Alla
        </ToggleButton>
        <ToggleButton value={FilterOrderStatus.PENDING} sx={{ width: 70 }}>
          2
        </ToggleButton>
        <ToggleButton value={FilterOrderStatus.INCOMPLETE} sx={{ width: 70 }}>
          X
        </ToggleButton>
        <ToggleButton value={FilterOrderStatus.COMPLETE} sx={{ width: 70 }}>
          4
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default FilterByStatus;
