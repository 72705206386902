import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useHistory, useLocation } from "react-router";
import IPage from "interfaces/page";
import MainContainer from "features/containers/MainContainer/MainContainer";
import SearchBar from "features/searchBar/SearchBar";
import { DateRange } from "features/mui";
import { FilterOrderStatus } from "utilities/orders";
import {
  FilterOrdersParams,
  getOrders,
  selectCurrentFilters,
  selectHasMorePages,
  selectLoading,
  selectOrders,
  selectPreviousOrder,
  setLastViewedOrder,
} from "./ordersSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterOrders from "./components/FilterOrders";
import OrdersTableMui from "./components/OrdersTableMui";
import { BeatLoader } from "react-spinners";
import { Col, Row } from "react-bootstrap";

const Orders: React.FunctionComponent<IPage> = () => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const location = useLocation();
  const queryParams = useQuery();
  const orders = useAppSelector(selectOrders);
  const loading = useAppSelector(selectLoading);
  const currentFilters = useAppSelector(selectCurrentFilters);
  const hasMorePages = useAppSelector(selectHasMorePages);
  const [query, setQuery] = useState<string>("");
  const [filterNotRatedChecklists, setFilterNotRatedChecklists] =
    useState<boolean>(false);
  const [filterReferenceOrders, setFilterReferenceOrders] =
    useState<boolean>(false);
  const [filterCustomerFollowUpNotDone, setFilterCustomerFollowUpNotDone] =
    useState<boolean>(false);
  const [filterByOrderStatus, setFilterByOrderStatus] = useState<
    FilterOrderStatus | string | null
  >(FilterOrderStatus.ALL);

  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);
  const [filterParamsLoaded, setFilterParamsLoaded] = useState(false);
  const [sort, setSort] = useState<{ sortBy: string; sortType: string } | null>(
    null
  );
  const hasPreviousOrder = useAppSelector(selectPreviousOrder);

  const [filterParams, setFilterParams] = useState<FilterOrdersParams | null>({
    filterText: "",
    fromDate: null,
    toDate: null,
    filterByReferenceOrder: false,
    filterOnlyCustomerFollowUpNotDone: false,
    filterByOrderStatus: null,
    filterNotRatedChecklists: null,
    sort: null,
  });

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  // 1st useEffect: Sets state on initial render
  useEffect(() => {
    // ------------------- Check for currentFilters in queryParams -------------------
    // Check searchQuery from URL
    const filterSearchQueryParam = queryParams.get("filterText");
    if (filterSearchQueryParam) {
      setQuery(filterSearchQueryParam);
    }

    // Check sort from URL
    const sortByParam = queryParams.get("sortBy");
    const sortTypeParam = queryParams.get("sortType");

    if (sortByParam && sortTypeParam) {
      setSort({
        sortBy: sortByParam,
        sortType: sortTypeParam,
      });
    }

    // Check filterNotRatedChecklists
    const filterNotRatedChecklistsParam = queryParams.get(
      "filterNotRatedChecklists"
    );

    if (filterNotRatedChecklistsParam) {
      setFilterNotRatedChecklists(true);
    }

    // Check filterCustomerFollowUpNotDone
    const filterCustomerFollowUpNotDoneParam = queryParams.get(
      "filterOnlyCustomerFollowUpNotDone"
    );
    if (filterCustomerFollowUpNotDoneParam) {
      setFilterCustomerFollowUpNotDone(true);
    }

    // Check date params
    const fromDateParam = queryParams.get("fromDate");
    if (fromDateParam) {
      const toDateParam = queryParams.get("toDate");
      setDateRange([new Date(fromDateParam), new Date(toDateParam!)]);
    }

    // Check filterByReference
    const filterByReferenceOrders = queryParams.get("filterByReferenceOrder");
    if (filterByReferenceOrders) {
      setFilterReferenceOrders(true);
    }

    // ------------------- Check for currentFilters in reduxState -------------------
    if (currentFilters?.filterByOrderStatus) {
      setFilterByOrderStatus(currentFilters.filterByOrderStatus);
    }
    if (currentFilters?.filterNotRatedChecklists) {
      setFilterNotRatedChecklists(currentFilters.filterNotRatedChecklists);
    }
    if (currentFilters?.filterByReferenceOrder) {
      setFilterReferenceOrders(currentFilters.filterByReferenceOrder);
    }
    if (currentFilters?.filterOnlyCustomerFollowUpNotDone) {
      setFilterCustomerFollowUpNotDone(
        currentFilters.filterOnlyCustomerFollowUpNotDone
      );
    }
    if (currentFilters?.fromDate && currentFilters.toDate) {
      setDateRange([
        new Date(currentFilters.fromDate),
        new Date(currentFilters.toDate),
      ]);
    }
    if (currentFilters?.filterText) {
      setQuery(currentFilters.filterText);
    }

    // Check for sort in reduxState
    if (currentFilters?.sort) {
      setSort(currentFilters?.sort);
    }

    setFilterParamsLoaded(true);

    return () => {
      //dispatch(setLastViewedOrder(null));
    };
  }, []);

  // Wait to determine if user is done typing, if so set state variable for search query
  let queryTimer;
  const onSearch = (value) => {
    let filterText = currentFilters?.filterText;
    if (filterText === null) {
      if (queryTimer) {
        clearTimeout(queryTimer);
      }
      queryTimer = setTimeout(() => {
        setQuery(value);
      }, 500);
    } else if (filterText !== null) {
      setQuery(value);
    }
  };

  // 2nd useEffect: Runs on initial load and when any state in filter/sort is changed
  useEffect(() => {
    if (filterParamsLoaded) {
      const newFilterParamsState: FilterOrdersParams = {
        filterText: "",
        fromDate: null,
        toDate: null,
        filterByReferenceOrder: false,
        filterOnlyCustomerFollowUpNotDone: false,
        filterByOrderStatus: null,
        filterNotRatedChecklists: null,
        sort: null,
      };

      // Set searchQuery
      newFilterParamsState.filterText = query;

      // Set filterNotRatedChecklists
      newFilterParamsState.filterNotRatedChecklists = filterNotRatedChecklists;

      // Set Date
      if (dateRange[0] && dateRange[1]) {
        newFilterParamsState.fromDate = dateRange[0].toISOString();
        newFilterParamsState.toDate = dateRange[1].toISOString();
      } else {
        newFilterParamsState.toDate = null;
        newFilterParamsState.fromDate = null;
      }

      // Set filterByReference
      newFilterParamsState.filterByReferenceOrder = filterReferenceOrders;

      // Set filterByOrderStatus
      if (filterByOrderStatus === "ALL") {
        newFilterParamsState.filterByOrderStatus = null;
      } else if (
        filterByOrderStatus === "PENDING" ||
        "INCOMPLETE" ||
        "COMPLETE"
      ) {
        newFilterParamsState.filterByOrderStatus = filterByOrderStatus;
      }

      // Set FilterCustomerFollowUp
      newFilterParamsState.filterOnlyCustomerFollowUpNotDone =
        filterCustomerFollowUpNotDone;

      // Set sort
      newFilterParamsState.sort = sort;

      setFilterParams(newFilterParamsState);
    }
  }, [
    filterParamsLoaded,
    query,
    filterNotRatedChecklists,
    dateRange,
    filterReferenceOrders,
    filterCustomerFollowUpNotDone,
    filterByOrderStatus,
    sort,
  ]);

  // 3rd useEffect: Load on filterChange
  useEffect(() => {
    if (filterParamsLoaded) {
      // Set queryparams
      updateQueryParams();
      dispatch(getOrders(filterParams!));
    }
  }, [filterParams]);

  const countActiveFilters = (filterParams) => {
    let count = 0;

    if (filterParams) {
      Object.entries(filterParams).forEach((value) => {
        const filterName = value[0];
        const filterValue = value[1];
        if (
          filterValue &&
          filterName !== "sort" &&
          filterName !== "toDate" &&
          filterName !== "filterText"
        ) {
          count++;
        }
      });
    }
    return count;
  };

  const updateURL = (filterParams, sort) => {
    const queryParams = new URLSearchParams();

    if (filterParams) {
      Object.entries(filterParams).forEach((value) => {
        const filterName = value[0];
        const filterValue = value[1];

        if (filterValue && filterName !== "sort") {
          queryParams.append(filterName, String(filterValue));
        }
      });
    }

    if (sort && sort.sortBy && sort.sortType) {
      queryParams.append("sortBy", sort.sortBy);
      queryParams.append("sortType", sort.sortType);
    }

    const queryString = queryParams.toString();
    history.replace({ search: queryString });
  };

  const updateQueryParams = () => {
    const count = countActiveFilters(filterParams);
    updateURL(filterParams, sort);
    setActiveFiltersCount(count);
  };

  return (
    <>
      <MainContainer title="Ordrar">
        <Row className="px-2">
          <Col xs={12} lg={4} xl={4} xxl={3} className="p-1">
            <SearchBar
              label="Sök ordernr, företag"
              onChange={onSearch}
              value={query}
            />
          </Col>

          <Col xs={12} lg={8} xl={8} xxl={9} className="p-1">
            <FilterOrders
              activeFiltersCount={activeFiltersCount}
              dateRange={dateRange}
              setDateRange={setDateRange}
              filterNotRatedChecklists={filterNotRatedChecklists}
              setFilterNotRatedChecklists={setFilterNotRatedChecklists}
              filterReferenceOrders={filterReferenceOrders}
              setFilterReferenceOrders={setFilterReferenceOrders}
              filterCustomerFollowUpNotDone={filterCustomerFollowUpNotDone}
              setFilterCustomerFollowUpNotDone={
                setFilterCustomerFollowUpNotDone
              }
              filterByOrderStatus={filterByOrderStatus}
              setFilterByOrderStatus={setFilterByOrderStatus}
            />
          </Col>
        </Row>

        <InfiniteScroll
          initialScrollY={0}
          onScroll={(e) => {}}
          dataLength={orders ? orders.length : 0}
          next={() => {
            if (filterParamsLoaded && hasMorePages) {
              dispatch(getOrders(filterParams!));
            }
          }}
          scrollableTarget="scrollContainer"
          hasMore={hasMorePages && !loading}
          loader={
            <div className="d-flex flex-column align-items-center justify-content-center my-5">
              <BeatLoader color="#5f5f5f" className="mb-3" />
              <p>Scrolla för att hämta fler ordrar</p>
            </div>
          }
          endMessage={
            !hasMorePages && (
              <p className="text-center my-4">
                Det finns inga fler ordrar att hämta.
              </p>
            )
          }
        >
          <OrdersTableMui orders={orders} setSort={setSort} />
        </InfiniteScroll>
      </MainContainer>
    </>
  );
};

export default Orders;
