import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { selectLastViewedChecklist, selectOrder } from "../viewOrderSlice";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Rating,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HouseIcon from "@mui/icons-material/House";
import tableStyles from "../../../../utilities/styles/tableStyle.module.scss";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";

const ChecklistsTable = ({ filteredChecklists }) => {
  const history = useHistory();
  const order = useAppSelector(selectOrder);
  const hasPreviousChecklist = useAppSelector(selectLastViewedChecklist);
  const [sortedChecklists, setSortedChecklists] = useState(filteredChecklists);
  const [sortOrder, setSortOrder] = useState({
    column: "",
    direction: "",
  });

  // Scroll to last visited checklist (when everything is loaded)
  useEffect(() => {
    if (order) {
      scrollToVisitedChecklist();
    }
  }, [order]);

  const scrollToVisitedChecklist = () => {
    if (hasPreviousChecklist) {
      let element = document.getElementById(`${hasPreviousChecklist}`);
      element?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handleSort = (label) => {
    let direction = "asc";
    if (sortOrder.column === label && sortOrder.direction === "asc") {
      // If the current column is already sorted in ascending order, switch the direction to descending.
      direction = "desc";
    }
    // Update the state with the new sorting order.
    setSortOrder({ column: label, direction });
  };

  useEffect(() => {
    if (filteredChecklists) {
      let sortedList = [...filteredChecklists];

      if (sortOrder.column === "Namn") {
        sortedList.sort((a, b) => a.roomName.localeCompare(b.roomName));
      } else if (sortOrder.column === "Skapad av") {
        sortedList.sort((a, b) =>
          a.creator.userName.localeCompare(b.creator.userName)
        );
      } else if (sortOrder.column === "Funktionskontroll") {
        sortedList.sort((a, b) => a.functionCheck - b.functionCheck);
      } else if (sortOrder.column === "Godkänd av kund") {
        sortedList.sort((a, b) => a.approval - b.approval);
      } else if (sortOrder.column === "Dokumentation klar") {
        sortedList.sort((a, b) => a.documentationDone - b.documentationDone);
      } else if (sortOrder.column === "Ändrad") {
        sortedList.sort((a, b) => a.updatedAt.localeCompare(b.updatedAt));
      } else if (sortOrder.column === "Betyg") {
        sortedList.sort((a, b) => a.ratings.average - b.ratings.average);
      }

      if (sortOrder.direction === "desc") {
        sortedList.reverse();
      }

      setSortedChecklists(sortedList);
    }
  }, [sortOrder, filteredChecklists]);

  const handleClick = (checklistId) => {
    const checklistLink = `/orders/view/${order?.ordernr}/checklist/${checklistId}`;
    history.push(checklistLink);
  };

  const labels = [
    "Rumsnamn", // roomName
    "Skapad av", // creator.userName
    "Dokumentation klar", // documentationDone
    "Funktionskontroll", // functionCheck
    "Godkänd av kund", // approval
    "Ändrad", // updatedAt
    "Betyg", // ratings.average
  ];

  return (
    <>
      {sortedChecklists && sortedChecklists.length > 0 ? (
        <div className={tableStyles.checklistsTable}>
          <TableContainer>
            <Table size="small" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow sx={{ bgcolor: "rgba(220, 220, 220, 0.5)" }}>
                  {labels.map((label, index) => (
                    <TableCell key={index} sx={{}}>
                      <TableSortLabel
                        className="fw-bold py-2"
                        onClick={() => handleSort(label)}
                        active={sortOrder.column === label}
                        direction={
                          sortOrder.column === label
                            ? sortOrder.direction === "asc"
                              ? "desc"
                              : "asc"
                            : undefined
                        }
                      >
                        {label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& tr:nth-of-type(2n)": {
                    backgroundColor: "grey.100",
                  },
                }}
              >
                {sortedChecklists.map((checklist, index) => {
                  const isLastRow = index === sortedChecklists.length - 1;
                  return (
                    <TableRow
                      key={index}
                      id={checklist.id}
                      hover={true}
                      onClick={() => handleClick(checklist.id)}
                      className={isLastRow ? tableStyles.lastRow : ""}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell className="text-decoration-none">
                        <HouseIcon
                          className={`${iconStyles.houseIcon} ${
                            checklist.functionCheck &&
                            checklist.documentationDone
                              ? iconStyles.houseIconGreen
                              : iconStyles.houseIconGray
                          } me-2 `}
                        />

                        {checklist.roomName}
                      </TableCell>

                      <TableCell>{checklist.creator.userName}</TableCell>

                      <TableCell>
                        {checklist.documentationDone ? (
                          <CheckCircleOutlineIcon
                            className={iconStyles.yesIcon}
                          />
                        ) : (
                          <HighlightOffIcon className={iconStyles.noIcon} />
                        )}
                      </TableCell>

                      <TableCell>
                        {checklist.functionCheck ? (
                          <CheckCircleOutlineIcon
                            className={iconStyles.yesIcon}
                          />
                        ) : (
                          <HighlightOffIcon className={iconStyles.noIcon} />
                        )}
                      </TableCell>

                      <TableCell>
                        {checklist.approval ? (
                          <CheckCircleOutlineIcon
                            className={iconStyles.yesIcon}
                          />
                        ) : (
                          <HighlightOffIcon className={iconStyles.noIcon} />
                        )}
                      </TableCell>

                      <TableCell>{checklist.updatedAt}</TableCell>

                      <TableCell>
                        {checklist.ratings.average === null ||
                        checklist.ratings.average === 0 ? (
                          "-"
                        ) : (
                          <Rating
                            value={checklist.ratings.average}
                            readOnly
                            precision={0.1}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <p>Din sökning gav inga resultat</p>
      )}
    </>
  );
};

export default ChecklistsTable;
