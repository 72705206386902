import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import IPage from "interfaces/page";
import { Company } from "models/Company";
import {
  getAllCompanies,
  selectLoaded,
  selectLoading,
  selectCompanies,
  selectIsInvalidated,
} from "./companiesSlice";
import SearchBar from "features/searchBar/SearchBar";
import MainContainer from "features/containers/MainContainer/MainContainer";
import AddNewCompanyModal from "./addNewCompany/AddNewCompanyModal";
import MapWrapper from "./components/map/MapWrapper";
import CompaniesTable from "./CompaniesTable";
import CompaniesTableAdvanced from "./CompaniesTableAdvanced";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Spinner } from "react-bootstrap";
import { Button } from "@mui/material";

const Companies: React.FunctionComponent<IPage> = (props) => {
  const { name } = props;
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoading);
  const isLoaded = useAppSelector(selectLoaded);
  const isInvalidated = useAppSelector(selectIsInvalidated);
  const companies = useAppSelector(selectCompanies);
  const [showAddNewCompany, setShowAddNewCompany] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [showAs, setShowAs] = useState("simple");

  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    if (showAs === "advanced") {
      setShowAs("simple");
    } else setShowAs("advanced");
  };

  useEffect(() => {
    if (!isLoaded || isInvalidated) {
      dispatch(getAllCompanies());
    }
  }, [isLoaded, isInvalidated, dispatch]);

  const onSearch = (queryString: string) => {
    setQuery(queryString);
  };

  let filteredCompanies: Company[] | null | undefined = companies;

  if (query) {
    filteredCompanies = companies?.filter((company) =>
      company.namn.toLowerCase().includes(query.toLowerCase())
    );
  }

  return (
    <>
      <MainContainer title={name}>
        <div className="d-flex justify-content-between">
          <div style={{ width: 250 }}>
            <SearchBar label="Sök företag" onChange={onSearch} value={query} />
          </div>

          <div className="mb-4">
            <Button
              variant="contained"
              onClick={() => setShowAddNewCompany(true)}
            >
              Skapa nytt företag
            </Button>
          </div>
        </div>

        {isLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner animation={"border"} />
          </div>
        ) : (
          <>
            <MapWrapper />

            <div className="d-flex justify-content-center mb-3">
              <ToggleButtonGroup
                color="primary"
                value={showAs}
                exclusive
                onChange={handleChange}
                aria-label="Simple/Advanced table"
              >
                <ToggleButton value="simple">Standard</ToggleButton>
                <ToggleButton value="advanced">Detaljerad</ToggleButton>
              </ToggleButtonGroup>
            </div>

            {showAs === "simple" ? (
              <CompaniesTable
                filteredCompanies={filteredCompanies}
                isLoaded={isLoaded}
              />
            ) : (
              <CompaniesTableAdvanced
                filteredCompanies={filteredCompanies}
                isLoaded={isLoaded}
              />
            )}
          </>
        )}
      </MainContainer>
      <AddNewCompanyModal
        handleClose={() => setShowAddNewCompany(false)}
        show={showAddNewCompany}
      ></AddNewCompanyModal>
    </>
  );
};

export default Companies;
