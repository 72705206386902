import { TimePeriodFilters } from "pages/dashboard/components/statistics/TopInstallers";

export function getTimePeriodFiltersTimePeriodFiltersString(
  value: TimePeriodFilters
): string {
  switch (value) {
    case TimePeriodFilters.FROM_START:
      return "Från början";
    case TimePeriodFilters.PAST_MONTH:
      return "Senaste månaden";
    case TimePeriodFilters.PAST_WEEK:
      return "Senaste veckan";
    case TimePeriodFilters.PAST_YEAR:
      return "Senaste året";
  }
}
