import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm } from "hooks/useForm";
import { getAllUsers, selectUsers } from "pages/users/usersSlice";
import {
  getAllCompanies,
  selectCompanies,
  selectLoading as selectCompaniesLoading,
} from "pages/companies/companiesSlice";
import { Modal } from "react-bootstrap";
import {
  editProfile,
  selectProfileLoading,
  selectUser,
  selectUserRole,
} from "features/auth/authSlice";
import { User, UserRole } from "features/auth/types/user";
import { CreateUser } from "pages/users/addNewUser/AddNewUserModal";
import EditProfileForm from "./EditProfileForm";

export interface IModalProps {
  handleClose: () => void;
  show: boolean;
}

const EditProfile: React.FunctionComponent<IModalProps> = (props) => {
  const { show, handleClose, children } = props;
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const userToEdit = useAppSelector(selectUser);
  const editInProgress = useAppSelector(selectProfileLoading);
  const companies = useAppSelector(selectCompanies);
  const companiesLoading = useAppSelector(selectCompaniesLoading);
  const userRole = useAppSelector(selectUserRole);

  useEffect(() => {
    fetchExtraData();
  });

  const fetchExtraData = () => {
    if (!companies && (userRole === "ADMIN" || userRole === "USER_ADMIN")) {
      dispatch(getAllCompanies());
    }
  };

  const {
    handleSubmit,
    handleChange,
    data: userData,
    errors,
  } = useForm<CreateUser>({
    validations: {
      userName: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett namn.",
        },
      },
      email: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig epostadress.",
        },
      },
      phone: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett telefonnummer.",
        },
      },
      companyId: {
        custom: {
          isValid: (value) => {
            if (userData.role === UserRole.EXTERNAL_INSTALLER) {
              return value !== null && value.length > 0;
            }
            return true;
          },
          message: "Välj ett företag",
        },
      },
      role: {
        custom: {
          isValid: (value) => value.length > 1,
          message: "Välj en roll",
        },
      },
    },
    initialValues: {
      id: userToEdit?.id,
      userName: userToEdit?.userName,
      email: userToEdit?.email,
      phone: userToEdit?.phone,
      companyId: userToEdit?.companyId,
      admin: userToEdit?.admin,
      externalInstaller: userToEdit?.externalInstaller,
      internalInstaller: userToEdit?.internalInstaller,
      coworker: userToEdit?.coworker,
      econ: userToEdit?.econ,
      state: userToEdit?.state,
      anstnrHogia: userToEdit?.anstnrHogia,
      role: userToEdit?.role,
      controllerId: userToEdit?.controllerId ? userToEdit?.controllerId : null,
    },
    onSubmit: () => {
      // Lookup controller name
      if (userData.controllerId) {
        const user = adminUsers.find((user) => {
          return user.id === userData.controllerId;
        });

        if (user) {
          userData.controllerName = user.userName;
        }
      }

      // Validate data
      if (userData.anstnrHogia === "") {
        userData.anstnrHogia = null;
      }

      // Update user
      dispatch(editProfile(userData));

      // Close modal
      handleClose();
    },
  });

  useEffect(() => {
    if (users) {
      // Sort Users
      let sorted = [...users];
      sorted.sort((a, b) => {
        if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;
        else return -1;
      });
      // Filter only admins
      const filtered = sorted.filter((user) => {
        return user.role === UserRole.ADMIN;
      });

      setAdminUsers(filtered);
    } else {
      if (userRole === "ADMIN" || userRole === "USER_ADMIN") {
        dispatch(getAllUsers());
      }
    }
  }, [users]);

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Redigera profil</Modal.Title>
        </Modal.Header>
        <EditProfileForm
          handleSubmit={handleSubmit}
          companiesLoading={companiesLoading}
          handleChange={handleChange}
          errors={errors}
          userData={userData}
          userToEdit={userToEdit}
          companies={companies}
          adminUsers={adminUsers}
          handleClose={handleClose}
          editInProgress={editInProgress}
        />
      </Modal>
    </>
  );
};

export default EditProfile;
