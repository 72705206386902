import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getUserRoleName, User } from "features/auth/types/user";
import {
  getAllUsers,
  selectisInvalidated,
  selectLoaded,
  selectLoading,
  selectUsers,
} from "./usersSlice";
import IPage from "interfaces/page";
import MainContainer from "features/containers/MainContainer/MainContainer";
import SearchBar from "features/searchBar/SearchBar";
import AddNewUserModal from "./addNewUser/AddNewUserModal";
import UsersTable from "./UsersTable";
import BeatLoader from "react-spinners/BeatLoader";
import PendingUsers from "./PendingUsers";
import { Button } from "@mui/material";

const Users: React.FunctionComponent<IPage> = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoading);
  const isLoaded = useAppSelector(selectLoaded);
  const isInvalidated = useAppSelector(selectisInvalidated);
  const users = useAppSelector(selectUsers);
  const [addNewUserShowing, setAddNewUserShowing] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(getAllUsers());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isInvalidated) {
      dispatch(getAllUsers());
    }
  }, [isInvalidated]);

  // New filter/search
  const onSearch = (queryString: string) => {
    setQuery(queryString);
  };

  let filteredUsers: User[] | null | undefined = users;

  if (query) {
    filteredUsers = users?.filter(
      (user) =>
        user.userName.toLowerCase().includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        getUserRoleName(user.role).toLowerCase().includes(query.toLowerCase())
    );
  }

  return (
    <MainContainer title="Användare">
      <div className="d-flex justify-content-between">
        <div style={{ width: 250 }}>
          <SearchBar
            label="Sök namn, e-post, roll"
            onChange={onSearch}
            value={query}
          />
        </div>

        <div className="mb-4">
          <Button
            variant="contained"
            onClick={() => {
              setAddNewUserShowing(true);
            }}
          >
            Skapa ny användare
          </Button>
        </div>
      </div>

      <PendingUsers users={users} />

      {isLoading ? (
        <div className="d-flex flex-column align-items-center justify-content-center my-5">
          <BeatLoader color="#5f5f5f" className="mb-3" />
          <p>Hämtar användare</p>
        </div>
      ) : (
        <UsersTable filteredUsers={filteredUsers} isLoaded={isLoaded} />
      )}

      <AddNewUserModal
        handleClose={() => {
          setAddNewUserShowing(false);
        }}
        show={addNewUserShowing}
      />
    </MainContainer>
  );
};

export default Users;
