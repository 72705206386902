import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  resetUploadFile,
  selectChecklist,
  UploadFileType,
  uploadPhoto,
  uploadVideo,
  viewChecklist,
} from "../viewChecklistSlice";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";

const UploadFile = (props) => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const viewChecklistState = useAppSelector(viewChecklist);
  const fileIsLoading = viewChecklistState.fileIsLoading;

  const handleModalClose = () => {
    props.onCloseModal();
  };

  const handleFileChange = (event) => {
    // Update the state with the selected file
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (checklist && selectedFile) {
      const fileToUpload: UploadFileType = {
        file: selectedFile,
        checklistId: checklist?.id,
      };

      // Determine if image or video
      if (fileToUpload.file.type === "video/mp4") {
        dispatch(uploadVideo(fileToUpload));
      } else if (fileToUpload.file.type === "image/jpeg") {
        dispatch(uploadPhoto(fileToUpload));
      }
    }
  };

  useEffect(() => {
    if (viewChecklistState.fileWasUploaded) {
      handleModalClose();
      notifyAdd();
    }
  }, [viewChecklistState.fileWasUploaded]);

  const notifyAdd = () => {
    if (viewChecklistState.error === null) {
      toast.success("Filen har laddats upp!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else
      toast.error("Någonting gick fel när filen skulle laddas upp.", {
        autoClose: 3000,
        hideProgressBar: true,
      });

    dispatch(resetUploadFile());
  };

  return (
    <>
      <ToastContainer position="top-center" />

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ladda upp bild eller film</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fileIsLoading ? (
            <div className="d-flex flex-column align-items-center justify-content-center my-3">
              <BeatLoader color="#5f5f5f" className="mb-3" />
              <p>Vänta medan filen laddas upp</p>
            </div>
          ) : (
            <>
              <input type="file" onChange={handleFileChange} />
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="contained"
            color="error"
            className="me-2"
            onClick={handleModalClose}
          >
            Avbryt
          </Button>

          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={selectedFile === null}
          >
            Ladda upp
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadFile;
