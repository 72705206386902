import React, { useEffect, useState } from "react";
import { Comment } from "models/Comment";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  addInternalComment,
  deleteInternalComment,
  editInternalComment,
  resetInternalComments,
  selectChecklist,
  viewChecklist,
} from "../../viewChecklistSlice";
import { Form, Modal } from "react-bootstrap";
import { Button, Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";
import EditInternalComments from "./EditInternalComments";

const EditInternalComment = ({ nameHidden }) => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const [commentString, setCommentString] = useState<string>("");
  const [newCommentString, setNewCommentString] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updatedComments, setUpdatedComments] = useState<Comment[]>([]);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [commentBeingEdited, setCommentBeingEdited] = useState("");
  const internalComments = checklist?.internalComments;
  const viewChecklistState = useAppSelector(viewChecklist);

  useEffect(() => {
    if (internalComments) {
      setUpdatedComments(internalComments);
    }
  }, [internalComments]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDelete = (commentId: string) => {
    setConfirmDelete(false);
    dispatch(deleteInternalComment(commentId));

    setTimeout(() => {
      dispatch(resetInternalComments());
    }, 3000);
  };

  const addComment = () => {
    if (checklist) {
      const requestBody = {
        message: newCommentString,
        listId: checklist.id,
      };

      dispatch(addInternalComment(requestBody));

      setTimeout(() => {
        dispatch(resetInternalComments());
      }, 3000);
    }
    setNewCommentString("");
  };

  const confirmChange = (commentId: string) => {
    if (checklist) {
      const requestBody = {
        message: commentString,
        commentId: commentId,
      };

      dispatch(editInternalComment(requestBody));

      setTimeout(() => {
        dispatch(resetInternalComments());
      }, 3000);

      setEdit(false);
      setCommentString("");
      setCommentBeingEdited("");
    }
  };

  const handleChange = (message: string, commentId: string, index: number) => {
    setEdit(true);
    setEditIndex(index);
    let tempState = updatedComments.map((comment) => {
      if (comment.id === commentId) {
        let tempComment = { ...comment, message: message };

        return tempComment;
      }
      return comment;
    });

    setCommentString(message);
    setCommentBeingEdited(message);
    setUpdatedComments(tempState);
  };

  const notifyAdd = () => {
    if (viewChecklistState.error === null)
      toast.success("Kommentaren har lagts till!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    else {
      toast.error("Något gick fel när kommentaren skulle läggas till. ", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const notifyEdit = () => {
    if (viewChecklistState.error === null) {
      toast.success("Kommentaren har redigerats!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när kommentaren skulle redigeras. ", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const notifyDelete = () => {
    if (viewChecklistState.error === null) {
      toast.success("Kommentaren har raderats!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när kommentaren skulle raderas.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (viewChecklistState.internalCommentWasAdded) {
      notifyAdd();
    }

    if (viewChecklistState.internalCommentWasEdited) {
      notifyEdit();
    }
    if (viewChecklistState.internalCommentWasDeleted) {
      notifyDelete();
    }
  }, [
    viewChecklistState.internalCommentWasAdded,
    viewChecklistState.internalCommentWasEdited,
    viewChecklistState.internalCommentWasDeleted,
  ]);

  return (
    <>
      <ToastContainer position="top-center" />
      <Tooltip arrow title={<>Redigera interna kommentarer</>}>
        <EditOutlinedIcon
          onClick={handleClick}
          className={iconStyles.editIcon}
        />
      </Tooltip>

      <Modal show={showModal} onHide={handleClose} size="xl">
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Redigera interna kommentarer</Modal.Title>
          </Modal.Header>

          {internalComments && internalComments?.length > 0 && (
            <Modal.Body>
              <EditInternalComments
                updatedComments={updatedComments}
                handleChange={handleChange}
                deleteIndex={deleteIndex}
                confirmDelete={confirmDelete}
                handleDelete={handleDelete}
                setConfirmDelete={setConfirmDelete}
                editIndex={editIndex}
                setEdit={setEdit}
                setEditIndex={setEditIndex}
                edit={edit}
                confirmChange={confirmChange}
                setDeleteIndex={setDeleteIndex}
                setNewCommentString={setNewCommentString}
                addComment={addComment}
                commentBeingEdited={commentBeingEdited}
                nameHidden={nameHidden}
              />
            </Modal.Body>
          )}

          {/* NEW Comment */}
          <Modal.Footer>
            <div className="d-flex justify-content-between mb-2 w-100">
              <Form.Control
                type="text"
                placeholder="Lägg till ny kommentar"
                onChange={(e) => {
                  setNewCommentString(e.target.value);
                }}
                value={newCommentString}
              />

              <Button
                disabled={newCommentString === ""}
                variant="contained"
                className="ms-2"
                onClick={addComment}
                type="submit"
              >
                Spara
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditInternalComment;
