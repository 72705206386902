import React from "react";
import { UserRole } from "features/auth/types/user";

const UserInfoFields = ({ shownUser }) => {
  return (
    <div>
      <p>
        <span className="fw-bold">Användarnamn: </span>
        {shownUser?.userName}
      </p>

      <p>
        <span className="fw-bold">E-post: </span>
        {shownUser?.email}
      </p>
      {shownUser?.requestedCompanyName && (
        <p>
          <span className="fw-bold">Önskat företag: </span>
          {shownUser?.requestedCompanyName}{" "}
        </p>
      )}

      <p>
        <span className="fw-bold">Telefon: </span>
        {shownUser?.phone}
      </p>
      {shownUser?.role === UserRole.EXTERNAL_INSTALLER && (
        <p>
          <span className="fw-bold">Företag: </span>
          {shownUser?.companyName}
        </p>
      )}
      <p>
        <span className="fw-bold">Roll: </span>
        {shownUser?.role === UserRole.ADMIN && "Administratör"}
        {shownUser?.role === UserRole.INTERNAL_INSTALLER &&
          "Intern installatör"}
        {shownUser?.role === UserRole.EXTERNAL_INSTALLER &&
          "Extern installatör"}
        {shownUser?.role === UserRole.EMPLOYEE && "Medarbetare"}
        {shownUser?.role === UserRole.PAYROLL && "Ekonomi"}
        {shownUser?.role === UserRole.USER_ADMIN && "Användaradmin"}
        {shownUser?.role === UserRole.SALES && "Säljare"}
      </p>
      <p>
        <span className="fw-bold">Status: </span>
        {shownUser?.state === "PENDING" && "Väntar på godkännande"}
        {shownUser?.state === "ACTIVE" && "Aktiv"}
        {shownUser?.state === "CREATED" && "E-post ej verifierad"}{" "}
      </p>
      {shownUser?.role && shownUser.role !== UserRole.EXTERNAL_INSTALLER && (
        <p>
          <span className="fw-bold">Anställningsnummer: </span>
          {shownUser?.anstnrHogia ? shownUser?.anstnrHogia : "Ej tillagt"}
        </p>
      )}
      <p>
        <span className="fw-bold">Attesterare: </span>
        {shownUser?.controllerId && shownUser.controllerName
          ? shownUser.controllerName
          : "Ingen tillagd"}
      </p>
    </div>
  );
};

export default UserInfoFields;
