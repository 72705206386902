import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { selectChecklist } from "../../viewChecklistSlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import SerialComponent from "./SerialComponent";
import EditProducts from "./EditProducts";
import {
  selectProductsState,
  updateProductsFromChecklist,
} from "./productsSlice";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./products.module.scss";
import textStyles from "../../../../../utilities/styles/textStyles.module.scss";

const Products = () => {
  const dispatch = useDispatch();
  const checklist = useAppSelector(selectChecklist);
  const productsState = useAppSelector(selectProductsState);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);
  const userIsUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const products = productsState.products;

  // Populating the productsState with the correct serialEntities
  useEffect(() => {
    if (checklist?.serialEntities) {
      dispatch(updateProductsFromChecklist(checklist.serialEntities));
    }
  }, [checklist]);

  return (
    <DataContainer>
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between">
          <p className="fw-bold">Produkter ({products.length} st) </p>
          {userIsAdmin || userIsUserAdmin ? <EditProducts /> : ""}
        </div>

        {checklist && checklist?.serialEntities.length > 2 ? (
          <div className={styles.products}>
            <Accordion className={styles.customAccordion}>
              <AccordionSummary
                className={styles.accordionButton}
                expandIcon={<ExpandMoreIcon />}
              >
                Visa alla
              </AccordionSummary>

              <AccordionDetails>
                <div className="d-flex flex-wrap justify-content-between">
                  {products.map((serial, index) => {
                    return (
                      <SerialComponent
                        key={serial.id}
                        index={index}
                        serial={serial}
                      />
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <div className="d-flex flex-wrap justify-content-between">
            {products.map((serial, index) => {
              return (
                <SerialComponent
                  key={serial.id}
                  index={index}
                  serial={serial}
                />
              );
            })}
          </div>
        )}

        {products.length < 1 && (
          <p className={textStyles.smallText}>Inga tillagda</p>
        )}
      </div>
    </DataContainer>
  );
};

export default Products;
