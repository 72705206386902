import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { getUserRoleName } from "features/auth/types/user";
import { selectLastViewedUser } from "./usersSlice";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import tableStyles from "../../utilities/styles/tableStyle.module.scss";

const UsersTable = ({ filteredUsers, isLoaded }) => {
  const history = useHistory();
  const hasPreviousUser = useAppSelector(selectLastViewedUser);
  const [sortedUsers, setSortedUsers] = useState(filteredUsers);
  const [sortOrder, setSortOrder] = useState({
    column: "",
    direction: "",
  });

  // Scroll to visited user (when everything is loaded)
  useEffect(() => {
    if (isLoaded) {
      scrollToVisitedUser();
    }
  }, [isLoaded]);

  const scrollToVisitedUser = () => {
    if (hasPreviousUser) {
      let element = document.getElementById(`${hasPreviousUser}`);
      element?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handleSort = (label) => {
    let direction = "asc";
    if (sortOrder.column === label && sortOrder.direction === "asc") {
      direction = "desc";
    }
    setSortOrder({ column: label, direction });
  };

  const labels = ["Namn", "E-post", "Roll"];

  useEffect(() => {
    if (filteredUsers) {
      let sortedList = [...filteredUsers];

      if (sortOrder.column === "Namn") {
        sortedList.sort((a, b) => a.userName.localeCompare(b.userName));
      } else if (sortOrder.column === "E-post") {
        sortedList.sort((a, b) => a.email.localeCompare(b.email));
      } else if (sortOrder.column === "Roll") {
        sortedList.sort((a, b) =>
          getUserRoleName(a.role).localeCompare(getUserRoleName(b.role))
        );
      }

      if (sortOrder.direction === "desc") {
        sortedList.reverse();
      }

      setSortedUsers(sortedList);
    }
  }, [sortOrder, filteredUsers]);

  const handleClick = (userId) => {
    const userLink = `/users/view/${userId}`;
    history.push(userLink);
  };

  return (
    <>
      {sortedUsers && sortedUsers.length > 0 ? (
        <>
          <div className={tableStyles.usersTable}>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table size="small" sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: "rgba(220, 220, 220, 0.5)" }}>
                    {labels.map((label, index) => (
                      <TableCell key={index}>
                        <TableSortLabel
                          className="fw-bold py-2"
                          onClick={() => handleSort(label)}
                          active={sortOrder.column === label}
                          direction={
                            sortOrder.column === label
                              ? sortOrder.direction === "asc"
                                ? "desc"
                                : "asc"
                              : undefined
                          }
                        >
                          {label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    "& tr:nth-of-type(2n)": {
                      backgroundColor: "grey.100",
                    },
                  }}
                >
                  {sortedUsers.map((user, index) => {
                    const isLastRow = index === sortedUsers.length - 1;
                    const pending = user.state === "PENDING";

                    return (
                      <TableRow
                        key={index}
                        id={user.id}
                        hover={true}
                        onClick={() => handleClick(user.id)}
                        className={`${isLastRow ? tableStyles.lastRow : ""} ${
                          pending ? tableStyles.pending : ""
                        }`}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>{user.userName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {getUserRoleName(user.role)}
                          {pending ? " (Väntar på godkännande)" : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <p>Din sökning gav inga resultat</p>
      )}
    </>
  );
};

export default UsersTable;
