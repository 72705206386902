import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

import axios from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";

export type RequestResetData = {
  email: string;
};
export type ResetPasswordData = {
  email: string;
  newPassword: string;
  confirmPassword: string;
  code: string;
};
// export type ResetPasswordResponse = {
//   userId: string;
// };

interface ResetPasswordState {
  status: "idle" | "emailSent" | "done" | "loading" | "error";
  error: string | null;
  recoveringUserEmail: string | null;
  isLoading: boolean;
}

const initialState: ResetPasswordState = {
  status: "idle",
  error: null,
  recoveringUserEmail: null,
  isLoading: false,
};

export const requestReset = createAsyncThunk<
  RequestResetData,
  RequestResetData,
  { rejectValue: GenericError; state: RootState }
>("resetPassword/requestReset", async (data, thunkApi) => {
  const response = await axios
    .post<RequestResetData>(`${BE_URL}/user/recovery`, data)
    .then((res) => {
      //   setRecoveringUserId(data.email);
      return data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Inget konto med den angivna e-postadressen hittades.",
        });
      }
    });
  return response as RequestResetData;
});
export const verifyResetCode = createAsyncThunk<
  string,
  ResetPasswordData,
  { rejectValue: GenericError; state: RootState }
>("resetPassword/verifyResetCode", async (data, thunkApi) => {
  const response = await axios
    .post<string>(`${BE_URL}/user/recover/`, data)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Koden du angav är fel.",
        });
      } else {
        return thunkApi.rejectWithValue({
          message: "Nånting gick fel, försök igen.",
        });
      }
    });
  return response as string;
});

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.status = "loading";
      state.isLoading = true;
    },
    resetState: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestReset.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(requestReset.fulfilled, (state, { payload }) => {
        state.status = "emailSent";
        state.isLoading = false;
        state.recoveringUserEmail = payload.email;
      })
      .addCase(requestReset.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.error = payload.message;
        }
      })
      .addCase(verifyResetCode.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(verifyResetCode.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.isLoading = false;
      })
      .addCase(verifyResetCode.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.error = payload.message;
        }
      });
  },
});

export const { setIsLoading, resetState } = resetPasswordSlice.actions;
export const selectError = (state: RootState) => state.resetPassword.error;
export const selectStatus = (state: RootState) => state.resetPassword.status;
export const selectLoading = (state: RootState) =>
  state.resetPassword.isLoading;
export const selectUserEmail = (state: RootState) =>
  state.resetPassword.recoveringUserEmail;

export default resetPasswordSlice.reducer;
