import React, { useEffect, useState } from "react";

const Average = (props) => {
  const { installationRatingValue, picturesRatingValue, commentsRatingValue } =
    props;

  const [updatedAverage, setUpdatedAverage] = useState<number>(0);

  useEffect(() => {
    calculateAverage();
  });

  const calculateAverage = () => {
    // Create an array of non-zero values by filtering out the 0-values
    const nonZeroValues = [
      installationRatingValue,
      picturesRatingValue,
      commentsRatingValue,
    ].filter((value) => value !== 0 && value !== null);

    console.log(`noZeroValues ${nonZeroValues}`);

    // Calculate the sum of non-zero values using reduce
    const sum = nonZeroValues.reduce(
      (accumulator, value) => accumulator + value,
      0
    );

    // Get the count of non-zero values
    const count = nonZeroValues.length;

    // Calculate the average by dividing the sum by the count,
    // or set it to 0 if count is 0 (to avoid divide by zero error)
    const averageRatingValue = count !== 0 ? sum / count : 0;

    // Round the average to a maximum of two decimal places
    const roundedAverage = Math.round(averageRatingValue * 100) / 100;

    setUpdatedAverage(roundedAverage);
  };

  return (
    <div className="d-flex justify-content-between mt-4 align-content-end">
      <div>
        <p className="fw-bold mb-2">Genomsnitt</p>
      </div>
      <span>{updatedAverage === 0 ? "-" : updatedAverage}</span>
    </div>
  );
};

export default Average;
