import React from "react";

const CompanyInfoFields = (props) => {
  const { shownCompany } = props;

  return (
    <>
      <p>
        <span className="fw-bold">Företagsnamn: </span>
        {shownCompany?.namn}
      </p>
      <p>
        <span className="fw-bold">Leverantörskod: </span>
        {shownCompany?.uecode ?? "Leverantörskod saknas"}
      </p>

      <p>
        <span className="fw-bold">Orgnummer: </span>
        {shownCompany?.orgnr ?? "Organisationsnummer saknas"}
      </p>

      <p>
        <span className="fw-bold">E-post: </span>
        {shownCompany?.mail ?? "E-post saknas"}
      </p>

      <p>
        <span className="fw-bold">Telefon: </span>
        {shownCompany?.phone ?? "Telefonnummer saknas"}
      </p>

      <p>
        <span className="fw-bold">Adress: </span>
        {`${shownCompany?.address}, ${shownCompany?.postnr} ${shownCompany?.postaddress}`}
      </p>

      <p>
        <span className="fw-bold">Timpris: </span>
        {shownCompany?.rate && shownCompany.rate > 0 ? (
          <span>{shownCompany.rate} kr/h</span>
        ) : (
          <span>Inte angivet</span>
        )}
      </p>

      <p>
        <span className="fw-bold">Kompetens: </span>
        {shownCompany?.competence ? shownCompany.competence : "Inte angivet"}
      </p>

      <p>
        <span className="fw-bold">Kommentar: </span>
        {shownCompany?.comment ? shownCompany.comment : "Kommentar saknas"}
      </p>
    </>
  );
};

export default CompanyInfoFields;
