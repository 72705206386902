import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import IPage from "interfaces/page";
import AuthHeader from "features/authHeader/AuthHeader";
import PendingApprovalComponent from "features/pendingApprovalComponent";
import MainContainer from "features/containers/MainContainer/MainContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  getInstallerTypeFilterString,
  getTimePeriodFiltersTimePeriodFiltersString,
  InstallerTypeFilter,
  TimePeriodFilters,
} from "pages/dashboard/components/statistics/TopInstallers";
import { BeatLoader } from "react-spinners";
import { useForm } from "hooks/useForm";
import { TopFiveInstallersRequest } from "models/InstallerChecklistStatistics";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getAllInstallersStatistics,
  selectAllInstallersStatisticsState,
} from "pages/dashboard/statisticsSlice";
import { useHistory } from "react-router-dom";

const InstallerStatistics: React.FunctionComponent<IPage> = (props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectAllInstallersStatisticsState);
  const history = useHistory();

  const [sortOrder, setSortOrder] = useState<SortPrams>({
    sortDirection: null,
    sortKey: null,
  });
  const [sortedStatistics, setSortedStatistics] = useState(
    state.allInstallerStatistics
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setSortOrder({ sortDirection: null, sortKey: null });
    setSortedStatistics(state.allInstallerStatistics);
  }, [state.allInstallerStatistics]);

  const { data: allInstallersFilters } = useForm<TopFiveInstallersRequest>({
    initialValues: {
      timePeriod: TimePeriodFilters[TimePeriodFilters.FROM_START],
      installerType: InstallerTypeFilter[InstallerTypeFilter.ALL],
      onlyIncludeApprovedChecklists: true,
    },
  });

  function installerTypeSelected(installerType: InstallerTypeFilter) {
    if (
      InstallerTypeFilter[installerType].toString() !==
      allInstallersFilters.installerType
    ) {
      allInstallersFilters.installerType = InstallerTypeFilter[installerType];
      fetchData();
    }
  }

  function timePeriodSelected(timePeriod: TimePeriodFilters) {
    if (
      TimePeriodFilters[timePeriod].toString() !==
      allInstallersFilters.timePeriod
    ) {
      allInstallersFilters.timePeriod = TimePeriodFilters[timePeriod];
      fetchData();
    }
  }

  function onlyIncludeApprovedSelected(value: boolean) {
    if (allInstallersFilters.onlyIncludeApprovedChecklists !== value) {
      allInstallersFilters.onlyIncludeApprovedChecklists = value;
      fetchData();
    }
  }

  const fetchData = () => {
    console.log(`fetching data with params: ${allInstallersFilters}`);
    dispatch(getAllInstallersStatistics(allInstallersFilters));
  };

  const labels: TableColumn[] = [
    {
      value: "Namn",
      key: InstallerStatisticsSortKey.NAME,
    },
    {
      value: "Antal checklistor",
      key: InstallerStatisticsSortKey.NUMBER_OF_CHECKLISTS,
    },
    {
      value: "Genomsnittsbetyg",
      key: InstallerStatisticsSortKey.AVERAGE,
    },
    {
      value: "Installation",
      key: InstallerStatisticsSortKey.INSTALLATION_AVERAGE,
    },
    {
      value: "Foto",
      key: InstallerStatisticsSortKey.PHOTO_AVERAGE,
    },
    {
      value: "Kommentar",
      key: InstallerStatisticsSortKey.COMMENT_AVERAGE,
    },
  ];

  const cellWidths = ["40%", "20%", "10%", "10%", "10%", "10%"];

  const handleSort = (label: InstallerStatisticsSortKey) => {
    let direction = SortDirection.ASC;

    if (
      sortOrder.sortKey === label &&
      sortOrder.sortDirection === SortDirection.ASC
    ) {
      // If the current column is already sorted in ascending order, switch the direction to descending.
      direction = SortDirection.DESC;

      // Update the state with the new sorting order.
      setSortOrder({ sortKey: label, sortDirection: direction });
      return;
    }

    if (
      sortOrder.sortKey === label &&
      sortOrder.sortDirection === SortDirection.DESC
    ) {
      // If the current column is already sorted in ascending order, switch the direction to descending.

      // Update the state with the new sorting order.
      setSortOrder({ sortKey: null, sortDirection: null });
      return;
    }

    setSortOrder({ sortKey: label, sortDirection: direction });
  };

  useEffect(() => {
    sortBySelectedParams();
  }, [sortOrder]);

  function sortAscending(
    a: string | number | null,
    b: string | number | null
  ): number {
    if (a === null && b === null) {
      return 0;
    } else if (b === null) {
      return 1;
    } else if (a === null) {
      return -1;
    } else {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    }
  }

  function sortDescending(
    a: string | number | null,
    b: string | number | null
  ): number {
    if (a === null && b === null) {
      return 0;
    } else if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    } else {
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      }
      return 0;
    }
  }

  function sortBySelectedParams() {
    let listCopy = [...state.allInstallerStatistics];

    if (sortOrder.sortDirection === null) {
      setSortedStatistics(listCopy);
    }

    switch (sortOrder.sortKey) {
      case InstallerStatisticsSortKey.NAME: {
        var sorted = listCopy.sort((a, b) => {
          if (sortOrder.sortDirection === SortDirection.ASC) {
            return sortAscending(a.userName, b.userName);
          } else if (sortOrder.sortDirection === SortDirection.DESC) {
            return sortDescending(a.userName, b.userName);
          } else {
            return 0;
          }
        });
        setSortedStatistics(sorted);
        break;
      }
      case InstallerStatisticsSortKey.NUMBER_OF_CHECKLISTS: {
        var sorted = listCopy.sort((a, b) => {
          if (sortOrder.sortDirection === SortDirection.ASC) {
            return sortAscending(
              a.numberOfCompletedChecklists,
              b.numberOfCompletedChecklists
            );
          } else if (sortOrder.sortDirection === SortDirection.DESC) {
            return sortDescending(
              a.numberOfCompletedChecklists,
              b.numberOfCompletedChecklists
            );
          } else {
            return 0;
          }
        });
        setSortedStatistics(sorted);
        break;
      }
      case InstallerStatisticsSortKey.AVERAGE: {
        var sorted = listCopy.sort((a, b) => {
          if (sortOrder.sortDirection === SortDirection.ASC) {
            return sortAscending(a.averageRating, b.averageRating);
          } else if (sortOrder.sortDirection === SortDirection.DESC) {
            return sortDescending(a.averageRating, b.averageRating);
          } else {
            return 0;
          }
        });
        setSortedStatistics(sorted);
        break;
      }
      case InstallerStatisticsSortKey.INSTALLATION_AVERAGE: {
        var sorted = listCopy.sort((a, b) => {
          if (sortOrder.sortDirection === SortDirection.ASC) {
            return sortAscending(
              a.averageInstallationRating,
              b.averageInstallationRating
            );
          } else if (sortOrder.sortDirection === SortDirection.DESC) {
            return sortDescending(
              a.averageInstallationRating,
              b.averageInstallationRating
            );
          } else {
            return 0;
          }
        });
        setSortedStatistics(sorted);
        break;
      }
      case InstallerStatisticsSortKey.PHOTO_AVERAGE: {
        var sorted = listCopy.sort((a, b) => {
          if (sortOrder.sortDirection === SortDirection.ASC) {
            return sortAscending(a.averagePhotoRating, b.averagePhotoRating);
          } else if (sortOrder.sortDirection === SortDirection.DESC) {
            return sortDescending(a.averagePhotoRating, b.averagePhotoRating);
          } else {
            return 0;
          }
        });
        setSortedStatistics(sorted);
        break;
      }
      case InstallerStatisticsSortKey.COMMENT_AVERAGE: {
        var sorted = listCopy.sort((a, b) => {
          if (sortOrder.sortDirection === SortDirection.ASC) {
            return sortAscending(
              a.averageCommentRating,
              b.averageCommentRating
            );
          } else if (sortOrder.sortDirection === SortDirection.DESC) {
            return sortDescending(
              a.averageCommentRating,
              b.averageCommentRating
            );
          } else {
            return 0;
          }
        });
        setSortedStatistics(sorted);
        break;
      }
    }
  }

  const handleClick = (userId: string) => {
    console.log("Clicked row");
    const url = `/users/view/${userId}`;
    history.push(url);
  };

  return (
    <MainContainer title={props.name}>
      <>
        {/* Filter row */}
        <Row className="mb-2 mt-4">
          {/* <Col
              xs={"auto"}
              className="d-flex justify-content-center align-items-center flex-direction-column"
            >
              <h5 className="m-0">Topp 5</h5>
            </Col> */}
          <Col xs={"auto"}>
            <Form>
              <DropdownButton
                variant="info"
                id="dropdown-basic-button"
                className=""
                title={getInstallerTypeFilterString(
                  InstallerTypeFilter[allInstallersFilters.installerType]
                )}
                //onChange={(e) => installerTypeSelected()}
              >
                <Dropdown.Item
                  onClick={
                    () => installerTypeSelected(InstallerTypeFilter.ALL)
                    //setSelectedInstallerTypeFilter(InstallerTypeFilter.ALL)
                  }
                >
                  {getInstallerTypeFilterString(InstallerTypeFilter.ALL)}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    installerTypeSelected(
                      InstallerTypeFilter.INTERNAL_INSTALLER
                    )
                  }
                >
                  {getInstallerTypeFilterString(
                    InstallerTypeFilter.INTERNAL_INSTALLER
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    installerTypeSelected(
                      InstallerTypeFilter.EXTERNAL_INSTALLER
                    )
                  }
                >
                  {getInstallerTypeFilterString(
                    InstallerTypeFilter.EXTERNAL_INSTALLER
                  )}
                </Dropdown.Item>
              </DropdownButton>
            </Form>
          </Col>
          <Col xs={"auto"}>
            <DropdownButton
              variant="info"
              id="dropdown-basic-button"
              title={getTimePeriodFiltersTimePeriodFiltersString(
                TimePeriodFilters[allInstallersFilters.timePeriod]
              )}
              // onChange={filtersChanged}
            >
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.FROM_START)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.FROM_START
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_WEEK)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_WEEK
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_MONTH)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_MONTH
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_YEAR)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_YEAR
                )}
              </Dropdown.Item>
            </DropdownButton>
          </Col>
          <Col xs={"auto"}>
            <Form.Switch
              checked={allInstallersFilters.onlyIncludeApprovedChecklists} // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Endast godkända checklistor"
              onChange={(e) => onlyIncludeApprovedSelected(e.target.checked)}
              // onChange={filtersChanged}
            />
          </Col>
        </Row>
        <TableContainer sx={{ minWidth: 1000 }}>
          <Table size="small" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow sx={{ bgcolor: "rgba(220, 220, 220, 0.5)" }}>
                {labels.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{ width: cellWidths[index] }}
                    align={index < 2 ? "left" : "center"}
                  >
                    <TableSortLabel
                      className="fw-bold py-2"
                      onClick={() => handleSort(column.key)}
                      active={sortOrder.sortKey === column.key}
                      direction={
                        sortOrder.sortKey === column.key
                          ? sortOrder.sortDirection === SortDirection.ASC
                            ? "asc"
                            : "desc"
                          : undefined
                      }
                    >
                      {column.value}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                "& tr:nth-of-type(2n)": {
                  backgroundColor: "grey.100",
                },
              }}
            >
              {!state.isLoading &&
                state.loaded &&
                sortedStatistics.length > 0 &&
                sortedStatistics.map((statistics, index) => {
                  //const isLastRow = index === sortedOrders.length - 1;
                  return (
                    <TableRow
                      key={index}
                      hover={false}
                      onClick={() => handleClick(statistics.userId)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="left">{statistics.userName}</TableCell>
                      <TableCell align="left">
                        {statistics.numberOfCompletedChecklists}
                      </TableCell>
                      <TableCell align="center">
                        {statistics.averageRating
                          ? statistics.averageRating.toFixed(2)
                          : "Inget betyg"}
                      </TableCell>
                      <TableCell align="center">
                        {statistics.averageInstallationRating
                          ? statistics.averageInstallationRating.toFixed(2)
                          : "Inget betyg"}
                      </TableCell>
                      <TableCell align="center">
                        {statistics.averagePhotoRating
                          ? statistics.averagePhotoRating.toFixed(2)
                          : "Inget betyg"}
                      </TableCell>
                      <TableCell align="center">
                        {statistics.averageCommentRating
                          ? statistics.averageCommentRating.toFixed(2)
                          : "Inget betyg"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!state.isLoading &&
                state.loaded &&
                sortedStatistics.length < 0 && <p>Ej tillgänglig</p>}
            </TableBody>
          </Table>
        </TableContainer>
        {state.isLoading && (
          <Row>
            <Col>
              <div className="d-flex flex-column align-items-center justify-content-center my-5">
                <BeatLoader color="#5f5f5f" className="mb-3" />
              </div>
            </Col>
          </Row>
        )}
      </>
    </MainContainer>
  );
};

export enum SortDirection {
  ASC,
  DESC,
}
interface TableColumn {
  key: InstallerStatisticsSortKey;
  value: string;
}

export enum InstallerStatisticsSortKey {
  NAME,
  NUMBER_OF_CHECKLISTS,
  AVERAGE,
  INSTALLATION_AVERAGE,
  PHOTO_AVERAGE,
  COMMENT_AVERAGE,
}

interface SortPrams {
  sortDirection: SortDirection | null;
  sortKey: InstallerStatisticsSortKey | null;
}

export default InstallerStatistics;
