import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link,
} from "@react-pdf/renderer";
import { Order } from "models/Order";
import { Checklist } from "models/Checklist";
import { MediaEntity } from "models/MediaEntity";
import greenCheck from "assets/check.png";
import AvenirNext from "assets/fonts/AvenirNextFont.ttf";
import AvenirNextBold from "assets/fonts/AvenirNextLTPro-Bold.otf";
import logoWhite from "assets/logo-white.png";
import QRbanner from "assets/qr-banner-min.png";
import avsBackground from "assets/background-min.png";
import header from "assets/avs-header-pdf-min.png";

Font.register({
  family: "Avenir Next",
  fonts: [
    { src: AvenirNext, fontStyle: "normal", fontWeight: "normal" },
    { src: AvenirNextBold, fontStyle: "normal", fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  // CONTAINERS
  marginContainer: {
    margin: 30,
  },
  infoBox: {
    backgroundColor: "#ebebeb", // ljusgrå bakground
    borderRadius: 8,
    padding: 15,
  },
  grayContainer: {
    backgroundColor: "#ebebeb", // ljusgrå
    padding: 10,
    borderRadius: 8,
  },

  time: {
    textAlign: "right",
    fontSize: 10,
    marginBottom: -10,
    fontFamily: "Avenir Next",
  },

  // FOOTER
  firstPageFooter: {
    position: "absolute",
    bottom: 0,
    backgroundColor: "#50626e", // grå
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  logoFooter: {
    width: 50,
    padding: 10,
    marginLeft: 10,
  },

  // PRODUCTS
  productsWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  productContainer: {
    borderRadius: 8,
    minWidth: 250,
    height: 165,
    padding: 10,
    marginVertical: 5,
  },
  product: {
    fontSize: 10,
    fontFamily: "Avenir Next",
    marginBottom: 5,
  },
  productBold: {
    fontSize: 10,
    fontFamily: "Avenir Next",
    fontWeight: 700,
    marginBottom: 10,
  },
  // BORDERS
  grayBorder: {
    border: "1px solid #c6c6c6", // mörkgrå border
    borderRadius: 8,
    marginBottom: 10,
  },
  whiteBorder: {
    borderRadius: 8,
    border: "1px solid white",
  },
  // FONTS
  footerText: {
    color: "white",
    fontSize: 8,
    fontFamily: "Avenir Next",
    paddingTop: 10,
    paddingBottom: 10,
    maxWidth: "50%",
    marginLeft: 200,
  },
  text: {
    fontSize: 12,
    lineHeight: 2,
    fontFamily: "Avenir Next",
  },
  h1: {
    fontSize: 16,
    fontFamily: "Avenir Next",
    fontWeight: 700,
    textAlign: "left",
    marginVertical: 5,
    marginLeft: 5,
    color: "#1e939d", // turkos
  },
  h2: {
    fontSize: 16,
    marginLeft: 5,
    marginBottom: 5,
    fontFamily: "Avenir Next",
    fontWeight: 700,
    color: "#1e939d", // turkos
  },
  h3: {
    fontSize: 14,
    lineHeight: 2,
    fontFamily: "Avenir Next",
    fontWeight: 700,
  },
  h4: {
    fontSize: 12,
    fontFamily: "Avenir Next",
    marginTop: 30,
    marginBottom: 10,
    fontWeight: 700,
  },
  h5: {
    fontSize: 12,
    fontFamily: "Avenir Next",
    marginVertical: 10,
    fontWeight: 700,
  },
  bold: {
    fontSize: 12,
    fontFamily: "Avenir Next",
    lineHeight: 2,
    fontWeight: 700,
  },

  // CONTACT
  contactText: {
    marginTop: 30,
    marginBottom: 10,
    paddingRight: 140,
    marginLeft: 5,
    color: "#1e939d", // turkos
    fontSize: 12,
  },
  contactLink: {
    color: "#1e939d", // turkos
  },
  QRbanner: {
    borderRadius: 6,
  },

  // IMAGES
  imgWrapper: {
    minWidth: 155,
    maxWidth: 155,
    maxHeight: 215,
    marginVertical: 5,
    marginHorizontal: 5,
  },
  image: {
    objectFit: "cover",
    borderRadius: 2,
  },
  flexImages: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  flex: {
    flexDirection: "row",
  },

  checkIcon: {
    width: 14,
    height: 12,
    position: "relative",
    bottom: 2,
    left: 4,
  },
  pageNumberView: {
    backgroundColor: "#1e939d", // turkos
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 45,
    width: "100%",
  },
  sid: {
    position: "relative",
    top: 15,
    right: 15,
    color: "white",
    fontFamily: "Avenir Next",
    fontSize: 16,
  },
  pageNumber: {
    position: "absolute",
    top: 15,
    fontFamily: "Avenir Next",
    fontSize: 16,
    color: "white",
  },
  background: {
    zIndex: 0,
    position: "absolute",
    objectFit: "cover",
    width: "100%",
    opacity: 0.35,
  },
});

// Create Document Component
const PDFDocument = (
  order: Order,
  checklists: Checklist[],
  images: MediaEntity[]
) => {
  // Slutbesiktningar
  const customerApprovals = order?.customerApprovals;
  const customerApprovalsData = customerApprovals?.map((approval) => {
    return {
      customerOnSite: approval.customerOnSite,
      customerName: approval.customerName,
      customerComment: approval.customerComment,
      notOnSiteDescription: approval.notOnSiteDescription,
      approvedChecklists: approval.approvedChecklists,
      noOfChecklists: approval.noOfChecklists,
      timestamp: approval.timestamp,
      owner: approval.owner?.userName,
      customerApprovalId: approval.id,
    };
  });

  return (
    <Document>
      {/* FIRST PAGE */}
      <Page>
        <Image style={styles.background} src={avsBackground}></Image>
        <Image src={header} />

        <View style={styles.marginContainer}>
          <Text style={styles.h1}>Ordernr: {order.ordernr}</Text>
          <View style={{ marginBottom: 50 }}>
            <View style={styles.grayBorder}>
              <View style={styles.whiteBorder}>
                <View style={styles.infoBox}>
                  <Text style={styles.text}>
                    <Text style={styles.bold}>Kund: </Text>
                    {order.companyName}
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.bold}>Address: </Text>
                    {order.address}.{order.postaddress} {order.postnr}
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.bold}>Checklistor: </Text>
                    {checklists
                      .map((checklist) => checklist.roomName)
                      .join(", ")}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* If only one customer approval, show att first page */}
          {customerApprovals.length < 2 && (
            <>
              <Text style={styles.h2}>Slutbesiktningar</Text>
              {customerApprovalsData &&
                customerApprovalsData.map((approval, index) => {
                  return (
                    <View style={styles.grayBorder} key={index}>
                      <View
                        style={styles.infoBox}
                        key={approval.customerApprovalId}
                      >
                        {approval.timestamp && (
                          <Text style={styles.time}>
                            {approval.timestamp.substring(0, 16)}
                          </Text>
                        )}
                        <Text style={styles.text}>
                          Slutbesiktning
                          {approval.customerOnSite ? " med" : " utan"} kund på
                          plats.
                        </Text>

                        {approval.customerOnSite &&
                          approval.customerName.length > 0 && (
                            <Text style={styles.text}>
                              Kundens namn: {approval.customerName}
                            </Text>
                          )}

                        {approval.customerOnSite &&
                          approval.customerComment.length > 0 && (
                            <Text style={styles.text}>
                              Kundens kommentar: {approval.customerComment}
                            </Text>
                          )}

                        {!approval.customerOnSite &&
                          approval.notOnSiteDescription &&
                          approval.notOnSiteDescription.length > 0 && (
                            <Text style={styles.text}>
                              Kommentar: {approval.notOnSiteDescription}
                            </Text>
                          )}

                        {approval.approvedChecklists.length > 0 && (
                          <Text style={styles.text}>
                            Godkända checklistor:{" "}
                            {approval.approvedChecklists
                              .map((checklist) => checklist.roomName)
                              .join(", ")}
                          </Text>
                        )}
                      </View>
                    </View>
                  );
                })}
            </>
          )}

          <Text style={styles.contactText}>
            Behöver du komma i kontakt med oss angående nya projekt eller
            upplever ett problem med utrustningen? Scanna QR-koden eller{" "}
            <Link
              style={styles.contactLink}
              src="https://support.avs.se/hc/sv/requests/new"
            >
              klicka här.
            </Link>
          </Text>
          <View style={styles.grayBorder}>
            <View style={styles.whiteBorder}>
              <Image src={QRbanner} style={styles.QRbanner} />
            </View>
          </View>
        </View>

        <View style={styles.firstPageFooter}>
          <Image src={logoWhite} style={styles.logoFooter} />
          <Text style={styles.footerText}>
            AVS är en totalleverantör inom ljud- & bildteknik. Bolaget grundades
            1986 med namnet AV Syd och har sedan dess blivit en av Sveriges
            ledande aktörer inom AV-teknik. Vi arbetar med stora och små projekt
            inom offentlig och privat sektor.
          </Text>
        </View>
      </Page>

      {/* IF MORE THAN ONE - NEW PAGE FOR SLUTBESIKTNINGAR/CUSTOMERAPPROVALS */}
      {customerApprovals.length > 1 && (
        <Page>
          <Image style={styles.background} src={avsBackground}></Image>
          <View style={styles.marginContainer}>
            <Text style={styles.h2}>Slutbesiktningar</Text>
            {customerApprovalsData &&
              customerApprovalsData.map((approval, index) => {
                return (
                  <View style={styles.grayBorder} key={index}>
                    <View style={styles.infoBox}>
                      {approval.timestamp && (
                        <Text style={styles.time}>
                          {approval.timestamp.substring(0, 16)}
                        </Text>
                      )}
                      <Text style={styles.text}>
                        Slutbesiktning
                        {approval.customerOnSite ? " med" : " utan"} kund på
                        plats.
                      </Text>

                      {approval.customerOnSite &&
                        approval.customerName.length > 0 && (
                          <Text style={styles.text}>
                            Kundens namn: {approval.customerName}
                          </Text>
                        )}

                      {approval.customerOnSite &&
                        approval.customerComment.length > 0 && (
                          <Text style={styles.text}>
                            Kundens kommentar: {approval.customerComment}
                          </Text>
                        )}

                      {!approval.customerOnSite &&
                        approval.notOnSiteDescription &&
                        approval.notOnSiteDescription.length > 0 && (
                          <Text style={styles.text}>
                            Kommentar: {approval.notOnSiteDescription}
                          </Text>
                        )}

                      {approval.approvedChecklists.length > 0 && (
                        <Text style={styles.text}>
                          Godkända checklistor:{" "}
                          {approval.approvedChecklists
                            .map((checklist) => checklist.roomName)
                            .join(", ")}
                        </Text>
                      )}
                    </View>
                  </View>
                );
              })}
          </View>

          <View style={styles.firstPageFooter}>
            <Image src={logoWhite} style={styles.logoFooter} />
            <Text style={styles.footerText}>
              AVS är en totalleverantör inom ljud- & bildteknik. Bolaget
              grundades 1986 med namnet AV Syd och har sedan dess blivit en av
              Sveriges ledande aktörer inom AV-teknik. Vi arbetar med stora och
              små projekt inom offentlig och privat sektor.
            </Text>
          </View>
        </Page>
      )}

      {checklists.map((checklist, index) => {
        const checklistImages = images.filter(
          (image) => image.checklist === checklist.id
        );

        /* NEW PAGE FOR EACH CHECKLIST */
        return (
          <>
            <Page key={index}>
              <Image style={styles.background} src={avsBackground}></Image>
              <View style={styles.pageNumberView}>
                <Text style={styles.sid}>sid</Text>
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber}/${totalPages}`
                  }
                  fixed
                />
              </View>

              <View style={styles.marginContainer}>
                <View style={styles.grayBorder}>
                  <View style={styles.whiteBorder}>
                    <View style={styles.infoBox}>
                      <Text style={styles.h3}>
                        Rum {checklist.roomName} - översikt
                      </Text>

                      {checklist.functionCheck && (
                        <View style={styles.flex}>
                          <Text style={styles.text}>
                            Funktionskontroll utförd
                          </Text>
                          <Image src={greenCheck} style={styles.checkIcon} />
                        </View>
                      )}

                      {checklist.documentationDone && (
                        <View style={styles.flex}>
                          <Text style={styles.text}>Dokumentation klar</Text>
                          <Image src={greenCheck} style={styles.checkIcon} />
                        </View>
                      )}

                      {/* Always display the first 2 products in the Översikt */}
                      <>
                        <Text style={styles.h4}>
                          Produkter rum {checklist.roomName} (
                          {checklist.serialEntities.length} st)
                        </Text>
                        <View style={styles.productsWrapper}>
                          {checklist.serialEntities
                            .slice(0, 2)
                            .map((serialEntity, index) => {
                              return (
                                <View style={{ marginBottom: 10 }} key={index}>
                                  <View style={styles.grayBorder}>
                                    <View style={styles.productContainer}>
                                      <Text style={styles.productBold}>
                                        {index + 1}/
                                        {checklist.serialEntities.length}
                                      </Text>

                                      {serialEntity.serialNumber && (
                                        <Text style={styles.product}>
                                          Serienummer:{" "}
                                          {serialEntity.serialNumber}
                                        </Text>
                                      )}

                                      {serialEntity.maker && (
                                        <Text style={styles.product}>
                                          Fabrikat: {serialEntity.maker}
                                        </Text>
                                      )}

                                      {serialEntity.model && (
                                        <Text style={styles.product}>
                                          Modell: {serialEntity.model}
                                        </Text>
                                      )}

                                      {serialEntity.macAddress && (
                                        <Text style={styles.product}>
                                          MAC-address: {serialEntity.macAddress}
                                        </Text>
                                      )}

                                      {serialEntity.networkSocket && (
                                        <Text style={styles.product}>
                                          Nätverksuttag:{" "}
                                          {serialEntity.networkSocket}
                                        </Text>
                                      )}

                                      {serialEntity.other && (
                                        <Text style={styles.product}>
                                          Firmware version/Övrigt:{" "}
                                          {serialEntity.other}
                                        </Text>
                                      )}

                                      {serialEntity.theftProtection && (
                                        <Text style={styles.product}>
                                          Stöldmärkning:{" "}
                                          {serialEntity.theftProtection}
                                        </Text>
                                      )}

                                      {serialEntity.macAddressWifi && (
                                        <Text style={styles.product}>
                                          MAC-address WiFi:{" "}
                                          {serialEntity.macAddressWifi}
                                        </Text>
                                      )}

                                      {serialEntity.ipAddress && (
                                        <Text style={styles.product}>
                                          IP-address: {serialEntity.ipAddress}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              );
                            })}
                        </View>
                      </>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.firstPageFooter}>
                <Image src={logoWhite} style={styles.logoFooter} />
                <Text style={styles.footerText}>
                  AVS är en totalleverantör inom ljud- & bildteknik. Bolaget
                  grundades 1986 med namnet AV Syd och har sedan dess blivit en
                  av Sveriges ledande aktörer inom AV-teknik. Vi arbetar med
                  stora och små projekt inom offentlig och privat sektor.
                </Text>
              </View>
            </Page>

            {/* if more than 14 serialEntitys, render on översikt + 3 pages */}
            {checklist.serialEntities.length > 14 ? (
              <>
                {/* First page */}
                <Page>
                  <Image style={styles.background} src={avsBackground}></Image>

                  <View style={styles.pageNumberView}>
                    <Text style={styles.sid}>sid</Text>
                    <Text
                      style={styles.pageNumber}
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber}/${totalPages}`
                      }
                      fixed
                    />
                  </View>
                  <View style={styles.marginContainer}>
                    <View style={styles.grayBorder}>
                      <View style={styles.whiteBorder}>
                        <View style={styles.grayContainer}>
                          <View style={styles.productsWrapper}>
                            {checklist.serialEntities
                              .slice(2, 8)
                              .map((serialEntity, index) => (
                                <View key={index} style={{ marginBottom: 10 }}>
                                  <View style={styles.grayBorder}>
                                    <View style={styles.productContainer}>
                                      <Text style={styles.productBold}>
                                        {index + 3}/
                                        {checklist.serialEntities.length}
                                      </Text>

                                      {serialEntity.serialNumber && (
                                        <Text style={styles.product}>
                                          Serienummer:{" "}
                                          {serialEntity.serialNumber}
                                        </Text>
                                      )}

                                      {serialEntity.maker && (
                                        <Text style={styles.product}>
                                          Fabrikat: {serialEntity.maker}
                                        </Text>
                                      )}

                                      {serialEntity.model && (
                                        <Text style={styles.product}>
                                          Modell: {serialEntity.model}
                                        </Text>
                                      )}

                                      {serialEntity.macAddress && (
                                        <Text style={styles.product}>
                                          MAC-address: {serialEntity.macAddress}
                                        </Text>
                                      )}

                                      {serialEntity.networkSocket && (
                                        <Text style={styles.product}>
                                          Nätverksuttag:{" "}
                                          {serialEntity.networkSocket}
                                        </Text>
                                      )}

                                      {serialEntity.other && (
                                        <Text style={styles.product}>
                                          Firmware version/Övrigt:{" "}
                                          {serialEntity.other}
                                        </Text>
                                      )}

                                      {serialEntity.theftProtection && (
                                        <Text style={styles.product}>
                                          Stöldmärkning:{" "}
                                          {serialEntity.theftProtection}
                                        </Text>
                                      )}

                                      {serialEntity.macAddressWifi && (
                                        <Text style={styles.product}>
                                          MAC-address WiFi:{" "}
                                          {serialEntity.macAddressWifi}
                                        </Text>
                                      )}
                                      {serialEntity.ipAddress && (
                                        <Text style={styles.product}>
                                          IP-address: {serialEntity.ipAddress}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.firstPageFooter}>
                    <Image src={logoWhite} style={styles.logoFooter} />
                    <Text style={styles.footerText}>
                      AVS är en totalleverantör inom ljud- & bildteknik. Bolaget
                      grundades 1986 med namnet AV Syd och har sedan dess blivit
                      en av Sveriges ledande aktörer inom AV-teknik. Vi arbetar
                      med stora och små projekt inom offentlig och privat
                      sektor.
                    </Text>
                  </View>
                </Page>

                {/* Second page */}
                <Page>
                  <Image style={styles.background} src={avsBackground}></Image>

                  <View style={styles.pageNumberView}>
                    <Text style={styles.sid}>sid</Text>
                    <Text
                      style={styles.pageNumber}
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber}/${totalPages}`
                      }
                      fixed
                    />
                  </View>
                  <View style={styles.marginContainer}>
                    <View style={styles.grayBorder}>
                      <View style={styles.whiteBorder}>
                        <View style={styles.grayContainer}>
                          <View style={styles.productsWrapper}>
                            {checklist.serialEntities
                              .slice(8, 14)
                              .map((serialEntity, index) => (
                                <View key={index} style={{ marginBottom: 10 }}>
                                  <View style={styles.grayBorder}>
                                    <View style={styles.productContainer}>
                                      <Text style={styles.productBold}>
                                        {index + 9}/
                                        {checklist.serialEntities.length}
                                      </Text>
                                      {serialEntity.serialNumber && (
                                        <Text style={styles.product}>
                                          Serienummer:{" "}
                                          {serialEntity.serialNumber}
                                        </Text>
                                      )}

                                      {serialEntity.maker && (
                                        <Text style={styles.product}>
                                          Fabrikat: {serialEntity.maker}
                                        </Text>
                                      )}

                                      {serialEntity.model && (
                                        <Text style={styles.product}>
                                          Modell: {serialEntity.model}
                                        </Text>
                                      )}

                                      {serialEntity.macAddress && (
                                        <Text style={styles.product}>
                                          MAC-address: {serialEntity.macAddress}
                                        </Text>
                                      )}

                                      {serialEntity.networkSocket && (
                                        <Text style={styles.product}>
                                          Nätverksuttag:{" "}
                                          {serialEntity.networkSocket}
                                        </Text>
                                      )}

                                      {serialEntity.other && (
                                        <Text style={styles.product}>
                                          Firmware version/Övrigt:{" "}
                                          {serialEntity.other}
                                        </Text>
                                      )}

                                      {serialEntity.theftProtection && (
                                        <Text style={styles.product}>
                                          Stöldmärkning:{" "}
                                          {serialEntity.theftProtection}
                                        </Text>
                                      )}

                                      {serialEntity.macAddressWifi && (
                                        <Text style={styles.product}>
                                          MAC-address WiFi:{" "}
                                          {serialEntity.macAddressWifi}
                                        </Text>
                                      )}
                                      {serialEntity.ipAddress && (
                                        <Text style={styles.product}>
                                          IP-address: {serialEntity.ipAddress}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.firstPageFooter}>
                    <Image src={logoWhite} style={styles.logoFooter} />
                    <Text style={styles.footerText}>
                      AVS är en totalleverantör inom ljud- & bildteknik. Bolaget
                      grundades 1986 med namnet AV Syd och har sedan dess blivit
                      en av Sveriges ledande aktörer inom AV-teknik. Vi arbetar
                      med stora och små projekt inom offentlig och privat
                      sektor.
                    </Text>
                  </View>
                </Page>

                {/* Third page */}
                <Page>
                  <Image style={styles.background} src={avsBackground}></Image>

                  <View style={styles.pageNumberView}>
                    <Text style={styles.sid}>sid</Text>
                    <Text
                      style={styles.pageNumber}
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber}/${totalPages}`
                      }
                      fixed
                    />
                  </View>

                  <View style={styles.marginContainer}>
                    <View style={styles.grayBorder}>
                      <View style={styles.whiteBorder}>
                        <View style={styles.grayContainer}>
                          <View style={styles.productsWrapper}>
                            {checklist.serialEntities
                              .slice(14)
                              .map((serialEntity, index) => (
                                <View key={index} style={{ marginBottom: 10 }}>
                                  <View style={styles.grayBorder}>
                                    <View style={styles.productContainer}>
                                      <Text style={styles.productBold}>
                                        {index + 15}/
                                        {checklist.serialEntities.length}
                                      </Text>
                                      {serialEntity.serialNumber && (
                                        <Text style={styles.product}>
                                          Serienummer:{" "}
                                          {serialEntity.serialNumber}
                                        </Text>
                                      )}

                                      {serialEntity.maker && (
                                        <Text style={styles.product}>
                                          Fabrikat: {serialEntity.maker}
                                        </Text>
                                      )}

                                      {serialEntity.model && (
                                        <Text style={styles.product}>
                                          Modell: {serialEntity.model}
                                        </Text>
                                      )}

                                      {serialEntity.macAddress && (
                                        <Text style={styles.product}>
                                          MAC-address: {serialEntity.macAddress}
                                        </Text>
                                      )}

                                      {serialEntity.networkSocket && (
                                        <Text style={styles.product}>
                                          Nätverksuttag:{" "}
                                          {serialEntity.networkSocket}
                                        </Text>
                                      )}

                                      {serialEntity.other && (
                                        <Text style={styles.product}>
                                          Firmware version/Övrigt:{" "}
                                          {serialEntity.other}
                                        </Text>
                                      )}

                                      {serialEntity.theftProtection && (
                                        <Text style={styles.product}>
                                          Stöldmärkning:{" "}
                                          {serialEntity.theftProtection}
                                        </Text>
                                      )}

                                      {serialEntity.macAddressWifi && (
                                        <Text style={styles.product}>
                                          MAC-address WiFi:{" "}
                                          {serialEntity.macAddressWifi}
                                        </Text>
                                      )}
                                      {serialEntity.ipAddress && (
                                        <Text style={styles.product}>
                                          IP-address: {serialEntity.ipAddress}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.firstPageFooter}>
                    <Image src={logoWhite} style={styles.logoFooter} />
                    <Text style={styles.footerText}>
                      AVS är en totalleverantör inom ljud- & bildteknik. Bolaget
                      grundades 1986 med namnet AV Syd och har sedan dess blivit
                      en av Sveriges ledande aktörer inom AV-teknik. Vi arbetar
                      med stora och små projekt inom offentlig och privat
                      sektor.
                    </Text>
                  </View>
                </Page>
              </>
            ) : // If more than 8 products render on översikt + two pages
            checklist.serialEntities.length > 8 ? (
              <>
                {/* First page */}
                <Page>
                  <Image style={styles.background} src={avsBackground}></Image>

                  <View style={styles.pageNumberView}>
                    <Text style={styles.sid}>sid</Text>
                    <Text
                      style={styles.pageNumber}
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber}/${totalPages}`
                      }
                      fixed
                    />
                  </View>

                  <View style={styles.marginContainer}>
                    <View style={styles.grayBorder}>
                      <View style={styles.whiteBorder}>
                        <View style={styles.grayContainer}>
                          <View style={styles.productsWrapper}>
                            {checklist.serialEntities
                              .slice(2, 8)
                              .map((serialEntity, index) => (
                                <View key={index} style={{ marginBottom: 10 }}>
                                  <View style={styles.grayBorder}>
                                    <View style={styles.productContainer}>
                                      <Text style={styles.productBold}>
                                        {index + 3}/
                                        {checklist.serialEntities.length}
                                      </Text>
                                      {serialEntity.serialNumber && (
                                        <Text style={styles.product}>
                                          Serienummer:{" "}
                                          {serialEntity.serialNumber}
                                        </Text>
                                      )}

                                      {serialEntity.maker && (
                                        <Text style={styles.product}>
                                          Fabrikat: {serialEntity.maker}
                                        </Text>
                                      )}

                                      {serialEntity.model && (
                                        <Text style={styles.product}>
                                          Modell: {serialEntity.model}
                                        </Text>
                                      )}

                                      {serialEntity.macAddress && (
                                        <Text style={styles.product}>
                                          MAC-address: {serialEntity.macAddress}
                                        </Text>
                                      )}

                                      {serialEntity.networkSocket && (
                                        <Text style={styles.product}>
                                          Nätverksuttag:{" "}
                                          {serialEntity.networkSocket}
                                        </Text>
                                      )}

                                      {serialEntity.other && (
                                        <Text style={styles.product}>
                                          Firmware version/Övrigt:{" "}
                                          {serialEntity.other}
                                        </Text>
                                      )}

                                      {serialEntity.theftProtection && (
                                        <Text style={styles.product}>
                                          Stöldmärkning:{" "}
                                          {serialEntity.theftProtection}
                                        </Text>
                                      )}

                                      {serialEntity.macAddressWifi && (
                                        <Text style={styles.product}>
                                          MAC-address WiFi:{" "}
                                          {serialEntity.macAddressWifi}
                                        </Text>
                                      )}
                                      {serialEntity.ipAddress && (
                                        <Text style={styles.product}>
                                          IP-address: {serialEntity.ipAddress}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.firstPageFooter}>
                    <Image src={logoWhite} style={styles.logoFooter} />
                    <Text style={styles.footerText}>
                      AVS är en totalleverantör inom ljud- & bildteknik. Bolaget
                      grundades 1986 med namnet AV Syd och har sedan dess blivit
                      en av Sveriges ledande aktörer inom AV-teknik. Vi arbetar
                      med stora och små projekt inom offentlig och privat
                      sektor.
                    </Text>
                  </View>
                </Page>

                {/* Second page */}
                <Page>
                  <Image style={styles.background} src={avsBackground}></Image>

                  <View style={styles.pageNumberView}>
                    <Text style={styles.sid}>sid</Text>
                    <Text
                      style={styles.pageNumber}
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber}/${totalPages}`
                      }
                      fixed
                    />
                  </View>

                  <View style={styles.marginContainer}>
                    <View style={styles.grayBorder}>
                      <View style={styles.whiteBorder}>
                        <View style={styles.grayContainer}>
                          <View style={styles.productsWrapper}>
                            {checklist.serialEntities
                              .slice(8)
                              .map((serialEntity, index) => (
                                <View key={index} style={{ marginBottom: 10 }}>
                                  <View style={styles.grayBorder}>
                                    <View style={styles.productContainer}>
                                      <Text style={styles.productBold}>
                                        {index + 9}/
                                        {checklist.serialEntities.length}
                                      </Text>
                                      {serialEntity.serialNumber && (
                                        <Text style={styles.product}>
                                          Serienummer:{" "}
                                          {serialEntity.serialNumber}
                                        </Text>
                                      )}

                                      {serialEntity.maker && (
                                        <Text style={styles.product}>
                                          Fabrikat: {serialEntity.maker}
                                        </Text>
                                      )}

                                      {serialEntity.model && (
                                        <Text style={styles.product}>
                                          Modell: {serialEntity.model}
                                        </Text>
                                      )}

                                      {serialEntity.macAddress && (
                                        <Text style={styles.product}>
                                          MAC-address: {serialEntity.macAddress}
                                        </Text>
                                      )}

                                      {serialEntity.networkSocket && (
                                        <Text style={styles.product}>
                                          Nätverksuttag:{" "}
                                          {serialEntity.networkSocket}
                                        </Text>
                                      )}

                                      {serialEntity.other && (
                                        <Text style={styles.product}>
                                          Firmware version/Övrigt:{" "}
                                          {serialEntity.other}
                                        </Text>
                                      )}

                                      {serialEntity.theftProtection && (
                                        <Text style={styles.product}>
                                          Stöldmärkning:{" "}
                                          {serialEntity.theftProtection}
                                        </Text>
                                      )}

                                      {serialEntity.macAddressWifi && (
                                        <Text style={styles.product}>
                                          MAC-address WiFi:{" "}
                                          {serialEntity.macAddressWifi}
                                        </Text>
                                      )}
                                      {serialEntity.ipAddress && (
                                        <Text style={styles.product}>
                                          IP-address: {serialEntity.ipAddress}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.firstPageFooter}>
                    <Image src={logoWhite} style={styles.logoFooter} />
                    <Text style={styles.footerText}>
                      AVS är en totalleverantör inom ljud- & bildteknik. Bolaget
                      grundades 1986 med namnet AV Syd och har sedan dess blivit
                      en av Sveriges ledande aktörer inom AV-teknik. Vi arbetar
                      med stora och små projekt inom offentlig och privat
                      sektor.
                    </Text>
                  </View>
                </Page>
              </>
            ) : (
              <>
                {/* Elseif there more than 2 products, render on single page */}
                {checklist.serialEntities.length > 2 && (
                  <Page>
                    <Image
                      style={styles.background}
                      src={avsBackground}
                    ></Image>

                    <View style={styles.pageNumberView}>
                      <Text style={styles.sid}>sid</Text>
                      <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) =>
                          `${pageNumber}/${totalPages}`
                        }
                        fixed
                      />
                    </View>
                    <View style={styles.marginContainer}>
                      <View style={styles.grayBorder}>
                        <View style={styles.whiteBorder}>
                          <View style={styles.grayContainer}>
                            <View style={styles.productsWrapper}>
                              {checklist.serialEntities
                                .slice(2)
                                .map((serialEntity, index) => {
                                  return (
                                    <View
                                      style={{ marginBottom: 10 }}
                                      key={index}
                                    >
                                      <View style={styles.grayBorder}>
                                        <View style={styles.productContainer}>
                                          <Text style={styles.productBold}>
                                            {index + 3}/
                                            {checklist.serialEntities.length}
                                          </Text>
                                          {serialEntity.serialNumber && (
                                            <Text style={styles.product}>
                                              Serienummer:{" "}
                                              {serialEntity.serialNumber}
                                            </Text>
                                          )}

                                          {serialEntity.maker && (
                                            <Text style={styles.product}>
                                              Fabrikat: {serialEntity.maker}
                                            </Text>
                                          )}

                                          {serialEntity.model && (
                                            <Text style={styles.product}>
                                              Modell: {serialEntity.model}
                                            </Text>
                                          )}

                                          {serialEntity.macAddress && (
                                            <Text style={styles.product}>
                                              MAC-address:{" "}
                                              {serialEntity.macAddress}
                                            </Text>
                                          )}

                                          {serialEntity.networkSocket && (
                                            <Text style={styles.product}>
                                              Nätverksuttag:{" "}
                                              {serialEntity.networkSocket}
                                            </Text>
                                          )}

                                          {serialEntity.other && (
                                            <Text style={styles.product}>
                                              Firmware version/Övrigt:{" "}
                                              {serialEntity.other}
                                            </Text>
                                          )}

                                          {serialEntity.theftProtection && (
                                            <Text style={styles.product}>
                                              Stöldmärkning:{" "}
                                              {serialEntity.theftProtection}
                                            </Text>
                                          )}

                                          {serialEntity.macAddressWifi && (
                                            <Text style={styles.product}>
                                              MAC-address WiFi:{" "}
                                              {serialEntity.macAddressWifi}
                                            </Text>
                                          )}
                                          {serialEntity.ipAddress && (
                                            <Text style={styles.product}>
                                              IP-address:{" "}
                                              {serialEntity.ipAddress}
                                            </Text>
                                          )}
                                        </View>
                                      </View>
                                    </View>
                                  );
                                })}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.firstPageFooter}>
                      <Image src={logoWhite} style={styles.logoFooter} />
                      <Text style={styles.footerText}>
                        AVS är en totalleverantör inom ljud- & bildteknik.
                        Bolaget grundades 1986 med namnet AV Syd och har sedan
                        dess blivit en av Sveriges ledande aktörer inom
                        AV-teknik. Vi arbetar med stora och små projekt inom
                        offentlig och privat sektor.
                      </Text>
                    </View>
                  </Page>
                )}
              </>
            )}

            {/* Always new page for images, if less than 10, render on one page  */}
            {checklistImages.length > 0 && (
              <>
                {checklistImages.length < 10 ? (
                  <Page>
                    <Image style={styles.background} src={avsBackground} />
                    <View style={styles.pageNumberView}>
                      <Text style={styles.sid}>sid</Text>
                      <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) =>
                          `${pageNumber}/${totalPages}`
                        }
                        fixed
                      />
                    </View>
                    <View style={styles.marginContainer}>
                      <View style={styles.grayBorder}>
                        <View style={styles.whiteBorder}>
                          <View style={styles.grayContainer}>
                            <Text style={styles.h5}>
                              Bilder rum {checklist.roomName} (
                              {checklistImages.length} st)
                            </Text>
                            <View style={{ width: "100%" }}>
                              <View style={styles.flexImages}>
                                {checklistImages.map((image, index) => {
                                  // Create URL of the compressed image file
                                  const compressedImageURL =
                                    URL.createObjectURL(image.compressedImage);
                                  return (
                                    <View style={styles.imgWrapper} key={index}>
                                      <Image
                                        src={compressedImageURL}
                                        key={image.id}
                                        style={styles.image}
                                      />
                                    </View>
                                  );
                                })}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.firstPageFooter}>
                      <Image src={logoWhite} style={styles.logoFooter} />
                      <Text style={styles.footerText}>
                        AVS är en totalleverantör inom ljud- & bildteknik.
                        Bolaget grundades 1986 med namnet AV Syd och har sedan
                        dess blivit en av Sveriges ledande aktörer inom
                        AV-teknik. Vi arbetar med stora och små projekt inom
                        offentlig och privat sektor.
                      </Text>
                    </View>
                  </Page>
                ) : (
                  // If more than 9 images, render on two pages
                  <>
                    {/* Page 1 */}
                    <Page>
                      <Image style={styles.background} src={avsBackground} />
                      <View style={styles.pageNumberView}>
                        <Text style={styles.sid}>sid</Text>
                        <Text
                          style={styles.pageNumber}
                          render={({ pageNumber, totalPages }) =>
                            `${pageNumber}/${totalPages}`
                          }
                          fixed
                        />
                      </View>
                      <View style={styles.marginContainer}>
                        <View style={styles.grayBorder}>
                          <View style={styles.whiteBorder}>
                            <View style={styles.grayContainer}>
                              <Text style={styles.h5}>
                                Bilder rum {checklist.roomName} (
                                {checklistImages.length} st)
                              </Text>
                              <View style={{ width: "100%" }}>
                                <View style={styles.flexImages}>
                                  {checklistImages
                                    .slice(0, 9)
                                    .map((image, index) => {
                                      const compressedImageURL =
                                        URL.createObjectURL(
                                          image.compressedImage
                                        );

                                      return (
                                        <View
                                          style={styles.imgWrapper}
                                          key={index}
                                        >
                                          <Image
                                            src={compressedImageURL} // Use the compressed image URL here
                                            key={image.id}
                                            style={styles.image}
                                          />
                                        </View>
                                      );
                                    })}
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.firstPageFooter}>
                        <Image src={logoWhite} style={styles.logoFooter} />
                        <Text style={styles.footerText}>
                          AVS är en totalleverantör inom ljud- & bildteknik.
                          Bolaget grundades 1986 med namnet AV Syd och har sedan
                          dess blivit en av Sveriges ledande aktörer inom
                          AV-teknik. Vi arbetar med stora och små projekt inom
                          offentlig och privat sektor.
                        </Text>
                      </View>
                    </Page>

                    {/* Page 2 */}
                    <Page>
                      <Image style={styles.background} src={avsBackground} />
                      <View style={styles.pageNumberView}>
                        <Text style={styles.sid}>sid</Text>
                        <Text
                          style={styles.pageNumber}
                          render={({ pageNumber, totalPages }) =>
                            `${pageNumber}/${totalPages}`
                          }
                          fixed
                        />
                      </View>
                      <View style={styles.marginContainer}>
                        <View style={styles.grayBorder}>
                          <View style={styles.whiteBorder}>
                            <View style={styles.grayContainer}>
                              <View style={{ width: "100%" }}>
                                <View style={styles.flexImages}>
                                  {checklistImages
                                    .slice(0, 9)
                                    .map((image, index) => {
                                      const compressedImageURL =
                                        URL.createObjectURL(
                                          image.compressedImage
                                        );

                                      return (
                                        <View
                                          style={styles.imgWrapper}
                                          key={index}
                                        >
                                          <Image
                                            src={compressedImageURL} // Use the compressed image URL here
                                            key={image.id}
                                            style={styles.image}
                                          />
                                        </View>
                                      );
                                    })}
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.firstPageFooter}>
                        <Image src={logoWhite} style={styles.logoFooter} />
                        <Text style={styles.footerText}>
                          AVS är en totalleverantör inom ljud- & bildteknik.
                          Bolaget grundades 1986 med namnet AV Syd och har sedan
                          dess blivit en av Sveriges ledande aktörer inom
                          AV-teknik. Vi arbetar med stora och små projekt inom
                          offentlig och privat sektor.
                        </Text>
                      </View>
                    </Page>
                  </>
                )}
              </>
            )}
          </>
        );
      })}
    </Document>
  );
};

export default PDFDocument;
