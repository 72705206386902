import React, { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import {
  selectChecklist,
  viewChecklist,
} from "pages/orders/viewChecklist/viewChecklistSlice";
import { Modal } from "react-bootstrap";
import Features from "./Features";
import SlideShow from "./SlideShow";
import styles from "./slideShow.module.scss";

const SlideShowModal = ({ initialSlideIndex, onCloseModal, nameHidden }) => {
  const checklist = useAppSelector(selectChecklist);
  const viewChecklistState = useAppSelector(viewChecklist);
  const number = viewChecklistState.checklist?.verification.length;
  const [currentSlide, setCurrentSlide] = useState<number>(initialSlideIndex);
  const [currentVerification, setCurrentVerification] = useState(
    checklist?.verification[currentSlide]
  );

  useEffect(() => {
    setCurrentVerification(checklist?.verification[currentSlide]);
  }, [currentSlide]);

  const handleSlideshowModalClose = () => {
    onCloseModal();
  };

  // Update currentSlide state when child component SlideShow does so
  const handleSlideChange = (newSlide) => {
    setCurrentSlide(newSlide);
  };

  return (
    <>
      <Modal
        dialogClassName={styles.customModal}
        show={true}
        onHide={onCloseModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bilder & Filmer ({number} st)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <SlideShow
              initialSlideIndex={initialSlideIndex}
              onSlideChange={handleSlideChange}
            />

            <Features
              currentSlide={currentSlide}
              currentVerification={currentVerification}
              setCurrentSlide={setCurrentSlide}
              handleSlideshowModalClose={handleSlideshowModalClose}
              nameHidden={nameHidden}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SlideShowModal;
