import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const FilterByNotRatedChecklist = ({
  filterNotRatedChecklists,
  setFilterNotRatedChecklists,
}) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={filterNotRatedChecklists}
            onChange={() =>
              setFilterNotRatedChecklists(!filterNotRatedChecklists)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Endast ordrar med ej betygsatta checklistor"
      />
    </div>
  );
};

export default FilterByNotRatedChecklist;
