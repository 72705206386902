import React, { useEffect } from "react";
import "./App.scss";
import {
  Route,
  Switch,
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import routes from "./config/routes";
import AuthRoute from "features/authRoute/AuthRoute";
import UnAuthenticatedRoute from "features/UnAuthenticatedRoute";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  setIsLoading,
  setLoaded,
  setToken,
  validateToken,
  selectUser,
  selectLoginStatus,
} from "features/auth/authSlice";
import MainNavigation from "features/navigation/MainNavigation";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "CustomMuiTheme";
import RootRoute from "features/routes/RootRoute";
import PendingVerificationRoute from "features/routes/PendingVerificationRoute";
import { Col, Container, Row } from "react-bootstrap";

const App = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector(selectUser);
  const authStatus = useAppSelector(selectLoginStatus);
  const userIsVerified = user?.state !== "CREATED";
  const userIsApproved = user?.state !== "PENDING";
  const viewReportMode = useRouteMatch("/report/:slug");

  const checkLocalStorageForToken = () => {
    dispatch(setIsLoading(true));
    const token = localStorage.getItem("token");

    if (token !== null) {
      // Validate token
      dispatch(setToken(token));
      dispatch(validateToken(token));
    } else {
      dispatch(setLoaded(true));
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    checkLocalStorageForToken();
  }, []);

  useEffect(() => {
    if (authStatus === "loggedOut") {
      history.push("/");
    }
  }, [authStatus]);

  const mappedRoutes = () => {
    return routes.map((route, index) => {
      if (
        route.type === "pendingApproval" ||
        route.type === "pendingVerification"
      ) {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props: RouteComponentProps<any>) => (
              <PendingVerificationRoute />
            )}
          />
        );
      }

      if (route.private) {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props: RouteComponentProps<any>) => (
              <AuthRoute authorizedRoles={route.authorizedRoles}>
                <route.component
                  name={route.name}
                  {...props}
                  {...route.props}
                />
              </AuthRoute>
            )}
          />
        );
      } else {
        if (route.noAuthCheck) {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props: RouteComponentProps<any>) => (
                <route.component
                  name={route.name}
                  {...props}
                  {...route.props}
                />
              )}
            />
          );
        }
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props: RouteComponentProps<any>) => (
              <UnAuthenticatedRoute>
                <route.component
                  name={route.name}
                  {...props}
                  {...route.props}
                />
              </UnAuthenticatedRoute>
            )}
          />
        );
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container fluid className="p-0 mx-0 vh-100">
        <Row className="mx-auto h-100 gx-5">
          {user !== null &&
            userIsVerified &&
            userIsApproved &&
            !viewReportMode && (
              <Col
                className="px-0"
                lg={3}
                md={12}
                sm={12}
                xs={12}
                xl={3}
                xxl={2}
              >
                <MainNavigation />
              </Col>
            )}
          <Switch>
            <Col className="scrollContainer" id="scrollContainer">
              <RootRoute />
              {mappedRoutes()}
            </Col>
          </Switch>
        </Row>
      </Container>
    </ThemeProvider>
  );
};

export default App;
