import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import { BE_URL } from "config/api";
import { User } from "features/auth/types/user";
import { GenericError } from "interfaces/errorTypes";
import { setIsInvalidated } from "../usersSlice";

interface AddNewUserState {
  isLoading: boolean;
  isLoaded: boolean;
  success: boolean;
  error: string | null;
}

const initialState: AddNewUserState = {
  isLoading: false,
  isLoaded: false,
  success: false,
  error: null,
};

export const addUser = createAsyncThunk<
  string,
  User,
  { rejectValue: GenericError; state: RootState }
>("addNewUser/add", async (userData, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<string>(`${BE_URL}/user/create`, userData, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Invalidate users list
      dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte skapa användare.",
        });
      } else if (e.response.status === 500) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte skapa användare. E-postadressen finns redan registrerad.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

export const addCompanySlice = createSlice({
  name: "addNewCompany",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInitialState: (state) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUser.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.success = true;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.error = action.payload?.message || "";
        state.isLoading = false;
        state.isLoaded = true;
        state.success = false;
      });
  },
});

export const { setIsLoading, setInitialState } = addCompanySlice.actions;

export const selectError = (state: RootState) => state.addUser.error;
export const selectLoading = (state: RootState) => state.addUser.isLoading;
export const selectLoaded = (state: RootState) => state.addUser.isLoaded;
export const selectSuccess = (state: RootState) => state.addUser.success;

export default addCompanySlice.reducer;
