import React from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Link } from "react-router-dom";
import { useForm } from "hooks/useForm";
import { CenterContainer } from "features/containers/centerContainer/CenterContainer";
import { Alert, Spinner } from "react-bootstrap";
import {
  requestReset,
  RequestResetData,
  selectError,
  selectLoading,
} from "./resetPasswordSlice";
import textStyles from "../../utilities/styles/textStyles.module.scss";
import { Button } from "@mui/material";

const ResetPasswordForm: React.FunctionComponent = (props) => {
  const dispatch = useAppDispatch();
  const resetLoading = useAppSelector(selectLoading);
  const error = useAppSelector(selectError);

  // Using form hook for validation
  const {
    handleSubmit,
    handleChange,
    data: resetPasswordData,
    errors,
  } = useForm<RequestResetData>({
    validations: {
      email: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig epostadress.",
        },
      },
    },
    initialValues: {
      email: "",
    },
    onSubmit: () => {
      dispatch(requestReset(resetPasswordData));
    },
  });
  return (
    <CenterContainer>
      <main className="form-signin">
        <form onSubmit={handleSubmit} noValidate>
          <div className="text-center"></div>
          <h1 className="h3 mb-3 fw-normal text-center">Återställ lösenord</h1>
          <div className="my-4">
            <p className="text-center">
              Har du glömt ditt lösenord? Inga problem, ange din email så
              skickar vi en länk för att återställa det.
            </p>
          </div>
          <div className="form-floating">
            <input
              value={resetPasswordData.email}
              onChange={handleChange("email" || "")}
              type="email"
              className={`form-control ${errors.email && "is-invalid"}`}
              id="floatingInput"
              placeholder="name@example.com"
            />
            <label htmlFor="floatingInput">E-post</label>
          </div>

          <div className="mb-3 mt-3">
            {
              // Show validation errors if present
              Object.keys(errors).length > 0 && (
                <Alert variant={"danger"}>
                  {Object.values(errors).map((error, index) => {
                    return (
                      <p className="m-0" key={index}>
                        {error}
                      </p>
                    );
                  })}
                </Alert>
              )
            }
            {
              // Show server errors if present
              error && Object.keys(errors).length < 1 && (
                <Alert variant={"danger"}>
                  <p className="m-0">{error}</p>
                </Alert>
              )
            }
          </div>
          <div className="d-flex align-items-center justify-content-between my-3">
            <Link to="/login" className={textStyles.link}>
              Till logga in
            </Link>

            <Button type="submit" variant="contained">
              {resetLoading ? (
                <Spinner size={"sm"} animation={"border"} />
              ) : (
                "Skicka återställningslänk"
              )}
            </Button>
          </div>
        </form>
      </main>
    </CenterContainer>
  );
};

export default ResetPasswordForm;
