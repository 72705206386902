import { Alert } from "react-bootstrap";

const Errors = (props) => {
  const { errors, serverError } = props;

  // Error codes to translated to error messages
  const translatedErrorMessages = {
    INVALID_NAME: "Företagsnamnet finns redan i systemet.",
    INVALID_ORG_NUMBER: "Organisationsnumret finns redan i systemet.",
    INVALID_UE_CODE:
      "Ett företag med denna leverantörskod finns redan i systemet.",
  };

  return (
    <div>
      {/* Display validation errors if present */}
      {Object.keys(errors).length > 0 && (
        <Alert variant={"danger"}>
          <ul className="mb-0">
            {Object.values(errors).map((error, index) => {
              return (
                <li className="m-0" key={index}>
                  {error as string}
                </li>
              );
            })}
          </ul>
        </Alert>
      )}

      {/* Display server errors if present */}
      {serverError && (
        <Alert variant="danger">
          <p className="m-0">{translatedErrorMessages[serverError?.message]}</p>
        </Alert>
      )}
    </div>
  );
};

export default Errors;
