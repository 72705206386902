import React, { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { Link } from "react-router-dom";
import { selectChecklist } from "../../viewChecklistSlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import EditInternalComment from "./EditInternalComment";
import { Tooltip } from "@mui/material";
import textStyles from "../../../../../utilities/styles/textStyles.module.scss";
import styles from "../../checklist.module.scss";

interface InternalComment {
  id: string;
  message: string;
  postedAt: string;
  poster: {
    userId: string;
    userName: string;
  };
}

interface InternalCommentsProps {
  nameHidden: boolean;
}

const InternalComments: React.FC<InternalCommentsProps> = ({ nameHidden }) => {
  const checklist = useAppSelector(selectChecklist);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);
  const userIsUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const [sortedComments, setSortedComments] = useState<InternalComment[]>([]);

  useEffect(() => {
    if (checklist?.internalComments) {
      // Function to sort comments by timestamp in ascending order
      const sorted = [...checklist.internalComments].sort((a, b) => {
        return new Date(a.postedAt).getTime() - new Date(b.postedAt).getTime();
      });
      setSortedComments(sorted);
    }
  }, [checklist?.internalComments]);

  return (
    <DataContainer>
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between">
          <p className="fw-bold">Interna kommentarer</p>
          {userIsAdmin || userIsUserAdmin ? (
            <EditInternalComment nameHidden={nameHidden} />
          ) : (
            ""
          )}
        </div>

        <div>
          {sortedComments.map((comment: InternalComment) => {
            if (comment.message && comment.message.length > 0) {
              return (
                <div className={styles.comment} key={comment.id}>
                  <p className="mb-0">{comment.message}</p>
                  <p className={`${textStyles.smallText} mb-0`}>
                    {nameHidden ? (
                      <>{comment.postedAt.substring(0, 16)}</>
                    ) : (
                      <>
                        <>{comment.postedAt.substring(0, 16)}</>
                        {" av "}
                        <Tooltip arrow title="Gå till användarprofilen">
                          <Link to={`/users/view/${comment.poster.userId}`}>
                            <span className={textStyles.smallText}>
                              {comment.poster.userName}
                            </span>
                          </Link>
                        </Tooltip>
                      </>
                    )}
                  </p>
                </div>
              );
            }
          })}
          {sortedComments.length === 0 && (
            <p className={textStyles.smallText}>Inga tillagda</p>
          )}
        </div>
      </div>
    </DataContainer>
  );
};

export default InternalComments;