// export const BE_URL =
//   process.env.NODE_ENV === "production"
//     ? "https://api.avs-app.net:8443/AVS"
//     : "http://localhost:8080/AVS";

export const getBackendEndpoint = (): string => {
  console.log("REACT_ENV: ", process.env.REACT_APP_ENV);
  console.log("NODE_ENV: ", process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case "production":
      if (process.env.REACT_APP_ENV === "production") {
        return "https://api.avs-app.net:8443/AVS";
      } else {
        return "http://54.73.207.193:8080/AVS";
      }
    case "development":
      return "http://localhost:8080/AVS";
    case "test":
      return "http://54.73.207.193:8080";
    default:
      return "";
  }
};

export const BE_URL = getBackendEndpoint();
