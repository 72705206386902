import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainContainer from "features/containers/MainContainer/MainContainer";

const PageNotFound = (props) => {
  const { checklistNotFound, setChecklistNotFound } = props;
  const history = useHistory();
  const [countdown, setCountdown] = useState(5); // Initial countdown value
  const [pageName, setPageName] = useState("Sidan");

  useEffect(() => {
    let redirectTimeout;

    if (checklistNotFound) {
      setPageName("Checklistan");
    }

    redirectTimeout = setTimeout(() => {
      history.push("/");
    }, countdown * 1000);

    // Update countdown every second
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearTimeout(redirectTimeout);
      clearInterval(interval);
    };
  }, [checklistNotFound, countdown, history]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      setChecklistNotFound(false);
    };
  }, []);

  return (
    <MainContainer title={"404 - Sidan kunde inte hittas"}>
      <h4>{pageName} hittades tyvärr inte.</h4>

      {checklistNotFound && (
        <h5 className="mt-5">
          Omdirigerar till startsidan om {countdown} sekunder...
        </h5>
      )}
    </MainContainer>
  );
};

export default PageNotFound;
