import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { Company } from "models/Company";

interface CompaniesState {
  companies: Company[] | null;
  isLoading: boolean;
  isLoaded: boolean;
  isInvalidated: boolean;
  error: string | null;
  editInProgress: boolean;
  editDone: boolean;
  lastViewedCompany: string | null;
}

const initialState: CompaniesState = {
  companies: null,
  isLoading: false,
  isLoaded: false,
  isInvalidated: false,
  error: null,
  editInProgress: false,
  editDone: false,
  lastViewedCompany: null,
};

export const getAllCompanies = createAsyncThunk<
  Company[],
  void,
  { rejectValue: GenericError; state: RootState }
>("companies/getAll", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<Company[]>(`${BE_URL}/company/list`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      console.log("Server res:  ", e.response.data);
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Koden du angav var fel.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as Company[];
});

export const addCompany = createAsyncThunk<
  string,
  Company,
  { rejectValue: GenericError; state: RootState }
>("companies/createCompany", async (companyData, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<string>(`${BE_URL}/company/`, companyData, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Koden du angav var fel.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsInvalidated: (state) => {
      state.isInvalidated = true;
    },
    setLastViewedCompany: (state, action: PayloadAction<string | null>) => {
      state.lastViewedCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder
      // Login
      .addCase(getAllCompanies.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.companies = action.payload;
        state.isInvalidated = false;
      });
  },
});

export const { setIsLoading, setIsInvalidated, setLastViewedCompany } = companiesSlice.actions;

export const selectLastViewedCompany = (state: RootState) => state.companies.lastViewedCompany;
export const selectCompanies = (state: RootState) => state.companies.companies;
export const selectError = (state: RootState) => state.users.error;
export const selectLoading = (state: RootState) => state.companies.isLoading;
export const selectLoaded = (state: RootState) => state.companies.isLoaded;
export const selectIsInvalidated = (state: RootState) =>
  state.companies.isInvalidated;

export default companiesSlice.reducer;
