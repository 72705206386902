import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { TimeEntry } from "models/TimeEntry";
import { DateRange } from "features/mui/DateRangePicker/RangeTypes";

interface TimeManagementState {
  entriesList: TimeEntry[] | null;
  isLoading: boolean;
  isLoaded: boolean;
  isInvalidated: boolean;
  error: string | null;
  statusUpdating: boolean;
  payrollStatusLoadingIds: string[];
  lastPageLoad: number | null;
  pageCount: number | null;
  resultsCounter: number | null;
  currentFilters: FilterTimeEntryParams | null;
  CSVData: CSVRow[] | null;
  CSVDataLoading: boolean;
}

const initialState: TimeManagementState = {
  entriesList: null,
  isLoading: false,
  isLoaded: false,
  isInvalidated: false,
  error: null,
  statusUpdating: false,
  payrollStatusLoadingIds: [],
  lastPageLoad: null,
  pageCount: null,
  resultsCounter: null,
  currentFilters: null,
  CSVData: null,
  CSVDataLoading: false,
};

export type GetTimeEntriesResponse = {
  resultsCount: number;
  page: number;
  pageCount: number;
  results: TimeEntry[];
};

export type CSVRow = {
  name: string;
  from: string;
  to: string;
  type: string;
  comment: string;
  status: string;
};

export enum TimeEntryType {
  null,
  "Sjuk",
  "Betald semester",
  "VAB",
  "Tjänstledigt",
  "Övertid",
  "Vård av anhörig",
  "Föräldraledig",
}

export enum TimeEntryStatus {
  PENDING = "Ej behandlad",
  APPROVED = "Godkänd",
  REJECTED = "Ej godkänd",
}

export interface TimeEntryItemProps {
  timeEntry: TimeEntry;
  expanded: boolean;
  onClick: (event, newExpanded) => void;
  filterWithinRange: DateRange<Date> | null;
}

export interface TimeEntryAdminComment {
  adminComment: string;
}

export const getAllTimeEntries = createAsyncThunk<
  TimeEntry[],
  void,
  { rejectValue: GenericError; state: RootState }
>("timeManagement/getAllTimeEntries", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<TimeEntry[]>(`${BE_URL}/absence/all`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde ej hämta övertid och frånvaro.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as TimeEntry[];
});

export interface FilterTimeEntryParams {
  page: number;
  pageSize: number;
  searchQuery: string;
  fromDate?: string | null;
  toDate?: string | null;
  state?: "PENDING" | "APPROVED" | "REJECTED" | null;
  type?: number | null;
  processed?: boolean | null;
  orderByEmployeeNumber?: boolean;
}

const filterParamsAreEqual = (
  currentFilters: FilterTimeEntryParams,
  newFilters: FilterTimeEntryParams
): boolean => {
  const orderedA = Object.keys(currentFilters)
    .sort()
    .reduce((obj, key) => {
      obj[key] = currentFilters[key];
      return obj;
    }, {});

  const orderedB = Object.keys(newFilters)
    .sort()
    .reduce((obj, key) => {
      obj[key] = newFilters[key];
      return obj;
    }, {});

  let areEqual = JSON.stringify(orderedA) === JSON.stringify(orderedB);

  return areEqual;
};

export const filterTimeEntries = createAsyncThunk<
  GetTimeEntriesResponse | null,
  FilterTimeEntryParams,
  { rejectValue: GenericError; state: RootState }
>("timeManagement/filterTimeEntries", async (filterParams, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body: FilterTimeEntryParams = {
    page: state.timeManagement.currentFilters?.page || 0,
    pageSize: 300,
    searchQuery: filterParams.searchQuery,
    processed: filterParams.processed ? true : null,
    type: filterParams.type && filterParams.type > 0 ? filterParams.type : null,
    fromDate: filterParams.fromDate || null,
    toDate: filterParams.toDate || null,
    state: filterParams.state ? filterParams.state : null,
    orderByEmployeeNumber: filterParams.orderByEmployeeNumber,
  };
  if (
    state.timeManagement.currentFilters &&
    state.timeManagement.pageCount &&
    state.timeManagement.lastPageLoad !== null
  ) {
    if (filterParamsAreEqual(state.timeManagement.currentFilters, body)) {
      if (
        state.timeManagement.lastPageLoad ===
        state.timeManagement.pageCount! - 1
      ) {
        return null;
      }
      // Increment last loaded page
      body.page = state.timeManagement.lastPageLoad + 1;
    } else {
      // Reset page
      body.page = 0;
    }
  }
  // Set currentFilters
  dispatch(setCurrentFilters(body));

  const response = await axios
    .post<GetTimeEntriesResponse>(`${BE_URL}/absence/filter`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde ej hämta övertid och frånvaro.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response;
});

export const approveEntry = createAsyncThunk<
  string,
  { id: string; message?: string },
  { rejectValue: GenericError; state: RootState }
>("timeManagement/approveEntry", async (entry, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = {
    message: entry.message,
    absenceId: entry.id,
    state: "APPROVED",
  };

  const response = await axios
    .post<string>(`${BE_URL}/absence/adminSetStatus`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      dispatch(updateEntryStatus({ ...entry, state: "APPROVED" }));
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde ej hämta övertid och frånvaro.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response;
});

export const rejectEntry = createAsyncThunk<
  string,
  { id: string; message?: string },
  { rejectValue: GenericError; state: RootState }
>("timeManagement/rejectEntry", async (entry, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = {
    message: entry.message,
    absenceId: entry.id,
    state: "REJECTED",
  };

  const response = await axios
    .post<string>(`${BE_URL}/absence/adminSetStatus`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      dispatch(updateEntryStatus({ ...entry, state: "REJECTED" }));
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde ej hämta övertid och frånvaro.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response;
});

export const setPayrollStatus = createAsyncThunk<
  boolean,
  { id: string; processed: boolean },
  { rejectValue: GenericError; state: RootState }
>("timeManagement/setProcessed", async (item, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<string>(`${BE_URL}/absence/processed/`, item, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return item.processed;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde ej hämta övertid och frånvaro.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response;
});

export const timeManagementSlice = createSlice({
  name: "timeManagement",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsInvalidated: (state) => {
      state.isInvalidated = true;
    },
    updateEntryStatus: (
      state,
      action: PayloadAction<{ id: string; message?: string; state: string }>
    ) => {
      if (state.entriesList) {
        const entries = [...state.entriesList];
        const itemToUpdate = entries.findIndex((item) => {
          return item.id === action.payload.id;
        });
        if (itemToUpdate > -1) {
          entries[itemToUpdate].state = action.payload.state;
          entries[itemToUpdate].message = action.payload.message || "";
        }
      }
    },
    setCurrentFilters: (
      state,
      action: PayloadAction<FilterTimeEntryParams | null>
    ) => {
      state.currentFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTimeEntries.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getAllTimeEntries.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.entriesList = action.payload;
        state.isInvalidated = false;
      })
      .addCase(getAllTimeEntries.rejected, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.isInvalidated = false;
      })
      .addCase(filterTimeEntries.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(filterTimeEntries.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;

        if (action.payload && action.payload.page === 0) {
          state.entriesList = action.payload.results;
        } else {
          if (action.payload) {
            state.entriesList = [
              ...state.entriesList!,
              ...action.payload.results,
            ];
          }
        }
        state.isInvalidated = false;
        state.lastPageLoad = action.payload ? action.payload.page : 0;
        state.pageCount = action.payload ? action.payload.pageCount : 0;
        state.resultsCounter = action.payload ? action.payload.resultsCount : 0;
      })
      .addCase(filterTimeEntries.rejected, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.isInvalidated = false;
      })
      .addCase(approveEntry.pending, (state) => {
        state.error = null;
        state.statusUpdating = true;
      })
      .addCase(approveEntry.fulfilled, (state, action) => {
        state.error = null;
        state.statusUpdating = false;
      })
      .addCase(approveEntry.rejected, (state, action) => {
        state.error = null;
        state.statusUpdating = false;
      })
      .addCase(rejectEntry.pending, (state) => {
        state.error = null;
        state.statusUpdating = true;
      })
      .addCase(rejectEntry.fulfilled, (state, action) => {
        state.error = null;
        state.statusUpdating = false;
      })
      .addCase(rejectEntry.rejected, (state, action) => {
        state.error = null;
        state.statusUpdating = false;
      })
      .addCase(setPayrollStatus.pending, (state, action) => {
        state.payrollStatusLoadingIds.push(action.meta.arg.id);
      })
      .addCase(setPayrollStatus.fulfilled, (state, action) => {
        // Update loading state
        let indexToRemove = state.payrollStatusLoadingIds.indexOf(
          action.meta.arg.id
        );
        state.payrollStatusLoadingIds.splice(indexToRemove, 1);

        // Update time entry state
        const timeEntryToUpdate = state.entriesList?.find((entry) => {
          return entry.id === action.meta.arg.id;
        });

        if (timeEntryToUpdate) {
          timeEntryToUpdate.processed = action.payload;
        }
      })
      .addCase(setPayrollStatus.rejected, (state, action) => {
        let indexToRemove = state.payrollStatusLoadingIds.indexOf(
          action.meta.arg.id
        );
        state.payrollStatusLoadingIds.splice(indexToRemove, 1);
      });
  },
});

export const {
  setIsLoading,
  setIsInvalidated,
  updateEntryStatus,
  setCurrentFilters,
} = timeManagementSlice.actions;

export const selectRootState = (state: RootState) => state;
export const selectEntries = (state: RootState) =>
  state.timeManagement.entriesList;
export const selectError = (state: RootState) => state.timeManagement.error;
export const selectLoading = (state: RootState) =>
  state.timeManagement.isLoading;
export const selectLoaded = (state: RootState) => state.timeManagement.isLoaded;
export const selectIsInvalidated = (state: RootState) =>
  state.timeManagement.isInvalidated;
export const selectStatusUpdating = (state: RootState) =>
  state.timeManagement.statusUpdating;
export const selectPayrollStatusLoading = (state: RootState) =>
  state.timeManagement.payrollStatusLoadingIds;

export const selectHasMorePages = (state: RootState) => {
  if (
    state.timeManagement.pageCount !== null &&
    state.timeManagement.lastPageLoad !== null &&
    state.timeManagement.isLoading === false
  ) {
    return (
      state.timeManagement.lastPageLoad < state.timeManagement.pageCount - 1
    );
  }
  return true;
};
export const selectTotalResults = (state: RootState) => {
  return state.timeManagement.resultsCounter;
};
export const selectDownloadedCSVData = (state: RootState) => {
  return state.timeManagement.CSVData;
};

export default timeManagementSlice.reducer;
