import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "app/hooks";
import {
  editChecklist,
  resetAfterRoomNameEdit,
  selectChecklist,
  viewChecklist,
} from "../../viewChecklistSlice";
import { ToastContainer, toast } from "react-toastify";
import { Button, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";

const EditRoomName = () => {
  const [showModal, setShowModal] = useState(false);
  const checklist = useAppSelector(selectChecklist);
  const viewChecklistState = useAppSelector(viewChecklist);
  const dispatch = useAppDispatch();
  const [newRoomName, setNewRoomName] = useState("");

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (checklist) {
      const requestBody = {
        roomName: newRoomName,
        checklistId: checklist.id,
      };

      dispatch(editChecklist(requestBody));
    }

    handleClose();
  };

  const notify = () => {
    if (viewChecklistState.error === null) {
      toast.success("Namnet på checklistan redigerades!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när namnet skulle redigeras.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (viewChecklistState.roomNameWasEdited === true) {
      notify();
      dispatch(resetAfterRoomNameEdit());
    }
  }, [viewChecklistState.roomNameWasEdited]);

  return (
    <>
      <ToastContainer position="top-center" />

      <Tooltip arrow title={<>Redigera</>}>
        <EditOutlinedIcon
          onClick={handleClick}
          className={iconStyles.editIcon}
        />
      </Tooltip>

      <Modal show={showModal} onHide={handleClose}>
        <Form className="my-2">
          <Modal.Header closeButton>
            <Modal.Title>Redigera namn på {checklist?.roomName}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            Skriv in nytt namn på rum/checklista
            <Form.Control
              type="text"
              value={newRoomName}
              className="my-2"
              onChange={(e) => setNewRoomName(e.target.value)}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="contained"
              color="error"
              onClick={handleClose}
              className="me-2"
            >
              Avbryt
            </Button>
            <Button
              disabled={newRoomName === ""}
              variant="contained"
              onClick={handleSave}
            >
              Spara
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditRoomName;
