import React from "react";
import { useAppSelector } from "app/hooks";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { Rating } from "@mui/material";
import styles from "../../../checklist.module.scss";
import { selectRatingState } from "../ratingSlice";
import { BeatLoader } from "react-spinners";

const CommentRating = ({
  setCommentsRatingValue,
  commentsRatingValue,
  editable,
}) => {
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const isLoading = useAppSelector(selectRatingState).ratingLoading;

  const handleCommentsChange = (
    event: React.SyntheticEvent,
    value: number | null
  ) => {
    if (value !== null) {
      console.log("handleCommentChange called");
      setCommentsRatingValue(value);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between my-1">
        <div className="d-flex">
          <p>Kommentarer: </p>
          {isLoading ? (
            <BeatLoader color="#5f5f5f" className="mb-3" />
          ) : isAdmin || isUserAdmin ? (
            <Rating
              readOnly={!editable}
              className={styles.rating}
              onChange={handleCommentsChange}
              value={commentsRatingValue}
            />
          ) : (
            <Rating
              readOnly
              className={styles.rating}
              value={commentsRatingValue}
            />
          )}
        </div>
        <span>{commentsRatingValue !== 0 ? commentsRatingValue : "-"}</span>
      </div>
    </>
  );
};

export default CommentRating;
