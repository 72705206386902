import { createTheme } from "@mui/material";
// When using TypeScript 4.x and above
import type {} from "@mui/lab/themeAugmentation";

// Change default colors by creating a new theme
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(255, 167, 0)", // $primary-orange
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          top: "0px",
          height: "calc(3.5rem + 2px)",
          border: "1px solid #ced4da",
          fontFamily: "Avenir Next",
          color: "green",
        },
        root: {
          // opacity: "0.65",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          top: "7px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          flexGrow: 1,
        },
      },
    },
  },
});

export default theme;
