import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectChecklist, viewChecklist } from "../viewChecklistSlice";
import {
  selectThumbnailsLoading,
  selectImageThumbUrls,
  fetchThumbnailMedia,
  selectImageUrls,
  resetFullMediaUrls,
  resetThumbnailUrls,
} from "pages/orders/viewOrder/viewOrderSlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { MediaThumbnail } from "models/MediaThumbnail";
import { MediaEntity } from "models/MediaEntity";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import SlideShowModal from "features/slideShow/SlideShowModal";
import UploadFile from "./UploadFile";
import Thumbnails from "./Thumbnails";
import { BeatLoader } from "react-spinners";
import { Tooltip } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";

const ImagesAndMovies = ({ nameHidden }) => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);
  const userIsUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const thumbnailsLoading = useAppSelector(selectThumbnailsLoading);
  const imageThumbUrls = useAppSelector(selectImageThumbUrls);
  const [showModalAddImage, setShowModalAddImage] = useState<boolean>(false);

  const [showSlideshow, setShowSlideshow] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const thumbnailUrlsRef = useRef<MediaThumbnail[]>();
  const mediaEntities = useAppSelector(selectImageUrls);
  const imageUrlsRef = useRef<MediaEntity[]>();

  const viewChecklistState = useAppSelector(viewChecklist);
  const number = viewChecklistState.checklist?.verification.length;

  useEffect(() => {
    // Needed for the initial fetch
    if (checklist && !imageThumbUrls) {
      dispatch(fetchThumbnailMedia(checklist));
    }
  }, [checklist]);

  useEffect(() => {
    // This is needed so the component updates after a new image/video has been added
    if (checklist && imageThumbUrls) {
      dispatch(fetchThumbnailMedia(checklist));
    }
  }, [checklist?.verification]);

  // Cleanup function
  useEffect(() => {
    return () => {
      if (thumbnailUrlsRef.current) {
        thumbnailUrlsRef.current.forEach((url) => {
          window.URL.revokeObjectURL(url.imgUrl);
        });
      }
      // cleanup
      if (imageUrlsRef.current) {
        imageUrlsRef.current.forEach((url) => {
          window.URL.revokeObjectURL(url.imgUrl);
        });
      }

      dispatch(resetThumbnailUrls());
      dispatch(resetFullMediaUrls());
    };
  }, []);

  useEffect(() => {
    if (imageThumbUrls) {
      thumbnailUrlsRef.current = imageThumbUrls;
    }
    if (mediaEntities) {
      imageUrlsRef.current = mediaEntities;
    }
  }, [imageThumbUrls, mediaEntities]);

  const openSlideShow = (index) => {
    setShowSlideshow(true);
    setCurrentSlide(index);
  };

  const addImage = () => {
    setShowModalAddImage(true);
  };

  const handleCloseModal = () => {
    setShowModalAddImage(false);
    setShowSlideshow(false);
  };

  return (
    <DataContainer>
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between">
          <p className="fw-bold">
            Bilder & Filmer {number === 0 ? "" : `(${number} st)`}
          </p>

          {userIsAdmin || userIsUserAdmin ? (
            <Tooltip arrow title={<>Lägg till bild eller film</>}>
              <AddCircle
                className={iconStyles.addImageIcon}
                onClick={addImage}
              />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        {thumbnailsLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center my-5">
            <BeatLoader color="#5f5f5f" className="mb-3" />
          </div>
        ) : (
          <Thumbnails
            imageThumbUrls={imageThumbUrls}
            openSlideShow={openSlideShow}
          />
        )}
      </div>

      {showSlideshow && (
        <SlideShowModal
          onCloseModal={handleCloseModal}
          initialSlideIndex={currentSlide}
          nameHidden={nameHidden}
        />
      )}

      {showModalAddImage && <UploadFile onCloseModal={handleCloseModal} />}
    </DataContainer>
  );
};

export default ImagesAndMovies;
