import React, { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { CenterContainer } from "features/containers/centerContainer/CenterContainer";
import { Link } from "react-router-dom";
import { resetState } from "./resetPasswordSlice";

const ResetComplete: React.FunctionComponent = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  });

  return (
    <CenterContainer>
      <main className="form-signin">
        <h1>Klart!</h1>
        <p>Du kan nu logga in med ditt nya lösenord!</p>
        <div className="mb-5 mt-3">
          <Link to="/login">Till logga in</Link>
        </div>
      </main>
    </CenterContainer>
  );
};

export default ResetComplete;
