import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import { BE_URL } from "config/api";
import { User } from "features/auth/types/user";
import { GenericError } from "interfaces/errorTypes";
import { Company } from "models/Company";
import { SimpleUser } from "models/SimpleUser";
import { setIsInvalidated } from "../companiesSlice";

interface ShowCompanyState {
  company: Company | null;
  isLoading: boolean;
  isLoaded: boolean;
  error: GenericError | undefined | null;
  state: "idle" | "invalidated" | "deleted";
  isInvalidated: boolean;
  editIsLoading: boolean;
  editIsDone: boolean;
  addingExternalInstaller: boolean;
  loadingExternalInstallers: boolean;
  allExternalInstallers: SimpleUser[];
  // For toastify
  userWasDeleted: boolean;
  userWasAdded: boolean;
  companyWasEdited: boolean;
}

const initialState: ShowCompanyState = {
  company: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  state: "idle",
  isInvalidated: false,
  editIsLoading: false,
  editIsDone: false,
  addingExternalInstaller: false,
  loadingExternalInstallers: false,
  allExternalInstallers: [],
  userWasDeleted: false,
  userWasAdded: false,
  companyWasEdited: false,
};

export const getCompany = createAsyncThunk<
  Company,
  string,
  { rejectValue: GenericError; state: RootState }
>("showCompany/getCompany", async (companyId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<Company>(`${BE_URL}/company/${companyId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      res.data.id = companyId;
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Nånting gick fel, försök igen.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as Company;
});

export const deleteCompany = createAsyncThunk<
  string,
  string,
  { rejectValue: GenericError; state: RootState }
>("showCompany/delete", async (companyId, thunkApi) => {
  const state = thunkApi.getState();

  const response = await axios
    .delete<string>(`${BE_URL}/company/${companyId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then((res) => {
      thunkApi.dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Någonting gick fel när företaget skulle raderas.",
      });
    });

  return response as string;
});

export const editCompany = createAsyncThunk<
  string,
  Company,
  { rejectValue: GenericError; state: RootState }
>("showCompany/edit", async (company, thunkApi) => {
  const state = thunkApi.getState();

  const response = await axios
    .patch<string>(`${BE_URL}/company/${company.id}`, company, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then((res) => {
      thunkApi.dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Någonting gick fel när företagsinfon skulle redigeras.",
      });
    });

  return response as string;
});

export const addUser = createAsyncThunk<
  string,
  { userId: string; companyId: string },
  { rejectValue: GenericError; state: RootState }
>("showCompany/addUser", async (body, thunkApi) => {
  const state = thunkApi.getState();

  const response = await axios
    .post<string>(`${BE_URL}/company/adduser`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then((res) => {
      thunkApi.dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Någonting gick fel när användaren skulle läggas till.",
      });
    });

  return response;
});

export const removeUser = createAsyncThunk<
  string,
  string,
  { rejectValue: GenericError; state: RootState }
>("showCompany/removeUser", async (userId, thunkApi) => {
  const state = thunkApi.getState();

  console.log(`Remove user: ${userId}`);
  const response = await axios
    .patch<string>(
      `${BE_URL}/company/removeuser`,
      { userId: userId },
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then((res) => {
      thunkApi.dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Någonting gick fel när användaren skulle tas bort.",
      });
    });

  return response;
});

export const getExternalInstallers = createAsyncThunk<
  SimpleUser[],
  void,
  { rejectValue: GenericError; state: RootState }
>("showCompany/getExternalInstallers", async (_, thunkApi) => {
  const state = thunkApi.getState();

  const response = await axios
    .get<SimpleUser[]>(`${BE_URL}/user/getExternalInstallers`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Nånting gick fel, försök igen.",
      });
    });

  return response;
});

export const showCompanySlice = createSlice({
  name: "showCompany",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
      state.state = "idle";
    },
    resetEdit: (state) => {
      state.editIsDone = false;
    },
    resetUsersAfterEdit: (state) => {
      state.userWasDeleted = false;
      state.userWasAdded = false;
      state.error = null;
    },
    resetCompanyAfterEdit: (state) => {
      state.companyWasEdited = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.company = action.payload;
        state.isInvalidated = false;
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.company = null;
        state.state = "deleted";
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(editCompany.pending, (state) => {
        state.error = null;
        state.editIsLoading = true;
      })
      .addCase(editCompany.fulfilled, (state, action) => {
        state.error = null;
        state.editIsLoading = false;
        state.editIsDone = true;
        state.isInvalidated = true;
        state.companyWasEdited = true;
      })
      .addCase(editCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.companyWasEdited = true; // technically not added but update state for toastify functionality
      })
      .addCase(addUser.pending, (state, action) => {
        state.addingExternalInstaller = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addingExternalInstaller = false;
        state.isInvalidated = true;
        state.userWasAdded = true;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addingExternalInstaller = false;
        state.error = action.payload;
        state.userWasAdded = true; // technically not added, but update state for toastify functionality
      })
      .addCase(removeUser.pending, (state, action) => {})
      .addCase(removeUser.fulfilled, (state, action) => {
        state.isInvalidated = true;
        state.userWasDeleted = true;
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.userWasDeleted = true; // technically not deleted, but update state for toastify functionality
        state.error = action.payload;
      })
      .addCase(getExternalInstallers.pending, (state, action) => {
        state.loadingExternalInstallers = true;
      })
      .addCase(getExternalInstallers.fulfilled, (state, action) => {
        state.loadingExternalInstallers = false;
        state.allExternalInstallers = action.payload;
      })
      .addCase(getExternalInstallers.rejected, (state, action) => {
        state.loadingExternalInstallers = false;
      });
  },
});

export const {
  setIsLoading,
  setCompany,
  resetEdit,
  resetUsersAfterEdit,
  resetCompanyAfterEdit,
} = showCompanySlice.actions;

export const selectCompany = (state: RootState) => state.showCompany.company;
export const selectError = (state: RootState) => state.showCompany.error;
export const selectLoading = (state: RootState) => state.showCompany.isLoading;
export const selectLoaded = (state: RootState) => state.showCompany.isLoaded;
export const selectIsInvalidated = (state: RootState) =>
  state.showCompany.isInvalidated;
export const selectShowCompanyState = (state: RootState) => state.showCompany;
export const selectEditLoading = (state: RootState) =>
  state.showCompany.editIsLoading;
export const selectEditDone = (state: RootState) =>
  state.showCompany.editIsDone;
export const selectExternalUserLoading = (state: RootState) =>
  state.showCompany.addingExternalInstaller;
export const selectExternalUsers = (state: RootState) =>
  state.showCompany.allExternalInstallers;

export default showCompanySlice.reducer;
