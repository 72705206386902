import React from "react";
import { DateRangePicker, LocalizationProvider } from "features/mui";
import { TextField, Box } from "@mui/material";
import DateAdapter from "features/mui/AdapterDateFns";
import svLocale from "date-fns/locale/sv";

const FilterByDate = ({ dateRange, setDateRange }) => {
  return (
    <div className="mb-5">
      <div style={{ maxWidth: "500px" }}>
        <p className="mb-0">Orderdatum</p>
        <LocalizationProvider dateAdapter={DateAdapter} locale={svLocale}>
          <DateRangePicker
            startText="Från"
            endText="Till"
            value={dateRange}
            onChange={(newValue) => {
              setDateRange(newValue);
            }}
            inputFormat={"dd/MM/yyyy"}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default FilterByDate;
