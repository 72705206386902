import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectUser,
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { Switch, Tooltip } from "@mui/material";
import {
  excludePhotoFromPDF,
  viewChecklist,
} from "pages/orders/viewChecklist/viewChecklistSlice";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal";
import { switchStyle } from "utilities/styles/switchStyle";
import styles from "../slideShow.module.scss";
import iconStyles from "../../../utilities/styles/iconStyles.module.scss";
import { UserRole } from "features/auth/types/user";

const ImageFeatures = (props) => {
  const {
    currentVerification,
    currentSlide,
    setCurrentSlide,
    handleSlideshowModalClose,
  } = props;
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const user = useAppSelector(selectUser);
  const [excludeFromPDF, setExcludeFromPDF] = useState<boolean>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const viewChecklistState = useAppSelector(viewChecklist);

  const reduxPDF =
    viewChecklistState.checklist?.verification[currentSlide]?.excludedFromPdf;

  // Setting the PDF state for the current verification from redux state
  useEffect(() => {
    setExcludeFromPDF(reduxPDF);
  }, [currentSlide]);

  const handlePDFChange = () => {
    setExcludeFromPDF(!excludeFromPDF);
    const requestBody = {
      id: currentVerification.id,
      excluded: !excludeFromPDF,
    };
    dispatch(excludePhotoFromPDF(requestBody));
  };

  const handleCloseModal = () => {
    setConfirmDelete(false);
  };

  // Check if currentVerification is of type image (!video)
  return currentVerification.type === 1 ? (
    <>
      {confirmDelete && (
        <ConfirmDeleteModal
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          currentVerification={currentVerification}
          onCloseModal={handleCloseModal}
          handleSlideshowModalClose={handleSlideshowModalClose}
        />
      )}

      {isAdmin || isUserAdmin || user?.role === UserRole.SALES ? (
        <div className={styles.features}>
          <div className={styles.icons}>
            <div className={styles.includeInPDF}>
              <div className="d-flex">
                <p className={styles.includeLabel}>
                  {excludeFromPDF
                    ? "Exkluderad från installationsbevis"
                    : "Inkluderad i installationsbevis"}
                </p>

                <Tooltip
                  arrow
                  title="Ska bilden inkluderas i kundens installationsbevis?"
                >
                  <Switch
                    checked={!excludeFromPDF}
                    className={styles.switch}
                    sx={switchStyle}
                    onChange={handlePDFChange}
                  />
                </Tooltip>
              </div>
            </div>
            {isAdmin && (
              <>
                <Tooltip arrow title="Radera bilden">
                  <DeleteIcon
                    sx={{ position: "relative", top: 7 }}
                    className={iconStyles.deleteIcon}
                    onClick={() => {
                      setConfirmDelete(true);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  ) : null;
};

export default ImageFeatures;
