import { useAppSelector } from "app/hooks";
import { Redirect } from "react-router";
import { selectLoaded, selectUser } from "features/auth/authSlice";

export interface IUnAuthorizedProps {}

const UnAuthenticatedRoute: React.FunctionComponent<IUnAuthorizedProps> = (
  props
) => {
  const { children } = props;
  const authIsLoaded = useAppSelector(selectLoaded);
  const user = useAppSelector(selectUser);

  if (authIsLoaded) {
    if (user) {
      return <Redirect to="/" />;
    } else {
      return <>{children}</>;
    }
  } else {
    return <></>;
  }
};

export default UnAuthenticatedRoute;
