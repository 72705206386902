import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Link, useHistory } from "react-router-dom";
import { StatisticsSummaryRequest } from "models/InstallerChecklistStatistics";

import { BeatLoader } from "react-spinners";
import tableStyles from "../../../../utilities/styles/tableStyle.module.scss";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { RoleFilter } from "pages/dashboard/utilities/utilities";
import styles from "../../styles.module.scss";
import {
  getStatisticsSummary,
  getTopFiveInstallers,
  selectStatisticsSummary,
  selectTopFiveInStallers,
  selectTopFiveInStallersLoaded,
  selectTopFiveInStallersLoading,
  setStatisticsSummaryLoaded,
} from "pages/dashboard/statisticsSlice";
import { TimePeriodFilters } from "./TopInstallers";
import { Rating } from "@mui/material";
import { useForm } from "hooks/useForm";

const StatisticsSummary = () => {
  const statistcsSummary = useAppSelector(selectStatisticsSummary);
  const dispatch = useAppDispatch();
  const [initialDataFetchComplete, setInitialDataFetchComplete] =
    useState(false);

  const [selectedTimePeriodFilter, setSelectedTimePeriodFilters] = useState(
    TimePeriodFilters.FROM_START
  );
  const [onlyApprovedFilter, setOnlyApprovedFilter] = useState<boolean>(true);
  const [initialFiltersLoaded, setInititalFiltersLoaded] =
    useState<boolean>(false);

  // Handle filter selections
  const { data: statisticsSummaryFilters } = useForm<StatisticsSummaryRequest>({
    initialValues: {
      timePeriod: TimePeriodFilters[TimePeriodFilters.FROM_START],
      onlyIncludeApprovedChecklists: true,
    },
  });

  const fetchData = () => {
    // const requestBody: StatisticsSummaryRequest = {
    //   timePeriod: TimePeriodFilters[selectedTimePeriodFilter],
    //   onlyIncludeApprovedChecklists: onlyApprovedFilter,
    // };
    dispatch(getStatisticsSummary(statisticsSummaryFilters));
  };

  useEffect(() => {
    fetchData();
  }, []);

  function timePeriodSelected(timePeriod: TimePeriodFilters) {
    if (
      TimePeriodFilters[timePeriod].toString() !==
      statisticsSummaryFilters.timePeriod
    ) {
      statisticsSummaryFilters.timePeriod = TimePeriodFilters[timePeriod];
      fetchData();
    }
  }

  function onlyIncludeApprovedSelected(value: boolean) {
    console.log(`Set only approveto: ${value}`);
    if (statisticsSummaryFilters.onlyIncludeApprovedChecklists !== value) {
      statisticsSummaryFilters.onlyIncludeApprovedChecklists = value;
      fetchData();
    }
  }

  return (
    <Container
      className={`w-100 fluid d-flex flex-column ${styles.modalContainer}`}
    >
      <>
        {/* Filter row */}
        <Row className="mb-2 mt-4">
          <Col
            xs={"auto"}
            className="d-flex justify-content-center align-items-center flex-direction-column"
          >
            <h5 className="m-0 fw-bold text-center">Installationer</h5>
          </Col>
          <Col xs={4}>
            <DropdownButton
              variant="info"
              id="dropdown-basic-button"
              title={getTimePeriodFiltersTimePeriodFiltersString(
                TimePeriodFilters[statisticsSummaryFilters.timePeriod]
              )}
            >
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.FROM_START)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.FROM_START
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_WEEK)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_WEEK
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_MONTH)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_MONTH
                )}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => timePeriodSelected(TimePeriodFilters.PAST_YEAR)}
              >
                {getTimePeriodFiltersTimePeriodFiltersString(
                  TimePeriodFilters.PAST_YEAR
                )}
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        <Row className={"mb-4"}>
          <Col>
            <Form.Check
              checked={statisticsSummaryFilters.onlyIncludeApprovedChecklists} // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Endast godkända"
              onChange={(e) => onlyIncludeApprovedSelected(e.target.checked)}
            />
          </Col>
        </Row>
        {/* Header */}

        {statistcsSummary.isLoading ? (
          <BeatLoader color="#5f5f5f" className="mb-3" />
        ) : (
          <>
            <Row className="flex-grow-1">
              <div className="d-flex flex-column">
                {/* <h1 className="fw-bold">
                  {statistcsSummary.averageRating !== null
                    ? statistcsSummary.averageRating.toFixed(2)
                    : "Inget genomsnittsbetyg för vald period"}
                </h1> */}

                <p className="fw-bold">Genomsnittsbetyg</p>
                <div className="mb-2">
                  {statistcsSummary.averageRating !== null ? (
                    <>
                      <h1 className="fw-bold">
                        {statistcsSummary.averageRating.toFixed(2)}
                      </h1>
                      <Rating
                        className=""
                        value={statistcsSummary.averageRating}
                        precision={0.1}
                        readOnly
                      />
                    </>
                  ) : (
                    <p>Inget genomsnittbetyg för vald period</p>
                  )}
                </div>

                {/* {statistcsSummary.totalNumberOfCompletedChecklists && (
                  <p className="fw-bold">
                    {`Antal färdiga installationer: ${statistcsSummary.totalNumberOfCompletedChecklists}`}
                  </p>
                )} */}
              </div>
            </Row>
            <Row className="flex-grow-1">
              <Row className="flex-grow-1">
                <p className="fw-bold">
                  {`Antal skapade checklistor: ${statistcsSummary.numberOfChecklists}`}
                </p>
              </Row>
              {statistcsSummary.percentageCompleted !== null && (
                <Row className="flex-grow-1">
                  <p className="fw-bold">
                    {`Andel slutförda och godkända: ${statistcsSummary.percentageCompleted.toFixed(
                      2
                    )} %`}
                  </p>
                </Row>
              )}
              {statistcsSummary.percentageCompletedInternal !== null && (
                <Row className="flex-grow-1">
                  <p className="fw-bold">
                    {`Andel slutförda och godkända internt: ${statistcsSummary.percentageCompletedInternal.toFixed(
                      2
                    )} %`}
                  </p>
                </Row>
              )}
            </Row>
          </>
        )}
      </>
    </Container>
  );
};

function getTimePeriodFiltersTimePeriodFiltersString(
  value: TimePeriodFilters
): string {
  switch (value) {
    case TimePeriodFilters.FROM_START:
      return "Från början";
    case TimePeriodFilters.PAST_MONTH:
      return "Senaste månaden";
    case TimePeriodFilters.PAST_WEEK:
      return "Senaste veckan";
    case TimePeriodFilters.PAST_YEAR:
      return "Senaste året";
  }
}

export default StatisticsSummary;

export interface StatisticsSummaryState {
  isLoading: boolean;
  loaded: Boolean;
  totalNumberOfCompletedChecklists: number | null;
  totalNumberOfRating: number | null;
  averageRating: number | null;
  numberOfChecklists: number | null;
  percentageCompleted: number | null;
  percentageCompletedInternal: number | null;
}
