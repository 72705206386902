// Styling for MUI Switch component
export const switchStyle = {
  borderRadius: 2,
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "rgb(255, 167, 0);",
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "rgb(255, 167, 0, 0.5);",
  },
  "&:hover .MuiSwitch-switchBase": {
    color: "rgb(255, 167, 0);",
  },
};
