import React from "react";
import IPage from "interfaces/page";
import SignUpForm from "features/signUp/SignUpForm";
import AuthHeader from "features/authHeader/AuthHeader";
import { Container } from "react-bootstrap";

const SignUp: React.FunctionComponent<IPage> = (props) => {
  return (
    <Container className="p-0">
      <AuthHeader />
      <SignUpForm />
    </Container>
  );
};

export default SignUp;
