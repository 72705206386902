import React from "react";
import { DateRange } from "features/mui/DateRangePicker";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import LocalizationProvider from "features/mui/LocalizationProvider";
import DateAdapter from "features/mui/AdapterDateFns";
import svLocale from "date-fns/locale/sv";
import DateRangePicker from "features/mui/DateRangePicker";
import Filterbar from "features/filterbar/Filterbar";
import { Col, FloatingLabel, Form } from "react-bootstrap";

const TimeFilter = ({
  activeFiltersCounter,
  tempDateRange,
  setTempDateRange,
  typeFilter,
  setTypeFilter,
  statusFilter,
  setStatusFilter,
  processedFilter,
  setProcessedFilter,
  sortByNumber,
  setSortByNumber,
}) => {
  const clearFilters = () => {
    setTypeFilter("0");
    setStatusFilter("");
    setTempDateRange([null, null]);
    setProcessedFilter(false);
    setSortByNumber(false);
  };

  return (
    <Filterbar
      clearFilters={clearFilters}
      numberOfFiltersActive={activeFiltersCounter}
    >
      <p className="fw-bold mb-0">Datum</p>
      <LocalizationProvider dateAdapter={DateAdapter} locale={svLocale}>
        <DateRangePicker
          startText="Från"
          endText="Till"
          value={tempDateRange}
          onChange={(newValue: DateRange<Date | null>) => {
            let fromDate: Date | null = null;
            let toDate: Date | null = null;
            if (newValue[0] instanceof Date && !isNaN(newValue[0].getTime())) {
              fromDate = newValue[0];
            }
            if (newValue[1] instanceof Date && !isNaN(newValue[1].getTime())) {
              toDate = newValue[1];
            }
            setTempDateRange([fromDate, toDate]);
          }}
          disableCloseOnSelect={true}
          inputFormat={"dd/MM/yyyy"}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} className="me-2 mb-3" />
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>

      <Col className="pe-0">
        <FloatingLabel controlId="floatingSelect" label="Typ" className="mb-3">
          <Form.Control
            as="select"
            aria-label="Floating label"
            value={typeFilter}
            onChange={(e: any) => setTypeFilter(e.target.value)}
          >
            <option value="0">Alla</option>
            <option value="1">Sjuk</option>
            <option value="2">Betald semester</option>
            <option value="3">VAB</option>
            <option value="4">Tjänstledigt</option>
            <option value="5">Övertid</option>
            <option value="6">Vård av anhörig</option>
            <option value="7">Föräldraledig</option>
          </Form.Control>
        </FloatingLabel>
      </Col>

      <Col className="ps-2">
        <FloatingLabel
          controlId="floatingSelect"
          label="Status"
          className="mb-3"
        >
          <Form.Control
            aria-label="Floating label"
            as="select"
            value={statusFilter}
            onChange={(e: any) => setStatusFilter(e.target.value)}
          >
            <option value="">Alla</option>
            <option value="PENDING">Ej behandlad</option>
            <option value="APPROVED">Godkänd</option>
            <option value="REJECTED">Ej godkänd</option>
          </Form.Control>
        </FloatingLabel>
      </Col>

      <div className="d-flex justify-content-center flex-row w-100">
        <FormControlLabel
          control={
            <Checkbox
              checked={processedFilter}
              onChange={() => setProcessedFilter(!processedFilter)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Visa endast ej behandlade av ekonomi"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={sortByNumber}
              onChange={() => setSortByNumber(!sortByNumber)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Sortera efter anställningsnummer"
        />
      </div>
    </Filterbar>
  );
};

export default TimeFilter;
