import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm } from "hooks/useForm";
import { Modal, Row, Form, Col, FloatingLabel, Spinner } from "react-bootstrap";
import {
  selectCompany,
  selectEditLoading,
  selectEditDone,
  resetEdit,
  editCompany,
} from "../showCompanySlice";
import { Company } from "models/Company";
import { Button } from "@mui/material";

export interface IModalProps {
  handleClose: () => void;
  show: boolean;
}

const EditCompany: React.FunctionComponent<IModalProps> = (props) => {
  const { show, handleClose } = props;
  const dispatch = useAppDispatch();
  const companyToEdit = useAppSelector(selectCompany);
  const editLoading = useAppSelector(selectEditLoading);
  const doneEditing = useAppSelector(selectEditDone);

  useEffect(() => {
    if (doneEditing) {
      dispatch(resetEdit());
      handleClose();
    }
  }, [doneEditing]);

  const {
    handleSubmit,
    handleChange,
    data: companyData,
    errors,
  } = useForm<Company>({
    validations: {
      namn: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett namn.",
        },
      },
      mail: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig e-postadress.",
        },
      },
      phone: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett telefonnummer.",
        },
      },
      postaddress: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange en postadress.",
        },
      },
      address: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange en adress.",
        },
      },
      postnr: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett postnummer.",
        },
      },
      orgnr: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett organisationsnummer.",
        },
      },
      rate: {
        custom: {
          isValid: (value) =>
            value === "" || (!isNaN(Number(value)) && Number(value) >= 0),
          message: "Ange ett giltigt timpris.",
        },
      },
      competence: {
        custom: {
          isValid: (value) => !value || value.length > 0,
          message: "Ange kompetens.",
        },
      },
      comment: {
        custom: {
          isValid: (value) => !value || value.length > 0,
          message: "Skriv en kommentar.",
        },
      },
    },
    initialValues: {
      namn: companyToEdit?.namn,
      mail: companyToEdit?.mail,
      phone: companyToEdit?.phone,
      orgnr: companyToEdit?.orgnr,
      postaddress: companyToEdit?.postaddress,
      postnr: companyToEdit?.postnr,
      address: companyToEdit?.address,
      id: companyToEdit?.id,
      rate: companyToEdit?.rate,
      competence: companyToEdit?.competence,
      comment: companyToEdit?.comment,
    },
    onSubmit: () => {
      dispatch(editCompany(companyData));
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Redigera företag</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <FloatingLabel
              controlId="floatingSelect"
              label="Företagsnamn"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Företagsnamn"
                onChange={handleChange("namn")}
                className={`${errors.namn && "is-invalid"}`}
                value={companyData.namn ? companyData.namn : ""}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="Orgnummer"
              className="mb-3"
            >
              <Form.Control
                type="number"
                placeholder="Orgnummer"
                onChange={handleChange("orgnr")}
                className={`${errors.orgnr && "is-invalid"}`}
                value={companyData.orgnr ? companyData.orgnr : ""}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="Telefonnummer"
              className="mb-3"
            >
              <Form.Control
                type="number"
                placeholder="Telefonnummer"
                onChange={handleChange("phone")}
                className={`${errors.phone && "is-invalid"}`}
                value={companyData.phone ? companyData.phone : ""}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingSelect"
              label="E-post"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="E-post"
                onChange={handleChange("mail")}
                className={`${errors.mail && "is-invalid"}`}
                value={companyData.mail ? companyData.mail : ""}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingSelect"
              label="Adress"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Adress"
                onChange={handleChange("address")}
                className={`${errors.address && "is-invalid"}`}
                value={companyData.address ? companyData.address : ""}
              />
            </FloatingLabel>
            <Row className="mb-3">
              <Col>
                <FloatingLabel controlId="floatingSelect" label="Postort">
                  <Form.Control
                    type="text"
                    placeholder="Postort"
                    onChange={handleChange("postaddress")}
                    className={`${errors.postaddress && "is-invalid"}`}
                    value={
                      companyData.postaddress ? companyData.postaddress : ""
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingSelect" label="Postnummer">
                  <Form.Control
                    type="number"
                    placeholder="Postnummer"
                    onChange={handleChange("postnr")}
                    className={`${errors.postnr && "is-invalid"}`}
                    value={companyData.postnr ? companyData.postnr : ""}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <FloatingLabel
              controlId="floatingSelect"
              label="Timpris"
              className="mb-3"
            >
              <Form.Control
                type="number"
                placeholder="Timpris"
                onChange={handleChange("rate")}
                className={`${errors.rate && "is-invalid"}`}
                value={companyData.rate ? companyData.rate : ""}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="Kompetens"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Kompetens"
                onChange={handleChange("competence")}
                className={`${errors.competence && "is-invalid"}`}
                value={companyData.competence ? companyData.competence : ""}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label="Kommentar"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Kommentar"
                onChange={handleChange("comment")}
                className={`${errors.comment && "is-invalid"}`}
                value={companyData.comment ? companyData.comment : ""}
              />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="error"
              onClick={handleClose}
              className="me-2"
            >
              Avbryt
            </Button>
            <Button type="submit" variant="contained">
              {editLoading ? (
                <Spinner size={"sm"} animation={"border"} />
              ) : (
                "Spara"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditCompany;
