import React, { useEffect } from "react";
import { Button, Tooltip, useStepContext } from "@mui/material";
import { useAppSelector, useAppDispatch } from "app/hooks";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import iconStyles from "utilities/styles/iconStyles.module.scss";
import editFuntionCheckStyles from "pages/orders/viewChecklist/components/edit-components/editFunctionCheck.module.scss";
import {
  Form,
  Modal,
  FloatingLabel,
  Spinner,
  Alert,
  Container,
  Row,
  Col,
  ListGroup,
  Badge,
} from "react-bootstrap";
import { useForm } from "hooks/useForm";
import { useState } from "react";
import textStyles from "utilities/styles/textStyles.module.scss";
import {
  selectOrder,
  selectLoading,
} from "pages/orders/viewOrder/viewOrderSlice";
import { ToastContainer, toast } from "react-toastify";
import { selectChecklist } from "../../viewChecklistSlice";
import CheckedComponent from "features/statusComponents/CheckedComponent";
import { Link } from "react-router-dom";
import {
  EditFunctionCheckRequest,
  LoadingState,
  editFunctionCheck,
  fetchInstallers,
  selectAvailableInstallers,
  selectLoadingState,
} from "./editFunctionCheckSlice";
import { InstallerInformation } from "models/Users";
import { UserRole, getUserRoleName } from "features/auth/types/user";
import { isNullOrUndef } from "chart.js/dist/helpers/helpers.core";

const EditFunctionCheck = ({ show, setShow }) => {
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector(selectLoadingState);
  const allInstallers = useAppSelector(selectAvailableInstallers);
  const checklist = useAppSelector(selectChecklist);
  const [filteredInstallers, setFilteredInstallers] = useState<
    InstallerInformation[]
  >([]);
  const [searchString, setSearchString] = useState<string>("");
  const [functionCheckValue, setFunctionCheckValue] = useState<boolean>(
    checklist?.functionCheck || false
  );
  const [selectedInstaller, setSelectedInstaller] =
    useState<InstallerInformation | null>(null);

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = () => {
    setShow(true);
  };

  useEffect(() => {
    switch (loadingState) {
      case LoadingState.idle: {
        break;
      }
      case LoadingState.loading: {
        break;
      }
      case LoadingState.success: {
        setShow(false);
        break;
      }
    }
  }, [loadingState]);

  useEffect(() => {
    if (show) {
      dispatch(fetchInstallers(null));
    } else {
      setSelectedInstaller(null);
      setSearchString("");
      setFunctionCheckValue(checklist?.functionCheck || false);
    }
  }, [show]);

  useEffect(() => {
    searchInstaller(searchString);
  }, [searchString]);

  function searchInstaller(search: string) {
    if (search.length > 0) {
      let filteredResults = allInstallers.filter((installer) => {
        return installer.name.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredInstallers(filteredResults);
    } else {
      setFilteredInstallers([]);
    }
  }

  const selectInstaller = (installer: InstallerInformation) => {
    setSelectedInstaller(installer);
    setFilteredInstallers([]);
    setSearchString("");
  };

  const save = () => {
    const requestBody: EditFunctionCheckRequest = {
      id: checklist?.id || "",
      state: functionCheckValue,
      installerId: selectedInstaller?.userId,
    };
    dispatch(editFunctionCheck(requestBody));
  };

  const isValidated = () => {
    if (checklist?.functionCheck === true) {
      if (functionCheckValue) {
        return selectedInstaller !== null;
      } else {
        return true;
      }
    } else if (checklist?.functionCheck === false) {
      return selectedInstaller !== null && functionCheckValue;
    }
  };

  return (
    <div>
      <ToastContainer position="top-center" />
      <Tooltip arrow title={"Sätt funktionskontroll"}>
        <EditOutlinedIcon
          className={iconStyles.editIcon}
          onClick={handleClick}
        />
      </Tooltip>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Funktionskontroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checklist?.functionCheck && (
            <>
              <Row>
                <Col>
                  <div className="d-flex w-100">
                    <p className={textStyles.smallText}>
                      Funktionskontroll utförd
                      <span className="ms-2 mb-2">
                        <CheckedComponent />
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <p className={`mt-3 ${textStyles.smallText} mb-0`}>
                  {checklist?.functionCheckAt?.slice(0, 16)}
                  {" av "}
                  <Tooltip arrow title="Gå till användarprofilen">
                    <Link
                      to={`/users/view/${checklist?.functionCheckBy.userId}`}
                      className={textStyles.link}
                    >
                      <span className={textStyles.smallText}>
                        {checklist?.functionCheckBy.userName}
                      </span>
                    </Link>
                  </Tooltip>
                </p>
              </Row>
              {checklist.functionCheckByAdmin && (
                <Row>
                  <p className={`${textStyles.smallText}`}>
                    Redigerad av admin
                  </p>
                </Row>
              )}
            </>
          )}

          {loadingState !== LoadingState.loading && (
            <Container>
              <Row>
                <Modal.Title>Sätt ny funktionskontroll</Modal.Title>
              </Row>
              <Row className="my-3">
                <Col>
                  <Form.Check
                    type="switch"
                    checked={functionCheckValue}
                    onChange={(e) => setFunctionCheckValue(e.target.checked)}
                    id="set-function-check-switch"
                    label="Funktionskontroll utförd"
                  />
                </Col>
              </Row>
              {functionCheckValue && (
                <>
                  <Row>
                    <p className={`${textStyles.orderInfoTitle} mx-0`}>
                      Välj installatör
                    </p>
                  </Row>
                  <Row>
                    <ListGroup>
                      <Form.Control
                        placeholder="Installatör"
                        aria-label="Installatör"
                        value={searchString}
                        onChange={(e) => {
                          setSearchString(e.target.value);
                        }}
                      />

                      <div
                        className={`${editFuntionCheckStyles.fixedSizeContainer}`}
                      >
                        {filteredInstallers.map((installer) => {
                          return (
                            <ListGroup.Item
                              action
                              onClick={() => selectInstaller(installer)}
                            >
                              <Row>
                                <Col xs={"auto"}>{installer.name}</Col>
                                {installer.userRole ==
                                  UserRole.INTERNAL_INSTALLER && (
                                  <Col xs={"auto"}>
                                    <Badge pill bg="success">
                                      {getUserRoleName(installer.userRole)}
                                    </Badge>
                                  </Col>
                                )}
                                {installer.userRole ==
                                  UserRole.EXTERNAL_INSTALLER && (
                                  <>
                                    <Col xs={"auto"}>
                                      <Badge pill bg="primary">
                                        {getUserRoleName(installer.userRole)}
                                      </Badge>
                                    </Col>

                                    <Col xs={"auto"}>
                                      <Badge pill bg="secondary">
                                        {installer.userCompany}
                                      </Badge>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </ListGroup.Item>
                          );
                        })}
                        {/* <ListGroup.Item action>
                          <Row>
                            <Col xs={"auto"}>
                              {"Långt namn på installatören alkdsfkl"}
                            </Col>
                            <Row>
                              <Col xs={3}>
                                <Badge pill bg="primary" className={``}>
                                  {"Extern installatör"}
                                </Badge>
                              </Col>
                              <Col xs={3}>
                                <Badge pill bg="secondary" className={``}>
                                  {
                                    "Installatörens företag installationer och Städ AB"
                                  }
                                </Badge>
                              </Col>
                            </Row>
                          </Row>
                        </ListGroup.Item> */}
                      </div>
                    </ListGroup>
                  </Row>
                  {selectedInstaller !== null && (
                    <Row className="mt-3">
                      <Col>
                        <p className={`${textStyles.orderInfoTitle}`}>
                          Vald installatör:
                        </p>
                      </Col>
                      <Col>
                        <p className={`${textStyles.orderInfoTitle} mx-0`}>
                          {selectedInstaller.name}
                          {selectedInstaller.userRole ===
                            UserRole.EXTERNAL_INSTALLER &&
                            ", " + selectedInstaller.userCompany}
                        </p>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="error"
            variant="contained"
            className="me-2"
            onClick={handleClose}
          >
            Avbryt
          </Button>
          <Button
            disabled={!isValidated()}
            variant="contained"
            type="submit"
            onClick={save}
          >
            {loadingState === LoadingState.loading ? (
              <Spinner size={"sm"} animation={"border"} />
            ) : (
              "spara"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditFunctionCheck;
