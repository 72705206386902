import React from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm } from "hooks/useForm";
import { CenterContainer } from "features/containers/centerContainer/CenterContainer";
import { Alert, FloatingLabel, Form, Spinner } from "react-bootstrap";

import {
  ResetPasswordData,
  selectError,
  selectLoading,
  selectUserEmail,
  verifyResetCode,
} from "./resetPasswordSlice";
import { Button } from "@mui/material";

const EnterNewPassword: React.FunctionComponent = (props) => {
  const dispatch = useAppDispatch();
  const resetLoading = useAppSelector(selectLoading);
  const resetErrors = useAppSelector(selectError);
  const userEmail = useAppSelector(selectUserEmail);

  // Using form hook for validation
  const {
    handleSubmit,
    handleChange,
    data: resetPasswordData,
    errors,
  } = useForm<ResetPasswordData>({
    validations: {
      newPassword: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett nytt lösenord.",
        },
      },
      confirmPassword: {
        custom: {
          isValid: (value) => value === resetPasswordData.newPassword,
          message: "Lösenorden matchar inte.",
        },
      },
      code: {
        custom: {
          isValid: (value) => value.length > 5,
          message: "Ange koden.",
        },
      },
    },
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      email: userEmail || "",
      code: "",
    },
    onSubmit: () => {
      dispatch(verifyResetCode(resetPasswordData));
    },
  });
  return (
    <CenterContainer>
      <main className="form-signin">
        <form className="" onSubmit={handleSubmit} noValidate>
          <div className="text-center"></div>
          <h1 className="h3 mb-3 fw-normal text-center">Ange nytt lösenord</h1>
          <div className="mb-4 mt-4">
            <p className="text-center">
              Ett mail har nu skickats till dig med en kod. Ange koden här och
              välj ett nytt lösenord
            </p>
          </div>
          <FloatingLabel
            controlId="floatingSelect"
            label="Verifieringskod"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Verifieringskod"
              onChange={handleChange("code" || "")}
              className={`${errors.code && "is-invalid"}`}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingSelect"
            label="Nytt lösenord"
            className="mb-3"
          >
            <Form.Control
              type="password"
              placeholder="Nytt lösenord"
              onChange={handleChange("newPassword" || "")}
              className={`${errors.newPassword && "is-invalid"}`}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingSelect"
            label="Bekräfta nytt lösenord"
            className="mb-3"
          >
            <Form.Control
              type="password"
              placeholder="Bekräfta nytt lösenord"
              onChange={handleChange("confirmPassword" || "")}
              className={`${errors.confirmPassword && "is-invalid"}`}
            />
          </FloatingLabel>

          <div className="mb-3 mt-3">
            {
              // Show validation errors if present
              Object.keys(errors).length > 0 && (
                <Alert variant={"danger"}>
                  {Object.values(errors).map((error, index) => {
                    return (
                      <p className="m-0" key={index}>
                        {error}
                      </p>
                    );
                  })}
                </Alert>
              )
            }
            {
              // Show server errors if present
              resetErrors && Object.keys(errors).length < 1 && (
                <Alert variant={"danger"}>
                  <p className="m-0">{resetErrors}</p>
                </Alert>
              )
            }
          </div>

          <div className="d-flex justify-content-end">
            <Button type="submit" variant="contained">
              {resetLoading ? (
                <Spinner size={"sm"} animation={"border"} />
              ) : (
                "Nästa"
              )}
            </Button>
          </div>
        </form>
      </main>
    </CenterContainer>
  );
};

export default EnterNewPassword;
