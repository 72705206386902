import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Tooltip } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const ImageQuality = (props) => {
  const { imageQuality, setImageQuality } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageQuality((event.target as HTMLInputElement).value);
  };

  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between">
        <p className="mb-0 fw-bold">Välj bildkvalitet</p>

        <Tooltip
          arrow
          title={
            <>
              <p className="fw-bold">
                Bestäm kvaliteten på de bifogade bilderna.
              </p>

              <p className="mb-0">
                <strong>OBS!</strong> En högre bildkvalitet drar
                upp filstorleken. För att undvika för stora PDF-filer
                rekommenderas i vanliga fall därför{" "}
                <strong>låg </strong>
                bildkvalitet.
              </p>
            </>
          }
        >
          <HelpOutlineOutlinedIcon
            sx={{
              color: "#161616",
              opacity: 0.4,
              marginLeft: 1,
            }}
          />
        </Tooltip>
      </div>

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="low"
          name="radio-buttons-group"
          value={imageQuality}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="low" control={<Radio />} label="Låg" />
          <FormControlLabel value="medium" control={<Radio />} label="Medium" />
          <FormControlLabel value="high" control={<Radio />} label="Hög" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default ImageQuality;
