import React, { useEffect } from "react";
import { UserRole } from "features/auth/types/user";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectProfile, resetAfterEditProfile } from "features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";

const ProfileUserData = (props) => {
  const { user } = props;
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const profileWasEdited = profile?.profileWasEdited;

  // Toastify
  useEffect(() => {
    if (profileWasEdited) {
      if (profile.error === null) {
        toast.success(`Dina uppgifter har redigerats!`, {});
      } else {
        toast.error(`${profile.error?.message}`);
      }
    }
  }, [profileWasEdited]);

  // Cleanup when component unmount
  useEffect(() => {
    if (profileWasEdited) {
      return () => {
        dispatch(resetAfterEditProfile());
      };
    }
  }, [profileWasEdited]);

  return (
    <>
      <ToastContainer position="top-center" />
      <div>
        {user && (
          <>
            <div className="d-flex justify-content-between">
              {user?.email && (
                <p>
                  <span className="fw-bold">E-post: </span>
                  {user?.email}
                </p>
              )}
            </div>

            {user?.phone && (
              <p>
                <span className="fw-bold">Telefon: </span>
                {user.phone}
              </p>
            )}

            {user.role === UserRole.EXTERNAL_INSTALLER && user?.companyName && (
              <p>
                <span className="fw-bold">Företag: </span>
                {user.companyName}
              </p>
            )}

            <p>
              <span className="fw-bold">Roll: </span>
              {user?.role === UserRole.ADMIN && "Administratör"}
              {user?.role === UserRole.INTERNAL_INSTALLER &&
                "Intern installatör"}
              {user?.role === UserRole.EXTERNAL_INSTALLER &&
                "Extern installatör"}
              {user?.role === UserRole.EMPLOYEE && "Medarbetare"}
              {user?.role === UserRole.PAYROLL && "Ekonomi"}
              {user?.role === UserRole.USER_ADMIN && "Användaradmin"}
              {user?.role === UserRole.SALES && "Säljare"}
            </p>

            {user?.state && (
              <p>
                <span className="fw-bold">Status: </span>
                {user?.state === "PENDING" && "Väntar på godkännande"}
                {user?.state === "ACTIVE" && "Aktiv"}
                {user?.state === "CREATED" && "E-post ej verifierad"}
              </p>
            )}

            {user?.role && user.role !== UserRole.EXTERNAL_INSTALLER && (
              <>
                <p>
                  <span className="fw-bold">Anställningsnummer: </span>
                  {user?.anstnrHogia ? user?.anstnrHogia : "Ej tillagt"}
                </p>

                <p>
                  <span className="fw-bold">Attesterare: </span>
                  {user?.controllerId && user.controllerName
                    ? user.controllerName
                    : "Ingen tillagd"}
                </p>
              </>
            )}

            {/* {user?.role === UserRole.INTERNAL_INSTALLER && (
              <>
                <p>
                  <span className="fw-bold">Artikel tid: </span>
                  {user?.articleTime && user.articleTime
                    ? user.controllerName
                    : "Ingen tillagd"}
                </p>

                <p>
                  <span className="fw-bold">Artikel tid i bil: </span>
                  {user?.articleTimeInCar && user.articleTimeInCar
                    ? user.controllerName
                    : "Ingen tillagd"}
                </p>
              </>
            )} */}
          </>
        )}
      </div>
    </>
  );
};

export default ProfileUserData;
