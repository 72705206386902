import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  deleteCompany,
  resetCompanyAfterEdit,
  selectCompany,
  selectLoading,
  selectShowCompanyState,
} from "../showCompanySlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { CompanyIdParam } from "models/Company";
import ConfirmModal from "features/confirmModal/ConfirmModal";
import EditCompany from "./EditCompany";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { setIsInvalidated } from "pages/companies/companiesSlice";
import { Tooltip } from "@mui/material";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";
import CompanyInfoFields from "./CompanyInfoFields";

const CompanyInfo = () => {
  const dispatch = useAppDispatch();
  const shownCompany = useAppSelector(selectCompany);
  const showCompanyState = useAppSelector(selectShowCompanyState);
  const adminPrivilege = useAppSelector(selectUserIsAdmin);
  const userAdminPrivilege = useAppSelector(selectUserIsUserAdmin);
  const companyLoading = useAppSelector(selectLoading);
  const { id } = useParams<CompanyIdParam>();
  const history = useHistory();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deletePending, setDeletePending] = useState<boolean>(false);
  const location = useLocation();
  const query = useQuery();
  const companyWasEdited = showCompanyState.companyWasEdited;

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const openModal = () => {
    query.append("edit", "true");
    history.replace({
      search: query.toString(),
    });
    setEditMode(true);
  };

  const closeModal = () => {
    query.delete("edit");
    history.replace({
      search: query.toString(),
    });
    setEditMode(false);
  };

  const dismissConfirmModal = () => {
    setDeletePending(false);
  };

  const confirmDelete = () => {
    dispatch(deleteCompany(id));
    setDeletePending(false);
  };

  // When component unmount
  useEffect(() => {
    return () => {
      dispatch(setIsInvalidated());
    };
  }, []);

  // Toastify
  useEffect(() => {
    if (companyWasEdited) {
      if (showCompanyState.error === null) {
        toast.success(`Företagets uppgifter har uppdaterats!`, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else if (showCompanyState.error) {
        toast.error(`${showCompanyState.error.message}`);
      }
    }
  }, [companyWasEdited]);

  // Cleanup when component unmount
  useEffect(() => {
    if (companyWasEdited) {
      return () => {
        dispatch(resetCompanyAfterEdit());
      };
    }
  }, []);

  return (
    <>
      <ToastContainer position="top-center" />
      <div className={containerStyles.companyInfo}>
        <div className="d-flex justify-content-between mb-5">
          <h5 className="fw-bold">Företagsinfo</h5>
          {(adminPrivilege || userAdminPrivilege) && (
            <div>
              <Tooltip arrow title="Redigera företagsinfo">
                <EditOutlinedIcon
                  className={iconStyles.editIcon}
                  sx={{
                    marginRight: 1,
                  }}
                  onClick={openModal}
                />
              </Tooltip>

              <Tooltip arrow title="Radera företag">
                <DeleteIcon
                  className={iconStyles.deleteIcon}
                  onClick={() => setDeletePending(true)}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <CompanyInfoFields shownCompany={shownCompany} />
      </div>

      {editMode && !companyLoading && (
        <EditCompany show={editMode} handleClose={closeModal} />
      )}

      {deletePending && (
        <ConfirmModal
          isLoading={showCompanyState.isLoading}
          handleClose={dismissConfirmModal}
          handleConfirm={confirmDelete}
          show={true}
          title={"Ta bort"}
          message="Är du säker på att du vill ta bort det här företaget?"
          confirmLabel="Ja, ta bort"
          dismissLabel="Avbryt"
        />
      )}
    </>
  );
};

export default CompanyInfo;
