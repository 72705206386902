import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios, { AxiosResponse } from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { Checklist } from "models/Checklist";
import { MoveChecklist } from "models/MoveChecklist";
import { CreateComment } from "models/CreateComment";
import { Comment } from "models/Comment";
import { EditComment } from "models/EditComment";
import { Verification } from "models/Verification";
import { MediaEntity } from "models/MediaEntity";

import {
  addMediaEntity,
  deleteMediaEntity,
  setIsInvalidated,
} from "../viewOrder/viewOrderSlice";
import { DocumentationDone } from "models/DocumentationDone";
import { EditRoomName } from "models/EditRoomName";
import {
  PhotoComment,
  CreatePhotoComment,
  EditPhotoComment,
} from "models/PhotoComment";
import { ExcludeFromPDF } from "models/ExcludeFromPDF";
import { editFunctionCheck } from "./components/edit-components/editFunctionCheckSlice";

export interface ViewChecklistState {
  isLoading: boolean;
  fileIsLoading: boolean;
  statusDone: boolean;
  checklist: Checklist | null;
  commentLoading: boolean;
  isInvalidated: boolean;
  error: GenericError | undefined | null;
  isLoaded: boolean;
  moveChecklistLoading: boolean;
  moveChecklistDone: boolean;
  allImagesLoaded: boolean;
  // Toastify
  roomNameWasEdited: boolean;
  internalCommentWasEdited: boolean;
  internalCommentWasAdded: boolean;
  internalCommentWasDeleted: boolean;
  extraWasAdded: boolean;
  extraWasDeleted: boolean;
  extraWasEdited: boolean;

  fileWasUploaded: boolean;
  fileWasDeleted: boolean;
}

const initialState: ViewChecklistState = {
  isLoading: false,
  fileIsLoading: false,
  statusDone: false,
  checklist: null,
  commentLoading: false,
  isInvalidated: false,
  error: null,
  isLoaded: false,
  moveChecklistLoading: false,
  moveChecklistDone: false,
  allImagesLoaded: false,
  // Toastify
  roomNameWasEdited: false,
  internalCommentWasEdited: false,
  internalCommentWasAdded: false,
  internalCommentWasDeleted: false,
  extraWasAdded: false,
  extraWasDeleted: false,
  extraWasEdited: false,

  fileWasUploaded: false,
  fileWasDeleted: false,
};

export const getChecklist = createAsyncThunk<
  Checklist,
  string,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/getChecklist", async (checklistId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<Checklist>(`${BE_URL}/checklist/${checklistId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Ordernumret existerar inte.",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as Checklist;
});

export const moveChecklist = createAsyncThunk<
  Checklist,
  MoveChecklist,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/moveChecklist", async (moveChecklist, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<Checklist>(`${BE_URL}/checklist/move-checklist`, moveChecklist, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Invalidate order
      dispatch(setIsInvalidated());
      return res.data;
    })

    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Ordernumret du angav existerar inte.",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

// Delete checklist
export const deleteChecklist = createAsyncThunk<
  string,
  string,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/deleteChecklist", async (checklistId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  try {
    const response = await axios.delete<string>(
      `${BE_URL}/checklist/${checklistId}`,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    );
    // Invalidate order
    dispatch(setIsInvalidated());
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // The request was made, but the server responded with a status code
        const status = error.response.status;
        if (status === 401) {
          // Invalid token or user is not admin
          return thunkApi.rejectWithValue({
            message: "Invalid token or user is not admin",
          });
        } else if (status === 404) {
          // Checklist does not exist
          return thunkApi.rejectWithValue({
            message: "Checklist does not exist",
          });
        }
      }
    }

    // Handle other errors and reject the promise with a generic message
    return thunkApi.rejectWithValue({
      message: "Failed to delete checklist",
    });
  }
});

export const setDocumentationDone = createAsyncThunk<
  DocumentationDone,
  DocumentationDone,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/setDocumentationDone", async (documentation, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<DocumentationDone>(
      `${BE_URL}/checklist/set-documentation-done`,
      documentation,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      // Invalidate order
      dispatch(setIsInvalidated());
      return res.data;
    })

    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Something went wrong",
        status: e.response.status,
      });
    });

  return response;
});

export const addInternalComment = createAsyncThunk<
  Comment,
  CreateComment,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/addInternalComment", async (createComment, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = createComment;

  const response = await axios
    .post<Comment>(`${BE_URL}/checklist/internal`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as Comment;
});

export const addExtra = createAsyncThunk<
  Comment,
  CreateComment,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/addExtra", async (createExtra, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = createExtra;

  const response = await axios
    .post<Comment>(`${BE_URL}/checklist/extras`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as Comment;
});

// edit roomName
export const editChecklist = createAsyncThunk<
  Checklist,
  EditRoomName,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/editChecklist", async (editChecklist, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = editChecklist;

  const response = await axios
    .patch<Checklist>(
      `${BE_URL}/checklist/${editChecklist.checklistId}`,
      body,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      // Invalidate order
      dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Checklistan existerar inte.",
          status: e.response.status,
        });
      } else {
        // some other error
        return thunkApi.rejectWithValue({
          message: "okänt fel uppstod",
          status: e.response.status,
        });
      }
    });
  return response as Checklist;
});

export const editInternalComment = createAsyncThunk<
  Comment,
  EditComment,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/editInternalComment", async (editComment, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = editComment;

  const response = await axios
    .patch<Comment>(`${BE_URL}/checklist/edit-comment`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as Comment;
});

export const editExtra = createAsyncThunk<
  Comment,
  EditComment,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/editExtra", async (editComment, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = editComment;

  const response = await axios
    .patch<Comment>(`${BE_URL}/checklist/edit-comment`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as Comment;
});

export const deleteInternalComment = createAsyncThunk<
  {},
  string,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/deleteInternalComment", async (commentId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .delete<string>(`${BE_URL}/checklist/comment/${commentId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return commentId;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const deleteExtra = createAsyncThunk<
  {},
  string,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/deleteExtra", async (commentId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .delete<string>(`${BE_URL}/checklist/comment/${commentId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return commentId;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

// Get all medias
export const fetchAllMedia = createAsyncThunk<
  MediaEntity[],
  Checklist[],
  { rejectValue: GenericError; state: RootState }
>("viewOrder/fetchAllImages", async (checklists, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const allRequests: Promise<AxiosResponse<Blob>>[] = [];
  const returnResponses: MediaEntity[] = [];

  checklists.forEach((checklist) => {
    checklist.verification.forEach((item) => {
      const id = item.id;
      let request = axios.get<Blob>(`${BE_URL}/bin/${id}`, {
        responseType: "blob",
        headers: {
          token: state.auth.token || "",
        },
        data: {
          checklistId: checklist.id,
        },
      });

      allRequests.push(request);
    });
  });

  const allResponses = await axios
    .all(allRequests)
    .then(
      axios.spread((...responses) => {
        responses.forEach((res) => {
          const i = res.config.url?.lastIndexOf("/");
          let id;
          if (i) {
            id = res.config.url?.substring(i + 1);
          }

          const mediaType = res.data.type;
          const localImageUrl = window.URL.createObjectURL(
            new Blob([res.data])
          );
          const returnObj = {
            id: id,
            imgUrl: localImageUrl,
            mediaType: mediaType,
            checklist: JSON.parse(res.config.data).checklistId || null,
            isLoading: false,
            compressedImage: undefined,
          };

          returnResponses.push(returnObj);
        });

        thunkApi.dispatch(setAllImagesLoaded(true));
        return returnResponses;
      })
    )
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hämta fil.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Något annat gick fel.",
        });
      }
    });
  return allResponses;
});

// Upload photo
export type UploadFileType = {
  compressedImage?: File;
  file: File;
  checklistId: string;
};

export const uploadPhoto = createAsyncThunk<
  Verification,
  UploadFileType,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/uploadPhoto", async (fileObject, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const formData = new FormData();

  formData.append("file", fileObject.file);

  const response = await axios
    .post<Verification>(
      `${BE_URL}/bin/photo/${fileObject.checklistId}`,
      formData,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      const objectUrl = window.URL.createObjectURL(fileObject.file);

      const mediaEntity: MediaEntity = {
        imgUrl: objectUrl,
        id: res.data.id,
        mediaType: "image/jpeg",
        isLoading: false,
        checklist: fileObject.checklistId,
        compressedImage: undefined,
      };

      console.log(
        "uploadPhoto: run dispatch addMediaEntity w mediaEntity: ",
        mediaEntity
      );
      dispatch(addMediaEntity(mediaEntity));
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId does not exist.",
          status: e.response.status,
        });
      } else {
        // Some other error occurred
        return thunkApi.rejectWithValue({
          message: "An unknown error has occurred.",
          status: e.response.status,
        });
      }
    });

  return response;
});

export const uploadVideo = createAsyncThunk<
  Verification,
  UploadFileType,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/uploadVideo", async (fileObject, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const formData = new FormData();

  formData.append("file", fileObject.file);

  try {
    const response = await axios.post<Verification>(
      `${BE_URL}/bin/video/${fileObject.checklistId}`,
      formData,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    );

    const objectUrl = window.URL.createObjectURL(fileObject.file);

    const mediaEntity: MediaEntity = {
      imgUrl: objectUrl,
      id: response.data.id,
      mediaType: "video/mp4",
      isLoading: false,
      checklist: fileObject.checklistId,
      compressedImage: undefined,
    };

    dispatch(addMediaEntity(mediaEntity));

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const responseStatus = e.response?.status || 500; // Use a default status if response is undefined
      if (responseStatus === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId does not exist.",
          status: responseStatus,
        });
      } else {
        // Some other error occurred
        return thunkApi.rejectWithValue({
          message: "An unknown error has occurred.",
          status: responseStatus,
        });
      }
    } else {
      // Handle non-Axios errors
      return thunkApi.rejectWithValue({
        message: "An unexpected error has occurred.",
      });
    }
  }
});

export const deletePhoto = createAsyncThunk<
  // Also works for delete video
  string,
  string,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/deletePhoto", async (id, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .delete<string>(`${BE_URL}/bin/${id}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      dispatch(deleteMediaEntity(id));

      return id;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Image does not exist",
          status: e.response.status,
        });
      } else {
        // Some other error occurred
        return thunkApi.rejectWithValue({
          message: "An unknown error has occurred",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const addPhotoComment = createAsyncThunk<
  PhotoComment,
  CreatePhotoComment,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/addPhotoComment", async (createPhotoComment, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<PhotoComment>(`${BE_URL}/bin/add-photo-comment`, createPhotoComment, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Invalidate order
      dispatch(setIsInvalidated());
      res.data.mediaId = createPhotoComment.mediaId;
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "an unknown error occurred",
        status: e.response.status,
      });
    });
  return response as PhotoComment;
});

export const editPhotoComment = createAsyncThunk<
  PhotoComment,
  EditPhotoComment,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/editPhotoComment", async (editPhotoComment, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .patch<PhotoComment>(`${BE_URL}/bin/edit-photo-comment`, editPhotoComment, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Invalidate order
      dispatch(setIsInvalidated());
      res.data.mediaId = editPhotoComment.mediaId;
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "an unknown error occurred",
        status: e.response.status,
      });
    });
  return response as PhotoComment;
});

export const deletePhotoComment = createAsyncThunk<
  string,
  string,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/deletePhotoComment", async (commentId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .delete<string>(`${BE_URL}/bin/delete-photo-comment`, {
      headers: {
        token: state.auth.token || "",
      },
      data: {
        id: commentId,
      },
    })
    .then(async (res) => {
      return commentId;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "an unknown error occurred",
        status: e.response.status,
      });
    });
  return response;
});

export const excludePhotoFromPDF = createAsyncThunk<
  ExcludeFromPDF,
  ExcludeFromPDF,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/excludeFromPDF", async (excludeFromPDF, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<ExcludeFromPDF>(
      `${BE_URL}/bin/set-excluded-from-pdf`,
      excludeFromPDF,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      // invalidate order
      dispatch(setIsInvalidated());
      return excludeFromPDF;
    })

    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "something went wrong",
        status: e.response.status,
      });
    });
  return response as ExcludeFromPDF;
});

export const viewChecklistSlice = createSlice({
  name: "viewChecklist",
  initialState,
  reducers: {
    resetStatusDone: (state) => {
      state.statusDone = false;
    },
    setChecklistState: (state, action: PayloadAction<Checklist>) => {
      state.checklist = action.payload;
    },
    clearChecklist: (state) => {
      state.checklist = null;
      state.allImagesLoaded = false;
    },
    setIsInvalidatedChecklist: (state) => {
      state.isInvalidated = true;
    },
    resetMoveChecklistDone: (state) => {
      state.moveChecklistDone = false;
    },
    resetError: (state) => {
      state.error = null;
    },
    setAllImagesLoaded: (state, action: PayloadAction<boolean>) => {
      state.allImagesLoaded = action.payload;
    },
    resetAfterRoomNameEdit: (state) => {
      state.roomNameWasEdited = false;
    },
    resetInternalComments: (state) => {
      state.internalCommentWasEdited = false;
      state.internalCommentWasAdded = false;
      state.internalCommentWasDeleted = false;
    },
    resetExtras: (state) => {
      state.extraWasAdded = false;
      state.extraWasDeleted = false;
      state.extraWasEdited = false;
    },
    resetUploadFile: (state) => {
      state.fileWasUploaded = false;
    },
    resetDeleteFile: (state) => {
      state.fileWasDeleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChecklist.pending, (state) => {
        state.error = null;
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(getChecklist.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.checklist = action.payload;
        state.isInvalidated = false;
      })
      .addCase(getChecklist.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoaded = true;
      })

      // delete checklist
      .addCase(deleteChecklist.pending, (state) => {
        state.error = null;
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(deleteChecklist.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteChecklist.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isLoaded = true;
      })

      // edit checklist
      .addCase(editChecklist.pending, (state) => {
        state.error = null;
        state.isLoading = true;
        state.roomNameWasEdited = false;
      })
      .addCase(editChecklist.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.checklist = action.payload;
        state.roomNameWasEdited = true;
      })
      .addCase(editChecklist.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.roomNameWasEdited = true; // technically not edited, but update state for toastify function
      })

      // set exclude from PDF
      .addCase(excludePhotoFromPDF.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(excludePhotoFromPDF.fulfilled, (state, action) => {
        state.isLoading = false;
        // find the correct verification and save it in a variable
        if (state.checklist) {
          const mediaId = action.payload.id;
          const verificationIndex = state.checklist?.verification.findIndex(
            (verification) => verification.id === mediaId
          );

          if (verificationIndex >= 0) {
            // set the value on the correct verification
            state.checklist.verification[verificationIndex].excludedFromPdf =
              action.payload.excluded;
          }
        }
      })

      .addCase(excludePhotoFromPDF.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // edit documentation done
      .addCase(setDocumentationDone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setDocumentationDone.fulfilled, (state, action) => {
        state.isLoading = false;

        if (state.checklist) {
          state.checklist.documentationDone = action.payload.documentationDone;
        }
      })

      .addCase(setDocumentationDone.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // add photoComment
      .addCase(addPhotoComment.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(
        addPhotoComment.fulfilled,
        (state, action: PayloadAction<PhotoComment>) => {
          state.isLoading = false;

          if (state.checklist) {
            // Find the correct verification
            const mediaId = action.payload.mediaId;
            const verificationIndex = state.checklist.verification.findIndex(
              (verification) => verification.id === mediaId
            );
            if (verificationIndex >= 0) {
              // Add the new comment to the verification's comments array
              state.checklist.verification[verificationIndex].comments.push(
                action.payload
              );
            }
          }
        }
      )

      .addCase(addPhotoComment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // edit photoComment
      .addCase(editPhotoComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editPhotoComment.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.checklist) {
          // Find the correct verification
          const commentId = action.payload.id;
          const mediaId = action.payload.mediaId;

          const verificationIndex = state.checklist?.verification.findIndex(
            (verification) => verification.id === mediaId
          );

          if (verificationIndex >= 0) {
            // find the correct comment
            const commentIndex = state.checklist.verification[
              verificationIndex
            ].comments.findIndex((comment) => comment.id === commentId);

            // update the comment
            if (commentIndex >= 0) {
              state.checklist.verification[verificationIndex].comments[
                commentIndex
              ].text = action.payload.text;
            }
          }
        }
      })
      .addCase(editPhotoComment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // delete photoComment
      .addCase(deletePhotoComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePhotoComment.fulfilled, (state, action) => {
        state.isLoading = false;

        if (state.checklist) {
          const commentId = action.payload;
          const { verification } = state.checklist;

          // Find the verification/photo that contains the comment
          const photoIndex = verification.findIndex((photo) =>
            photo.comments.some((comment) => comment.id === commentId)
          );

          if (photoIndex !== -1) {
            const photo = verification[photoIndex];
            const comments = photo.comments.filter(
              (comment) => comment.id !== commentId
            );

            // Create an updated photo object with the new comment array
            const updatedPhoto = { ...photo, comments };

            // Update the verification array with the updated photo object
            const updatedVerification = [
              ...verification.slice(0, photoIndex),
              updatedPhoto,
              ...verification.slice(photoIndex + 1),
            ];

            // Update the checklist object with the updated verification array
            state.checklist = {
              ...state.checklist,
              verification: updatedVerification,
            };
          }
        }
      })

      .addCase(deletePhotoComment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // move checklist
      .addCase(moveChecklist.pending, (state) => {
        state.moveChecklistLoading = true;
        state.moveChecklistDone = false;
      })
      .addCase(moveChecklist.rejected, (state, action) => {
        state.moveChecklistLoading = false;
        state.error = action.payload;
        state.moveChecklistDone = false;
      })
      .addCase(moveChecklist.fulfilled, (state, action) => {
        state.moveChecklistLoading = false;
        state.checklist = action.payload;
        state.moveChecklistDone = true;
      })

      // fetch all media
      .addCase(fetchAllMedia.rejected, (state, action) => {
        state.error = action.payload || null;
        // state.fullMediaLoaded = true;
      })
      .addCase(fetchAllMedia.pending, (state, action) => {
        // state.fullMediaLoaded = false;
      })
      .addCase(fetchAllMedia.fulfilled, (state, action) => {})

      //upload photo
      .addCase(uploadPhoto.pending, (state) => {
        state.fileIsLoading = true;
      })
      .addCase(uploadPhoto.rejected, (state, action) => {
        state.fileIsLoading = false;
        state.fileWasUploaded = true; // Technically not uploaded, but for Toastify functionality
        state.error = action.payload;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.fileIsLoading = false;
        state.checklist?.verification.push(action.payload);
        state.fileWasUploaded = true;
      })

      // upload video
      .addCase(uploadVideo.pending, (state) => {
        state.fileIsLoading = true;
      })
      .addCase(uploadVideo.rejected, (state, action) => {
        state.fileIsLoading = false;
        state.fileWasUploaded = true; // Technically not uploaded, but for Toastify functionality
        state.error = action.payload;
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        state.fileIsLoading = false;
        state.fileWasUploaded = true;
        state.checklist?.verification.push(action.payload);
      })

      // delete photo
      .addCase(deletePhoto.pending, (state) => {
        state.fileIsLoading = true;
        state.fileWasDeleted = false;
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        state.fileIsLoading = false;
        state.fileWasDeleted = true; // Technically not deleted, but for Toastify functionality
        state.error = action.payload;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.fileIsLoading = false;
        state.fileWasDeleted = true;

        if (state.checklist?.verification) {
          const array = state.checklist.verification.filter((photo) => {
            return photo.id !== action.payload;
          });
          state.checklist.verification = array;
        }
      })

      // add internal comment
      .addCase(addInternalComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addInternalComment.rejected, (state) => {
        state.isLoading = false;
        state.internalCommentWasAdded = true; // Techically not edited but update for toastify functionality
      })
      .addCase(
        addInternalComment.fulfilled,
        (state, action: PayloadAction<Comment>) => {
          state.isLoading = false;
          state.internalCommentWasAdded = true;

          if (state.checklist?.internalComments) {
            const array = [...state.checklist.internalComments, action.payload];
            state.checklist.internalComments = array;
          }
        }
      )

      //add extra
      .addCase(addExtra.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addExtra.rejected, (state) => {
        state.isLoading = false;
        state.extraWasAdded = true; // Techincally not added but update for toastify funcitonality
      })
      .addCase(addExtra.fulfilled, (state, action: PayloadAction<Comment>) => {
        state.isLoading = false;
        state.extraWasAdded = true;

        if (state.checklist?.additionalExtras) {
          const array = [...state.checklist.additionalExtras, action.payload];
          state.checklist.additionalExtras = array;
        }
      })

      // edit internal comment
      .addCase(editInternalComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editInternalComment.rejected, (state) => {
        state.isLoading = false;
        state.internalCommentWasEdited = true; // Techically not edited but update for toastify functionality
      })
      .addCase(
        editInternalComment.fulfilled,
        (state, action: PayloadAction<Comment>) => {
          state.isLoading = false;
          state.internalCommentWasEdited = true;

          if (state.checklist?.internalComments) {
            state.checklist.internalComments = state.checklist.internalComments
              .filter((comment) => comment.id !== action.payload.id)
              .concat(action.payload);
          }
        }
      )

      // edit extra
      .addCase(editExtra.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editExtra.rejected, (state) => {
        state.isLoading = false;
        state.extraWasEdited = true; // Technically not edited but update state for toastify functionality
      })
      .addCase(editExtra.fulfilled, (state, action: PayloadAction<Comment>) => {
        state.isLoading = false;
        state.extraWasEdited = true;

        if (state.checklist?.additionalExtras) {
          state.checklist.additionalExtras = state.checklist.additionalExtras
            .filter((extra) => extra.id !== action.payload.id)
            .concat(action.payload);
        }
      })

      // delete internal comment
      .addCase(deleteInternalComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInternalComment.rejected, (state) => {
        state.isLoading = false;
        state.internalCommentWasDeleted = true; // Technically not deleted but update for toastify functionality
      })
      .addCase(deleteInternalComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.internalCommentWasDeleted = true;

        if (state.checklist?.internalComments) {
          const array = state.checklist.internalComments.filter((comment) => {
            return comment.id !== action.payload;
          });
          state.checklist.internalComments = array;
        }
      })

      // delete extra
      .addCase(deleteExtra.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteExtra.rejected, (state) => {
        state.isLoading = false;
        state.extraWasDeleted = true; // Technically not deleted but update for toastify functionailty
      })
      .addCase(deleteExtra.fulfilled, (state, action) => {
        state.isLoading = false;
        state.extraWasDeleted = true;

        if (state.checklist?.additionalExtras) {
          const array = state.checklist.additionalExtras.filter((extra) => {
            return extra.id !== action.payload;
          });
          state.checklist.additionalExtras = array;
        }
      })
      // Handle edit function check
      .addCase(editFunctionCheck.fulfilled, (state, action) => {
        if (state.checklist) {
          let updatedFunctionCheck = action.payload;

          state.checklist.functionCheck = updatedFunctionCheck.functionCheck;
          state.checklist.functionCheckAt =
            updatedFunctionCheck.functionCheckAt;
          state.checklist.functionCheckBy =
            updatedFunctionCheck.functionCheckBy;
          state.checklist.functionCheckByAdmin =
            updatedFunctionCheck.functionCheckByAdmin;
        }
      });
  },
});

export const {
  resetStatusDone,
  setChecklistState,
  clearChecklist,
  setIsInvalidatedChecklist,
  resetMoveChecklistDone,
  resetError,
  setAllImagesLoaded,
  resetAfterRoomNameEdit,
  resetInternalComments,
  resetExtras,
  resetUploadFile,
  resetDeleteFile,
} = viewChecklistSlice.actions;

export const selectChecklistLoading = (state: RootState) =>
  state.viewChecklist.isLoading;
export const selectCommentLoading = (state: RootState) =>
  state.viewChecklist.commentLoading;
export const selectStatusDone = (state: RootState) =>
  state.viewChecklist.statusDone;
export const selectChecklist = (state: RootState) =>
  state.viewChecklist.checklist;
export const selectIsInvalidatedChecklist = (state: RootState) =>
  state.viewChecklist.isInvalidated;
export const selectVerification = (state: RootState) =>
  state.viewChecklist.checklist?.verification;

export const selectIsLoaded = (state: RootState) =>
  state.viewChecklist.isLoaded;
export const viewChecklist = (state: RootState) => state.viewChecklist;

export default viewChecklistSlice.reducer;
