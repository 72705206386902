import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { SerialEntity } from "models/Checklist";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { selectChecklist } from "../../viewChecklistSlice";
import {
  editProduct,
  resetProductsAfterUpdate,
  selectProductsState,
} from "./productsSlice";
import AddNewProduct from "./AddNewProduct";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./products.module.scss";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";

const EditProducts: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const productsState = useAppSelector(selectProductsState);
  const isLoading = productsState.productLoading;
  const products = productsState.products;

  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updatedProducts, setUpdatedProducts] = useState<SerialEntity[]>([]);

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [serialNumberString, setSerialNumberString] = useState<string | null>(
    ""
  );
  const [makerString, setMakerString] = useState<string | null>("");
  const [modelString, setModelString] = useState<string | null>("");
  const [macAddressString, setMacAddressString] = useState<string | null>("");
  const [networkSocketString, setNetworkSocketString] = useState<string | null>(
    ""
  );
  const [otherString, setOtherString] = useState<string | null>("");
  const [theftProtectionString, setTheftProtectionString] = useState<
    string | null
  >("");
  const [macAddressWifiString, setMacAddressWifiString] = useState<
    string | null
  >("");
  const [ipAddressString, setipAddressString] = useState<string | null>("");
  const [usernameString, setUsernameString] = useState<string | null>("");
  const [passwordString, setPasswordString] = useState<string | null>("");

  useEffect(() => {
    if (products) {
      setUpdatedProducts(products);
    }
  }, [products]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    dispatch(resetProductsAfterUpdate());
    setShowModal(false);
  };

  const confirmChange = (productId: string) => {
    if (checklist) {
      const requestBody = {
        checklistId: checklist.id,
        id: productId,
        serialNumber: serialNumberString,
        maker: makerString,
        model: modelString,
        macAddress: macAddressString,
        macAddressWifi: macAddressWifiString,
        ipAddress: ipAddressString,
        theftProtection: theftProtectionString,
        networkSocket: networkSocketString,
        username: usernameString,
        password: passwordString,
        other: otherString,
        noSerials: false, // vad baseras denna på?
      };

      dispatch(editProduct(requestBody));
      setEdit(false);
    }
  };

  const restoreChange = () => {
    if (products) {
      setUpdatedProducts(products);
    }

    setEdit(false);
  };

  const handleChange = (
    serialNumber: string | null,
    maker: string | null,
    model: string | null,
    macAddress: string | null,
    networkSocket: string | null,
    other: string | null,
    theftProtection: string | null,
    macAddressWifi: string | null,
    ipAddress: string | null,
    username: string | null,
    password: string | null,
    index: number,
    productId: string
  ) => {
    setEdit(true);
    setEditIndex(index);

    let tempState = updatedProducts.map((product) => {
      if (product.id === productId) {
        let tempProduct = {
          ...product,
          serialNumber: serialNumber,
          maker: maker,
          model: model,
          macAddress: macAddress,
          networkSocket: networkSocket,
          other: other,
          theftProtection: theftProtection,
          macAddressWifi: macAddressWifi,
          ipAddress: ipAddress,
          username: username,
          password: password,
        };
        return tempProduct;
      }
      return product;
    });

    setSerialNumberString(serialNumber);
    setMakerString(maker);
    setModelString(model);
    setMacAddressString(macAddress);
    setNetworkSocketString(networkSocket);
    setOtherString(other);
    setTheftProtectionString(theftProtection);
    setMacAddressWifiString(macAddressWifi);
    setipAddressString(ipAddress);
    setUsernameString(username);
    setPasswordString(password);
    setUpdatedProducts(tempState);
  };

  const notify = () => {
    if (productsState.error === null) {
      toast.success("Produkten har redigerats!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när produkten skulle redigeras.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (productsState.productWasEdited === true) {
      notify();
    }
  }, [productsState.productWasEdited]);

  return (
    <>
      <ToastContainer position="top-center" />

      <Tooltip arrow title={<>Redigera produkter</>}>
        <EditOutlinedIcon
          onClick={handleClick}
          className={iconStyles.editIcon}
        />
      </Tooltip>

      <Modal
        show={showModal}
        size="xl"
        onHide={handleClose}
        dialogClassName={styles.customModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Redigera produkter ({updatedProducts.length} st)
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={styles.modalBody}>
          <AddNewProduct />

          <Form className={styles.formContainer}>
            {isLoading ? (
              <Spinner animation={"border"} />
            ) : (
              <>
                {updatedProducts &&
                  updatedProducts.map((product, index) => (
                    <div className={styles.editProductForm} key={index}>
                      <div className="text-end">
                        <Tooltip arrow title={<>Radera produkten</>}>
                          <ClearIcon
                            className={iconStyles.deleteProductIcon}
                            onClick={() => {
                              setDeleteIndex(index);
                              setConfirmDelete(true);
                            }}
                          />
                        </Tooltip>
                      </div>

                      {deleteIndex === index && (
                        <ConfirmDeleteModal
                          confirmDelete={confirmDelete}
                          setConfirmDelete={setConfirmDelete}
                          product={product}
                        />
                      )}

                      <Form.Label>Serienummer</Form.Label>

                      {product.serialNumber ? (
                        <Form.Control
                          type="text"
                          value={product.serialNumber}
                          onChange={(e) => {
                            handleChange(
                              e.target.value, // 1. Serienummer
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              e.target.value, // 1. Serienummer
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}

                      <div className="d-flex">
                        <Form.Label className="w-50">Fabrikat</Form.Label>
                        <Form.Label className="w-50">Modell</Form.Label>
                      </div>

                      <div className="d-flex">
                        {product.maker ? (
                          <Form.Control
                            type="text"
                            value={product.maker}
                            onChange={(e) => {
                              handleChange(
                                product.serialNumber,
                                e.target.value, // 2. Fabrikat
                                product.model,
                                product.macAddress,
                                product.networkSocket,
                                product.other,
                                product.theftProtection,
                                product.macAddressWifi,
                                product.ipAddress,
                                product.username,
                                product.password,
                                index,
                                product.id
                              );
                            }}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            value=""
                            onChange={(e) => {
                              handleChange(
                                product.serialNumber,
                                e.target.value, // 2. Fabrikat
                                product.model,
                                product.macAddress,
                                product.networkSocket,
                                product.other,
                                product.theftProtection,
                                product.macAddressWifi,
                                product.ipAddress,
                                product.username,
                                product.password,
                                index,
                                product.id
                              );
                            }}
                          />
                        )}
                        {product.model ? (
                          <Form.Control
                            type="text"
                            value={product.model}
                            onChange={(e) => {
                              handleChange(
                                product.serialNumber,
                                product.maker,
                                e.target.value, // 3. Modell
                                product.macAddress,
                                product.networkSocket,
                                product.other,
                                product.theftProtection,
                                product.macAddressWifi,
                                product.ipAddress,
                                product.username,
                                product.password,
                                index,
                                product.id
                              );
                            }}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            value=""
                            onChange={(e) => {
                              handleChange(
                                product.serialNumber,
                                product.maker,
                                e.target.value, // 3. Modell
                                product.macAddress,
                                product.networkSocket,
                                product.other,
                                product.theftProtection,
                                product.macAddressWifi,
                                product.ipAddress,
                                product.username,
                                product.password,
                                index,
                                product.id
                              );
                            }}
                          />
                        )}
                      </div>

                      <Form.Label>MAC-address</Form.Label>
                      {product.macAddress ? (
                        <Form.Control
                          type="text"
                          value={product.macAddress}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              e.target.value, // 4. MAC-address
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              e.target.value, // 4. MAC-address
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}

                      <Form.Label>Nätverksuttag</Form.Label>
                      {product.networkSocket ? (
                        <Form.Control
                          type="text"
                          value={product.networkSocket}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              e.target.value, // 5. Nätverksuttag
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              e.target.value, // 5. Nätverksuttag
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}

                      <Form.Label>Stöldmärkning</Form.Label>
                      {product.theftProtection ? (
                        <Form.Control
                          type="text"
                          value={product.theftProtection}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              e.target.value, // 7. Stöldmärkning
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              e.target.value, // 7. Stöldmärkning
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}

                      <Form.Label>MAC-adress Wi-Fi</Form.Label>
                      {product.macAddressWifi ? (
                        <Form.Control
                          type="text"
                          value={product.macAddressWifi}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              e.target.value, // 8. macAdressWifi
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              e.target.value, // 8. macAdressWifi
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}

                      <Form.Label>IP-adress (om fast) </Form.Label>
                      {product.ipAddress ? (
                        <Form.Control
                          type="text"
                          value={product.ipAddress}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              e.target.value, // 9. ipAdress
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              e.target.value, // 9. ipAdress
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}
                      <Form.Label>Användarnamn</Form.Label>
                      {product.username ? (
                        <Form.Control
                          type="text"
                          value={product.username}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              e.target.value, // 10 username
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              e.target.value, // 10. username
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}
                      <Form.Label>Lösenord </Form.Label>
                      {product.password ? (
                        <Form.Control
                          type="text"
                          value={product.password}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              e.target.value, // 11 Password
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              product.other,
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              e.target.value, // 11 Password
                              index,
                              product.id
                            );
                          }}
                        />
                      )}
                      <Form.Label>Övrigt/Firmware-version</Form.Label>
                      {product.other ? (
                        <Form.Control
                          type="text"
                          value={product.other}
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              e.target.value, // 6. Övrigt/Firmware-version
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value=""
                          onChange={(e) => {
                            handleChange(
                              product.serialNumber,
                              product.maker,
                              product.model,
                              product.macAddress,
                              product.networkSocket,
                              e.target.value, // 6. Övrigt/Firmware-version
                              product.theftProtection,
                              product.macAddressWifi,
                              product.ipAddress,
                              product.username,
                              product.password,
                              index,
                              product.id
                            );
                          }}
                        />
                      )}

                      {editIndex === index && edit && (
                        <div className="d-flex justify-content-center mb-2">
                          <Button
                            onClick={() => confirmChange(product.id)}
                            className="mx-3"
                          >
                            Spara ändringar
                          </Button>
                          <Button onClick={restoreChange}>Avbryt</Button>
                        </div>
                      )}
                    </div>
                  ))}
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProducts;
