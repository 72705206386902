import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  loginRequest,
  validateTokenRequest,
  verifyEmailRequest,
} from "./authAPI";
import { User, UserRole } from "./types/user";
import { LoginResponse, LoginError } from "./types/authResponseTypes";
import { LoginData, VerifyEmailData } from "interfaces/formTypes";
import axios from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";

interface AuthState {
  auth: LoginResponse | null;
  token: string | null;
  user: User | null;
  loginStatus: "idle" | "done" | "loading" | "error" | "loggedOut";
  isLoading: boolean;
  isLoaded: boolean;
  error: GenericError | undefined | null;
  profileUpdating: boolean;
  profileUpdated: boolean;
  profileErrors: string | null;
  // Toastify
  profileWasEdited: boolean;
}

const initialState: AuthState = {
  auth: null,
  token: null,
  user: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  loginStatus: "idle",
  profileUpdating: false,
  profileUpdated: false,
  profileErrors: null,
  // Toastify
  profileWasEdited: false,
};

export const login = createAsyncThunk<
  LoginResponse,
  LoginData,
  { rejectValue: LoginError }
>("auth/login", async ({ email, password }, thunkApi) => {
  const response = await loginRequest<LoginResponse>(email, password)
    .then((res) => {
      // Set token to localstorage
      localStorage.setItem("token", res.data.token);
      thunkApi.dispatch(validateToken(res.data.token));

      // return the result data
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 403) {
        return thunkApi.rejectWithValue({
          message: "Du har inte tillåtelse att logga in här.",
        });
      } else if (e.response.status !== 200) {
        return thunkApi.rejectWithValue({
          message: "E-post eller lösenord är fel.",
        });
      }
    });
  return response as LoginResponse;
});

export const validateToken = createAsyncThunk<
  User,
  string,
  { rejectValue: LoginError }
>("auth/validateToken", async (token, thunkApi) => {
  const response = await validateTokenRequest(token)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        // Token invalid, remove from local storage
        localStorage.removeItem("token");
        return thunkApi.rejectWithValue({
          message: "Du har loggats ut pga inaktivitet.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Server error.",
        });
      }
    });
  return response as User;
});

export const verifyEmail = createAsyncThunk<
  string,
  VerifyEmailData,
  { rejectValue: LoginError; state: RootState }
>("auth/verifyEmail", async (code, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();
  const token = state.auth.token || "";

  const response = await verifyEmailRequest(code, token)
    .then(async (res) => {
      dispatch(validateToken(token));
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Koden du angav var fel.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

export interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const changePassword = createAsyncThunk<
  string,
  ChangePasswordData,
  { rejectValue: LoginError; state: RootState }
>("auth/changePassword", async (passwordData, thunkApi) => {
  const state = thunkApi.getState();

  const response = await axios
    .post<string>(`${BE_URL}/user/changePassword`, passwordData, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Lösenorden matchar ej.",
        });
      } else if (e.response.status === 401) {
        return thunkApi.rejectWithValue({
          message: "Lösenordet du angav var fel.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });

  return response;
});

export const editProfile = createAsyncThunk<
  User,
  User,
  { rejectValue: GenericError; state: RootState }
>("showUser/editProfile", async (userData, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  if (userData.role !== UserRole.ADMIN || !userData.admin) {
    return thunkApi.rejectWithValue({
      message: "Endast admins kan uppdatera sin profil.",
    });
  }

  const response = await axios
    .patch<string>(`${BE_URL}/user/${userData.id}`, userData, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Invalidate users list
      return userData;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte uppdatera profilen.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod när profilen skulle uppdateras.",
        });
      }
    });
  return response;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logOut: (state) => {
      // Remove from localstorage
      localStorage.removeItem("token");

      // Reset state
      state.token = null;
      state.auth = null;
      state.user = null;
      state.loginStatus = "loggedOut";
    },
    resetChangePasswordState: (state) => {
      state.profileErrors = null;
      state.profileUpdated = false;
      state.profileUpdating = false;
    },
    resetAfterEditProfile: (state) => {
      state.profileWasEdited = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.error = null;
        state.loginStatus = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginStatus = "done";
        state.token = action.payload.token;
        state.auth = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload;
        state.loginStatus = "error";
      })

      // Validate token
      .addCase(validateToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.user = action.payload;
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.user = null;
        if (action.payload) {
          state.error = action.payload;
        }
      })
      // Verify email
      .addCase(verifyEmail.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verifyEmail.rejected, (state, { payload }) => {
        if (payload) {
          state.error = payload;
          state.isLoading = false;
        }
      })
      // Updating profile
      .addCase(changePassword.pending, (state) => {
        state.profileErrors = null;
        state.profileUpdating = true;
        state.profileUpdated = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.profileUpdating = false;
        state.profileUpdated = true;
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        if (payload) {
          state.profileErrors = payload.message;
          state.profileUpdating = false;
        }
      })
      .addCase(editProfile.pending, (state) => {
        state.profileUpdating = true;
      })
      .addCase(editProfile.fulfilled, (state, action: PayloadAction<User>) => {
        state.profileUpdating = false;
        state.profileUpdated = true;
        state.user = action.payload;
        state.profileWasEdited = true;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.profileUpdating = false;
        state.profileWasEdited = true; // Technically not updated, but update state for toastify functionality
        state.error = action.payload;
      });
  },
});

export const {
  setIsLoading,
  setLoaded,
  setToken,
  logOut,
  resetChangePasswordState,
  resetAfterEditProfile,
} = authSlice.actions;
export const selectProfile = (state: RootState) => state.auth;
export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectError = (state: RootState) => state.auth.error;
export const selectAuth = (state: RootState) => state.auth.auth;
export const selectLoading = (state: RootState) => state.auth.isLoading;
export const selectLoaded = (state: RootState) => state.auth.isLoaded;
export const selectLoginStatus = (state: RootState) => state.auth.loginStatus;
export const selectUserIsAdmin = (state: RootState) =>
  state.auth.user?.role === UserRole.ADMIN;
export const selectUserIsAdminOrUserAdmin = (state: RootState) =>
  state.auth.user?.role === UserRole.ADMIN ||
  state.auth.user?.role === UserRole.USER_ADMIN;
export const selectUserIsUserAdmin = (state: RootState) =>
  state.auth.user?.role === UserRole.USER_ADMIN;
export const selectUserRole = (state: RootState) => state.auth.user?.role;
export const selectUserIsPayrollUser = (state: RootState) =>
  state.auth.user?.econ || state.auth.user?.role === UserRole.PAYROLL;
export const selectProfileLoading = (state: RootState) =>
  state.auth.profileUpdating;
export const selectProfileUpdated = (state: RootState) =>
  state.auth.profileUpdated;
export const selectProfileErrors = (state: RootState) =>
  state.auth.profileErrors;

export default authSlice.reducer;
