import { Fragment } from "react";
import { useAppSelector } from "app/hooks";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import { selectChecklist } from "../../viewChecklistSlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import EditExtras from "./EditExtras";
import textStyles from "../../../../../utilities/styles/textStyles.module.scss";

const Extras = ({ nameHidden }) => {
  const checklist = useAppSelector(selectChecklist);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);
  const userIsUserAdmin = useAppSelector(selectUserIsUserAdmin);

  return (
    <DataContainer>
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between">
          <p className="fw-bold">Tillägg</p>
          {userIsAdmin || userIsUserAdmin ? <EditExtras nameHidden={nameHidden} /> : ""}
        </div>

        <div>
          <ul className="ps-3">
            {checklist?.additionalExtras.map((comment) => {
              if (comment.message && comment.message.length > 0) {
                return (
                  <Fragment key={comment.id}>
                    <li className={textStyles.smallText}>{comment.message}</li>
                  </Fragment>
                );
              }
            })}

            {checklist && checklist?.additionalExtras.length < 1 && (
              <li className={textStyles.smallText}>Inga tillagda</li>
            )}
          </ul>
        </div>
      </div>
    </DataContainer>
  );
};

export default Extras;
