import { useAppSelector } from "app/hooks";
import {
  selectAuth,
  selectLoaded,
  selectToken,
  selectUser,
  selectUserIsAdmin,
} from "features/auth/authSlice";
import React from "react";
import { UserRole } from "features/auth/types/user";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { Row, Spinner } from "react-bootstrap";

const RootRoute: React.FunctionComponent = (props) => {
  const authIsLoaded = useAppSelector(selectLoaded);
  const user = useAppSelector(selectUser);
  const userIsUnVerified = user?.state === "CREATED";
  const userIsNotApproved = user?.state === "PENDING";

  return (
    <Route
      path={"/"}
      exact
      render={() => {
        if (authIsLoaded) {
          if (!user) {
            // User is not logged in, redirect to login
            return <Redirect to="/login" />;
          } else if (userIsUnVerified) {
            return <Redirect to="/verify" />;
          } else if (userIsNotApproved) {
            return <Redirect to="/pendingapproval" />;
          }

          switch (user?.role) {
            case UserRole.ADMIN || UserRole.INTERNAL_INSTALLER:
              return <Redirect to={"/dashboard"} />;
            case UserRole.PAYROLL:
              return <Redirect to={"/reports"} />;
            default:
              return <Redirect to={"/dashboard"} />;
          }
        } else {
          return (
            <Row className="d-flex align-items-center">
              <Spinner animation={"border"} />
            </Row>
          );
        }
      }}
    />
  );
};

export default RootRoute;
