import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm } from "hooks/useForm";
import {
  approveEntry,
  rejectEntry,
  selectStatusUpdating,
  TimeEntryItemProps,
  TimeEntryAdminComment,
} from "../timeManagementSlice";
import {
  calculateOvertimeHours,
  calculateOvertimePercentages,
  calculateTimePeriod,
  calculateWorkHours,
} from "utilities/Conversions";

import { Accordion, AccordionDetails } from "@mui/material";
import Details from "./Details";
import TimeEntryItemSummary from "./TimeEntryItemSummary";
import styles from "../timeManagement.module.scss";

const TimeEntryItem: React.FunctionComponent<TimeEntryItemProps> = (props) => {
  const { timeEntry, onClick, expanded, filterWithinRange } = props;
  const dispatch = useAppDispatch();
  const statusUpdating = useAppSelector(selectStatusUpdating);
  const [submitType, setSubmitType] = useState<"APPROVE" | "REJECT">();
  const [isApprovedByPayRoll, setApprovedByPayroll] = useState<boolean>(
    timeEntry.processed
  );
  const [overTimeCalculations, setOvertimeCalculations] = useState<
    number[] | null
  >();
  const [intervalOverTimeCalculations, setIntervalOvertimeCalculations] =
    useState<number[] | null>();

  const [hoursTotal, setHoursTotal] = useState<number>(0);
  const [intervalHoursTotal, setIntervalHoursTotal] = useState<number>(0);

  const {
    handleSubmit,
    handleChange,
    data: data,
    errors,
  } = useForm<TimeEntryAdminComment>({
    validations: {
      adminComment: {},
    },
    initialValues: {
      adminComment: timeEntry.controllerMessage
        ? timeEntry.controllerMessage
        : "",
    },
    onSubmit: () => {
      switch (submitType) {
        case "APPROVE":
          // Dispatch action to approve
          dispatch(
            approveEntry({ id: timeEntry.id, message: data.adminComment })
          );
          break;
        case "REJECT":
          // Dispatch action to reject
          dispatch(
            rejectEntry({ id: timeEntry.id, message: data.adminComment })
          );
          break;
      }
    },
  });

  // Do some calculations on initial render
  useEffect(() => {
    // Calculate total hours
    setHoursTotal(
      calculateWorkHours(new Date(timeEntry.from), new Date(timeEntry.to))
    );

    // If type is overtime, calculate percentages
    if (timeEntry.type === 5) {
      let calculations = calculateOvertimePercentages(
        new Date(timeEntry.from),
        new Date(timeEntry.to)
      );
      // Calculate number of 50 and 100 percent hours
      setOvertimeCalculations(calculations);
    }
  }, []);

  useEffect(() => {
    calculateWithinFilteredInterval();
  }, [filterWithinRange]);

  const calculateWithinFilteredInterval = (): void => {
    if (filterWithinRange && filterWithinRange[0] && filterWithinRange[1]) {
      const datesToCount = calculateTimePeriod(
        new Date(timeEntry.from),
        new Date(timeEntry.to),
        filterWithinRange[0],
        filterWithinRange[1]
      );
      if (datesToCount !== null) {
        let results;
        if (timeEntry.type === 5) {
          let percentagesInInterval = calculateOvertimePercentages(
            datesToCount[0],
            datesToCount[1]
          );
          setIntervalOvertimeCalculations(percentagesInInterval);

          let totalOvertimeInInterval = calculateOvertimeHours(
            datesToCount[0],
            datesToCount[1]
          );
          setIntervalHoursTotal(totalOvertimeInInterval);
        } else {
          let totalHoursWithinInterval = calculateWorkHours(
            datesToCount[0],
            datesToCount[1]
          );
          setIntervalHoursTotal(totalHoursWithinInterval);
        }
      }
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={onClick}
      className={styles.customAccordion}
    >
      <TimeEntryItemSummary
        timeEntry={timeEntry}
        filterWithinRange={filterWithinRange}
        hoursTotal={hoursTotal}
        intervalHoursTotal={intervalHoursTotal}
      />

      <AccordionDetails>
        <Details
          filterWithinRange={filterWithinRange}
          timeEntry={timeEntry}
          intervalOverTimeCalculations={intervalOverTimeCalculations}
          overTimeCalculations={overTimeCalculations}
          isApprovedByPayRoll={isApprovedByPayRoll}
          statusUpdating={statusUpdating}
          handleSubmit={handleSubmit}
          setApprovedByPayroll={setApprovedByPayroll}
          handleChange={handleChange}
          data={data}
          errors={errors}
          submitType={submitType}
          setSubmitType={setSubmitType}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TimeEntryItem;
