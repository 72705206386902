import React, { FormEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  moveChecklist,
  resetError,
  selectChecklist,
  viewChecklist,
} from "../../viewChecklistSlice";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Form, Modal } from "react-bootstrap";
import { Alert, Button, Tooltip } from "@mui/material";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";

const EditOrderNumber: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const viewChecklistState = useAppSelector(viewChecklist);
  const [showModal, setShowModal] = useState(false);
  const [newOrderId, setNewOrderId] = useState("");
  const history = useHistory();
  const checklistId = checklist?.id;
  const error = viewChecklistState.error;

  useEffect(() => {
    if (viewChecklistState.moveChecklistDone === true) {
      if (error === null) {
        history.push(`/orders/view/${newOrderId}`);
        // dispatch "resetMoveChecklistDone" is done from the ViewOrder component
      }
    }
  }, [viewChecklistState.moveChecklistDone]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    dispatch(resetError());
    setNewOrderId("");
  };

  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    const requestBody = {
      checklistId: checklistId || "",
      newOrderId: newOrderId,
    };

    dispatch(moveChecklist(requestBody));
  };

  return (
    <>
      <Tooltip arrow title={<>Flytta checklistan till en annan order</>}>
        <EditOutlinedIcon
          onClick={handleClick}
          className={iconStyles.editIcon}
          sx={{
            marginLeft: 2,
          }}
        />
      </Tooltip>

      <Modal show={showModal} onHide={handleClose}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Flytta checklistan till en annan order?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Skriv in vilket ordernummer du vill flytta checklistan till</p>
            <Form.Control
              type="text"
              placeholder="Ordernr"
              value={newOrderId}
              onChange={(e) => setNewOrderId(e.target.value)}
            />
            {error && (
              <Alert severity="error" className="mt-3">
                {error.message}
              </Alert>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              color="error"
              variant="contained"
              className="me-2"
              onClick={handleClose}
            >
              Avbryt
            </Button>

            {newOrderId === "" ? (
              <Button
                disabled
                variant="contained"
                onClick={() => {
                  console.log("disabled button was clicked!");
                }}
              >
                Spara
              </Button>
            ) : (
              <Button
                onClick={(e) => handleSave(e)}
                type="submit"
                variant="contained"
              >
                Spara
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditOrderNumber;
