import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "app/store";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { Company } from "models/Company";
import { setIsInvalidated } from "../companiesSlice";

interface AddNewCompanyState {
  isLoading: boolean;
  isLoaded: boolean;
  success: boolean;
  error: GenericError | null | undefined;
}

const initialState: AddNewCompanyState = {
  isLoading: false,
  isLoaded: false,
  success: false,
  error: null,
};

export const addCompany = createAsyncThunk<
  string,
  Company,
  { rejectValue: GenericError; state: RootState }
>("addNewCompany/add", async (companyData, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();
  const response = await axios
    .post<string>(`${BE_URL}/company/`, companyData, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Invalidate companies list
      dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte skapa företag.",
        });
      } else if (e.response.status === 409) {
        return thunkApi.rejectWithValue({
          message: e.response.data.error,
        });
      } else {
        // Some other error occurred
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });

  return response as string;
});

export const addCompanySlice = createSlice({
  name: "addNewCompany",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInitialState: (state) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCompany.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(addCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.success = true;
      });
  },
});

export const { setIsLoading, setInitialState } = addCompanySlice.actions;

export const selectError = (state: RootState) => state.addCompany.error;
export const selectLoading = (state: RootState) => state.addCompany.isLoading;
export const selectLoaded = (state: RootState) => state.addCompany.isLoaded;
export const selectSuccess = (state: RootState) => state.addCompany.success;

export default addCompanySlice.reducer;
