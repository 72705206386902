import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { deleteProduct, selectProductsState } from "./productsSlice";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@mui/material";
import styles from "./products.module.scss";

const ConfirmDeleteModal = (props) => {
  const { product, confirmDelete, setConfirmDelete } = props;
  const productsState = useAppSelector(selectProductsState);

  const dispatch = useAppDispatch();

  const handleDelete = (productId: string) => {
    setConfirmDelete(false);
    dispatch(deleteProduct(productId));
  };

  const notify = () => {
    if (productsState.error === null) {
      toast.success("Produkten har tagits bort!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när produkten skulle tas bort.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (productsState.productWasDeleted === true) {
      notify();
    }
  }, [productsState.productWasDeleted]);

  return (
    <>
      <ToastContainer position="top-center" />
      {confirmDelete && (
        <div className={styles.confirmDeleteModal}>
          <p className="text-center mt-3 fw-bold">
            Är du säker på att du vill radera denna produkt?
          </p>
          <div className="d-flex justify-content-center mb-2">
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(product.id)}
              className="me-2"
            >
              Radera
            </Button>
            <Button onClick={() => setConfirmDelete(false)} variant="contained">
              Ångra
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmDeleteModal;
