import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Tooltip } from "@mui/material";
import styles from "../viewOrder.module.scss";

const ContractData = (props) => {
  const { order } = props;

  return (
    <div className={styles.contractData}>
      <div className={textStyles.contractData}>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row justify-content-between w-100">
            <p className="mb-4 me-4  fw-bold">Avtalsinformation</p>
            <Tooltip
              placement="left"
              arrow
              title={
                <p className="mb-0">
                  Denna order har avtal som inkluderar listade punkter.
                </p>
              }
            >
              <HelpOutlineOutlinedIcon
                sx={{
                  color: "#161616",
                  opacity: 0.4,
                }}
              />
            </Tooltip>
          </div>
          <ul className="ps-3 mb-0">
            {order?.contractCableLabeling === true && <li> Kabelmärkning </li>}
            {order?.contractSerialNumber === true && <li>Serienummer</li>}
            {order?.contractTheftProtection === true && <li>Stöldmärkning</li>}
            {order?.contractInspection === true && <li>Besiktning</li>}
            {order?.contractDemonstration === true && <li>Utbildning</li>}
            {order?.contractSourceCode === true && <li>Källkod</li>}
            {order?.contractCheatSheet === true && <li>Lathund</li>}
            {order?.contractDocumentation === true && <li>Dokumentation</li>}
            {order?.contractQrCode === true && <li>QR-kod</li>}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContractData;
