import { Checklist } from "./Checklist";
import { SimpleCompany } from "./SimpleCompany";
import { SimpleUser } from "./SimpleUser";

export type Order = {
  companyName: string;
  ordernr: string;
  address: string;
  postaddress: string;
  postnr: string;
  checklists: Checklist[];
  pyramidStatus: number;
  customerApprovals: CustomerApproval[];
  assignedInstallers: SimpleUser[];
  primaryContact: null | string;
  secondaryContact: null | string;
  approvalData: {
    id: string;
    customerComment: string;
    approvedChecklists: string[];
    customerContactId: string | null;
    notOnSiteDescription: string | null;
  };
  completion: Completion | null; // Used in OrderSummary.tsx
  referenceOrder: boolean;
  customerFollowUp: boolean;
  assignedCompanies: SimpleCompany[];
  averageRating: number;

  companyCode: string | null;
  customerType: string | null;
  subCustomerType: string | null;
  customerContract: string | null;
  customerOrderNumber: string | null;

  orderStatus: OrderStatus; // Used in the table with all the orders (OrdersTableMui.tsx)
  lastStatusUpdate: string | null;
  updatedAt: string | null;
}

export type Completion = {
 completedAt: string | null;
 completedBy: SimpleUser | null;
 completionState: string | null;
}

interface CustomerApproval {
  id: string;
  orderId: string;
  customerOnSite: boolean;
  customerName: string;
  customerComment: string;
  approvedChecklists: { id: string; roomName: string }[];
  noOfChecklists: number;
  timestamp: string;
  owner: {
    id: string;
    userName: string;
  };
  customerContactId: string | null;
  notOnSiteDescription: string | null;
  triedToContactPerson: string | null;
  triedToContactAt: string | null;
  triedToContactPhoneNumber: string | null;
}

export enum OrderStatus {
  UNKNOWN,
  PENDING,
  INCOMPLETE,
  COMPLETE,
}

export enum FilterOrderStatus {
  ALL = "ALL",
  PENDING = "PENDING",
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
  UNKNWON = "UNKNOWN"
}


export enum OrderSort {
  NONE = "NONE",
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortColumn {
  NONE = "NONE",
  ORDER_NUMBER = "ORDER_NUMBER",
  COMPANY = "COMPANY",
  ORDER_STATUS = "ORDER_STATUS",
  ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED",
  LAST_UPDATE = "LAST_UPDATE",
}
