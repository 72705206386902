export type User = {
  id: string;
  userName: string;
  password: string | null;
  confirmPassword: string | null;
  email: string;
  phone: string;
  admin: boolean;
  state: string;
  companyId: string | null;
  companyName: string | null;
  controllerId: string | null;
  controllerName: string | null;
  // Remove this temporary. Keep the comments if client wants function back
  // articleTime: string | null;
  // articleTimeInCar: string | null;
  internalInstaller: boolean;
  externalInstaller: boolean;
  sales: boolean;
  coworker: boolean;
  assignedOrders: string[];
  anstnrHogia: string | null;
  econ: boolean;
  appleId: string | null;
  role: UserRole;
  requestedCompanyName: string | null;
  validatedAt: String | null;
};

export type AbsentUser = {
  userId: string;
  userName: string;
  userRole: UserRole;
  reason: string;
  from: string;
  to: string;
  comment: string | null;
};

export type UserToApprove = {
  userId: string;
  role: UserRole;
  companyId: string | null;
};

export enum UserRole {
  ADMIN = "ADMIN",
  INTERNAL_INSTALLER = "INTERNAL_INSTALLER",
  EXTERNAL_INSTALLER = "EXTERNAL_INSTALLER",
  EMPLOYEE = "EMPLOYEE",
  PAYROLL = "PAYROLL",
  USER_ADMIN = "USER_ADMIN",
  NONE = "NONE",
  SALES = "SALES",
}

export function getUserRoleName(role: UserRole): string {
  switch (role) {
    case UserRole.ADMIN:
      return "Administratör";
    case UserRole.USER_ADMIN:
      return "Användaradmin";
    case UserRole.EMPLOYEE:
      return "Medarbetare";
    case UserRole.PAYROLL:
      return "Ekonomi";
    case UserRole.INTERNAL_INSTALLER:
      return "Intern installatör";
    case UserRole.EXTERNAL_INSTALLER:
      return "Extern installatör";
    case UserRole.SALES:
      return "Säljare";
    default:
      return "Okänd";
  }
}
