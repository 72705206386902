import React from "react";
import { useAppSelector } from "app/hooks";
import { selectUserIsAdmin } from "features/auth/authSlice";
import { FilterOrderStatus } from "utilities/orders";
import Filterbar from "features/filterbar/Filterbar";
import FilterByDate from "./filter-components/FilterByDate";
import FilterByNotRatedChecklist from "./filter-components/FilterByNotRatedChecklist";
import FilterByReferenceOrders from "./filter-components/FilterByReferenceOrders";
import FilterCustomerFollowUp from "./filter-components/FilterCustomerFollowUp";
import FilterByStatus from "./filter-components/FilterByStatus";

const FilterOrders = ({
  activeFiltersCount,
  dateRange,
  setDateRange,
  filterReferenceOrders,
  setFilterReferenceOrders,
  filterCustomerFollowUpNotDone,
  setFilterCustomerFollowUpNotDone,
  filterNotRatedChecklists,
  setFilterNotRatedChecklists,
  filterByOrderStatus,
  setFilterByOrderStatus,
}) => {
  const isAdmin = useAppSelector(selectUserIsAdmin);

  const clearFilters = (): void => {
    setDateRange([null, null]);
    setFilterNotRatedChecklists(false);
    setFilterReferenceOrders(false);
    setFilterCustomerFollowUpNotDone(false);
    setFilterByOrderStatus(FilterOrderStatus.ALL);
  };

  return (
    <div className="mb-5">
      <Filterbar
        clearFilters={clearFilters}
        numberOfFiltersActive={activeFiltersCount}
      >
        <FilterByDate dateRange={dateRange} setDateRange={setDateRange} />

        <FilterByStatus
          filterByOrderStatus={filterByOrderStatus}
          setFilterByOrderStatus={setFilterByOrderStatus}
        />

        {isAdmin && (
          <div className="d-flex justify-content-between flex-wrap">
            <FilterByNotRatedChecklist
              filterNotRatedChecklists={filterNotRatedChecklists}
              setFilterNotRatedChecklists={setFilterNotRatedChecklists}
            />

            <>
              <FilterByReferenceOrders
                filterReferenceOrders={filterReferenceOrders}
                setFilterReferenceOrders={setFilterReferenceOrders}
              />

              <FilterCustomerFollowUp
                filterCustomerFollowUpNotDone={filterCustomerFollowUpNotDone}
                setFilterCustomerFollowUpNotDone={
                  setFilterCustomerFollowUpNotDone
                }
              />
            </>
          </div>
        )}
      </Filterbar>
    </div>
  );
};

export default FilterOrders;
