import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios, { AxiosResponse } from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { FunctionCheckBy } from "models/SimpleUser";
import { InstallerInformation } from "models/Users";
import { EditCustomerDetailsState } from "pages/orders/viewOrder/components/editCustomerInfo/editCustomerSlice";
import { User } from "pages/users/types";

interface EditFunctionCheckState {
  loadingState: LoadingState;
  availableinstallers: InstallerInformation[];
  installersListLoading: boolean;
}

export enum LoadingState {
  idle,
  loading,
  success,
  fail,
}

const initialState: EditFunctionCheckState = {
  loadingState: LoadingState.idle,
  availableinstallers: [],
  installersListLoading: false,
};

export const fetchInstallers = createAsyncThunk<
  InstallerInformation[],
  null,
  { rejectValue: GenericError; state: RootState }
>("editFunctionCheck/fetchInstallers", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<InstallerInformation[]>(`${BE_URL}/user/get-all-installers`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Ordernumret existerar inte.",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export interface EditFunctionCheckRequest {
  id: string;
  state: boolean;
  installerId: string | undefined;
}

interface FunctionCheckResponse {
  functionCheck: boolean;
  functionCheckBy: FunctionCheckBy;
  functionCheckAt: string;
  functionCheckByAdmin: boolean;
}

export const editFunctionCheck = createAsyncThunk<
  FunctionCheckResponse,
  EditFunctionCheckRequest,
  { rejectValue: GenericError; state: RootState }
>("editFunctionCheck/editFunctionCheck", async (requestData, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<FunctionCheckResponse>(
      `${BE_URL}/checklist/admin-set-function-check`,
      requestData,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Ordernumret existerar inte.",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const editFunctionCheckSlice = createSlice({
  name: "editFunctionCheck",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstallers.pending, (state) => {
        state.installersListLoading = true;
      })
      .addCase(fetchInstallers.fulfilled, (state, action) => {
        state.installersListLoading = false;
        state.availableinstallers = action.payload;
      })
      .addCase(editFunctionCheck.pending, (state) => {
        state.loadingState = LoadingState.loading;
      })
      .addCase(editFunctionCheck.fulfilled, (state, action) => {
        state.loadingState = LoadingState.success;
      })
      .addCase(editFunctionCheck.rejected, (state) => {
        state.loadingState = LoadingState.fail;
      });
  },
});

export const {} = editFunctionCheckSlice.actions;

export const selectLoadingState = (state: RootState) =>
  state.editFunctionCheck.loadingState;
export const selectAvailableInstallers = (state: RootState) =>
  state.editFunctionCheck.availableinstallers;

export default editFunctionCheckSlice.reducer;
