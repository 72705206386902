import { ReactElement, ReactNode } from "react";
import { BeatLoader } from "react-spinners";
import containerStyles from "../../../utilities/styles/containerStyles.module.scss"

export const DataContainer = ({
  children,
  isLoading,
}: {
  children: ReactNode;
  isLoading?: boolean;
}): ReactElement => {
  return (
    <div className="mb-4">
      <div className={containerStyles.dataContainer}>
        {isLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center my-5">
            <BeatLoader color="#5f5f5f" className="mb-3" />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
