import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getOrder,
  selectIsInvalidated,
  selectOrder,
  setLastViewedChecklist,
} from "../viewOrder/viewOrderSlice";
import {
  clearChecklist,
  selectChecklist,
  setChecklistState,
  selectIsInvalidatedChecklist,
  getChecklist,
  selectChecklistLoading,
  viewChecklist,
} from "./viewChecklistSlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import IPage from "interfaces/page";
import PageNotFound from "pages/pageNotFound/PageNotFound";
import MainContainer from "features/containers/MainContainer/MainContainer";
import EditOrderNumber from "./components/edit-components/EditOrderNumber";
import InternalComments from "./components/internal-comments/InternalComments";
import Products from "./components/products/Products";
import Extras from "./components/extras/Extras";
import ImagesAndMovies from "./components/ImagesAndMovies";
import RatingChecklist from "./components/rating/RatingChecklist";
import EditRoomName from "./components/edit-components/EditRoomName";
import ChecklistNavigation from "./components/ChecklistNavigation";
import ChecklistData from "./components/ChecklistData";
import DeleteChecklist from "./components/DeleteChecklist";
import { resetRatings } from "./components/rating/ratingSlice";
import { Col, Row } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type ChecklistIdParam = {
  checklistId: string;
  id: string;
};
const ViewChecklist: React.FunctionComponent<IPage> = (props) => {
  const { checklistId } = useParams<ChecklistIdParam>();
  const { id } = useParams<ChecklistIdParam>();
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectOrder);
  const orderInvalidated = useAppSelector(selectIsInvalidated);
  const checklistInvalidated = useAppSelector(selectIsInvalidatedChecklist);
  const checklist = useAppSelector(selectChecklist);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);
  const userIsUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const checklistLoading = useAppSelector(selectChecklistLoading);
  const viewChecklistState = useAppSelector(viewChecklist);
  const checklistLoaded = viewChecklistState.isLoaded;
  const error = viewChecklistState.error;
  const [checklistNotFound, setChecklistNotFound] = useState<boolean>(false);
  useEffect(() => {
    dispatch(getChecklist(checklistId)); // Fetch checklist
    // Update checklist state and save as last viewed checklist
    if (checklist) {
      dispatch(setChecklistState(checklist));
      dispatch(setLastViewedChecklist(checklist.id));
    }
  }, [order, orderInvalidated, checklistInvalidated]);

  useEffect(() => {
    if (orderInvalidated) {
      dispatch(getOrder(id));
    }
  }, [orderInvalidated]);

  // Handle error (if checklist not found)
  useEffect(() => {
    if (checklistLoaded && error) {
      setChecklistNotFound(true);
    }
  }, [error, checklistLoaded]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      console.log("Cleanup function");
      dispatch(clearChecklist());
      dispatch(resetRatings());
    };
  }, []);

  const [nameHidden, setNameHidden] = useState(true);
  // Function to toggle the name visibility
  const toggleNameVisibility = () => {
    setNameHidden(!nameHidden);
  };

  const ratingEnabled = (): boolean => {
    if (!checklist?.partOfOrder) {
      return false;
    } else if (checklist) {
      return checklist.partOfOrder[0] !== "Z";
    } else {
      return false;
    }
  };

  return (
    <>
      {error &&
      checklistLoaded &&
      error.message !== "Ordernumret du angav existerar inte." ? (
        <PageNotFound
          checklistNotFound={checklistNotFound}
          setChecklistNotFound={setChecklistNotFound}
        />
      ) : (
        <MainContainer
          title={`${props.name} | ${checklist?.roomName}`}
          edit={
            userIsAdmin || userIsUserAdmin ? (
              <div className="position-relative mt-2 ms-3">
                <EditRoomName />
              </div>
            ) : (
              ""
            )
          }
        >
          <div className="d-flex justify-content-between mb-4">
            <Tooltip arrow title={<>Tillbaka till order</>}>
              <div style={{ height: 20 }}>
                <Link
                  to={`/orders/view/${id}`}
                  style={{ textDecoration: "none" }}
                >
                  <ArrowBackIcon />
                  Tillbaka
                </Link>
              </div>
            </Tooltip>
            <ChecklistNavigation />
            <div className="d-flex">
              <h4>Ordernr: {checklist?.partOfOrder}</h4>
              {userIsAdmin || userIsUserAdmin ? <EditOrderNumber /> : ""}
              {userIsAdmin && <DeleteChecklist />}
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <ChecklistData
                nameHidden={nameHidden}
                isLoading={checklistLoading}
              />
            </Col>
          </Row>
          <Row>
            {ratingEnabled() && (
              <Col xs={12} lg={6} xxl={4}>
                <RatingChecklist
                  nameHidden={nameHidden}
                  toggleNameVisibility={toggleNameVisibility}
                  setNameHidden={setNameHidden}
                />
              </Col>
            )}

            <Col xs={12} lg={6} xxl={4}>
              <InternalComments nameHidden={nameHidden} />
            </Col>
            <Col xs={12} lg={6} xxl={4}>
              <ImagesAndMovies nameHidden={nameHidden} />
            </Col>
            <Col xs={12} lg={6} xxl={4}>
              <Extras nameHidden={nameHidden} />
            </Col>
          </Row>
          <Row>
            <Products />
          </Row>
        </MainContainer>
      )}
    </>
  );
};
export default ViewChecklist;
