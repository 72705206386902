import React, { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { editPhotoComment } from "pages/orders/viewChecklist/viewChecklistSlice";
import { Form } from "react-bootstrap";
import { Button } from "@mui/material";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";
import styles from "../../slideShow.module.scss";

const EditCommentModal = ({
  selectedComment,
  currentVerification,
  handleCloseModal,
}) => {
  const dispatch = useAppDispatch();
  const [newComment, setNewComment] = useState<string>("");

  const saveEditComment = (e) => {
    e.preventDefault();

    const requestBody = {
      mediaId: currentVerification.id,
      commentId: selectedComment.id,
      newComment: newComment,
    };

    dispatch(editPhotoComment(requestBody));
    handleCloseModal();
  };

  return (
    <div className={containerStyles.overlay}>
      <div className={styles.writeComment}>
        <Form onSubmit={saveEditComment}>
          <p className="fw-bold text-dark my-2">Redigera kommentar</p>
          <Form.Control
            type="text"
            className="my-3"
            defaultValue={selectedComment?.text}
            onChange={(e) => setNewComment(e.target.value)}
          />

          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
              className="me-2"
            >
              Avbryt
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={newComment === ""}
            >
              Spara
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditCommentModal;
