import { Container } from "react-bootstrap";
import IPage from "interfaces/page";
import AuthHeader from "features/authHeader/AuthHeader";
import LoginForm from "features/login/LoginForm";

const Login: React.FunctionComponent<IPage> = (props) => {
  return (
    <Container className="p-0">
      <AuthHeader />
      <LoginForm />
    </Container>
  );
};

export default Login;
