import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getSignatureImage, selectOrder } from "../viewOrderSlice";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import Inspection from "./Inspection";

const Inspections = () => {
  const order = useAppSelector(selectOrder);
  const dispatch = useAppDispatch();
  const customerApprovals = order?.customerApprovals;

  const data = customerApprovals?.map((approval) => {
    return {
      customerOnSite: approval.customerOnSite,
      customerName: approval.customerName,
      customerComment: approval.customerComment,
      notOnSiteDescription: approval.notOnSiteDescription,
      approvedChecklists: approval.approvedChecklists,
      noOfChecklists: approval.noOfChecklists,
      timestamp: approval.timestamp?.substring(0, 16),
      owner: approval.owner?.userName,
      customerApprovalId: approval.id,
      triedToContactPerson: approval.triedToContactPerson,
      triedToContactAt: approval.triedToContactAt?.substring(0, 16),
      triedToContactPhoneNumber: approval.triedToContactPhoneNumber,
    };
  });

  // Use useEffect to dispatch the action when the component mounts
  useEffect(() => {
    if (order?.customerApprovals) {
      data?.map((approval) => {
        const customerApprovalId = approval.customerApprovalId;

        if (approval.customerOnSite) {
          dispatch(getSignatureImage(customerApprovalId));
        }
      });
    }
  }, [order]);

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <h5 className="mt-5">Besiktningar</h5>
          <DataContainer>
            <div className="flex-column w-100">
              {data.map((approval, index) => {
                const isLastData = index === data.length - 1;
                const customerApprovalId = approval.customerApprovalId;

                return (
                  <Inspection
                    key={index}
                    approval={approval}
                    isLastData={isLastData}
                    customerApprovalId={customerApprovalId}
                  />
                );
              })}
            </div>
          </DataContainer>
        </>
      ) : (
        <p className="my-4">Inga besiktningar är gjorda på denna order.</p>
      )}
    </>
  );
};

export default Inspections;
