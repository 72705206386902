import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import { BE_URL } from "config/api";
import { User, UserToApprove } from "features/auth/types/user";
import { GenericError } from "interfaces/errorTypes";
import { setIsInvalidated } from "../usersSlice";
import {
  CompletedChecklist,
  CompletedChecklistsRequest,
  CompletedChecklistsResponse,
  UserStatistics,
} from "models/UserStatistics";

interface CompletedChecklistsState {
  isLoading: boolean;
  completedChecklists: CompletedChecklist[];
}

interface ShowUserState {
  user: User | null;
  isLoading: boolean;
  isLoaded: boolean;
  isInvalidated: boolean;
  error: null | GenericError | undefined;
  editInProgress: boolean;
  deleted: boolean;
  deleteInProgress: boolean;
  userStatistics: null | UserStatistics;
  statisticsLoading: boolean;
  // Toastify
  userWasEdited: boolean;
  userWasApproved: boolean;
  completedChecklistsState: CompletedChecklistsState;
}

const initialState: ShowUserState = {
  user: null,
  isLoading: false,
  isLoaded: false,
  isInvalidated: false,
  editInProgress: false,
  error: null,
  deleted: false,
  deleteInProgress: false,
  userStatistics: null,
  statisticsLoading: false,
  // Toastify
  userWasEdited: false,
  userWasApproved: false,
  completedChecklistsState: {
    isLoading: false,
    completedChecklists: [],
  },
};

export const getUser = createAsyncThunk<
  User,
  string,
  { rejectValue: GenericError; state: RootState }
>("showUser/getUser", async (userId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<User>(`${BE_URL}/user/${userId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Nånting gick fel, försök igen.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as User;
});

export const getUserStatistics = createAsyncThunk<
  UserStatistics,
  string,
  { rejectValue: GenericError; state: RootState }
>("showUser/getUserStatistics", async (userId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<UserStatistics>(`${BE_URL}/user/user-statistics/${userId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Nånting gick fel, försök igen.",
        });
      }
      // Some other error occurred
      else {
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as UserStatistics;
});

export const getCompletedChecklists = createAsyncThunk<
  CompletedChecklistsResponse,
  CompletedChecklistsRequest,
  { rejectValue: GenericError; state: RootState }
>("showUser/getCompletedChecklists", async (requestBody, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<CompletedChecklistsResponse>(
      `${BE_URL}/user/get-completed-checklists`,
      requestBody,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message: "Nånting gick fel, försök igen.",
        });
      }
      // Some other error occurred
      else {
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as CompletedChecklistsResponse;
});

export const editUser = createAsyncThunk<
  User,
  User,
  { rejectValue: GenericError; state: RootState }
>("showUser/editUser", async (userData, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();
  const response = await axios
    .patch<string>(`${BE_URL}/user/${userData.id}`, userData, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Invalidate users list
      dispatch(setIsInvalidated());
      return userData;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message:
            "Error 400. Något gick fel när användaren skulle uppdateras.",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod när användaren skulle uppdateras.",
        });
      }
    });
  return response;
});

export const deleteUser = createAsyncThunk<
  string,
  string,
  { rejectValue: GenericError; state: RootState }
>("showUser/deleteUser", async (userId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .delete<string>(`${BE_URL}/user/${userId}`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      // Set users list to be invalidated
      dispatch(setIsInvalidated());
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        return thunkApi.rejectWithValue({
          message:
            "Någonting gick fel när användaren skulle raderas. Error code: 400",
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
        });
      }
    });
  return response as string;
});

export const approveUser = createAsyncThunk<
  string,
  UserToApprove,
  {
    rejectValue: GenericError;
    state: RootState;
  }
>("showUser/approveUser", async (userToApprove, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  try {
    const response = await axios.post<string>(
      `${BE_URL}/user/approve/${userToApprove.userId}`,
      {
        role: userToApprove.role,
        companyId: userToApprove.companyId,
      },
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    );

    // Set shown user to invalidated
    // Set all users to invalidated
    dispatch(setIsInvalidated());

    return response.data as string;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 400) {
        return thunkApi.rejectWithValue({
          message:
            "Ett fel uppstod när användaren skulle godkännas. Error code: 400",
        });
      } else {
        // Some other error occurred
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod när användaren skulle godkännas.",
        });
      }
    } else {
      // Handle non-Axios errors
      throw e;
    }
  }
});

export const showUserSlice = createSlice({
  name: "showUser",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    resetDeleted: (state) => {
      state.deleted = false;
    },
    resetUserStatistics: (state) => {
      state.userStatistics = null;
      state.user = null;
    },
    resetAfterEditUser: (state) => {
      state.userWasEdited = false;
    },
    resetAfterApproval: (state) => {
      state.userWasApproved = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.user = action.payload;
        state.isInvalidated = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isLoaded = true;
      })
      // getUserStatistics
      .addCase(getUserStatistics.pending, (state) => {
        state.error = null;
        state.statisticsLoading = true;
      })
      .addCase(getUserStatistics.fulfilled, (state, action) => {
        state.error = null;
        state.statisticsLoading = false;
        state.userStatistics = action.payload;
      })
      .addCase(getUserStatistics.rejected, (state, action) => {
        state.error = action.payload || null;
        state.statisticsLoading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.error = null;
        state.deleteInProgress = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteInProgress = false;
        state.deleted = true;
      })
      .addCase(editUser.pending, (state) => {
        state.error = null;
        state.editInProgress = true;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.editInProgress = false;
        state.isInvalidated = true;
        state.user = action.payload;
        state.userWasEdited = true;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.error = action.payload;
        state.editInProgress = false;
        state.userWasEdited = true; // Technically not edited, but update state for toastify functionality
      })
      // Approve user
      .addCase(approveUser.pending, (state) => {
        state.error = null;
        state.editInProgress = true;
      })
      .addCase(approveUser.fulfilled, (state, action) => {
        state.editInProgress = false;
        state.userWasApproved = true;
        state.isInvalidated = true;
        if (state.user) {
          state.user.state = "ACTIVE";
          state.user.validatedAt = "";
        }
      })
      .addCase(approveUser.rejected, (state, action) => {
        state.error = action.payload;
        state.editInProgress = false;
        state.userWasApproved = true; // Technically not approved, but update state for toastify functionality
      })
      .addCase(getCompletedChecklists.pending, (state) => {
        state.completedChecklistsState.isLoading = true;
      })
      .addCase(getCompletedChecklists.fulfilled, (state, action) => {
        state.completedChecklistsState.isLoading = false;
        state.completedChecklistsState.completedChecklists =
          action.payload.completedChecklists;
      })
      .addCase(getCompletedChecklists.rejected, (state) => {
        state.completedChecklistsState.isLoading = false;
      });
  },
});

export const {
  setIsLoading,
  setUser,
  resetDeleted,
  resetUserStatistics,
  resetAfterEditUser,
  resetAfterApproval,
} = showUserSlice.actions;

export const selectShowUser = (state: RootState) => state.showUser;
export const selectUser = (state: RootState) => state.showUser.user;
export const selectError = (state: RootState) => state.showUser.error;
export const selectLoading = (state: RootState) => state.showUser.isLoading;
export const selectInvalidated = (state: RootState) =>
  state.showUser.isInvalidated;
export const selectLoaded = (state: RootState) => state.showUser.isLoaded;
export const selectDeleteInProgress = (state: RootState) =>
  state.showUser.deleteInProgress;
export const selectDeleted = (state: RootState) => state.showUser.deleted;
export const selectEditInProgress = (state: RootState) =>
  state.showUser.editInProgress;
export const selectCompletedChecklistsState = (state: RootState) =>
  state.showUser.completedChecklistsState;

export default showUserSlice.reducer;
