import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { User, UserRole } from "features/auth/types/user";
import { useForm } from "hooks/useForm";
import {
  getAllCompanies,
  selectLoaded as companiesLoaded,
} from "pages/companies/companiesSlice";
import {
  getAllUsers,
  selectLoaded as usersLoaded,
  selectUsers,
} from "../usersSlice";
import {
  addUser,
  selectError,
  selectLoading as selectCreatingUser,
  selectSuccess,
  setInitialState,
} from "./addNewUserSlice";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import FormComponent from "./FormComponent";

export interface CreateUser extends User {
  password: string;
  confirmPassword: string;
  employeeNumber: string;
  appleId: string | null;
  useDifferentAppleId: boolean;
}

const AddNewUserModal = ({ handleClose, show }) => {
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(selectError);
  const users = useAppSelector(selectUsers);
  const [sortedUsers, setSortedUsers] = useState<User[]>([]);
  const companiesDoneLoading = useAppSelector(companiesLoaded);
  const usersDoneLoading = useAppSelector(usersLoaded);
  const userCreated = useAppSelector(selectSuccess);
  const [useDifferentAppleId, setUseDifferentAppleId] = useState<boolean>(false);

  const {
    handleSubmit,
    handleChange,
    data: userData,
    errors,
    clearFormData,
  } = useForm<CreateUser>({
    validations: {
      userName: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange för- och efternamn.",
        },
      },
      email: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig e-postadress.",
        },
      },
      phone: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett telefonnummer.",
        },
      },
      role: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Välj en roll",
        },
      },
      controllerId: {
        custom: {
          isValid: (value) => {
            if (userData.role !== UserRole.EXTERNAL_INSTALLER) {
              return value.length > 0;
            } else return true;
          },
          message: "Välj en attesterare.",
        },
      },
      companyId: {
        custom: {
          isValid: (value) => {
            if (userData.role === UserRole.EXTERNAL_INSTALLER) {
              return value !== "";
            } else {
              return true;
            }
          },
          message: "Välj ett företag",
        },
      },
      employeeNumber: {
        custom: {
          isValid: (value) => {
            if (userData.role !== UserRole.EXTERNAL_INSTALLER) {
              return value !== "";
            } else {
              return true;
            }
          },
          message: "Ange anställningsnummer",
        },
      },
      appleId: {
        custom: {
          isValid: (value) => {
            if (useDifferentAppleId) {
              return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                value
              );
            } else {
              return true;
            }
          },
          message: "Ange en e-postadress för Apple-ID",
        },
      },
    },
    initialValues: {
      userName: "",
      email: "",
      phone: "",
      companyId: "",
      admin: false,
      controllerId: "",
      role: UserRole.NONE,
      employeeNumber: "",
      appleId: "",
      useDifferentAppleId: false,
    },
    onSubmit: () => {
      const requestBody: User = {
        ...userData,
        admin: userData.role === UserRole.ADMIN,
        externalInstaller: userData.role === UserRole.EXTERNAL_INSTALLER,
        internalInstaller: userData.role === UserRole.INTERNAL_INSTALLER,
        coworker: userData.role === UserRole.EMPLOYEE,
        econ: userData.role === UserRole.PAYROLL,
        companyId:
          userData.role === UserRole.EXTERNAL_INSTALLER &&
          userData.companyId !== ""
            ? userData.companyId
            : null,
        controllerId:
          userData.controllerId !== "" ? userData.controllerId : null,
        anstnrHogia: userData.employeeNumber,
        appleId: userData.appleId ? userData.appleId : null,
      };

      dispatch(addUser(requestBody));
      console.log("dispatch addUser run w requestBody: ", requestBody);
    },
  });

  useEffect(() => {
    if (!companiesDoneLoading) {
      dispatch(getAllCompanies());
    }
    if (!usersDoneLoading) {
      dispatch(getAllUsers());
    }
  }, []);

  // Sort users when fetched
  useEffect(() => {
    if (users) {
      let sorted = [...users];
      sorted.sort((a, b) => {
        if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;
        else return -1;
      });
      // Filter only admins
      const filtered = sorted.filter((user) => {
        return user.role === UserRole.ADMIN;
      });

      setSortedUsers(filtered);
    }
  }, [users]);

  const onClose = () => {
    clearFormData();
    dispatch(setInitialState());
    handleClose();
  };

  useEffect(() => {
    if (userCreated) {
      handleClose();
      dispatch(setInitialState());
      clearFormData();

      toast.success(`Användaren har lagts till!`, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  }, [userCreated]);

  return (
    <>
      <ToastContainer position="top-center" />
      <Modal size="lg" show={show} onHide={onClose} animation={true}>
        <FormComponent
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          errors={errors}
          useDifferentAppleId={useDifferentAppleId}
          setUseDifferentAppleId={setUseDifferentAppleId}
          errorMessage={errorMessage}
          userData={userData}
          sortedUsers={sortedUsers}
          handleClose={handleClose}
        />
      </Modal>
    </>
  );
};

export default AddNewUserModal;
