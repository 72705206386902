import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  deletePhoto,
  viewChecklist,
  resetDeleteFile,
} from "pages/orders/viewChecklist/viewChecklistSlice";
import { Button } from "@mui/material";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";
import styles from "../../slideShow.module.scss";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

const ConfirmDeleteModal = (props) => {
  const { currentVerification, setCurrentSlide, handleSlideshowModalClose } =
    props;
  const dispatch = useAppDispatch();
  const viewChecklistState = useAppSelector(viewChecklist);
  const fileIsLoading = viewChecklistState.fileIsLoading;
  const fileWasDeleted = viewChecklistState.fileWasDeleted;

  const handleCloseModal = () => {
    props.onCloseModal(); // call the callback function passed down from parent
  };

  const handleDelete = () => {
    if (currentVerification) {
      dispatch(deletePhoto(currentVerification.id));
      setCurrentSlide(0);
    }
  };

  let mediaType = "bilden";
  if (currentVerification.type === 2) {
    mediaType = "filmen";
  }

  useEffect(() => {
    if (fileWasDeleted) {
      handleCloseModal();
      handleSlideshowModalClose();
      notifyDelete();
      dispatch(resetDeleteFile());
    }
  }, [fileWasDeleted]);

  const notifyDelete = () => {
    if (viewChecklistState.error === null) {
      toast.success(`${mediaType} har raderats!`, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error(`Någonting gick fel när ${mediaType} skulle raderas.`, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />

      <div className={containerStyles.overlay}>
        <div className={styles.confirmDelete}>
          {fileIsLoading ? (
            <div className="d-flex flex-column align-items-center justify-content-center my-3">
              <BeatLoader color="#5f5f5f" className="mb-3" />
              <p className="text-dark">Raderar {mediaType}...</p>
            </div>
          ) : (
            <>
              <p className="fw-bold text-dark mb-3">
                Är du säker på att du vill radera {mediaType}?
              </p>
              <Button
                variant="contained"
                onClick={() => handleDelete()}
                className="me-2"
                color="error"
              >
                Radera
              </Button>

              <Button onClick={handleCloseModal} variant="contained">
                Behåll
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmDeleteModal;
