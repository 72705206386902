import React from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "../../checklist.module.scss";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";
import textStyles from "../../../../../utilities/styles/textStyles.module.scss";

const Extra = (props) => {
  const {
    extra,
    index,
    handleChange,
    deleteIndex,
    confirmDelete,
    handleDelete,
    setConfirmDelete,
    edit,
    editIndex,
    setDeleteIndex,
    confirmChange,
    setEdit,
    setEditIndex,
    extraString,
    nameHidden,
  } = props;

  return (
    <div className={styles.extraWrapper}>
      <div className="d-flex justify-content-between" key={extra.id}>
        <div className="d-flex justify-content-center w-100 flex-column me-3">
          {edit && editIndex === index ? (
            <Form.Control
              key={index}
              type="text"
              value={extra.message}
              onChange={(e) => {
                handleChange(e.target.value, extra.id, index);
              }}
            />
          ) : (
            <div>
              <p>{extra.message}</p>
              <p className={`mb-0 ${textStyles.smallText}`}>
                {extra.postedAt.substring(0, 16)}

                {!nameHidden && (
                  <>
                    {" av "}
                    <Tooltip arrow title="Gå till användarprofilen">
                      <Link to={`/users/view/${extra.poster.id}`}>
                        <span className={textStyles.smallText}>
                          {extra.poster.userName}
                        </span>
                      </Link>
                    </Tooltip>
                  </>
                )}
              </p>
            </div>
          )}
        </div>

        <div className="d-flex flex-column justify-content-between">
          {!edit && (
            <>
              <Tooltip arrow title="Radera tillägg">
                <ClearIcon
                  className={iconStyles.clearIcon}
                  onClick={() => {
                    setDeleteIndex(index);
                    setConfirmDelete(true);
                  }}
                />
              </Tooltip>

              <Tooltip arrow title="Redigera tillägg">
                <EditIcon
                  className={iconStyles.editIconOrange}
                  onClick={() => {
                    setEdit(true);
                    setEditIndex(index);
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>

        {/* Confirm delete  */}
        {deleteIndex === index && confirmDelete && (
          <div className={styles.confirmDelete}>
            <p className="fw-bold">
              Är du säker på att du vill radera tillägget?
            </p>
            <div className="d-flex">
              <Button
                color="error"
                onClick={() => handleDelete(extra.id)}
                variant="contained"
              >
                Radera
              </Button>
              <Button
                variant="contained"
                onClick={() => setConfirmDelete(false)}
              >
                Ångra
              </Button>
            </div>
          </div>
        )}

        {/* Confirm change */}
        {editIndex === index && edit && (
          <div className="d-flex flex-column justify-content-between">
            <Button
              variant="contained"
              color="error"
              onClick={() => setEdit(false)}
            >
              Avbryt
            </Button>

            {extraString.length > 0 ? (
              <Button
                onClick={() => confirmChange(extra.id)}
                variant="contained"
                className="mt-2"
              >
                Spara
              </Button>
            ) : (
              <Button disabled variant="contained" className="mt-2">
                Spara
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Extra;
