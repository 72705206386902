import React from "react";
import { useAppSelector } from "app/hooks";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { Rating } from "@mui/material";
import styles from "../../../checklist.module.scss";
import { selectRatingState } from "../ratingSlice";
import { BeatLoader } from "react-spinners";

const InstallationRating = ({
  setInstallRatingValue,
  installRatingValue,
  editable,
}) => {
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const isLoading = useAppSelector(selectRatingState).ratingLoading;

  const handleInstallationChange = (
    event: React.SyntheticEvent,
    value: number | null
  ) => {
    if (value) {
      installRatingValue = value;
      setInstallRatingValue(value);
    }
  };

  return (
    <div className="d-flex justify-content-between my-1">
      <div className="d-flex">
        <p>Installation: </p>
        {isLoading ? (
          <BeatLoader color="#5f5f5f" className="mb-3" />
        ) : isAdmin || isUserAdmin ? (
          <>
            <Rating
              readOnly={!editable}
              className={styles.rating}
              onChange={handleInstallationChange}
              value={installRatingValue}
              defaultValue={installRatingValue}
            />
          </>
        ) : (
          <Rating
            readOnly // if not admin
            className={styles.rating}
            value={installRatingValue}
          />
        )}
      </div>
      <span>{installRatingValue !== 0 ? installRatingValue : "-"}</span>
    </div>
  );
};

export default InstallationRating;
