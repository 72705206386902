import { useAppSelector } from "app/hooks";
import { selectLoaded, selectUser } from "features/auth/authSlice";
import { UserRole } from "features/auth/types/user";
import { Redirect } from "react-router";

export interface IAuthRouteProps {
  notApprovedComponent?: React.ReactNode;
  authorizedRoles?: UserRole[];
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = ({ children, authorizedRoles }) => {
  const authIsLoaded = useAppSelector(selectLoaded);
  const user = useAppSelector(selectUser);
  const userIsUnVerified = user?.state === "CREATED";
  const userIsNotApproved = user?.state === "PENDING";

  const hasPermission = (): boolean => {
    if (!authorizedRoles) {
      return true;
    } else {
      return authorizedRoles.includes(user?.role ? user?.role : UserRole.NONE);
    }
  };

  if (authIsLoaded) {
    if (!user) {
      return <Redirect to="/login" />;
    } else if (userIsUnVerified) {
      return <Redirect to="/verify" />;
    } else if (userIsNotApproved) {
      return <Redirect to="/pendingapproval" />;
    } else if (hasPermission()) {
      return <>{children}</>;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return <></>;
  }
};

export default AuthRoute;
