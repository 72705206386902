import React from "react";
import { useAppSelector } from "app/hooks";
import { selectSignature } from "../viewOrderSlice";
import { Tooltip } from "@mui/material";
import textStyles from "utilities/styles/textStyles.module.scss";

const Inspection = (props) => {
  const { approval, isLastData, customerApprovalId } = props;
  const signatures = useAppSelector(selectSignature);

  // Find the signature that matches the customerApprovalId
  const matchingSignature = signatures.find(
    (signature) => signature.id === customerApprovalId
  );

  return (
    <React.Fragment>
      <p>
        <span className="fw-bold">
          Slutbesiktning
          {approval.customerOnSite ? " med" : " utan"} kund. Genomförd{" "}
          {approval.timestamp}
        </span>
      </p>

      {approval.customerOnSite && (
        <p>
          <span className="fw-bold">Kundens namn: </span>
          {approval.customerName}
        </p>
      )}
      {approval.customerOnSite ? (
        <p>
          <span className="fw-bold">Kundens kommentar: </span>
          {approval.customerComment}
        </p>
      ) : (
        <p>
          <span className="fw-bold">Kommentar: </span>
          {approval.notOnSiteDescription}
        </p>
      )}

      {!approval.customerOnSite && (
        <>
          <p>
            <span className="fw-bold">Namn på personen som sökts: </span>
            {approval.triedToContactPerson}
          </p>
          <p>
            <span className="fw-bold">Försökt nå personen: </span>
            {approval.triedToContactAt} på telefonnummer{" "}
            {approval.triedToContactPhoneNumber}
          </p>
        </>
      )}
      <p className="fw-bold">
        Godkända checklistor ({approval.approvedChecklists.length}/
        {approval.noOfChecklists}):
      </p>

      {approval.approvedChecklists.map((approvedChecklist) => (
        <p key={approvedChecklist.id}>- {approvedChecklist.roomName}</p>
      ))}

      <p>
        <span className="fw-bold">Installatör: </span>
        {approval.owner}
      </p>

      {approval.customerOnSite && matchingSignature && (
        <Tooltip arrow title="Öppna bild i ny flik">
          <a
            target="_blank"
            className={textStyles.link}
            href={matchingSignature.imgUrl || ""}
          >
            Bild på signatur
          </a>
        </Tooltip>
      )}
      {!isLastData && <hr />}
    </React.Fragment>
  );
};

export default Inspection;
