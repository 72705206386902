import { GenericError } from "interfaces/errorTypes";
import { FilterOrdersParams } from "pages/orders/ordersSlice";
import { SimpleOrder } from "./SimpleOrder";

interface OrdersState {
  orders: SimpleOrder[] | null;
  isLoading: boolean;
  isLoaded: boolean;
  isInvalidated: boolean;
  error: GenericError | undefined | null;
  lastPageLoad: number | null;
  pageCount: number | null;
  currentFilters: FilterOrdersParams | null;
  lastViewedOrder?: number | null;
}

export interface GetOrdersResponse {
  page: number;
  pageCount: number;
  orders: SimpleOrder[];
}

export const initialState: OrdersState = {
  orders: null,
  isLoading: false,
  isLoaded: false,
  isInvalidated: false,
  error: null,
  lastPageLoad: null,
  pageCount: null,
  currentFilters: null,
  lastViewedOrder: null,
};
