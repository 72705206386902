import React from "react";
import IPage from "interfaces/page";
import AuthHeader from "features/authHeader/AuthHeader";
import VerifyEmailForm from "features/verifyEmail/VerifyEmailForm";
import { Container } from "react-bootstrap";

const VerifyEmail: React.FunctionComponent<IPage> = () => {
  return (
    <Container className="p-0">
      <AuthHeader />
      <VerifyEmailForm />
    </Container>
  );
};

export default VerifyEmail;
