import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectUser } from "features/auth/authSlice";
import { selectChecklist, selectIsLoaded } from "../../viewChecklistSlice";
import {
  updateRatingsFromChecklist,
  selectRatingState,
  setRating,
  resetRatingWasSubmitted,
} from "./ratingSlice";
import { setIsInvalidated } from "pages/orders/viewOrder/viewOrderSlice";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import CommentModal from "./CommentModal";
import Average from "./components/Average";
import InstallationRating from "./components/InstallationRating";
import PicturesRating from "./components/PicturesRating";
import CommentRating from "./components/CommentRating";
import HideName from "./components/HideName";
import RatingInformation from "./components/RatingInformation";
import { Modal, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Button, Rating } from "@mui/material";
import styles from "../../checklist.module.scss";

const RatingChecklist = ({
  setNameHidden,
  toggleNameVisibility,
  nameHidden,
}) => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(selectUser);
  const checklist = useAppSelector(selectChecklist);
  const checklistLoaded = useAppSelector(selectIsLoaded);
  const ratingState = useAppSelector(selectRatingState);
  const checklistId = checklist?.id;

  const ratings = ratingState.ratings;
  const adminComment = ratingState.ratings.adminCommentNew;
  let average = ratingState.ratings.average;

  const [installationRatingValue, setInstallationRatingValue] =
    useState<number>(0);
  const [picturesRatingValue, setPicturesRatingValue] = useState<number>(0);
  const [commentsRatingValue, setCommentsRatingValue] = useState<number>(0);

  const [comment, setComment] = useState<string>("");
  const [runDispatch, setRunDispatch] = useState(false); // To avoid infinity loop
  const [initialValuesSet, setInitialValuesSet] = useState<boolean>(false);

  const [ratingModalOpen, setRatingModalOpen] = useState<boolean>(false);
  const [ratingChanged, setRatingChanged] = useState<boolean>(false);

  // Populating the ratingState with the ratings from checklistState.
  useEffect(() => {
    console.log("Run first useEffect");
    if (checklist?.ratings) {
      dispatch(updateRatingsFromChecklist(checklist.ratings));
      if (installationRatingValue === 0) {
        console.log("Set initial value ");
        //setInstallationRatingValue(checklist?.ratings.installation ?? 0);
        //dispatch(updateRatingsFromChecklist(checklist?.ratings));
      }

      setInstallationRatingValue(checklist?.ratings.installation ?? 0);
      setCommentsRatingValue(checklist?.ratings.comments ?? 0);
      setPicturesRatingValue(
        checklist?.ratings.photoAndVideoDocumentation ?? 0
      );
    }
  }, [checklistLoaded, checklist, dispatch]);

  useEffect(() => {
    if (ratingState.ratingWasSubmitted) {
      showSucessfulToast();
      closeModal();
    }
  }, [ratingState.ratingWasSubmitted]);

  useEffect(() => {
    if (ratingState.setRatingError) {
      showErrorToast();
      closeModal();
    }
  }, [ratingState.setRatingError]);

  // Check for existing values and display that in the UI
  // useEffect(() => {
  //   console.log("Checklist state changed");
  //   if (
  //     ratings &&
  //     ratings.installation !== undefined &&
  //     ratings.installation !== null
  //   ) {
  //     setInstallationRatingValue(ratings.installation);
  //   } else {
  //     setInstallationRatingValue(0);
  //   }
  //   if (
  //     ratings &&
  //     ratings.photoAndVideoDocumentation !== undefined &&
  //     ratings.photoAndVideoDocumentation !== null
  //   ) {
  //     setPicturesRatingValue(ratings.photoAndVideoDocumentation);
  //   } else {
  //     setPicturesRatingValue(0);
  //   }
  //   if (
  //     ratings &&
  //     ratings.comments !== undefined &&
  //     ratings.comments !== null
  //   ) {
  //     setCommentsRatingValue(ratings.comments);
  //   } else {
  //     setCommentsRatingValue(0);
  //   }
  //   if (adminComment?.comment) {
  //     setComment(adminComment.comment);
  //   } else if (adminComment === null) {
  //     setComment("");
  //   }
  // }, [ratings, checklist, adminComment]);

  // useEffect when some value is changed
  useEffect(() => {
    if (
      installationRatingValue !== 0 ||
      picturesRatingValue !== 0 ||
      commentsRatingValue !== 0
    ) {
      //handleSetRating();
      // Validate save button
      if (
        installationRatingValue !== ratingState.ratings.installation ||
        commentsRatingValue !== ratingState.ratings.comments ||
        picturesRatingValue !== ratingState.ratings.photoAndVideoDocumentation
      ) {
        setRatingChanged(true);
      } else {
        setRatingChanged(false);
      }
    }
  }, [installationRatingValue, picturesRatingValue, commentsRatingValue]);

  const handleSetRating = () => {
    console.log("handleSetRating");
    //if (runDispatch) {

    if (average === undefined) {
      average = 0;
    }
    // Check each rating value and replace 0 with null
    const picturesRating =
      picturesRatingValue !== 0
        ? picturesRatingValue
        : ratingState.ratings.photoAndVideoDocumentation;

    const commentsRating =
      commentsRatingValue !== 0
        ? commentsRatingValue
        : ratingState.ratings.comments;

    const installationRating =
      installationRatingValue !== 0
        ? installationRatingValue
        : ratingState.ratings.installation;

    const requestBody = {
      checklistId: checklist?.id,
      photoAndVideoDocumentation: picturesRatingValue,
      comments: commentsRatingValue,
      installation: installationRatingValue,
      average: null,
      adminCommentNew: {
        comment: comment,
        checklistId: checklist?.id,
        createdBy: {
          userName: loggedInUser?.userName || "",
          userId: loggedInUser?.id || "",
        },
        createdAt: "",
      },
    };
    // if (!ratingState.ratingLoading) {
    dispatch(setRating(requestBody));
    console.log("RequestBody: ", requestBody);
  };

  // const updateRatingValues = () => {
  //   const requestBody = {
  //     checklistId: checklist?.id,
  //     photoAndVideoDocumentation:
  //       ratingState.ratings.photoAndVideoDocumentation,
  //     comments: ratingState.ratings.comments,
  //     installation: ratingState.ratings.installation,
  //     average: null,
  //     adminCommentNew: {
  //       comment: comment,
  //       checklistId: checklist?.id,
  //       createdBy: {
  //         userName: loggedInUser?.userName || "",
  //         userId: loggedInUser?.id || "",
  //       },
  //       createdAt: "",
  //     },
  //   };
  //   dispatch(setRating(requestBody));
  //   console.log("RequestBody: ", requestBody);
  // };

  // Invalidate order when component unmounts
  useEffect(() => {
    return () => {
      dispatch(setIsInvalidated());
    };
  }, []);

  const closeModal = () => {
    setRatingModalOpen(false);

    //reset values
    setInstallationRatingValue(ratingState.ratings.installation ?? 0);
    setCommentsRatingValue(ratingState.ratings.comments ?? 0);
    setPicturesRatingValue(ratingState.ratings.photoAndVideoDocumentation ?? 0);
  };

  const saveRating = () => {
    handleSetRating();
    dispatch(resetRatingWasSubmitted());
  };

  const showSucessfulToast = () => {
    toast.success("Checklistan har betygsatts", {
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  const showErrorToast = () => {
    toast.error("Betyget kunde inte sättas", {
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <DataContainer>
        <div className="d-flex flex-column w-100">
          <RatingInformation
            onClick={() => {
              setRatingModalOpen(true);
            }}
          />

          <div
            className={styles.link}
            onClick={() => {
              setRatingModalOpen(true);
            }}
          >
            <InstallationRating
              editable={false}
              installRatingValue={ratingState.ratings.installation}
              setInstallRatingValue={setInstallationRatingValue}
            />
            <PicturesRating
              editable={false}
              picturesRatingValue={
                ratingState.ratings.photoAndVideoDocumentation
              }
              setPicturesRatingValue={setPicturesRatingValue}
            />

            <CommentRating
              editable={false}
              commentsRatingValue={ratingState.ratings.comments}
              setCommentsRatingValue={setCommentsRatingValue}
            />
            <hr></hr>
          </div>

          <CommentModal />

          <Average
            installationRatingValue={ratingState.ratings.installation}
            picturesRatingValue={ratingState.ratings.photoAndVideoDocumentation}
            commentsRatingValue={ratingState.ratings.comments}
          />
          <HideName
            toggleNameVisibility={toggleNameVisibility}
            nameHidden={nameHidden}
            setNameHidden={setNameHidden}
            commentsRatingValue={ratingState.ratings.comments}
            picturesRatingValue={ratingState.ratings.photoAndVideoDocumentation}
            installationRatingValue={ratingState.ratings.installation}
          />
        </div>
      </DataContainer>

      <Modal
        show={ratingModalOpen}
        onBackdropClick={closeModal}
        onHide={() => {
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Sätt betyg"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstallationRating
            editable={true}
            installRatingValue={installationRatingValue}
            setInstallRatingValue={setInstallationRatingValue}
          />
          <PicturesRating
            editable={true}
            picturesRatingValue={picturesRatingValue}
            setPicturesRatingValue={setPicturesRatingValue}
          />

          <CommentRating
            editable={true}
            commentsRatingValue={commentsRatingValue}
            setCommentsRatingValue={setCommentsRatingValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="error"
            variant="contained"
            className="me-2"
            onClick={closeModal}
          >
            Avbryt
          </Button>
          <Button
            disabled={!ratingChanged}
            variant="contained"
            type="submit"
            onClick={() => {
              saveRating();
            }}
          >
            {ratingState.ratingLoading ? (
              <Spinner size={"sm"} animation={"border"} />
            ) : (
              "Spara"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RatingChecklist;
