import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "assets/logo.png";

const AuthHeader: React.FunctionComponent = () => {
  return (
    <Row className="justify-content-center my-5">
      <Col xs={3} sm={4} md={3} lg={2} xl={1}>
        <div className="">
          <img className="img-fluid" alt="logo" src={logo}></img>
        </div>
      </Col>
    </Row>
  );
};

export default AuthHeader;
