import React, { useEffect, useState } from "react";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
  selectUserRole,
} from "features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getInstallerChecklistStatistics } from "pages/users/usersSlice";
import IPage from "interfaces/page";
import MainContainer from "features/containers/MainContainer/MainContainer";
import Greeting from "./components/Greeting";
import Navigation from "./components/Navigation";
import Container from "./components/Container";
import { Col, Row } from "react-bootstrap";
import UserStatistics from "./components/UserStatistics";
import Absent from "./components/Absent";
import UsersTable from "./components/statistics/UsersTable";
import TopInstallers, {
  InstallerTypeFilter,
  TimePeriodFilters,
} from "./components/statistics/TopInstallers";
import { TopFiveInstallersRequest } from "models/InstallerChecklistStatistics";
import styles from "./styles.module.scss";
import StatisticsSummary from "./components/statistics/StatisticsSummary";
import { UserRole } from "features/auth/types/user";

const Dashboard: React.FunctionComponent<IPage> = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const largeScreen = screenWidth >= 768; // Adjust the breakpoint as needed

  return (
    <>
      <MainContainer title="Dashboard">
        <Row className="mb-5">
          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.USER_ADMIN) && (
            <>
              <Col className="d-flex" xs={12} xl={5}>
                <StatisticsSummary />
              </Col>
              <Col className="d-flex" xs={12} xl={7}>
                <TopInstallers />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col className="" xs={12}>
            <Absent largeScreen={largeScreen} />
          </Col>
        </Row>

        {/* <div>
          <Greeting />
          {userIsAdmin || userIsUserAdmin ? (
            <div className="d-flex">
              <Navigation
                shownComponent={shownComponent}
                setShownComponent={setShownComponent}
              />

              <Container
                shownComponent={shownComponent}
                largeScreen={largeScreen}
              />
            </div>
          ) : (
            <></>
          )}
        </div> */}
      </MainContainer>
    </>
  );
};

export default Dashboard;
