import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector } from "app/hooks";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal";
import { Tooltip } from "@mui/material";
import styles from "../slideShow.module.scss";
import iconStyles from "../../../utilities/styles/iconStyles.module.scss";

const VideoFeatures = (props) => {
  const { currentVerification, currentSlide, setCurrentSlide, handleSlideshowModalClose } = props;
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const handleCloseModal = () => {
    setConfirmDelete(false);
  };

  return currentVerification.type === 2 ? (
    <>
      {confirmDelete && (
        <ConfirmDeleteModal
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          currentVerification={currentVerification}
          onCloseModal={handleCloseModal}
          handleSlideshowModalClose={handleSlideshowModalClose}
        />
      )}

      <div className={styles.videoFeatures}>
        {isAdmin && (
          <div className="d-flex justify-content-center">
            <p className="fw-bold me-2">Radera</p>

            <Tooltip arrow title="Radera filmen">
              <DeleteIcon
                className={iconStyles.deleteIcon}
                onClick={() => {
                  setConfirmDelete(true);
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </>
  ) : null;
};

export default VideoFeatures;
