import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";

export interface IConfirmModalProps {
  handleClose: () => void;
  handleConfirm: () => void;
  show: boolean;
  title: string;
  message: string;
  confirmLabel: string;
  dismissLabel: string;
  isLoading: boolean;
}

const ConfirmModal: React.FunctionComponent<IConfirmModalProps> = ({
  handleClose,
  handleConfirm,
  show,
  title,
  message,
  confirmLabel,
  dismissLabel,
  isLoading,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            color="error"
            variant="contained"
            className="me-2"
          >
            {dismissLabel}
          </Button>

          <Button onClick={handleConfirm} variant="contained">
            {confirmLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
