import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectChecklist } from "../../viewChecklistSlice";
import { selectRatingState, setAdminComment } from "./ratingSlice";
import {
  selectUser,
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { Button, Tooltip } from "@mui/material";
import { Modal, Form, FloatingLabel } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import iconStyles from "../../../../../utilities/styles/iconStyles.module.scss";
import textStyles from "../../../../../utilities/styles/textStyles.module.scss";
import { AdminComment } from "models/AdminComment";

const CommentModal = () => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const ratingState = useAppSelector(selectRatingState);
  const loggedInUser = useAppSelector(selectUser);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState<string>("");
  const adminComment = ratingState?.ratings?.adminCommentNew;

  const handleClose = () => {
    setShowCommentModal(false);
  };

  const handleOpen = () => {
    setShowCommentModal(true);
  };

  const onInputChange = (value: string) => {
    console.log(value);
  };

  const submitComment = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const requestBody = {
      comment: comment,
      checklistId: checklist?.id,
      createdAt: "",
      createdBy: {
        userName: loggedInUser?.userName || "",
        userId: loggedInUser?.id || "",
      },
    };

    dispatch(setAdminComment(requestBody));
    setShowCommentModal(false);
  };

  const notify = () => {
    if (ratingState.error === null) {
      toast.success("Kommentaren har sparats!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när kommentaren skulle sparas.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (ratingState.commentWasSubmitted === true) {
      notify();
    }
  }, [ratingState.commentWasSubmitted]);

  useEffect(() => {
    if (adminComment) {
      setComment(adminComment.comment);
    }
  }, [adminComment]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <p className="fw-bold">Kommentar</p>

        {isAdmin || isUserAdmin ? (
          <Tooltip
            arrow
            title={
              <>
                <p className="fw-bold mb-1">Lämna feedback</p>
                Komplettera betygsättningen med en kommentar.
              </>
            }
          >
            <EditOutlinedIcon
              onClick={handleOpen}
              className={iconStyles.editIcon}
            />
          </Tooltip>
        ) : null}
      </div>

      <ToastContainer position="top-center" />

      <Modal
        show={showCommentModal}
        onHide={handleClose}
        size="lg"
        className=""
      >
        <Modal.Header closeButton>
          <Modal.Title>Skriv en kommentar</Modal.Title>
        </Modal.Header>

        <Form onSubmit={submitComment}>
          <Modal.Body>
            {/* <FloatingLabel controlId="floatingSelect" label="Kommentar">
              
            </FloatingLabel> */}

            <Form.Control
              type="text"
              value={comment}
              onChange={(e) => {
                console.log(e.target.value);
                setComment(e.target.value);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              disabled={comment.length < 1}
            >
              Spara
            </Button>
            {/* {comment.length > 0 && comment !== adminComment.comment ? (
             
            ) : (
              <Button variant="contained" disabled>
                Spara
              </Button>
            )} */}
          </Modal.Footer>
        </Form>
      </Modal>

      <div className="mt-3">
        {adminComment !== null && (
          <p className="mb-0">{adminComment.comment}</p>
        )}
        {adminComment && (
          <p className={textStyles.smallText}>
            {adminComment?.createdAt?.substring(0, 16)} av{" "}
            <Tooltip arrow title="Gå till användarprofilen">
              <Link to={`/users/view/${adminComment?.createdBy?.userId}`}>
                <span className={textStyles.smallText}>
                  {adminComment?.createdBy?.userName}
                </span>
              </Link>
            </Tooltip>
          </p>
        )}
      </div>
    </>
  );
};

export default CommentModal;
