import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { useHistory, useParams } from "react-router-dom";
import {
  getCompletedChecklists,
  resetUserStatistics,
  selectCompletedChecklistsState,
  selectLoading,
  selectShowUser,
} from "../showUserSlice";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Rating,
  TableFooter,
  TablePagination,
} from "@mui/material";
import SearchBar from "features/searchBar/SearchBar";
import {
  CompletedChecklist,
  CompletedChecklistsRequest,
} from "models/UserStatistics";
import { BeatLoader } from "react-spinners";
import tableStyles from "../../../../utilities/styles/tableStyle.module.scss";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";
import { UserIdParam } from "models/Users";
import { Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { getTimePeriodFiltersTimePeriodFiltersString } from "utilities/filterUtilities";
import { TimePeriodFilters } from "pages/dashboard/components/statistics/TopInstallers";
import { useForm } from "hooks/useForm";

const CompletedInstallations = () => {
  const { id } = useParams<UserIdParam>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const shownUser = useAppSelector(selectShowUser);
  //const userStatistics = shownUser?.userStatistics;
  const isLoading = shownUser.statisticsLoading;
  const userLoading = useAppSelector(selectLoading);
  const [sortOrder, setSortOrder] = useState({
    column: "",
    direction: "",
  });
  //const completedChecklists = userStatistics?.completedChecklists;
  const completedChecklistsState = useAppSelector(
    selectCompletedChecklistsState
  );

  const [sortedChecklists, setSortedChecklists] = useState<
    CompletedChecklist[]
  >([]);
  const [filteredChecklists, setFilteredChecklists] = useState<
    CompletedChecklist[]
  >([]);

  const [filteredAndOrderedChecklsits, setFIlteredAndOrderedChecklists] =
    useState<CompletedChecklist[] | undefined>();

  const [query, setQuery] = useState<string>("");
  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const { data: filterParams } = useForm<CompletedChecklistsRequest>({
    initialValues: {
      userId: id,
      timePeriod: TimePeriodFilters[TimePeriodFilters.FROM_START],
      onlyIncludeApprovedChecklists: true,
    },
  });

  const labels = [
    "Checklista", // roomName
    "Tillhör order", // Ordernr
    "Skapad",
    "Installation",
    "Kommentar",
    "Foto",
    "Genomsnitt", // Rating
  ];

  const cellWidths = ["25%", "10%", "10%", "25%", "10%", "10%", "10%"];

  const fetchData = () => {
    dispatch(getCompletedChecklists(filterParams));
  };

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resetUserStatistics());
    };
  }, []);

  useEffect(() => {
    handleSortAndFilter();
  }, [completedChecklistsState]);

  useEffect(() => {
    handleSortAndFilter();
  }, [query]);

  useEffect(() => {
    handleSortAndFilter();
  }, [sortOrder]);

  const handleSortAndFilter = () => {
    var filteredResults = [...completedChecklistsState.completedChecklists];
    if (query.length > 0) {
      filteredResults = handleSearch(query);
    }

    if (sortOrder.direction !== "") {
      filteredResults = sortResults(filteredResults);
    }
    //setSortedChecklists(filteredResults);
    setFilteredChecklists(filteredResults);
  };

  const handleClick = (orderNumber, checklistId) => {
    const checklistLink = `/orders/view/${orderNumber}/checklist/${checklistId}`;
    if (orderNumber && checklistId) {
      history.push(checklistLink);
    } else console.log("something wrong with checklistLink: ", checklistLink);
  };

  const handleSort = (label) => {
    let direction = "asc";
    if (sortOrder.column === label && sortOrder.direction === "asc") {
      direction = "desc";
    }
    setSortOrder({ column: label, direction });
  };

  const sortResults = (results: CompletedChecklist[]): CompletedChecklist[] => {
    let sortedList = [...results];

    if (sortOrder.column === "Checklista") {
      sortedList.sort((a, b) =>
        (a.roomName || "").localeCompare(b.roomName || "")
      );
    } else if (sortOrder.column === "Tillhör order") {
      sortedList.sort((a, b) =>
        (a.orderNumber || "").localeCompare(b.orderNumber || "")
      );
    } else if (sortOrder.column === "Skapad") {
      sortedList.sort((a, b) =>
        (a.created || "").localeCompare(b.created || "")
      );
    } else if (sortOrder.column === "Installation") {
      sortedList.sort((a, b) => {
        return sortAscending(a.installation, b.installation);
      });
    } else if (sortOrder.column === "Kommentar") {
      sortedList.sort((a, b) => {
        return sortAscending(a.comments, b.comments);
      });
    } else if (sortOrder.column === "Foto") {
      sortedList.sort((a, b) => {
        return sortAscending(
          a.photoAndVideoDocumentation,
          b.photoAndVideoDocumentation
        );
      });
    } else if (sortOrder.column === "Genomsnitt") {
      sortedList.sort((a, b) => {
        return sortAscending(a.averageRating, b.averageRating);
      });
    }

    if (sortOrder.direction === "desc") {
      sortedList.reverse();
    }

    return sortedList;
  };

  function sortAscending(
    a: string | number | null,
    b: string | number | null
  ): number {
    if (a === null && b === null) {
      return 0;
    } else if (b === null) {
      return 1;
    } else if (a === null) {
      return -1;
    } else {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    }
  }

  const fiterSearch = (value: string) => {
    setQuery(value);
  };

  const handleSearch = (value: string): CompletedChecklist[] => {
    let filteredList = [...completedChecklistsState.completedChecklists];

    if (value) {
      filteredList = filteredList.filter(
        (checklist) =>
          checklist.roomName.toLowerCase().includes(value.toLowerCase()) ||
          checklist.orderNumber.toLowerCase().includes(value.toLowerCase())
      );
    }
    return filteredList;
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredChecklists?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
  };

  function timePeriodSelected(timePeriod: TimePeriodFilters) {
    if (TimePeriodFilters[timePeriod].toString() !== filterParams.timePeriod) {
      filterParams.timePeriod = TimePeriodFilters[timePeriod];
      fetchData();
    }
  }

  function onlyIncludeApprovedSelected(value: boolean) {
    if (filterParams.onlyIncludeApprovedChecklists !== value) {
      filterParams.onlyIncludeApprovedChecklists = value;
      fetchData();
    }
  }

  if (userLoading === true || shownUser.user?.state === "PENDING") return null;
  else
    return (
      <div className={containerStyles.completedInstallations}>
        <div className="d-flex flex-column w-100">
          {completedChecklistsState.isLoading ? (
            <div className="d-flex flex-column align-items-center justify-content-center my-5">
              <BeatLoader color="#5f5f5f" className="mb-3" />
              <p>Hämtar checklistor...</p>
            </div>
          ) : (
            <>
              <Row className={"mb-4"}>
                <h5 className="fw-bold">
                  Installationer utförda av {shownUser.user?.userName}
                  {completedChecklistsState.completedChecklists.length > 0 && (
                    <>
                      {" "}
                      ({completedChecklistsState.completedChecklists.length} st)
                    </>
                  )}
                </h5>
              </Row>

              <Row className={"mb-1"}>
                <Col xs={12} lg={4} className="d-flex align-items-center">
                  <div style={{ width: 300, maxWidth: "100%" }}>
                    <SearchBar
                      label="Sök checklista, ordernr"
                      onChange={fiterSearch}
                      value={query}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  lg={"auto"}
                  className={"d-flex align-items-center"}
                >
                  <DropdownButton
                    variant="info"
                    id="dropdown-basic-button"
                    title={getTimePeriodFiltersTimePeriodFiltersString(
                      TimePeriodFilters[filterParams.timePeriod]
                    )}
                  >
                    <Dropdown.Item
                      onClick={() =>
                        timePeriodSelected(TimePeriodFilters.FROM_START)
                      }
                    >
                      {getTimePeriodFiltersTimePeriodFiltersString(
                        TimePeriodFilters.FROM_START
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        timePeriodSelected(TimePeriodFilters.PAST_WEEK)
                      }
                    >
                      {getTimePeriodFiltersTimePeriodFiltersString(
                        TimePeriodFilters.PAST_WEEK
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        timePeriodSelected(TimePeriodFilters.PAST_MONTH)
                      }
                    >
                      {getTimePeriodFiltersTimePeriodFiltersString(
                        TimePeriodFilters.PAST_MONTH
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        timePeriodSelected(TimePeriodFilters.PAST_YEAR)
                      }
                    >
                      {getTimePeriodFiltersTimePeriodFiltersString(
                        TimePeriodFilters.PAST_YEAR
                      )}
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col
                  xs={12}
                  lg={"auto"}
                  className={"d-flex align-items-center"}
                >
                  <Form.Check
                    checked={filterParams.onlyIncludeApprovedChecklists} // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Endast godkända"
                    onChange={(e) =>
                      onlyIncludeApprovedSelected(e.target.checked)
                    }
                  />
                </Col>
              </Row>
              <Row></Row>

              <div className={tableStyles.completedChecklistsTable}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{ bgcolor: "rgba(220, 220, 220, 0.5)" }}>
                        {labels.map((label, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              width: cellWidths[index],
                            }}
                          >
                            <TableSortLabel
                              className="fw-bold py-2"
                              onClick={() => handleSort(label)}
                              active={sortOrder.column === label}
                              direction={
                                sortOrder.column === label
                                  ? sortOrder.direction === "asc"
                                    ? "desc"
                                    : "asc"
                                  : undefined
                              }
                            >
                              {label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {filteredChecklists.length > 0 ? (
                      <>
                        <TableBody
                          sx={{
                            "& tr:nth-of-type(2n)": {
                              backgroundColor: "grey.100",
                            },
                          }}
                        >
                          {filteredChecklists.map((checklist, index) => {
                            const isLastRow = index === currentItems.length - 1;
                            return (
                              <TableRow
                                key={index}
                                id={checklist.checklistId}
                                hover={true}
                                onClick={() =>
                                  handleClick(
                                    checklist.orderNumber,
                                    checklist.checklistId
                                  )
                                }
                                className={isLastRow ? tableStyles.lastRow : ""}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell>{checklist.roomName}</TableCell>

                                <TableCell>{checklist.orderNumber}</TableCell>

                                <TableCell>
                                  {checklist.created?.slice(0, -3)}
                                </TableCell>

                                <TableCell>
                                  {checklist.installation === null ||
                                  checklist.installation === 0 ? (
                                    "Inget betyg"
                                  ) : (
                                    <div className="d-flex">
                                      <span
                                        className="me-2 fw-bold"
                                        style={{ position: "relative", top: 2 }}
                                      >
                                        {checklist.installation}
                                      </span>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {checklist.comments === null ||
                                  checklist.comments === 0 ? (
                                    "Inget betyg"
                                  ) : (
                                    <div className="d-flex">
                                      <span
                                        className="me-2 fw-bold"
                                        style={{ position: "relative", top: 2 }}
                                      >
                                        {checklist.comments}
                                      </span>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {checklist.photoAndVideoDocumentation ===
                                    null ||
                                  checklist.photoAndVideoDocumentation === 0 ? (
                                    "Inget betyg"
                                  ) : (
                                    <div className="d-flex">
                                      <span
                                        className="me-2 fw-bold"
                                        style={{ position: "relative", top: 2 }}
                                      >
                                        {checklist.photoAndVideoDocumentation}
                                      </span>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {checklist.averageRating === null ||
                                  checklist.averageRating === 0 ? (
                                    "Inget betyg"
                                  ) : (
                                    <div className="d-flex">
                                      <span
                                        className="me-2 fw-bold"
                                        style={{ position: "relative", top: 2 }}
                                      >
                                        {checklist.averageRating}
                                      </span>

                                      <Rating
                                        value={checklist.averageRating}
                                        readOnly
                                        precision={0.1}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        {completedChecklistsState.completedChecklists &&
                          completedChecklistsState.completedChecklists.length >
                            10 && (
                            <TableFooter sx={{ borderBottom: "none" }}>
                              <TableRow sx={{ borderBottom: "none" }}>
                                <TablePagination
                                  sx={{ borderBottom: "none" }}
                                  rowsPerPageOptions={[10, 20, 50, 200]}
                                  count={filteredChecklists?.length || 0}
                                  rowsPerPage={itemsPerPage}
                                  page={currentPage - 1}
                                  onPageChange={handlePageChange}
                                  labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} av ${
                                      count !== -1 ? count : "more than" + to
                                    }`
                                  }
                                  labelRowsPerPage={"Checklistor per sida"}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </TableRow>
                            </TableFooter>
                          )}
                      </>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ borderBottom: "none" }}>
                            Inga checklistor hittades
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>
      </div>
    );
};

export default CompletedInstallations;
