import React from "react";
import InternalComment from "./InternalComment";

const EditInternalComments = (props) => {
  const {
    updatedComments,
    handleChange,
    deleteIndex,
    confirmDelete,
    setConfirmDelete,
    handleDelete,
    editIndex,
    setEdit,
    setEditIndex,
    edit,
    confirmChange,
    setDeleteIndex,
    commentBeingEdited,
    nameHidden
  } = props;

  return (
    <>
      {updatedComments &&
        updatedComments.map((comment, index) => (
          <InternalComment
            key={index}
            comment={comment}
            edit={edit}
            setDeleteIndex={setDeleteIndex}
            setConfirmDelete={setConfirmDelete}
            deleteIndex={deleteIndex}
            index={index}
            confirmDelete={confirmDelete}
            handleDelete={handleDelete}
            setEdit={setEdit}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
            handleChange={handleChange}
            confirmChange={confirmChange}
            commentBeingEdited={commentBeingEdited}
            nameHidden={nameHidden}
          />
        ))}
    </>
  );
};

export default EditInternalComments;
