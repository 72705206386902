import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import routes from "config/routes";
import { logOut, selectUserRole } from "features/auth/authSlice";
import { UserRole } from "features/auth/types/user";
import MainNavItem from "./MainNavItem";
import ConfirmModal from "features/confirmModal/ConfirmModal";
import LogoutLink from "./LogoutLink";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logo from "assets/logo.png";
import { Col, Container, Nav, Navbar, NavbarBrand, Row } from "react-bootstrap";
import styles from "./mainNavigation.module.scss";

const MainNavigation = () => {
  const role = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const renderMenu = (): JSX.Element[] => {
    const navigationRoutes = routes.filter((route) => {
      return (
        route.showInMainNav &&
        route.authorizedRoles?.includes(role ? role : UserRole.NONE)
      );
    });

    return navigationRoutes.map((route, index) => {
      return (
        <React.Fragment key={index}>
          <MainNavItem
            eventKey={index}
            to={route.path}
            label={route.name}
            Icon={route.navItemIcon}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <Navbar
        className={`${styles.navBarContainer} flex-lg-column`}
        collapseOnSelect={true}
        expand="lg"
      >
        <NavbarBrand className="mb-4 me-0 w-100 d-flex justify-content-center">
          <Container>
            <Row className="d-flex justify-content-center ">
              <Col xs={3} sm={3} md={2} lg={5}>
                <img
                  className={`${styles.navbarLogo}`}
                  src={logo}
                  alt="avs-logo"
                />
              </Col>
            </Row>
          </Container>
        </NavbarBrand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="align-items-start w-100 mt-3"
        >
          <Nav className={`${styles.navContainer} flex-column w-100`}>
            {renderMenu()}

            <span className={styles.logoutWrapper}>
              <MainNavItem
                eventKey={99}
                to={"/profile"}
                label={"Profil"}
                Icon={AccountCircleIcon}
              />
              <LogoutLink onClick={() => setShowConfirmModal(true)} />
            </span>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <ConfirmModal
        handleClose={() => {
          setShowConfirmModal(false);
        }}
        handleConfirm={() => {
          dispatch(logOut());
        }}
        show={showConfirmModal}
        title={"Logga ut"}
        message={"Är du säker på att du vill logga ut?"}
        confirmLabel={"Logga ut"}
        dismissLabel={"Avbryt"}
        isLoading={false}
      />
    </>
  );
};

export default MainNavigation;
