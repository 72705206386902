import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import IPage from "interfaces/page";
import AuthHeader from "features/authHeader/AuthHeader";
import PendingApprovalComponent from "features/pendingApprovalComponent";

const PendingApproval: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    console.log("Rendering page: ", props.name);
  }, [props.name]);

  return (
    <Container className="p-0">
      <AuthHeader />
      <PendingApprovalComponent />
    </Container>
  );
};

export default PendingApproval;
