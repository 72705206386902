import React from "react";
import ApprovedByCustomer from "./ApprovedByCustomer";
import CreatedBy from "./CreatedBy";
import DocumentationDone from "./DocumentationDone";
import FunctionCheck from "./FunctionCheck";
import { DataContainer } from "features/containers/dataContainer/DataContainer";

const ChecklistData = ({ isLoading, nameHidden }) => {
  return (
    <DataContainer isLoading={isLoading}>
      <div className="d-flex justify-content-start flex-wrap">
        <CreatedBy nameHidden={nameHidden} />
        <DocumentationDone nameHidden={nameHidden} />
        <FunctionCheck nameHidden={nameHidden} />
        <ApprovedByCustomer />
      </div>
    </DataContainer>
  );
};

export default ChecklistData;
