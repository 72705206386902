import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "app/hooks";
import IPage from "interfaces/page";
import { setLastViewedOrder } from "../ordersSlice";
import {
  LoadingState,
  getOrder,
  resetError,
  resetPyramidSyncLoadingState,
  selectError,
  selectIsInvalidated,
  selectLoading,
  selectOrder,
  selectPyramidSyncState,
  syncToPyramid,
} from "./viewOrderSlice";
import {
  resetMoveChecklistDone,
  viewChecklist,
} from "../viewChecklist/viewChecklistSlice";
import { BeatLoader } from "react-spinners";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip } from "@mui/material";
import Inspections from "./components/Inspections";
import ReportSection from "./components/report/ReportSection";
import Checklists from "./components/Checklists";
import MainContainer from "features/containers/MainContainer/MainContainer";
import OrderInfo from "./components/OrderInfo";
import {
  selectUserIsAdmin,
  selectUserIsAdminOrUserAdmin,
} from "features/auth/authSlice";

export type OrderIdParam = {
  id: string;
};

const ViewOrder: React.FunctionComponent<IPage> = (props) => {
  const history = useHistory();
  const { id } = useParams<OrderIdParam>();
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectOrder);
  const orderLoading = useAppSelector(selectLoading);
  const orderError = useAppSelector(selectError);
  const orderInvalidated = useAppSelector(selectIsInvalidated);
  const viewChecklistState = useAppSelector(viewChecklist);
  const pyramidSyncState = useAppSelector(selectPyramidSyncState);
  const isAdminOrUserAdmin = useAppSelector(selectUserIsAdminOrUserAdmin);
  const checklistError = viewChecklistState.error;
  const moveChecklistDone = viewChecklistState.moveChecklistDone;

  useEffect(() => {
    // Check if order has been fetched already
    if (order) {
      if (order.ordernr === id) return;
    }

    // Check if a new order needs to be fetched
    if (!orderLoading) {
      dispatch(getOrder(id));
    }
  }, []);

  // Saving the ordernr in orders.lastViewedOrder
  useEffect(() => {
    // if (order?.ordernr) {
    //   dispatch(setLastViewedOrder(order?.ordernr));
    // }
  }, [order]);

  // Check if order was not found
  useEffect(() => {
    if (orderError?.status === 404) {
      dispatch(resetError());
      history.push("/orders");
    }
  }, [orderError?.status]);

  // Detect if order was invalidated, then fetch new data
  useEffect(() => {
    if (orderInvalidated) {
      // Fetch new order data
      dispatch(getOrder(id));
    }
  }, [orderInvalidated]);

  // If ordernr was changed
  useEffect(() => {
    if (checklistError === null && moveChecklistDone) {
      toast.success(`Checklistan flyttades till order ${order?.ordernr}`);
      dispatch(resetMoveChecklistDone());
    }
  }, [moveChecklistDone]);

  // Handle pyramidsync state
  useEffect(() => {
    switch (pyramidSyncState) {
      case LoadingState.SUCCESS:
        toast.success("Ordern synkad till pyramid", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        break;
      case LoadingState.FAIL:
        toast.error("Ordern kunde inte synkas till pyramid", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        break;
      default:
        break;
    }
    setTimeout(() => {
      // Reset loading state
      dispatch(resetPyramidSyncLoadingState());
    }, 2000);
  }, [pyramidSyncState]);

  const syncToPyramidAction = () => {
    console.log("SyncToPyramidAction");
    if (order?.ordernr !== undefined) {
      dispatch(syncToPyramid(order?.ordernr));
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />

      <MainContainer title={`${props.name} | Ordernummer: ${order?.ordernr}`}>
        <Row className="mb-2 d-flex d-flex justify-content-between">
          <Col xs={"auto"} className="mb-3 d-flex align-items-start">
            <Tooltip arrow title={<>Alla ordrar</>}>
              <Link to="/orders" className="text-decoration-none">
                <ArrowBackIcon />
                Tillbaka
              </Link>
            </Tooltip>
          </Col>
          {isAdminOrUserAdmin && (
            <Col sm={"auto"}>
              <Button onClick={syncToPyramidAction}>
                {pyramidSyncState === LoadingState.LOADING ? (
                  <Spinner size={"sm"} animation={"border"} />
                ) : (
                  "Synka till pyramid"
                )}
              </Button>
            </Col>
          )}
        </Row>

        <OrderInfo order={order} />

        <Row className="mt-5">
          <Col>
            {orderLoading ? (
              <div className="d-flex flex-column align-items-center justify-content-center my-5">
                <p>Hämtar checklistor</p>
                <BeatLoader color="#5f5f5f" className="mb-3" />
              </div>
            ) : (
              <>
                <Checklists />
                <Inspections />
                <ReportSection />
              </>
            )}
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};

export default ViewOrder;
