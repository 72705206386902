import React, { useState } from "react";
import { useAppSelector } from "app/hooks";
import { selectVerification } from "../viewChecklistSlice";
import { Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import playIcon from "../../../../assets/play_icon.png";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import styles from "./thumbnails.module.scss";

const Thumbnails = ({ imageThumbUrls, openSlideShow }) => {
  const [showAllThumbnails, setShowAllThumbnails] = useState(false);
  const verifications = useAppSelector(selectVerification);

  // Determine media type
  const getMediaType = (verificationId) => {
    const verification = verifications?.find((v) => v.id === verificationId);
    return verification?.type;
  };

  return (
    <div className="w-100">
      <Row>
        {imageThumbUrls && imageThumbUrls.length > 0 ? (
          imageThumbUrls
            .slice(0, showAllThumbnails ? imageThumbUrls.length : 4)
            .map((url, index) => {
              const mediaType = getMediaType(url.id);

              return (
                <Col key={url.imgUrl} sm={6} className="mb-3">
                  <div
                    className={styles.thumbnailContainer}
                    onClick={() => {
                      openSlideShow(index);
                    }}
                  >
                    {/* Image */}
                    {mediaType === 1 && (
                      <img
                        src={url.imgUrl}
                        alt={"Checklist"}
                        className={styles.thumbnailImage}
                      />
                    )}

                    {/* Video without real thumbnail */}
                    {mediaType == 2 && url.mediaType === "text/xml" && (
                      <img
                        src={playIcon}
                        alt={"Play icon"}
                        className={styles.playIcon}
                      />
                    )}

                    {/* Video that has real thumbnail */}
                    {mediaType === 2 && url.mediaType === "image/png" && (
                      <>
                        <img
                          src={url.imgUrl}
                          alt={"Checklist"}
                          className={styles.thumbnailImageVideo}
                        />

                        <img
                          src={playIcon}
                          alt={"Play icon"}
                          className={styles.playIconOverlay}
                        />
                      </>
                    )}
                  </div>
                </Col>
              );
            })
        ) : (
          <p className={textStyles.smallText}>Inga bilder</p>
        )}
        {imageThumbUrls && imageThumbUrls.length > 4 && !showAllThumbnails && (
          <div
            className="d-flex justify-content-center"
            onClick={() => {
              setShowAllThumbnails(true);
            }}
          >
            <Button variant="outlined">Visa alla</Button>
          </div>
        )}
        {showAllThumbnails && (
          <div
            className="d-flex justify-content-center"
            onClick={() => {
              setShowAllThumbnails(false);
            }}
          >
            <Button variant="outlined">Visa färre</Button>
          </div>
        )}
      </Row>
    </div>
  );
};

export default Thumbnails;
