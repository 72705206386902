import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  approveUser,
  resetAfterApproval,
  selectShowUser,
} from "../showUserSlice";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import {
  getAllCompanies,
  selectCompanies,
} from "pages/companies/companiesSlice";
import { useForm } from "hooks/useForm";
import { Button, Tooltip } from "@mui/material";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { UserRole } from "features/auth/types/user";

const ApproveUser = (props) => {
  const dispatch = useAppDispatch();
  const { userIsVerified, currentUserIsNotApproved, shownUser } = props;
  const adminPrivilege = useAppSelector(selectUserIsAdmin);
  const userAdminPrivilege = useAppSelector(selectUserIsUserAdmin);
  const [show, setShow] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Välj användarroll");
  const [selectedCompany, setSelectedCompany] = useState("Välj företag");
  const companies = useAppSelector(selectCompanies);
  const showUser = useAppSelector(selectShowUser);
  const userWasApproved = showUser.userWasApproved;

  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const { handleSubmit, handleChange } = useForm<any>({
    initialValues: {},
    onSubmit: () => {
      const requestBody = {
        userId: shownUser?.id,
        role: selectedRole as UserRole,
        companyId: selectedCompany === "Välj företag" ? null : selectedCompany,
      };
      dispatch(approveUser(requestBody));
      console.log("dispatch approveUser run w requestBody: ", requestBody);
      setShow(false);
    },
  });

  // Toastify
  useEffect(() => {
    if (userWasApproved) {
      if (showUser.error === null) {
        toast.success(`Användaren har blivit godkänd!`, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else if (showUser.error) {
        toast.error(`${showUser.error.message}`);
      }
      dispatch(resetAfterApproval());
    }
  }, [userWasApproved]);

  // Cleanup effect when component unmount
  useEffect(() => {
    if (userWasApproved) {
      return () => {
        dispatch(resetAfterApproval());
      };
    }
  }, []);

  return (
    <>
      <ToastContainer position="top-center" />
      {(adminPrivilege || userAdminPrivilege) && (
        <>
          {userIsVerified && currentUserIsNotApproved && (
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Button
                variant="contained"
                className=""
                onClick={() => setShow(true)}
              >
                Godkänn användare
              </Button>
            </div>
          )}
        </>
      )}

      <Modal show={show} onHide={handleClose} animation={true}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Godkänn användare</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel label="Roll" className="mb-3">
              <Form.Select
                value={selectedRole}
                onChange={(e) => {
                  handleChange("role");
                  setSelectedRole(e.target.value);
                }}
              >
                <option value="Välj användarroll">Välj användarroll</option>
                <option value="ADMIN">Admin</option>
                <option value="USER_ADMIN">Användaradmin</option>
                <option value="INTERNAL_INSTALLER">Intern installatör</option>
                <option value="EXTERNAL_INSTALLER">Extern installatör</option>
                <option value="EMPLOYEE">Medarbetare</option>
                <option value="PAYROLL">Ekonomi</option>
                <option value="SALES">Säljare</option>
              </Form.Select>
            </FloatingLabel>

            {selectedRole === "EXTERNAL_INSTALLER" && (
              <FloatingLabel label="Företag">
                <Form.Select
                  value={selectedCompany}
                  onChange={(e) => {
                    handleChange("company");
                    setSelectedCompany(e.target.value);
                  }}
                >
                  <option>Välj företag</option>

                  {companies?.map((company, index) => {
                    return (
                      <option key={company.id} value={company.id}>
                        {company.namn}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            )}
          </Modal.Body>

          <Modal.Footer>
            {selectedRole === "Välj användarroll" && (
              <Tooltip
                arrow
                title="Du måste välja en användarroll för att kunna godkänna användaren."
              >
                <span>
                  <Button variant="contained" type="submit" disabled>
                    Godkänn
                  </Button>
                </span>
              </Tooltip>
            )}

            {selectedRole === "EXTERNAL_INSTALLER" &&
              selectedCompany === "Välj företag" && (
                <Tooltip
                  arrow
                  title="Du måste välja ett företag för att kunna godkänna externa installatörer."
                >
                  <span>
                    <Button variant="contained" type="submit" disabled>
                      Godkänn
                    </Button>
                  </span>
                </Tooltip>
              )}

            {selectedRole !== "Välj användarroll" &&
              selectedRole !== "EXTERNAL_INSTALLER" && (
                <span>
                  <Button variant="contained" type="submit">
                    Godkänn
                  </Button>
                </span>
              )}

            {selectedRole === "EXTERNAL_INSTALLER" &&
              selectedCompany !== "Välj företag" && (
                <span>
                  <Button variant="contained" type="submit">
                    Godkänn
                  </Button>
                </span>
              )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveUser;
