import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import textStyles from "../../utilities/styles/textStyles.module.scss";

const PendingUsers = (props) => {
  const { users } = props;
  const [pendingUsers, setPendingUsers] = useState<any>([]);

  useEffect(() => {
    if (users) {
      const filteredUsers = users.filter((user) => user.state === "PENDING");
      setPendingUsers(filteredUsers);
    }
  }, [users]);

  return (
    <>
      {pendingUsers.length === 0 ? (
        <p>För närvarande väntar inga användare på godkännande.</p>
      ) : (
        <>
          <p className="fw-bold mb-2">
            Väntar på godkännande ({pendingUsers.length} st)
          </p>

          <div className="mb-5">
            {pendingUsers.map((user, index) => {
              return (
                <p key={index} className="mb-1">
                  <Tooltip
                    arrow
                    placement="right"
                    title="Gå till användarprofil"
                  >
                    <Link
                      to={`/users/view/${user.id}`}
                      className={textStyles.link}
                    >
                      {user.userName}
                    </Link>
                  </Tooltip>
                </p>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default PendingUsers;
