import OrderSummary from "./OrderSummary";
import CustomerSummary from "./CustomerSummary";
import ContractData from "./ContractData";
import styles from "../viewOrder.module.scss";

const OrderInfo = (props) => {
  const { order } = props;

  return (
    <div className={styles.orderInfo}>
      <CustomerSummary />
      <OrderSummary />
      <ContractData order={order} />
    </div>
  );
};

export default OrderInfo;
