import { useAppSelector } from "app/hooks";
import SearchMap from "./SearchMap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectCompanies } from "../../companiesSlice";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styles from "./map.module.scss";

const MapWrapper = () => {
  const companies = useAppSelector(selectCompanies);

  // Create a new array of company objects w geoAddress (combined postaddress, postnr, postaddress)
  const companiesWithGeoAddress = companies?.map((company) => {
    const { address, postaddress, postnr, namn } = company;
    const geoAddress = `${address}, ${postaddress} ${postnr}`;
    return { ...company, geoAddress };
  });

  return (
    <div className="mb-5">
      <div className={styles.mapAccordion}>
        <Accordion className={styles.customAccordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="my-1">Visa karta över företag</p>
          </AccordionSummary>

          <AccordionDetails>
            <SearchMap props={companiesWithGeoAddress} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default MapWrapper;
