import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Switch, Tooltip } from "@mui/material";
import { switchStyle } from "utilities/styles/switchStyle";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styles from "../../../checklist.module.scss";
import iconStyles from "../../../../../../utilities/styles/iconStyles.module.scss";

const HideName = ({
  toggleNameVisibility,
  nameHidden,
  setNameHidden,
  commentsRatingValue,
  picturesRatingValue,
  installationRatingValue,
}) => {
  const [showToggle, setShowToggle] = useState(true);

  // Check if there is a rating in order to hide/display name
  useEffect(() => {
    console.log("Initial render");
    if (commentsRatingValue && picturesRatingValue && installationRatingValue) {
      setNameHidden(false);
      setShowToggle(false);
    } else {
      setNameHidden(true);
      setShowToggle(true);
    }
  }, [commentsRatingValue, picturesRatingValue, installationRatingValue]);

  return (
    <div className={styles.hideName}>
      {showToggle && (
        <Tooltip
          arrow
          title={
            <>
              <p className="fw-bold fs-6 mb-1">
                {nameHidden
                  ? "Installatörens namn är dolt"
                  : "Visar installatörens namn"}
              </p>
              <p>
                För att säkerställa rättvis betygsättning är installatörens namn
                som standard dolt före alla betyg är satta. Du kan välja att
                visa namnet ändå genom att slå på switchen.
              </p>
            </>
          }
        >
          <div className="d-flex justify-content-end">
            <Switch onChange={toggleNameVisibility} sx={switchStyle} />
            <p className={iconStyles.eyeIcon}>
              {nameHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default HideName;
