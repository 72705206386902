import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm } from "hooks/useForm";
import {
  changePassword,
  ChangePasswordData,
  resetChangePasswordState,
  selectProfileErrors,
  selectProfileLoading,
  selectProfileUpdated,
} from "features/auth/authSlice";
import DoneComponent from "features/statusComponents/DoneComponent";
import { Modal, FloatingLabel, Form } from "react-bootstrap";
import { Alert, Button } from "@mui/material";

export interface ChangePasswordModalProps {
  handleClose: () => void;
  handleConfirm: () => void;
  show: boolean;
  message: string;
  confirmLabel: string;
  dismissLabel: string;
  isLoading: boolean;
}

const ChangePasswordModal: React.FunctionComponent<ChangePasswordModalProps> = (
  props
) => {
  const { show, handleClose, handleConfirm } = props;
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectProfileLoading);
  const updated = useAppSelector(selectProfileUpdated);
  const errorResponse = useAppSelector(selectProfileErrors);

  const {
    handleSubmit,
    handleChange,
    data: passwordData,
    errors,
    clearFormData,
  } = useForm<ChangePasswordData>({
    validations: {
      oldPassword: {
        custom: {
          isValid: (value) => {
            return value.length > 0;
          },
          message: "Ange ditt gamla lösenord.",
        },
      },
      newPassword: {
        custom: {
          isValid: (value) => {
            return value.length > 5;
          },
          message: "Det nya lösenordet uppfyller inte kraven.",
        },
      },
      confirmNewPassword: {
        custom: {
          isValid: (value) => {
            return value === passwordData.newPassword;
          },
          message: "Lösenorden stämmer inte överens.",
        },
      },
    },
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: () => {
      dispatch(changePassword(passwordData));
    },
  });

  useEffect(() => {
    let timer;
    if (updated && errorResponse === null) {
      timer = setTimeout(() => {
        dispatch(resetChangePasswordState());
        clearFormData();
        handleClose();
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [updated]);

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Ändra lösenord</Modal.Title>
          </Modal.Header>
          {updated && errorResponse === null ? (
            <DoneComponent message={"Ditt lösenord har ändrats!"} />
          ) : (
            <>
              <Modal.Body>
                <div className="my-2">
                  <FloatingLabel label="Nuvarande lösenord">
                    <Form.Control
                      type="password"
                      onChange={handleChange("oldPassword" || "")}
                      className={`form-control ${
                        errors.oldPassword && "is-invalid"
                      }`}
                      value={passwordData.oldPassword}
                    />
                  </FloatingLabel>
                </div>

                <div className="my-2">
                  <FloatingLabel label="Nytt lösenord">
                    <Form.Control
                      type="password"
                      onChange={handleChange("newPassword" || "")}
                      className={`form-control ${
                        errors.newPassword && "is-invalid"
                      }`}
                      value={passwordData.newPassword}
                    />
                  </FloatingLabel>
                </div>

                <div className="my-2">
                  <FloatingLabel label="Bekräfta nytt lösenord">
                    <Form.Control
                      type="password"
                      onChange={handleChange("confirmNewPassword" || "")}
                      className={`form-control ${
                        errors.confirmNewPassword && "is-invalid"
                      }`}
                      value={passwordData.confirmNewPassword}
                    />
                  </FloatingLabel>
                </div>

                {/* Display server errors - i.e. wrong password */}
                {errorResponse && (
                  <Alert severity="error" className="mt-3">
                    {errorResponse}
                  </Alert>
                )}

                {/* Display validation errors */}
                {errors.oldPassword && (
                  <Alert severity="error" className="mt-3">
                    {errors.oldPassword}
                  </Alert>
                )}

                {errors.newPassword && (
                  <Alert severity="error" className="mt-3">
                    {errors.newPassword}
                  </Alert>
                )}

                {errors.confirmNewPassword && (
                  <Alert severity="error" className="mt-3">
                    {errors.confirmNewPassword}
                  </Alert>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  onClick={handleConfirm}
                  color="error"
                  variant="contained"
                  className="me-2"
                >
                  Avbryt
                </Button>

                {passwordData.oldPassword === "" ||
                passwordData.newPassword === "" ||
                passwordData.confirmNewPassword === "" ? (
                  <Button disabled variant="contained">
                    Spara
                  </Button>
                ) : (
                  <Button type="submit" variant="contained">
                    Spara
                  </Button>
                )}
              </Modal.Footer>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
