import { useEffect, useState } from "react";
import styles from "./searchBar.module.scss";

export interface SearchBarProps {
  label: string;
  onChange: (value: string) => void;
  value: string;
}
const SearchBar: React.FunctionComponent<SearchBarProps> = (props) => {
  const { label, onChange, value } = props;
  const [query, setQuery] = useState<string>(value);

  const onTextEnter = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (query === "") {
      setQuery(value);
    }
  }, [value]);

  useEffect(() => {
    if (query.length > 1) {
      onChange(query);
    } else {
      onChange("");
    }
  }, [query]);

  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-100">
      <div className="form-floating">
        <input
          autoComplete="off"
          spellCheck="false"
          type="search"
          className={`form-control mb-3 ps-3 ` + styles.searchBar}
          id="floatingInput"
          placeholder={label}
          onChange={onTextEnter}
          value={query}
        />
        <label className="ms-1" htmlFor="floatingInput">
          {label}
        </label>
      </div>
    </form>
  );
};

export default SearchBar;
