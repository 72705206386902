import React, { useEffect, useState } from "react";
import { Checklist } from "models/Checklist";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";

const ChooseChecklists = (props) => {
  const {
    PDFisLoading,
    downloading,
    checklistsToDownload,
    setChecklistsToDownload,
    checklists,
  } = props;

  const [includeAllChecklists, setIncludeAllChecklists] =
    useState<boolean>(true);

  useEffect(() => {
    if (includeAllChecklists) {
      setChecklistsToDownload(checklists);
    } else setChecklistsToDownload([]);
  }, []);

  // Populating array of selected checklists
  const checklistIsSelected = (id: Checklist): boolean => {
    if (!checklistsToDownload) return true;
    const isSelected = checklistsToDownload?.some((selection) => {
      return id === selection;
    });
    return isSelected;
  };

  const toggleChecklistSelection = (id: Checklist): void => {
    if (!checklistsToDownload) return;
    let index = checklistsToDownload.indexOf(id);
    if (index > -1) {
      // Remove from array
      let arrayCopy = [...checklistsToDownload];
      arrayCopy.splice(index, 1);
      setChecklistsToDownload(arrayCopy);
    } else {
      // Add to array
      setChecklistsToDownload([...checklistsToDownload, id]);
    }
  };

  return (
    <div className="mb-3">
      {!PDFisLoading && !downloading && (
        <div className="mb-3">
          <p className="mb-2 fw-bold">Kryssa i checklistor som ska inkluderas</p>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeAllChecklists}
                  onChange={() => {
                    setIncludeAllChecklists(!includeAllChecklists);
                    setChecklistsToDownload(
                      includeAllChecklists ? [] : checklists
                    );
                  }}
                />
              }
              label={"Alla"}
            />
          </FormGroup>
        </div>
      )}

      <FormGroup>
        {!includeAllChecklists &&
          !PDFisLoading &&
          !downloading &&
          checklists?.map((checklist) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={(() => {
                      return checklistIsSelected(checklist);
                    })()}
                    onChange={() => toggleChecklistSelection(checklist)}
                  />
                }
                label={checklist.roomName}
              />
            );
          })}
      </FormGroup>
    </div>
  );
};

export default ChooseChecklists;
