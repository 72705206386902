import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { User, UserRole } from "features/auth/types/user";
import { useForm } from "hooks/useForm";
import {
  getAllCompanies,
  selectCompanies,
} from "pages/companies/companiesSlice";
import { Modal, Row, Form, Col, FloatingLabel, Alert } from "react-bootstrap";
import { selectUser, editUser, selectEditInProgress } from "./showUserSlice";
import { selectUserRole } from "features/auth/authSlice";
import { selectLoading as selectCompaniesLoading } from "pages/companies/companiesSlice";
import { CreateUser } from "../addNewUser/AddNewUserModal";
import { getAllUsers, selectUsers } from "../usersSlice";
import { Redirect } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Button } from "@mui/material";

export interface IModalProps {
  handleClose: () => void;
  show: boolean;
}

const EditUser: React.FunctionComponent<IModalProps> = (props) => {
  const { show, handleClose } = props;
  const userRole = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const userToEdit = useAppSelector(selectUser);
  const editInProgress = useAppSelector(selectEditInProgress);
  const companies = useAppSelector(selectCompanies);
  const companiesLoading = useAppSelector(selectCompaniesLoading);

  useEffect(() => {
    fetchExtraData();
  }, []);

  const fetchExtraData = () => {
    // Check if list of companies needs too be fetched
    if (!companies) {
      // Fetch companies
      dispatch(getAllCompanies());
    }
  };

  const {
    handleSubmit,
    handleChange,
    data: userData,
    errors,
  } = useForm<CreateUser>({
    validations: {
      userName: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett namn.",
        },
      },
      email: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig epostadress.",
        },
      },
      phone: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett telefonnummer.",
        },
      },
      companyId: {
        custom: {
          isValid: (value) => {
            if (userData.role === UserRole.EXTERNAL_INSTALLER) {
              return value !== null && value.length > 0;
            }
            return true;
          },
          message: "Välj ett företag.",
        },
      },
      role: {
        custom: {
          isValid: (value) => value !== "Välj användartyp",
          message: "Välj en roll.",
        },
      },
      // controllerId: {
      //   custom: {
      //     isValid: (value) =>
      //       userToEdit?.role !== UserRole.EXTERNAL_INSTALLER &&
      //       value !== "Ingen attesterare",
      //     message: "Välj en attesterare.",
      //   },
      // },
    },
    initialValues: {
      id: userToEdit?.id,
      userName: userToEdit?.userName,
      email: userToEdit?.email,
      phone: userToEdit?.phone,
      companyId: userToEdit?.companyId,
      admin: userToEdit?.admin,
      state: userToEdit?.state,
      externalInstaller: userToEdit?.externalInstaller,
      internalInstaller: userToEdit?.internalInstaller,
      coworker: userToEdit?.coworker,
      econ: userToEdit?.econ,
      anstnrHogia: userToEdit?.anstnrHogia,
      role: userToEdit?.role,
      controllerId: userToEdit?.controllerId,
    },
    onSubmit: () => {
      switch (userData.role) {
        case UserRole.ADMIN:
          userData.internalInstaller = false;
          userData.externalInstaller = false;
          userData.coworker = false;
          userData.econ = false;
          userData.admin = true;
          userData.companyId = null;
          userData.sales = false;
          break;
        case UserRole.INTERNAL_INSTALLER:
          userData.internalInstaller = true;
          userData.externalInstaller = false;
          userData.coworker = false;
          userData.econ = false;
          userData.admin = false;
          userData.companyId = null;
          userData.sales = false;
          break;
        case UserRole.EXTERNAL_INSTALLER:
          userData.internalInstaller = false;
          userData.externalInstaller = true;
          userData.coworker = false;
          userData.econ = false;
          userData.admin = false;
          userData.sales = false;
          break;
        case UserRole.EMPLOYEE:
          userData.internalInstaller = false;
          userData.externalInstaller = false;
          userData.coworker = true;
          userData.econ = false;
          userData.admin = false;
          userData.companyId = null;
          userData.sales = false;
          break;
        case UserRole.PAYROLL:
          userData.internalInstaller = false;
          userData.externalInstaller = false;
          userData.coworker = false;
          userData.econ = true;
          userData.admin = false;
          userData.companyId = null;
          userData.sales = false;
          break;
        case UserRole.SALES:
          userData.internalInstaller = false;
          userData.externalInstaller = false;
          userData.coworker = false;
          userData.econ = false;
          userData.admin = false;
          userData.companyId = null;
          userData.sales = true;
          break;
      }

      // Lookup controller name
      if (userData.controllerId) {
        const user = adminUsers.find((user) => {
          return user.id === userData.controllerId;
        });

        if (user) {
          userData.controllerName = user.userName;
        }
      }

      // validate data
      if (userData.anstnrHogia === "") {
        userData.anstnrHogia = null;
      }
      dispatch(editUser(userData));
      handleClose();
    },
  });

  useEffect(() => {
    if (users) {
      // Sort Users
      let sorted = [...users];
      sorted.sort((a, b) => {
        if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;
        else return -1;
      });
      // Filter only admins
      const filtered = sorted.filter((user) => {
        return user.role === UserRole.ADMIN;
      });

      setAdminUsers(filtered);
    } else {
      dispatch(getAllUsers());
    }
  }, [users]);

  const getAvailableRoles = (): JSX.Element => {
    if (userRole === UserRole.ADMIN) {
      return (
        <>
          <option defaultValue="">Välj användartyp</option>
          <option value="ADMIN">Admin</option>
          <option value="USER_ADMIN">Användaradmin</option>
          <option value="INTERNAL_INSTALLER">Intern installatör</option>
          <option value="EXTERNAL_INSTALLER">Extern installatör</option>
          <option value="EMPLOYEE">Medarbetare</option>
          <option value="PAYROLL">Ekonomi</option>
          <option value="SALES">Säljare</option>
        </>
      );
    } else if (
      userRole === UserRole.USER_ADMIN &&
      userToEdit?.role !== UserRole.ADMIN
    ) {
      return (
        <>
          <option defaultValue="">Välj användartyp</option>
          <option value="USER_ADMIN">Användaradmin</option>
          <option value="INTERNAL_INSTALLER">Intern installatör</option>
          <option value="EXTERNAL_INSTALLER">Extern installatör</option>
          <option value="EMPLOYEE">Medarbetare</option>
          <option value="PAYROLL">Ekonomi</option>
          <option value="SALES">Säljare</option>
        </>
      );
    } else if (
      userRole === UserRole.USER_ADMIN &&
      userToEdit?.role === UserRole.ADMIN
    ) {
      return (
        <>
          <option defaultValue="ADMIN">Admin</option>
        </>
      );
    } else {
      return (
        <>
          <option defaultValue="">Välj användartyp</option>
          <option value="INTERNAL_INSTALLER">Intern installatör</option>
          <option value="EXTERNAL_INSTALLER">Extern installatör</option>
          <option value="EMPLOYEE">Medarbetare</option>
          <option value="PAYROLL">Ekonomi</option>
        </>
      );
    }
  };

  return (
    <>
      {userRole !== UserRole.ADMIN && userRole !== UserRole.USER_ADMIN ? (
        <Redirect to="/users" />
      ) : (
        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Redigera användare</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <>
                {companiesLoading ? (
                  <div className="d-flex flex-column justify-content-center align-items-center m-5">
                    <BeatLoader color="#5F5F5F" />
                  </div>
                ) : (
                  <Row>
                    <Col sm="12" md="12">
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Namn"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Telefon"
                          onChange={handleChange("userName" || "")}
                          className={`form-control mb-3 ${
                            errors.userName && "is-invalid"
                          }`}
                          value={userData.userName}
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        controlId="floatingSelect"
                        label="E-post"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="E-post"
                          onChange={handleChange("email" || "")}
                          className={`form-control mb-3 ${
                            errors.email && "is-invalid"
                          }`}
                          value={userData.email}
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Telefonnummer"
                        className="mb-3"
                      >
                        <Form.Control
                          type="phone"
                          placeholder="Telefon"
                          onChange={handleChange("phone" || "")}
                          className={`form-control mb-3 ${
                            errors.phone && "is-invalid"
                          }`}
                          value={userData.phone}
                        />
                      </FloatingLabel>
                      {userData.role !== UserRole.EXTERNAL_INSTALLER &&
                        userData.role !== UserRole.NONE && (
                          <FloatingLabel
                            controlId="floatingSelect"
                            label="Anställningsnummer"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Anställningsnummer"
                              onChange={handleChange("anstnrHogia" || "")}
                              className={`form-control mb-3 ${
                                errors.anstnrHogia && "is-invalid"
                              }`}
                              value={
                                userData.anstnrHogia ? userData.anstnrHogia : ""
                              }
                            />
                          </FloatingLabel>
                        )}

                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Roll"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Floating label"
                          value={userData.role}
                          onChange={handleChange("role" || "")}
                          disabled={
                            userRole === UserRole.USER_ADMIN &&
                            userToEdit?.role === UserRole.ADMIN
                          }
                        >
                          {getAvailableRoles()}
                        </Form.Select>
                      </FloatingLabel>

                      {userData.role === UserRole.EXTERNAL_INSTALLER && (
                        <FloatingLabel
                          controlId="floatingSelect"
                          label="Företag"
                          className="mb-3"
                        >
                          <Form.Select
                            className={`${errors.companyId && "is-invalid"}`}
                            aria-label="Floating label"
                            onChange={handleChange("companyId" || "")}
                            value={userData.companyId ? userData.companyId : ""}
                          >
                            <option
                              defaultValue={
                                userToEdit?.companyId
                                  ? userToEdit.companyId
                                  : ""
                              }
                            >
                              Välj ett företag
                            </option>
                            {companies?.map((company, index) => {
                              return (
                                <option
                                  key={company.id}
                                  value={company.id}
                                  selected={
                                    company.id === userToEdit?.companyId && true
                                  }
                                >
                                  {company.namn}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      )}

                      {userData?.role !== UserRole.EXTERNAL_INSTALLER && (
                        <FloatingLabel
                          controlId="floatingSelect"
                          label="Attesterare"
                          className="mb-3"
                        >
                          <Form.Select
                            aria-label="Floating label"
                            onChange={handleChange("controllerId" || "")}
                            value={
                              userData.controllerId ? userData.controllerId : ""
                            }
                          >
                            <option
                              defaultValue={
                                userToEdit?.controllerId
                                  ? userToEdit.controllerId
                                  : ""
                              }
                            >
                              Ingen attesterare
                            </option>

                            {adminUsers.map((user) => {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.userName}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      )}
                    </Col>
                  </Row>
                )}

                {/* Show validation errors if present */}
                {Object.keys(errors).length > 0 && (
                  <Alert variant="danger">
                    <ul className="mb-0">
                      {Object.values(errors).map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </Alert>
                )}
              </>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="contained"
                color="error"
                className="me-2"
                onClick={handleClose}
              >
                Avbryt
              </Button>
              <Button type="submit" variant="contained">
                {editInProgress ? <BeatLoader color="#5F5F5F" /> : "Spara"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditUser;
