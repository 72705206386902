import { Image } from "react-bootstrap";
import styles from "../slideShow.module.scss";

const ImageItem = ({ mediaUrl }) => {
  return (
    <div className={styles.imageWrapper}>
      <Image src={mediaUrl.imgUrl} className={styles.image} />
    </div>
  );
};

export default ImageItem;
