import React, { useState } from "react";
import { useAppSelector } from "app/hooks";
import { selectOrder } from "./../viewOrderSlice";
import SearchBar from "features/searchBar/SearchBar";
import ChecklistsTable from "./ChecklistsTable";

const Checklists: React.FunctionComponent = () => {
  const order = useAppSelector(selectOrder);
  const checklists = order?.checklists;
  const [query, setQuery] = useState<string>("");

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  let filteredChecklists = checklists;

  if (query) {
    filteredChecklists = checklists?.filter((checklist) =>
      checklist.roomName.toLowerCase().includes(query.toLowerCase())
    );
  }

  if (checklists && checklists.length < 1) {
    return <p>Den här ordern har inga checklistor</p>;
  } else {
    return (
      <>
        <div style={{ width: 300 }}>
          <SearchBar
            label="Sök checklista"
            onChange={handleSearch}
            value={query}
          />
        </div>
        <ChecklistsTable filteredChecklists={filteredChecklists} />
      </>
    );
  }
};

export default Checklists;
