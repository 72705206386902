import React from "react";
import styles from "../timeManagement.module.scss";

const StatusComponent = (props) => {
  const { timeEntry } = props;

  return (
    <div className={styles.StatusComponent}>
      {timeEntry.state === "APPROVED" && (
        <p className={styles.StatusComponentApproved}>Godkänd</p>
      )}

      {timeEntry.state === "PENDING" && (
        <p className={styles.StatusComponentPending}>Ej behandlad</p>
      )}

      {timeEntry.state === "REJECTED" && (
        <p className={styles.StatusComponentRejected}>Ej godkänd</p>
      )}
    </div>
  );
};

export default StatusComponent;
