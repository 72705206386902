import axios from "axios";
import { BE_URL } from "config/api";
import { TimeEntry } from "models/TimeEntry";
import {
  FilterTimeEntryParams,
  TimeEntryType,
  TimeEntryStatus,
} from "./timeManagementSlice";
import {
  calculateWorkHours,
  calculateOvertimePercentages,
} from "utilities/Conversions";
import { format } from "date-fns";

export interface CSVRow {
  name: string;
  from: string;
  to: string;
  type: string;
  comment: string;
  status: string;
  processed: string;
}

export const columns = [
  {
    id: "name",
    displayName: "Namn",
  },
  {
    id: "from",
    displayName: "Från",
  },
  {
    id: "to",
    displayName: "Till",
  },

  {
    id: "type",
    displayName: "Typ",
  },
  {
    id: "comment",
    displayName: "Kommentar",
  },
  {
    id: "status",
    displayName: "Status",
  },
  {
    id: "processed",
    displayName: "Behandlad av ekonomi",
  },
  {
    id: "workHours",
    displayName: "Total arbetstid",
  },
  {
    id: "overtimeFifty",
    displayName: "Övertid 50%",
  },
  {
    id: "overtimeHundred",
    displayName: "Övertid 100%",
  },
  {
    id: "workHoursWithinInterval",
    displayName: "Total arbetstid inom vald period",
  },
];

export const generateFileName = (from: Date, to: Date): string => {
  const fromDate = format(from, "d-L-y");
  const toDate = format(to, "d-L-y");

  return `Tidrapport_${fromDate}-${toDate}`;
};

export const getCSVData = (
  filters: FilterTimeEntryParams,
  token: string
): Promise<CSVRow[]> => {
  const body: FilterTimeEntryParams = {
    page: 0,
    pageSize: 12,
    searchQuery: filters.searchQuery,
    processed: filters.processed ? true : null,
    type: filters.type && filters.type > 0 ? filters.type : null,
    fromDate: filters.fromDate || null,
    toDate: filters.toDate || null,
    state: filters.state ? filters.state : null,
    orderByEmployeeNumber: filters.orderByEmployeeNumber,
  };

  return axios
    .post<TimeEntry[]>(`${BE_URL}/absence/filterAll`, body, {
      headers: {
        token: token || "",
      },
    })
    .then((res) => {
      let results = res.data.map((item) => {
        let overtime;

        if (item.type === 5) {
          overtime = calculateOvertimePercentages(
            new Date(item.from),
            new Date(item.to)
          );
        }

        return {
          name: item.userName,
          from: item.from,
          to: item.to,
          type: `${TimeEntryType[item.type]}`,
          // Sanitize comment by removing any line breaks
          comment: item.message.replace(/(\r\n|\n|\r)/gm, "").toString(),
          status: TimeEntryStatus[item.state],
          processed: item.processed ? "Ja" : "Nej",
          workHours:
            item.type != 5
              ? calculateWorkHours(
                  new Date(item.from),
                  new Date(item.to)
                ).toFixed(2)
              : "",
          overtimeFifty: overtime ? overtime[0].toFixed(2) : "",
          overtimeHundred: overtime ? overtime[1].toFixed(2) : "",
        };
      });
      return results;
    });
};
