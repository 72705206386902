import { useAppSelector } from "app/hooks";
import { Link } from "react-router-dom";
import { selectChecklist } from "../viewChecklistSlice";
import CheckedComponent from "features/statusComponents/CheckedComponent";
import DeniedComponent from "features/statusComponents/DeniedComponent";
import { Tooltip } from "@mui/material";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";
import EditFunctionCheck from "./edit-components/EditFunctionCheck";
import { useState } from "react";
import { selectUserIsAdmin } from "features/auth/authSlice";
import { Col, Container, Row } from "react-bootstrap";

const FunctionCheck = ({ nameHidden }) => {
  const checklist = useAppSelector(selectChecklist);
  const [editing, setEditing] = useState<boolean>(false);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  return (
    <div className={containerStyles.userDataContainer}>
      {checklist?.functionCheck ? (
        <>
          <Row>
            <Col>
              <div className="d-flex w-100">
                <p className={textStyles.orderInfoTitle}>
                  Funktionskontroll utförd
                  <span className="ms-2 mb-2">
                    <CheckedComponent />
                  </span>
                </p>
                {isAdmin && (
                  <EditFunctionCheck show={editing} setShow={setEditing} />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            {!nameHidden && (
              <p className={`mt-3 ${textStyles.smallText} mb-0`}>
                {checklist?.functionCheckAt?.slice(0, 16)}
                {" av "}
                <Tooltip arrow title="Gå till användarprofilen">
                  <Link
                    to={`/users/view/${checklist?.functionCheckBy.userId}`}
                    className={textStyles.link}
                  >
                    <span className={textStyles.smallText}>
                      {checklist?.functionCheckBy.userName}
                    </span>
                  </Link>
                </Tooltip>
              </p>
            )}
          </Row>
          {checklist.functionCheckByAdmin && (
            <Row>
              <p className={`${textStyles.smallText}`}>Redigerad av admin</p>
            </Row>
          )}
        </>
      ) : (
        <Row>
          <Col>
            <div className="d-flex w-100">
              <p className={textStyles.orderInfoTitle}>
                Funktionskontroll ej utförd
                <span className="ms-2 mb-2">
                  <DeniedComponent />
                </span>
              </p>
              {isAdmin && (
                <EditFunctionCheck show={editing} setShow={setEditing} />
              )}
            </div>
          </Col>
        </Row>

        // <p className={textStyles.orderInfoTitle}>
        //   Funktionskontroll ej utförd
        //   <span className="ms-2 mb-2">
        //     <DeniedComponent />
        //   </span>
        // </p>
      )}
    </div>
  );
};

export default FunctionCheck;
