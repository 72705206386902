import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import textStyles from "../../../utilities/styles/textStyles.module.scss";
import styles from "../slideShow.module.scss";

const CreatedBy = ({ currentVerification, nameHidden }) => {
  return (
    <div className={styles.createdBy}>
      <span className={textStyles.smallText}>
        Uppladdad {currentVerification.createdAt.substring(0, 16)}
        {!nameHidden && (
          <>
            {" av "}
            <Tooltip arrow title="Gå till användarprofilen">
              <Link to={`/users/view/${currentVerification.creator.id}`}>
                <span className={textStyles.smallText}>
                  {currentVerification.creator.userName}
                </span>
              </Link>
            </Tooltip>
          </>
        )}
      </span>
    </div>
  );
};

export default CreatedBy;
