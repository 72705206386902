import { useAppDispatch, useAppSelector } from "app/hooks";
import { Link } from "react-router-dom";
import { selectError, selectLoginStatus, login } from "features/auth/authSlice";
import { useForm } from "hooks/useForm";
import { LoginData } from "interfaces/formTypes";
import { BeatLoader } from "react-spinners";
import { CenterContainer } from "features/containers/centerContainer/CenterContainer";
import { Alert } from "react-bootstrap";
import { Button } from "@mui/material";
import textStyles from "../../utilities/styles/textStyles.module.scss";

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector(selectError);
  const isLoading = useAppSelector(selectLoginStatus) === "loading";

  // Using form hook for validation
  const {
    handleSubmit,
    handleChange,
    data: loginData,
    errors,
  } = useForm<LoginData>({
    validations: {
      email: {
        pattern: {
          value:
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          message: "Ange en giltig e-postadress.",
        },
      },
      password: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange ett lösenord.",
        },
      },
    },
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: () => {
      dispatch(login(loginData));
    },
  });

  return (
    <CenterContainer>
      {isLoading ? (
        <div className="justify-content-center">
          <p className="text-center m-5">Loggar in...</p>
          <BeatLoader className="text-center m-5" />
        </div>
      ) : (
        <main className="form-signin">
          <form className="" onSubmit={handleSubmit} noValidate>
            <div className="text-center"></div>

            <h1 className="h3 mb-3 fw-normal text-center">Logga in</h1>

            <div className="form-floating">
              <input
                value={loginData.email}
                onChange={handleChange("email" || "")}
                type="email"
                className={`form-control mb-3 ${errors.email && "is-invalid"}`}
                id="floatingInput"
                placeholder="name@example.com"
              />

              <label htmlFor="floatingInput">E-post</label>
            </div>
            <div className="form-floating">
              <input
                value={loginData.password}
                onChange={handleChange("password" || "")}
                type="password"
                className={`form-control mb-3 ${
                  errors.password && "is-invalid"
                }`}
                id="floatingPassword"
                placeholder="Password"
              />
              <label htmlFor="floatingPassword">Lösenord</label>
            </div>
            <div className="mb-3 mt-3">
              {
                // Show validation errors if present
                Object.keys(errors).length > 0 && (
                  <Alert variant={"danger"}>
                    {Object.values(errors).map((error, index) => {
                      return (
                        <p className="m-0" key={index}>
                          {error}
                        </p>
                      );
                    })}
                  </Alert>
                )
              }
              {
                // Show server errors if present
                loginError && Object.keys(errors).length < 1 && (
                  <Alert variant={"danger"}>
                    <p className="m-0">{loginError.message}</p>
                  </Alert>
                )
              }
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
              <Button type="submit" variant="contained">
                Logga in
              </Button>

              <Link to="/reset" className={textStyles.link}>
                Glömt lösenord?
              </Link>
            </div>
            <div className="mb-3 mt-3">
              <Link to="/signup" className={textStyles.link}>
                Ny användare? Skapa konto!
              </Link>
            </div>
          </form>
        </main>
      )}
    </CenterContainer>
  );
};

export default LoginForm;
