import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios, { AxiosResponse } from "axios";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { MediaEntity } from "models/MediaEntity";
import { Order } from "models/Order";
import { EditCustomerFormFields } from "./EditCustomerData";

export enum EditCustomerDetailsState {
  idle,
  loading,
  success,
  fail,
}

interface EditCustomerDataState {
  order: Order | null;
  isLoading: boolean;
  state: EditCustomerDetailsState;
}

export interface EditCustomerDetails {
  orderId: string;
  name: string | null;
  address: string | null;
  postAddress: string | null;
  postalCode: string | null;
}

const initialState: EditCustomerDataState = {
  order: null,
  isLoading: false,
  state: EditCustomerDetailsState.idle,
};

export const editCustomerDetails = createAsyncThunk<
  EditCustomerDetails,
  EditCustomerFormFields,
  { rejectValue: GenericError; state: RootState }
>("editCustomerDetails/edit", async (editCustomerDetails, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const requestBody: EditCustomerDetails = {
    orderId: state.viewOrder.order?.ordernr ?? "",
    name: editCustomerDetails.name.length > 0 ? editCustomerDetails.name : null,
    address:
      editCustomerDetails.address.length > 0
        ? editCustomerDetails.address
        : null,
    postAddress:
      editCustomerDetails.postAddress.length > 0
        ? editCustomerDetails.postAddress
        : null,
    postalCode:
      editCustomerDetails.postalCode.length > 0
        ? editCustomerDetails.postalCode
        : null,
  };

  const response = await axios
    .post<EditCustomerDetails>(
      `${BE_URL}/order/edit-customer-details`,
      requestBody,
      {
        headers: {
          token: state.auth.token || "",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "Kunde inte hitta ordern",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "Ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as EditCustomerDetails;
});

export const editCustomerDataSlice = createSlice({
  name: "editCustomerData",
  initialState,
  reducers: {
    addMediaEntity: (state, action: PayloadAction<MediaEntity>) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(editCustomerDetails.pending, (state) => {
        state.isLoading = true;
        state.state = EditCustomerDetailsState.loading;
      })
      .addCase(editCustomerDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.state = EditCustomerDetailsState.fail;
      })
      .addCase(editCustomerDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.state = EditCustomerDetailsState.success;
      });
  },
});

export const { addMediaEntity } = editCustomerDataSlice.actions;
export const selectIsLoading = (state: RootState) =>
  state.editCustomerData.isLoading;
export const selectProgressState = (state: RootState) =>
  state.editCustomerData.state;

export default editCustomerDataSlice.reducer;
