import React from "react";
import { useAppSelector } from "app/hooks";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import ApproveUser from "./ApproveUser";
import UserInfoFields from "./UserInfoFields";
import { selectLoading } from "../showUserSlice";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { BeatLoader } from "react-spinners";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";

const UserData = ({ shownUser, openModal, setDeletePending }) => {
  const adminPrivilege = useAppSelector(selectUserIsAdmin);
  const userAdminPrivilege = useAppSelector(selectUserIsUserAdmin);
  const isLoading = useAppSelector(selectLoading);
  const currentUserIsNotApproved = shownUser?.state === "PENDING";
  const userIsVerified = shownUser?.validatedAt !== null;

  return (
    <DataContainer>
      {isLoading ? (
        <div className="d-flex flex-column align-items-center justify-content-center my-5">
          <BeatLoader color="#5f5f5f" className="mb-3" />
          <p>Hämtar användaren</p>
        </div>
      ) : (
        <div className="flex-column w-100">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="fw-bold">Användaruppgifter</h5>
            {(adminPrivilege || userAdminPrivilege) &&
              currentUserIsNotApproved === false && (
                <div style={{ display: "inline" }}>
                  <Tooltip arrow title={<>Redigera användare</>}>
                    <EditOutlinedIcon
                      className={iconStyles.editIcon}
                      sx={{
                        marginRight: 1,
                      }}
                      onClick={openModal}
                    />
                  </Tooltip>
                  <Tooltip arrow title={<>Radera användare</>}>
                    <DeleteIcon
                      onClick={() => setDeletePending(true)}
                      className={iconStyles.deleteIcon}
                    />
                  </Tooltip>
                </div>
              )}
          </div>

          <UserInfoFields shownUser={shownUser} />

          <ApproveUser
            userIsVerified={userIsVerified}
            currentUserIsNotApproved={currentUserIsNotApproved}
            shownUser={shownUser}
          />
        </div>
      )}
    </DataContainer>
  );
};

export default UserData;
