import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "app/store";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import {
  TimePeriod,
  StatisticsOverTime,
  HistoryRating,
} from "models/Statistics";
import { AbsentUser } from "features/auth/types/user";
import {
  InstallerChecklistStatistics,
  InstallerStatisticFull,
  InstallerStatisticsMinimum,
  StatisticsSummaryRequest,
  StatisticsSummaryResponse,
  TopFiveInstallersRequest,
} from "models/InstallerChecklistStatistics";
import StatisticsSummary, {
  StatisticsSummaryState,
} from "./components/statistics/StatisticsSummary";
import { stat } from "fs";

interface StatisticsState {
  error: GenericError | undefined | null;
  loading: boolean;
  timePeriod: TimePeriod | null;
  historyRatings: HistoryRating[];
  topFiveInstallers: InstallerStatisticsMinimum[];
  topFiveInstallersLoading: boolean;
  topFiveInstallersLoaded: boolean;
  statisticsSummary: StatisticsSummaryState;
  allInstallerStatistics: AllInstallersStatisticsState;
}

const initialState: StatisticsState = {
  error: null,
  loading: false,
  timePeriod: null,
  historyRatings: [],
  topFiveInstallers: [],
  topFiveInstallersLoading: false,
  topFiveInstallersLoaded: false,
  statisticsSummary: {
    isLoading: false,
    loaded: false,
    totalNumberOfCompletedChecklists: null,
    totalNumberOfRating: null,
    averageRating: null,
    numberOfChecklists: null,
    percentageCompleted: null,
    percentageCompletedInternal: null,
  },
  allInstallerStatistics: {
    isLoading: false,
    loaded: false,
    allInstallerStatistics: [],
  },
};

export const getStatisticsSummary = createAsyncThunk<
  StatisticsSummaryResponse,
  StatisticsSummaryRequest,
  { rejectValue: GenericError; state: RootState }
>("users/getStatisticsSummary", async (body, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<StatisticsSummaryResponse>(
      `${BE_URL}/statistics/statistics-summary`,
      body,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Ett okänt fel uppstod",
      });
    });

  return response;
});

export const getTopFiveInstallers = createAsyncThunk<
  InstallerStatisticsMinimum[],
  TopFiveInstallersRequest,
  { rejectValue: GenericError; state: RootState }
>("users/getTopFiveInstallers", async (body, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<InstallerChecklistStatistics[]>(
      `${BE_URL}/statistics/top-five-installers`,
      body,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Ett okänt fel uppstod",
      });
    });

  return response;
});

export const getAllInstallersStatistics = createAsyncThunk<
  InstallerStatisticFull[],
  TopFiveInstallersRequest,
  { rejectValue: GenericError; state: RootState }
>("users/getAllInstallersStatistics", async (body, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<InstallerChecklistStatistics[]>(
      `${BE_URL}/statistics/all-installers-statistics`,
      body,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Ett okänt fel uppstod",
      });
    });

  return response;
});

export const getTodaysAbsentees = createAsyncThunk<
  AbsentUser[],
  string,
  { rejectValue: GenericError; state: RootState }
>("users/todays-absentees", async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .get<AbsentUser[]>(`${BE_URL}/statistics/todays-absentees`, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "Ett okänt fel uppstod",
      });
    });

  return response;
});

export const getRatingOverTime = createAsyncThunk<
  StatisticsOverTime,
  TimePeriod,
  { rejectValue: GenericError; state: RootState }
>("statistics/getRatingOverTime", async (timeOption, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .post<StatisticsOverTime>(
      `${BE_URL}/statistics/installation-rating-history`,
      timeOption,
      {
        headers: {
          token: state.auth.token || "",
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return thunkApi.rejectWithValue({
        message: "An unknown error has occurred",
        status: e.response.status,
      });
    });

  return response;
});

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    resetHistoryRatings: (state, action) => {
      state.historyRatings = [];
    },
    setStatisticsSummaryLoaded: (state, action: PayloadAction<boolean>) => {
      state.statisticsSummary.loaded = action.payload;
    },
    setTopFiveLoaded: (state, action: PayloadAction<boolean>) => {
      state.topFiveInstallersLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get rating over time
      .addCase(getRatingOverTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRatingOverTime.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRatingOverTime.fulfilled, (state, action) => {
        state.loading = false;
        state.timePeriod = action.payload.filteringBy;
        state.historyRatings = action.payload.historyRatings;
      })
      .addCase(getTopFiveInstallers.pending, (state) => {
        state.topFiveInstallersLoading = true;
      })
      .addCase(getTopFiveInstallers.rejected, (state) => {
        state.topFiveInstallersLoading = false;
      })
      .addCase(getTopFiveInstallers.fulfilled, (state, action) => {
        state.topFiveInstallersLoaded = true;
        state.topFiveInstallersLoading = false;
        state.topFiveInstallers = action.payload;
      })
      .addCase(getStatisticsSummary.pending, (state) => {
        state.statisticsSummary.isLoading = true;
      })
      .addCase(getStatisticsSummary.fulfilled, (state, action) => {
        state.statisticsSummary.isLoading = false;
        state.statisticsSummary.loaded = true;

        state.statisticsSummary.averageRating = action.payload.averageRating;
        state.statisticsSummary.totalNumberOfCompletedChecklists =
          action.payload.totalNumberOfCompletedChecklists;
        state.statisticsSummary.totalNumberOfRating =
          action.payload.totalNumberOfRating;
        state.statisticsSummary.numberOfChecklists =
          action.payload.numberOfChecklists;
        state.statisticsSummary.percentageCompleted =
          action.payload.percentageCompleted;
        state.statisticsSummary.percentageCompletedInternal =
          action.payload.percentageCompletedInternal;
      })
      .addCase(getStatisticsSummary.rejected, (state) => {
        state.statisticsSummary.isLoading = false;
      })
      .addCase(getAllInstallersStatistics.pending, (state) => {
        state.allInstallerStatistics.isLoading = true;
      })
      .addCase(getAllInstallersStatistics.fulfilled, (state, action) => {
        state.allInstallerStatistics.isLoading = false;
        state.allInstallerStatistics.allInstallerStatistics = action.payload;
        state.allInstallerStatistics.loaded = true;
      })
      .addCase(getAllInstallersStatistics.rejected, (state) => {
        state.allInstallerStatistics.isLoading = false;
      });
  },
});

export const {
  resetHistoryRatings,
  setStatisticsSummaryLoaded,
  setTopFiveLoaded,
} = statisticsSlice.actions;

export const selectStatisticsState = (state: RootState) => state.statistics;
export const selectTopFiveInStallers = (state: RootState) =>
  state.statistics.topFiveInstallers;
export const selectTopFiveInStallersLoading = (state: RootState) =>
  state.statistics.topFiveInstallersLoading;
export const selectTopFiveInStallersLoaded = (state: RootState) =>
  state.statistics.topFiveInstallersLoaded;
export const selectStatisticsSummary = (state: RootState) =>
  state.statistics.statisticsSummary;
export const selectAllInstallersStatisticsState = (state: RootState) =>
  state.statistics.allInstallerStatistics;

export default statisticsSlice.reducer;

export interface AllInstallersStatisticsState {
  isLoading: boolean;
  loaded: boolean;
  allInstallerStatistics: InstallerStatisticFull[];
}
