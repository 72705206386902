import React, { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { selectLastViewedCompany } from "./companiesSlice";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import tableStyles from "../../utilities/styles/tableStyle.module.scss";

const CompaniesTable = (props) => {
  const { filteredCompanies, isLoaded } = props;
  const history = useHistory();
  const [sortedCompanies, setSortedCompanies] = useState(filteredCompanies);
  const hasPreviousCompany = useAppSelector(selectLastViewedCompany);
  const [sortOrder, setSortOrder] = useState({
    column: "",
    direction: "",
  });

  // Scroll to visited company (when everything is loaded)
  useEffect(() => {
    if (isLoaded) {
      scrollToVisitedCompany();
    }
  }, [isLoaded]);

  const scrollToVisitedCompany = () => {
    if (hasPreviousCompany) {
      let element = document.getElementById(`${hasPreviousCompany}`);
      element?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handleSort = (label) => {
    let direction = "asc";
    if (sortOrder.column === label && sortOrder.direction === "asc") {
      direction = "desc";
    }

    setSortOrder({ column: label, direction });
  };

  const labels = ["Namn", "E-post"];

  useEffect(() => {
    if (filteredCompanies) {
      let sortedList = [...filteredCompanies];

      if (sortOrder.column === "Namn") {
        sortedList.sort((a, b) => a.namn.localeCompare(b.namn));
      } else if (sortOrder.column === "E-post") {
        sortedList.sort((a, b) => a.mail.localeCompare(b.mail));
      }

      if (sortOrder.direction === "desc") {
        sortedList.reverse();
      }

      setSortedCompanies(sortedList);
    }
  }, [sortOrder, filteredCompanies]);

  const handleClick = (companyId) => {
    const companyLink = `/companies/view/${companyId}`;
    history.push(companyLink);
  };

  return (
    <>
      {sortedCompanies && sortedCompanies.length > 0 ? (
        <div className={tableStyles.companiesTable}>
          <TableContainer sx={{ minWidth: 600 }}>
            <Table size="small" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow sx={{ bgcolor: "rgba(220, 220, 220, 0.5)" }}>
                  {labels.map((label, index) => (
                    <TableCell key={index}>
                      <TableSortLabel
                        className="fw-bold py-2"
                        onClick={() => handleSort(label)}
                        active={sortOrder.column === label}
                        direction={
                          sortOrder.column === label
                            ? sortOrder.direction === "asc"
                              ? "desc"
                              : "asc"
                            : undefined
                        }
                      >
                        {label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& tr:nth-of-type(2n)": {
                    backgroundColor: "grey.100",
                  },
                }}
              >
                {sortedCompanies.map((company, index) => {
                  const isLastRow = index === sortedCompanies.length - 1;
                  return (
                    <TableRow
                      key={index}
                      id={company.id}
                      hover={true}
                      onClick={() => handleClick(company.id)}
                      className={isLastRow ? tableStyles.lastRow : ""}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{company.namn}</TableCell>
                      <TableCell>{company.mail}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <p>Din sökning gav inga resultat</p>
      )}
    </>
  );
};

export default CompaniesTable;
