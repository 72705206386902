import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectChecklist } from "../../viewChecklistSlice";
import { addProduct, selectProductsState } from "./productsSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import styles from "./products.module.scss";
import containerStyles from "../../../../../utilities/styles/containerStyles.module.scss";

const AddNewProduct = () => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector(selectChecklist);
  const productsState = useAppSelector(selectProductsState);

  const [showNewForm, setShowNewForm] = useState(false);

  // Variables for new product form
  const [newSerialNumber, setNewSerialNumber] = useState<string>("");
  const [newMaker, setNewMaker] = useState<string>("");
  const [newModel, setNewModel] = useState<string>("");
  const [newMacAddress, setNewMacAddress] = useState<string>("");
  const [newNetworkSocket, setNewNetworkSocket] = useState<string>("");
  const [newOther, setNewOther] = useState<string>("");
  const [newTheftProtection, setNewTheftProtection] = useState<string>("");
  const [newMacAddressWifi, setNewMacAddressWifi] = useState<string>("");
  const [newipAddress, setNewipAddress] = useState<string>("");
  const [newUsername, setNewUsername] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const handleCancelNew = () => {
    setNewSerialNumber("");
    setNewMaker("");
    setNewModel("");
    setNewMacAddress("");
    setNewModel("");
    setNewNetworkSocket("");
    setNewOther("");
    setNewTheftProtection("");
    setNewMacAddressWifi("");
    setNewipAddress("");
    setNewUsername("");
    setNewPassword("");
    setShowNewForm(false);
  };

  const notify = () => {
    if (productsState.error === null) {
      toast.success("Produkten har lagts till!", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.error("Något gick fel när produkten skulle läggas till.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (productsState.productWasAdded === true) {
      notify();
    }
  }, [productsState.productWasAdded]);

  const addNewProduct = () => {
    if (checklist) {
      const requestBody = {
        checklistId: checklist?.id || "",
        id: "",
        serialNumber: newSerialNumber,
        maker: newMaker,
        model: newModel,
        macAddress: newMacAddress,
        macAddressWifi: newMacAddressWifi,
        noSerials: false, // vad baseras denna på?
        theftProtection: newTheftProtection,
        ipAddress: newipAddress,
        networkSocket: newNetworkSocket,
        username: newUsername,
        password: newPassword,
        other: newOther,
      };

      dispatch(addProduct(requestBody));
    }

    handleCancelNew(); // set all input fields to "" and close new form modal
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <div className="d-flex justify-content-center m-2">
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => setShowNewForm(true)}
        >
          Lägg till ny produkt
        </Button>
      </div>
      {showNewForm && (
        <div className={containerStyles.overlay}>
          <div className="d-flex justify-content-center mt-5">
            <div className={styles.newProductForm}>
              <p className="text-center fw-bold">Lägg till en ny produkt</p>

              <Form>
                <Form.Label>Serienummer</Form.Label>
                <Form.Control
                  type="text"
                  value={newSerialNumber}
                  onChange={(e) => {
                    setNewSerialNumber(e.target.value);
                  }}
                />

                <div className="d-flex justify-content-start">
                  <Form.Label className="w-50">Fabrikat</Form.Label>
                  <Form.Label className="w-50">Modell</Form.Label>
                </div>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={newMaker}
                    onChange={(e) => {
                      setNewMaker(e.target.value);
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={newModel}
                    onChange={(e) => {
                      setNewModel(e.target.value);
                    }}
                  />
                </div>

                <Form.Label>MAC-address</Form.Label>
                <Form.Control
                  type="text"
                  value={newMacAddress}
                  onChange={(e) => {
                    setNewMacAddress(e.target.value);
                  }}
                />
                <Form.Label>MAC-adress Wi-Fi</Form.Label>
                <Form.Control
                  type="text"
                  value={newMacAddressWifi}
                  onChange={(e) => {
                    setNewMacAddressWifi(e.target.value);
                  }}
                />

                <Form.Label>Nätverksuttag</Form.Label>
                <Form.Control
                  type="text"
                  value={newNetworkSocket}
                  onChange={(e) => {
                    setNewNetworkSocket(e.target.value);
                  }}
                />
                <Form.Label>Stöldmärkning</Form.Label>
                <Form.Control
                  type="text"
                  value={newTheftProtection}
                  onChange={(e) => {
                    setNewTheftProtection(e.target.value);
                  }}
                />

                <Form.Label>IP-adress (om fast) </Form.Label>
                <Form.Control
                  type="text"
                  value={newipAddress}
                  onChange={(e) => {
                    setNewipAddress(e.target.value);
                  }}
                />
                <Form.Label>Användarnamn</Form.Label>
                <Form.Control
                  type="text"
                  value={newUsername}
                  onChange={(e) => {
                    setNewUsername(e.target.value);
                  }}
                />
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />

                <Form.Label>Övrigt/Firmware-version</Form.Label>
                <Form.Control
                  type="text"
                  value={newOther}
                  onChange={(e) => {
                    setNewOther(e.target.value);
                  }}
                />

                <div className="d-flex justify-content-center my-4">
                  <Button
                    className="me-2"
                    variant="contained"
                    onClick={addNewProduct}
                  >
                    Lägg till
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCancelNew}
                  >
                    Avbryt
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewProduct;
