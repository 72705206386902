import React from "react";
import { useAppSelector } from "app/hooks";
import {
  selectUserIsAdmin,
  selectUserIsUserAdmin,
} from "features/auth/authSlice";
import { Rating } from "@mui/material";
import styles from "../../../checklist.module.scss";
import { selectRatingState } from "../ratingSlice";
import { BeatLoader } from "react-spinners";

const PicturesRating = ({
  picturesRatingValue,
  setPicturesRatingValue,
  editable,
}) => {
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isUserAdmin = useAppSelector(selectUserIsUserAdmin);
  const isLoading = useAppSelector(selectRatingState).ratingLoading;

  const handlePicturesChange = (
    event: React.SyntheticEvent,
    value: number | null
  ) => {
    if (value) {
      picturesRatingValue = value;
      setPicturesRatingValue(value);
    }
  };

  return (
    <div className="d-flex justify-content-between my-1">
      <div className="d-flex">
        <p>Bilder: </p>
        {isLoading ? (
          <BeatLoader color="#5f5f5f" className="mb-3" />
        ) : isAdmin || isUserAdmin ? (
          <Rating
            readOnly={!editable}
            className={styles.rating}
            onChange={handlePicturesChange}
            value={picturesRatingValue}
          />
        ) : (
          <Rating
            readOnly
            className={styles.rating}
            value={picturesRatingValue}
          />
        )}
      </div>

      <span>{picturesRatingValue !== 0 ? picturesRatingValue : "-"}</span>
    </div>
  );
};

export default PicturesRating;
