import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { SimpleUser, UserStatus } from "models/SimpleUser";
import { Link, useLocation } from "react-router-dom";
import {
  removeUser,
  selectCompany,
  selectShowCompanyState,
  resetUsersAfterEdit,
} from "../showCompanySlice";
import ConfirmModal from "features/confirmModal/ConfirmModal";
import AddInstaller from "../AddInstaller";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";

const Users = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const shownCompany = useAppSelector(selectCompany);
  const shownCompanyState = useAppSelector(selectShowCompanyState);
  const userWasDeleted = shownCompanyState.userWasDeleted;
  const userWasAdded = shownCompanyState.userWasAdded;
  const [addInstallerOpen, setAddInstallerOpen] = useState<boolean>(false);
  const [userPendingRemoval, setUserPendingremoval] =
    useState<SimpleUser | null>(null);
  const [confirmRemoveUserModalOpen, setConfirmRemoveUserModalOpen] =
    useState<boolean>(false);

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();

  const openAddInstallerModal = () => {
    query.append("addInstaller", "true");
    setAddInstallerOpen(true);
  };

  const openRemoveUserModal = (user: SimpleUser) => {
    setUserPendingremoval(user);
    setConfirmRemoveUserModalOpen(true);
  };

  const closeRemoveUserModal = () => {
    setUserPendingremoval(null);
    setConfirmRemoveUserModalOpen(false);
  };

  const confirmRemoveUser = () => {
    if (userPendingRemoval && userPendingRemoval.id !== null) {
      console.log(`id: ${userPendingRemoval.id}`);
      dispatch(removeUser(userPendingRemoval.id));
      closeRemoveUserModal();
    }
  };

  const closeAddInstallerModal = () => {
    query.delete("addInstaller");
    setAddInstallerOpen(false);
  };

  // Toastify
  useEffect(() => {
    if (userWasDeleted === true) {
      if (shownCompanyState.error === null) {
        toast.success(`Användaren har tagits bort från företaget!`, {});
      } else if (shownCompanyState.error !== null) {
        toast.error(`${shownCompanyState.error?.message}`, {});
      }
    } else if (userWasAdded === true) {
      if (shownCompanyState.error === null) {
        toast.success(`Användaren har lagts till!`, {});
      } else if (shownCompanyState.error) {
        toast.error(`${shownCompanyState.error.message}`, {});
      }
    }
  }, [userWasDeleted, userWasAdded]);

  // Cleanup when component unmount
  useEffect(() => {
    if (userWasDeleted || userWasAdded) {
      return () => {
        dispatch(resetUsersAfterEdit());
      };
    }
  }, []);

  return (
    <div className="mt-3">
      <ToastContainer position="top-center" />
      <div className={containerStyles.users}>
        <div className="d-flex justify-content-between mb-3">
          <h5 className="fw-bold">
            {shownCompany?.users && shownCompany?.users?.length > 0 ? (
              <>Användare ({shownCompany?.users?.length} st)</>
            ) : (
              <>Användare saknas</>
            )}
          </h5>

          <Tooltip arrow title="Lägg till användare">
            <AddCircleIcon
              className={iconStyles.addIcon}
              onClick={openAddInstallerModal}
            />
          </Tooltip>
        </div>

        {shownCompany?.users?.length
          ? shownCompany.users.map((user) => {
              return (
                <div className="d-flex flex-column" key={user.userId}>
                  <div className="d-flex justify-content-between">
                    <Link
                      to={`/users/view/${user.userId}`}
                      className="text-dark"
                    >
                      <Tooltip
                        arrow
                        title="Gå till användarprofilen"
                        placement="right"
                      >
                        <p className={textStyles.link}>
                          {user.status !== UserStatus.INACTIVE
                            ? user.userName
                            : "Inaktiv användare"}
                        </p>
                      </Tooltip>
                    </Link>

                    <Tooltip arrow title="Radera användare" placement="left">
                      <ClearIcon
                        onClick={() => openRemoveUserModal(user)}
                        className={iconStyles.clearIcon}
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            })
          : ""}
      </div>

      <ConfirmModal
        isLoading={false}
        handleClose={closeRemoveUserModal}
        handleConfirm={confirmRemoveUser}
        show={confirmRemoveUserModalOpen}
        title={"Ta bort"}
        message={`Är du säker på att du vill ta bort ${userPendingRemoval?.userName} från företaget?`}
        confirmLabel="Ja, ta bort"
        dismissLabel="Avbryt"
      />

      {addInstallerOpen && (
        <AddInstaller
          company={shownCompany}
          show={addInstallerOpen}
          handleClose={closeAddInstallerModal}
        />
      )}
    </div>
  );
};

export default Users;
