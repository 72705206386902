import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useHistory } from "react-router-dom";
import {
  getTodaysAbsentees,
  selectLoading,
  selectTodaysAbsentees,
} from "pages/users/usersSlice";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AbsentUser, UserRole } from "features/auth/types/user";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BeatLoader } from "react-spinners";
import {
  Badge,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { RollerShades } from "@mui/icons-material";
import { RoleFilter } from "../utilities/utilities";
import { selectUserRole } from "features/auth/authSlice";
import styles from "../styles.module.scss";

const Absent = (props) => {
  const { largeScreen } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isLoading = useAppSelector(selectLoading);
  const todaysAbsentees = useAppSelector(selectTodaysAbsentees);
  const userRole = useAppSelector(selectUserRole);

  const [selectedInstallerTypeFilter, setSelectedInstallerTypeFilter] =
    useState<UserRole | null>(null);

  const availableFilterLabels = [
    "Alla",
    "Intern installatör",
    "Medarbetare",
    "Admin",
  ];
  const [selectedFilterLabel, setSelectedFilterLabel] = useState(
    availableFilterLabels[0]
  );
  const [displayedAbsentees, setDisplayedAbsentees] = useState<AbsentUser[]>(
    []
  );
  const [showAs, setShowAs] = useState("all");

  useEffect(() => {
    dispatch(getTodaysAbsentees(""));
  }, []);

  useEffect(() => {
    if (todaysAbsentees) {
      setDisplayedAbsentees(todaysAbsentees);
    }
  }, [todaysAbsentees]);

  useEffect(() => {
    switch (selectedInstallerTypeFilter) {
      case null: {
        const filteredAbsentees = todaysAbsentees;
        if (filteredAbsentees) {
          setDisplayedAbsentees(filteredAbsentees);
        }

        break;
      }
      case UserRole.INTERNAL_INSTALLER: {
        const filteredAbsentees = todaysAbsentees?.filter(
          (user) => user.userRole === UserRole.INTERNAL_INSTALLER
        );
        if (filteredAbsentees) {
          setDisplayedAbsentees(filteredAbsentees);
        }
        break;
      }
      case UserRole.EMPLOYEE: {
        const filteredAbsentees = todaysAbsentees?.filter(
          (user) => user.userRole === UserRole.EMPLOYEE
        );
        if (filteredAbsentees) {
          setDisplayedAbsentees(filteredAbsentees);
        }
        break;
      }
      case UserRole.ADMIN: {
        const filteredAbsentees = todaysAbsentees?.filter(
          (user) => user.userRole === UserRole.ADMIN
        );
        if (filteredAbsentees) {
          setDisplayedAbsentees(filteredAbsentees);
        }
        break;
      }
      case UserRole.PAYROLL: {
        const filteredAbsentees = todaysAbsentees?.filter(
          (user) => user.userRole === UserRole.PAYROLL
        );
        if (filteredAbsentees) {
          setDisplayedAbsentees(filteredAbsentees);
        }
        break;
      }
    }
  }, [selectedInstallerTypeFilter]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    userId: string
  ) => {
    event.preventDefault();
    history.push(`/users/view/${userId}`);
  };

  function getUserRoleFilterString(value: UserRole | null): string {
    switch (value) {
      case null:
        return "Alla";

      case UserRole.INTERNAL_INSTALLER:
        return "Installatörer";

      case UserRole.ADMIN:
        return "Admin";

      case UserRole.EMPLOYEE:
        return "Medarbetare";

      case UserRole.PAYROLL:
        return "Ekonomi";
      default:
        return "";
    }
  }

  if (isLoading) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center my-5">
        <BeatLoader color="#5f5f5f" className="mb-3" />
        <p>Hämtar dagens frånvarande</p>
      </div>
    );
  } else
    return (
      <Container className={`${styles.modalContainer}`}>
        <Row className="mb-2 mt-4">
          <Col xs={"auto"}>
            <h5 className="fw-bold text-center">Dagens frånvarande</h5>
          </Col>
        </Row>
        {todaysAbsentees === null || todaysAbsentees?.length === 0 ? (
          <Row>
            <Col>
              <h5 className="fw-bold text-center mb-5">
                Idag är inga personer frånvarande!
              </h5>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="">
              <Col className="">
                <h6 className="mb-2">
                  {`Idag är ${todaysAbsentees?.length} personer frånvarande`}
                </h6>
              </Col>
              <Col xs={"auto"}>
                <DropdownButton
                  variant="info"
                  id="dropdown-basic-button"
                  title={getUserRoleFilterString(selectedInstallerTypeFilter)}
                >
                  <Dropdown.Item
                    onClick={() => setSelectedInstallerTypeFilter(null)}
                  >
                    {getUserRoleFilterString(null)}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setSelectedInstallerTypeFilter(
                        UserRole.INTERNAL_INSTALLER
                      )
                    }
                  >
                    {getUserRoleFilterString(UserRole.INTERNAL_INSTALLER)}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setSelectedInstallerTypeFilter(UserRole.ADMIN)
                    }
                  >
                    {getUserRoleFilterString(UserRole.ADMIN)}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setSelectedInstallerTypeFilter(UserRole.EMPLOYEE)
                    }
                  >
                    {getUserRoleFilterString(UserRole.EMPLOYEE)}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setSelectedInstallerTypeFilter(UserRole.PAYROLL)
                    }
                  >
                    {getUserRoleFilterString(UserRole.PAYROLL)}
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
            {/* <div className="d-xl-flex justify-content-between px-4">
              <ToggleButtonGroup
                color="primary"
                value={showAs}
                exclusive
                onChange={handleViewChange}
                aria-label="Diagram/List view"
                sx={{ height: 30 }}
                role="group"
                orientation={largeScreen ? "horizontal" : "vertical"}
                className={!largeScreen ? "mb-5" : ""}
              >
                <ToggleButton value="all">Alla</ToggleButton>
                <ToggleButton value="installators-only">
                  Endast installatörer
                </ToggleButton>
              </ToggleButtonGroup>
            </div> */}

            <div className="my-5">
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="fw-bold" sx={{ width: "25%" }}>
                        Namn
                      </TableCell>
                      <TableCell
                        align="left"
                        className="fw-bold"
                        sx={{ width: "25%" }}
                      >
                        Från
                      </TableCell>
                      <TableCell
                        align="left"
                        className="fw-bold"
                        sx={{ width: "25%" }}
                      >
                        Till
                      </TableCell>

                      {(userRole === UserRole.ADMIN ||
                        userRole === UserRole.USER_ADMIN ||
                        userRole === UserRole.PAYROLL) && (
                        <TableCell
                          align="left"
                          className="fw-bold"
                          //sx={{ width: "25%" }}
                        >
                          Anledning
                        </TableCell>
                      )}
                      {(userRole === UserRole.ADMIN ||
                        userRole === UserRole.PAYROLL) && (
                        <TableCell
                          align="left"
                          className="fw-bold"
                          sx={{ width: "25%" }}
                        >
                          Kommentar
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& tr:nth-of-type(2n)": {
                        backgroundColor: "grey.100",
                      },
                    }}
                  >
                    {displayedAbsentees.length > 0 &&
                      displayedAbsentees?.map((user) => (
                        <TableRow
                          key={user.userId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          // onClick={(event) => handleClick(event, user.userId)}
                        >
                          <TableCell align="left" component="th" scope="row">
                            {user.userName}
                          </TableCell>
                          <TableCell align="left">
                            {user.from.split(" ")[0]}
                          </TableCell>
                          <TableCell align="left">
                            {user.to.split(" ")[0]}
                          </TableCell>

                          {(userRole === UserRole.ADMIN ||
                            userRole === UserRole.USER_ADMIN ||
                            userRole === UserRole.PAYROLL) && (
                            <TableCell align="left">
                              <Badge bg="primary"> {user.reason}</Badge>
                            </TableCell>
                          )}
                          {(userRole === UserRole.ADMIN ||
                            userRole === UserRole.PAYROLL) && (
                            <TableCell align="left">
                              {user.comment ? user.comment : ""}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    {displayedAbsentees.length < 1 && (
                      <p className="fw-bold my-2">Inga frånvarande</p>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </Container>
    );
};

export default Absent;
