import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { pdf } from "@react-pdf/renderer";
import { Order } from "models/Order";
import { Checklist } from "models/Checklist";
import PDFDocument from "./PDFDocument";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchAllImages,
  selectFullMediaLoaded,
  selectImageUrls,
  selectPDFisLoading,
} from "../../viewOrderSlice";
import { GridLoader } from "react-spinners";
import imageCompression from "browser-image-compression";
import ImageQuality from "./ImageQuality";
import ChooseChecklists from "./ChooseChecklists";
import { Button } from "@mui/material";

interface PDFDocumentProps {
  order: Order;
}

const GeneratePDF: React.FunctionComponent<PDFDocumentProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [checklistsToDownload, setChecklistsToDownload] = useState<Checklist[]>(
    []
  );
  const [imageQuality, setImageQuality] = useState("low");
  const order = props.order;
  const dispatch = useAppDispatch();
  const checklists = order?.checklists;
  const images = useAppSelector(selectImageUrls);
  const imagesLoaded = useAppSelector(selectFullMediaLoaded);
  const PDFisLoading = useAppSelector(selectPDFisLoading);
  const [downloading, setDownloading] = useState(false);

  const fetchImages = (checklists: Checklist[]) => {
    dispatch(fetchAllImages(checklists));
  };

  useEffect(() => {
    if (imagesLoaded && downloading) {
      const fetchImageFile = async (imageUrl) => {
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const fileName = "image.jpg"; // Adjust the file name as needed
          const imageFile = new File([blob], fileName, {
            type: "image/jpeg",
          });
          return imageFile;
        } catch (error) {
          console.error("Image fetch error:", error);
          return null;
        }
      };

      const createPDF = async () => {
        // Compress the images before passing them to PDFDocument
        const compressedImages = await Promise.all(
          images.map(async (image) => {
            try {
              const imageFile = await fetchImageFile(image.imgUrl);
              if (imageFile) {
                let maxSizeMB;
                if (imageQuality === "low") {
                  maxSizeMB = 0.01;
                } else if (imageQuality === "medium") {
                  maxSizeMB = 0.25;
                } else if (imageQuality === "high") {
                  maxSizeMB = 1.0;
                }
                const compressedImageFile = await imageCompression(imageFile, {
                  maxSizeMB,
                });

                const compressedImage = new File(
                  [compressedImageFile],
                  "compressed.jpg",
                  {
                    type: "image/jpeg",
                  }
                );

                return {
                  ...image,
                  compressedImage,
                };
              } else {
                return image;
              }
            } catch (error) {
              console.error("Image compression error:", error);
              return image;
            }
          })
        );

        // Generate PDF with compressed images
        const blob = await pdf(
          PDFDocument(order, checklistsToDownload, compressedImages)
        ).toBlob();

        return blob;
      };

      createPDF().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);

        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${order.ordernr} - rapport.pdf`; // The filename
        alink.click();
        setDownloading(false);
      });
    }
  }, [imagesLoaded]);

  const handleSave = () => {
    setDownloading(true);
    fetchImages(checklistsToDownload);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="contained"
        onClick={() => setShowModal(true)}
      >
        Generera installationsbevis
      </Button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {!PDFisLoading && !downloading && (
            <Modal.Title>
              Skapa PDF-rapport för order {order.ordernr}
            </Modal.Title>
          )}
          {PDFisLoading && <Modal.Title>Genererar PDF-rapport</Modal.Title>}
          {downloading && !PDFisLoading && (
            <Modal.Title>Laddar ner PDF-rapport</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <ChooseChecklists
            PDFisLoading={PDFisLoading}
            downloading={downloading}
            setChecklistsToDownload={setChecklistsToDownload}
            checklists={checklists}
            checklistsToDownload={checklistsToDownload}
          />

          {!downloading && !PDFisLoading && (
            <ImageQuality
              imageQuality={imageQuality}
              setImageQuality={setImageQuality}
            />
          )}

          {PDFisLoading && (
            <div className="d-flex flex-column align-items-center justify-content-center my-5">
              <GridLoader color="#5f5f5f" className="my-5" />
              <p className="my-5">Vänta lite medan rapporten skapas</p>
            </div>
          )}
          {downloading && !PDFisLoading && (
            <div className="d-flex flex-column align-items-center justify-content-center my-5">
              <GridLoader color="#5f5f5f" className="my-5" />
              <p className="my-5">Vänta lite medan filen laddas ner</p>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {!downloading && !PDFisLoading && (
            <>
              <Button
                color="error"
                variant="contained"
                className="me-2"
                onClick={handleClose}
              >
                Avbryt
              </Button>

              <Button
                disabled={checklistsToDownload.length === 0}
                variant="contained"
                onClick={handleSave}
              >
                Ladda ner
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GeneratePDF;
