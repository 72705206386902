import { SerialEntity } from "models/Checklist";
import styles from "./products.module.scss";

interface SerialComponentProps {
  serial: SerialEntity;
  index?: number;
}

const SerialComponent: React.FC<SerialComponentProps> = ({ serial, index }) => {
  let displayIndex;
  if (index !== undefined) {
    displayIndex = index + 1;
  }

  return (
    <div key={serial.id} className={styles.product}>
      <p className="fw-bold">{displayIndex}.</p>
      {serial.serialNumber && <p>Serienummer: {serial.serialNumber},</p>}
      {serial.maker && <p>Fabrikat: {serial.maker},</p>}
      {serial.model && <p>Modell: {serial.model},</p>}
      {serial.macAddress && <p>MAC-Adress: {serial.macAddress},</p>}
      {serial.networkSocket && <p>Nätverksuttag: {serial.networkSocket},</p>}
      {serial.macAddressWifi && (
        <p>MAC-Address Wi-Fi: {serial.macAddressWifi},</p>
      )}
      {serial.ipAddress && <p>IP-address (om fast): {serial.ipAddress},</p>}
      {serial.theftProtection && (
        <p>Stöldmärkning: {serial.theftProtection},</p>
      )}
      {serial.username && <p>Användarnamn: {serial.username},</p>}
      {serial.password && <p>Lösenord: {serial.password},</p>}
      {serial.other && <p>Firmware version/Övrigt: {serial.other},</p>}
    </div>
  );
};

export default SerialComponent;
