import { UserRole } from "features/auth/types/user";
import { useAppSelector } from "app/hooks";
import { selectUserRole } from "features/auth/authSlice";
import { Form, Modal, FloatingLabel, Alert } from "react-bootstrap";
import { Button } from "@mui/material";
import { selectCompanies } from "pages/companies/companiesSlice";

const FormComponent = ({
  handleSubmit,
  handleChange,
  errors,
  useDifferentAppleId,
  setUseDifferentAppleId,
  errorMessage,
  userData,
  sortedUsers,
  handleClose,
}) => {
  const userRole = useAppSelector(selectUserRole);
  const companies = useAppSelector(selectCompanies);

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Header>
        <Modal.Title>Skapa ny användare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <FloatingLabel label="För- och efternamn" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Telefon"
              onChange={handleChange("userName" || "")}
              className={`form-control mb-3 ${errors.userName && "is-invalid"}`}
            />
          </FloatingLabel>

          <FloatingLabel label="E-post" className="mb-3">
            <Form.Control
              autoComplete="off"
              spellCheck="false"
              type="text"
              placeholder="E-post"
              onChange={handleChange("email" || "")}
              className={`form-control mb-3 ${errors.email && "is-invalid"}`}
            />
          </FloatingLabel>

          <Form.Check
            type={"checkbox"}
            id={`useDifferentAplleId`}
            label={`Använd en annan E-post för AppleId`}
            onChange={() => {
              setUseDifferentAppleId(!useDifferentAppleId);
            }}
            checked={useDifferentAppleId}
            className="my-3"
          />
          {useDifferentAppleId && (
            <FloatingLabel label="E-post för AppleId" className="mb-3">
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="AppleId"
                onChange={handleChange("appleId" || "")}
                className={`form-control mb-3 ${
                  errors.appleId && "is-invalid"
                }`}
              />
            </FloatingLabel>
          )}

          <FloatingLabel label="Telefonnummer" className="mb-3">
            <Form.Control
              type="phone"
              placeholder="Telefon"
              onChange={handleChange("phone" || "")}
              className={`form-control mb-3 ${errors.phone && "is-invalid"}`}
            />
          </FloatingLabel>

          <FloatingLabel controlId="floatingSelect" label="Roll">
            <Form.Select
              className={`mb-3 ${errors.role && "is-invalid"}`}
              onChange={handleChange("role" || "")}
              aria-label="Floating label"
            >
              <option value="">Välj användartyp</option>
              {userRole === UserRole.ADMIN && (
                <>
                  <option value="ADMIN">Admin</option>
                  <option value="USER_ADMIN">Användaradmin</option>
                </>
              )}

              <option value="INTERNAL_INSTALLER">Intern installatör</option>
              <option value="EXTERNAL_INSTALLER">Extern installatör</option>
              <option value="EMPLOYEE">Medarbetare</option>
              <option value="PAYROLL">Ekonomi</option>
              <option value="SALES">Säljare</option>
            </Form.Select>
          </FloatingLabel>

          {userData.role === UserRole.EXTERNAL_INSTALLER && (
            <FloatingLabel
              controlId="floatingSelect"
              label="Företag"
              className="mb-3"
            >
              <Form.Select
                onChange={handleChange("companyId" || "")}
                aria-label="Floating label"
                className={`${errors.companyId && "is-invalid"}`}
              >
                <option value="">Välj ett företag</option>
                {companies?.map((company) => {
                  return (
                    <option key={company.id} value={company.id}>
                      {company.namn}
                    </option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
          )}

          {userData.role !== UserRole.EXTERNAL_INSTALLER &&
            userData.role !== UserRole.NONE && (
              <FloatingLabel label="Anställningsnummer" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Telefon"
                  onChange={handleChange("employeeNumber" || "")}
                  className={`form-control mb-3 ${
                    errors.employeeNumber && "is-invalid"
                  }`}
                />
              </FloatingLabel>
            )}

          {userData.role !== UserRole.EXTERNAL_INSTALLER &&
            userData.role !== UserRole.NONE && (
              <FloatingLabel label="Välj en attesterare" className={`mb-3`}>
                <Form.Select
                  onChange={handleChange("controllerId" || "")}
                  aria-label="Floating label"
                  className={`${errors.controllerId && "is-invalid"}`}
                >
                  <option value="">Välj attesterare</option>
                  {sortedUsers.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.userName}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            )}

          {/* Server errors */}
          {errorMessage && (
            <Alert variant="danger">
              <p className="m-0">{errorMessage}</p>
            </Alert>
          )}

          {/* Display validation errors */}
          {Object.keys(errors).length > 0 && (
            <Alert variant="danger">
              <ul className="mb-0">
                {Object.keys(errors).map((field) => {
                  const errorValue = errors[field];

                  return (
                    <li className="m-0" key={field}>
                      {errorValue}
                    </li>
                  );
                })}
              </ul>
            </Alert>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="contained"
          color="error"
          className="me-2"
          onClick={() => handleClose()}
        >
          Avbryt
        </Button>

        <Button type="submit" variant="contained">
          Skapa
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default FormComponent;
