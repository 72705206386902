import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectUserIsAdmin } from "features/auth/authSlice";
import { selectChecklist, setDocumentationDone } from "../viewChecklistSlice";
import CheckedComponent from "features/statusComponents/CheckedComponent";
import DeniedComponent from "features/statusComponents/DeniedComponent";
import { Switch, Tooltip } from "@mui/material";
import { switchStyle } from "utilities/styles/switchStyle";
import styles from "../checklist.module.scss";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";

const DocumentationDone = ({ nameHidden }) => {
  const dispatch = useAppDispatch();
  const userIsAdmin = useAppSelector(selectUserIsAdmin);
  const checklist = useAppSelector(selectChecklist);
  const [documentationDoneUpdated, setDocumentationDoneUpdated] =
    useState<boolean>(false);

  // setDocumentationDoneUpdated to existing value
  useEffect(() => {
    if (checklist?.documentationDone !== undefined) {
      setDocumentationDoneUpdated(checklist?.documentationDone);
    }
  }, []);

  const handleChange = () => {
    setDocumentationDoneUpdated((prevState) => !prevState);

    const requestBody = {
      documentationDone: !documentationDoneUpdated,
      checklistId: checklist?.id || "",
    };

    dispatch(setDocumentationDone(requestBody));
  };

  return (
    <div className={containerStyles.userDataContainer}>
      <div className="d-flex">
        <div className="me-4">
          {documentationDoneUpdated ? (
            <p className={textStyles.orderInfoTitle}>Dokumentation klar</p>
          ) : (
            <p className={textStyles.orderInfoTitle}>Dokumentation ej klar</p>
          )}
        </div>

        <div>
          {userIsAdmin ? (
            <Switch
              onChange={handleChange}
              checked={documentationDoneUpdated}
              sx={switchStyle}
              className={styles.documentationSwitch}
            />
          ) : (
            <div>
              {documentationDoneUpdated ? (
                <CheckedComponent />
              ) : (
                <DeniedComponent />
              )}
            </div>
          )}
        </div>
      </div>

      {documentationDoneUpdated && (
        <p className={textStyles.smallText}>
          {checklist?.documentationDoneAt?.slice(0, 16)}

          {!nameHidden && (
            <>
              {" av "}
              <Tooltip arrow title="Gå till användarprofilen">
                <Link
                  to={`/users/view/${checklist?.documentationDoneBy?.userId}`}
                  className={textStyles.link}
                >
                  <span className={textStyles.smallText}>
                    {checklist?.documentationDoneBy?.userName}
                  </span>
                </Link>
              </Tooltip>
            </>
          )}
        </p>
      )}
    </div>
  );
};

export default DocumentationDone;
