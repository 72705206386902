import { useAppSelector } from "app/hooks";
import { selectChecklist } from "../viewChecklistSlice";
import CheckedComponent from "features/statusComponents/CheckedComponent";
import DeniedComponent from "features/statusComponents/DeniedComponent";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import containerStyles from "../../../../utilities/styles/containerStyles.module.scss";

const ApprovedByCustomer = () => {
  const checklist = useAppSelector(selectChecklist);

  return (
    <div className={containerStyles.userDataContainer}>
      {checklist?.approved ? (
        <>
          <p className={textStyles.orderInfoTitle}>
            Godkänd av kund
            <span className="ms-2 mb-2">
              <CheckedComponent />
            </span>
          </p>
        </>
      ) : (
        <p className={textStyles.orderInfoTitle}>
          Ej godkänd av kund
          <span className="ms-2 mb-2">
            <DeniedComponent />
          </span>
        </p>
      )}
    </div>
  );
};

export default ApprovedByCustomer;
