import { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { Link } from "react-router-dom";
import { getOrderStatusLongString } from "utilities/orders";
import { selectOrder } from "../viewOrderSlice";
import { Rating, Tooltip } from "@mui/material";
import { OrderStatus } from "models/Order";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import styles from "../viewOrder.module.scss";
import textStyles from "../../../../utilities/styles/textStyles.module.scss";
import iconStyles from "../../../../utilities/styles/iconStyles.module.scss";

const OrderSummary = () => {
  const order = useAppSelector(selectOrder);
  const [average, setAverage] = useState(0);
  const storedAverage = order?.averageRating;

  useEffect(() => {
    calculateOrderAverage();
  });

  const calculateOrderAverage = () => {
    const allAverages = order?.checklists.map((checklist) => {
      return checklist.ratings.average;
    });
    const filteredArray = allAverages?.filter(
      (value) => value !== null && value !== undefined
    );
    const sum = filteredArray?.reduce(
      (accumulator, currentValue) => accumulator! + currentValue!,
      0
    );
    setAverage(sum && filteredArray?.length ? sum / filteredArray.length : 0);
  };

  return (
    <div className={styles.orderSummary}>
      <div>
        {order && (
          <>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex flex-column">
                  <p className="mb-2 me-2 fw-bold">Orderstatus </p>
                  <p className="my-0 fw-bold">
                    {order?.completion?.completionState === "COMPLETE" && (
                      <CircleIcon className={iconStyles.greenCircle} />
                    )}
                    {order?.completion?.completionState === "PENDING" && (
                      <CircleIcon className={iconStyles.grayCircle} />
                    )}
                    {order?.completion?.completionState === "INCOMPLETE" && (
                      <CircleIcon className={iconStyles.redCircle} />
                    )}

                    {order?.completion?.completionState &&
                      getOrderStatusLongString(order?.completion?.completionState as unknown as OrderStatus)}
                  </p>
                </div>

                <Tooltip
                  arrow
                  title={
                    <>
                      <p className="mb-0">
                        <span className="fw-bold">Orderstatus</span> synkas från
                        pyramid.
                      </p>
                      <p className="">
                        4 = Slutförd. X = Ej slutförd. 2 = Pågående.
                      </p>

                      <p className="mb-1">
                        <span className="fw-bold">Samlat orderbetyg </span>
                        räknas ut automatiskt baserat på checklistornas betyg.
                      </p>
                    </>
                  }
                >
                  <HelpOutlineOutlinedIcon
                    sx={{
                      color: "#161616",
                      opacity: 0.4,
                    }}
                  />
                </Tooltip>
              </div>
            </div>

            {order?.completion?.completionState !== "PENDING" && (
              <p className={`${textStyles.smallText} mt-2`}>
                {order?.completion?.completedAt?.slice(0, 16)} {" av "}
                <Tooltip arrow title="Gå till användarprofilen">
                  <Link
                    to={`/users/view/${order?.completion?.completedBy?.id}`}
                  >
                    <span className={textStyles.smallText}>
                      {order?.completion?.completedBy?.userName}
                    </span>
                  </Link>
                </Tooltip>
              </p>
            )}
          </>
        )}
      </div>

      {storedAverage === null ? (
        <p className="mb-0">Samlat orderbetyg saknas</p>
      ) : (
        <div>
          <p className="fw-bold mb-0">
            Samlat orderbetyg: {average.toFixed(2)}
          </p>
          <Rating readOnly precision={0.1} value={average} />
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
