import { Button } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { verifyEmail, logOut } from "features/auth/authSlice";
import ConfirmModal from "features/confirmModal/ConfirmModal";
import { CenterContainer } from "features/containers/centerContainer/CenterContainer";
import { useForm } from "hooks/useForm";
import { VerifyEmailData } from "interfaces/formTypes";
import { useState } from "react";

const PendingApprovalComponent: React.FunctionComponent = (props) => {
  const dispatch = useAppDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // Using form hook for validation
  const {
    handleSubmit,
    handleChange,
    data: verifyData,
    errors,
  } = useForm<VerifyEmailData>({
    validations: {
      verificationCode: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Ange en kod",
        },
      },
    },
    initialValues: {
      verificationCode: "",
    },
    onSubmit: () => {
      dispatch(verifyEmail(verifyData));
    },
  });

  return (
    <CenterContainer>
      <h4 className="mb-3">Väntar på godkännande</h4>
      <p>
        För att du ska få tillgång till ditt konto måste det först godkännas av
        en admin.
      </p>
      <p>När ditt konto har godkänts kan du prova uppdatera den här sidan.</p>

      <Button
        className="mt-4"
        color="error"
        variant="contained"
        onClick={() => setShowConfirmModal(true)}
      >
        Logga ut
      </Button>

      <ConfirmModal
        handleClose={() => {
          setShowConfirmModal(false);
        }}
        handleConfirm={() => {
          dispatch(logOut());
        }}
        show={showConfirmModal}
        title={"Logga ut"}
        message={"Är du säker på att du vill logga ut?"}
        confirmLabel={"Logga ut mig"}
        dismissLabel={"Avbryt"}
        isLoading={false}
      />
    </CenterContainer>
  );
};

export default PendingApprovalComponent;
