import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getCompany,
  selectCompany,
  selectIsInvalidated,
  selectLoading,
  selectShowCompanyState,
  setCompany,
} from "./showCompanySlice";
import { selectCompanies, setLastViewedCompany } from "../companiesSlice";
import IPage from "interfaces/page";
import MainContainer from "features/containers/MainContainer/MainContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Col, Row } from "react-bootstrap";
import Users from "./components/Users";
import CompanyInfo from "./components/CompanyInfo";
import { CompanyIdParam } from "models/Company";
import { Tooltip } from "@mui/material";
import { BeatLoader } from "react-spinners";

const ShowCompany: React.FunctionComponent<IPage> = (props) => {
  const history = useHistory();
  const { id } = useParams<CompanyIdParam>();
  const shownCompany = useAppSelector(selectCompany);
  const allCompanies = useAppSelector(selectCompanies);
  const companyLoading = useAppSelector(selectLoading);
  const isInvalidated = useAppSelector(selectIsInvalidated);
  const showCompanyState = useAppSelector(selectShowCompanyState);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const query = useQuery();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  useEffect(() => {
    // Load company
    if ((!shownCompany && !companyLoading) || shownCompany?.id !== id) {
      loadCompany();
    }
  }, [shownCompany, companyLoading, id, query]);

  useEffect(() => {
    if (isInvalidated) {
      dispatch(getCompany(id));
    }
  }, [isInvalidated, dispatch, id]);

  useEffect(() => {
    let redirectTimer;
    if (showCompanyState.state === "deleted") {
      redirectTimer = setTimeout(() => {
        history.push("/companies");
      }, 2000);
    }

    return () => {
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
    };
  }, [showCompanyState.state]);

  const loadCompany = () => {
    let company;

    if (allCompanies && allCompanies?.length > 0) {
      // Lookup user in store
      company = allCompanies.find((company) => {
        return company.id === id;
      });

      if (company) {
        dispatch(setCompany(company));
      } else {
        // Fetch company
        dispatch(getCompany(id));
      }
    } else {
      // Fetch user
      dispatch(getCompany(id));
    }
  };

  // Save the shownCompany.id in companies.lastViewedCompany
  useEffect(() => {
    if (shownCompany) {
      dispatch(setLastViewedCompany(shownCompany.id));
    }
  }, [shownCompany]);

  return (
    <>
      <MainContainer title={`${props.name} | ${shownCompany?.namn}`}>
        <Row>
          <Col className="mb-5 d-flex">
            <Tooltip arrow title="Alla företag">
              <Link to={"/companies"} style={{ textDecoration: "none" }}>
                <ArrowBackIcon />
                Tillbaka
              </Link>
            </Tooltip>
          </Col>
        </Row>
        {companyLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center my-5">
            <BeatLoader color="#5f5f5f" className="mb-3" />
          </div>
        ) : showCompanyState.state === "idle" ? (
          <>
            <CompanyInfo />
            <Users />
          </>
        ) : (
          <h4>Företaget borttaget</h4>
        )}
      </MainContainer>
    </>
  );
};

export default ShowCompany;
