import axios from "axios";
import { User } from "./types/user";
import { BE_URL } from "config/api";
import { RegisterResponse } from "./types/authResponseTypes";
import { SignUpData, VerifyEmailData } from "interfaces/formTypes";

export const loginRequest = async <ResponseType>(
  email: string,
  password: string
) => {
  const response = await axios.post<ResponseType>(`${BE_URL}/auth/weblogin`, {
    email,
    password,
  });
  return response;
};

export const validateTokenRequest = async (token: string) => {
  const response = await axios.get<User>(`${BE_URL}/user/webcurrent`, {
    headers: {
      token: token,
    },
  });
  return response;
};
// export const signUp = async (email: string, password: string, confirmPassword: string)

export const createAccountRequest = async (formData: SignUpData) => {
  const response = await axios.post<RegisterResponse>(
    `${BE_URL}/user/register`,
    {
      ...formData,
    }
  );
  return response;
};

export const verifyEmailRequest = async (
  formData: VerifyEmailData,
  token: string
) => {
  const response = await axios.get<string>(
    `${BE_URL}/user/verify/${formData.verificationCode}`,
    {
      headers: {
        token: token,
      },
    }
  );
  return response;
};
