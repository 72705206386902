import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useHistory, useLocation } from "react-router-dom";
import MainContainer from "features/containers/MainContainer/MainContainer";
import SearchBar from "features/searchBar/SearchBar";
import IPage from "interfaces/page";
import {
  filterTimeEntries,
  FilterTimeEntryParams,
  selectEntries,
  selectLoading,
  selectHasMorePages,
  CSVRow,
  selectTotalResults,
} from "./timeManagementSlice";
import { Button } from "@mui/material";
import TimeEntryItem from "./components/TimeEntryItem";
import { DateRange } from "features/mui/DateRangePicker";
import InfiniteScroll from "react-infinite-scroll-component";
import { endOfDay, startOfDay, format } from "date-fns";
import CsvDownloader from "react-csv-downloader";
import { getCSVData, columns, generateFileName } from "./downloadCSVData";
import { selectToken } from "features/auth/authSlice";
import Chip from "@mui/material/Chip";
import { BeatLoader } from "react-spinners";
import TimeFilter from "./components/TimeFilter";
import { Col, Row } from "react-bootstrap";

const TimeManagement: React.FunctionComponent<IPage> = (props) => {
  const { name } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const queryParams = useQuery();
  let history = useHistory();
  let queryTimer;

  const token = useAppSelector(selectToken);
  const loading = useAppSelector(selectLoading);
  const allEntries = useAppSelector(selectEntries);
  const filteredResultsCounter = useAppSelector(selectTotalResults);
  const entriesLoading = useAppSelector(selectLoading);
  const hasMorePages = useAppSelector(selectHasMorePages);

  const [tempDateRange, setTempDateRange] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
  const [typeFilter, setTypeFilter] = useState<string>("0");
  const [statusFilter, setStatusFilter] = useState<
    "PENDING" | "APPROVED" | "REJECTED" | ""
  >("");
  const [activeFiltersCounter, setActiveFiltersCounter] = useState<number>(0);
  const [expanded, setExpanded] = useState("");
  const [query, setQuery] = useState("");
  const [processedFilter, setProcessedFilter] = useState<boolean>(false);
  const [sortByNumber, setSortByNumber] = useState<boolean>(false);
  const [initialParamsSet, setInitialFilterParamsSet] =
    useState<boolean>(false);
  const [filtersIndicator, setFiltersIndicator] = useState<FilterIndicator[]>();

  useEffect(() => {
    if (initialParamsSet) {
      let filterParams = getFilterParams();
      dispatch(filterTimeEntries(filterParams));
      generateFilterIndicators();
    }
  }, [
    initialParamsSet,
    typeFilter,
    statusFilter,
    dateRange,
    processedFilter,
    query,
    sortByNumber,
  ]);

  useEffect(() => {
    if (tempDateRange[0] && tempDateRange[1]) {
      setDateRange(tempDateRange);
    } else if (tempDateRange[0] === null && tempDateRange[1] === null) {
      setDateRange([null, null]);
    }
  }, [tempDateRange]);

  // Sets filter state and search query on initial render based on initial query params
  useEffect(() => {
    // Filters
    const rangeParams: DateRange<Date> = [null, null];
    queryParams.forEach((value, key) => {
      switch (key) {
        case "type":
          setTypeFilter(value);
          break;
        case "status":
          if (
            value === "REJECTED" ||
            value === "APPROVED" ||
            value === "PENDING" ||
            value === ""
          ) {
            setStatusFilter(value);
          }
          break;
        case "from":
          rangeParams[0] = new Date(value);
          break;
        case "to":
          rangeParams[1] = new Date(value);
          break;
        case "processed":
          setProcessedFilter(true);
          break;
        case "orderByEmployeeNumber":
          setSortByNumber(true);
          break;
      }
    });
    setTempDateRange(rangeParams);
    setDateRange(rangeParams);

    const searchParam = queryParams.get("search");
    if (searchParam) {
      setQuery(searchParam);
    }

    setInitialFilterParamsSet(true);
  }, []);

  // Listen to changes in filters
  useEffect(() => {
    if (
      (dateRange[0] !== null && dateRange[1] !== null) ||
      (dateRange[0] === null && dateRange[1] === null)
    ) {
      setExpanded("");
    }
  }, [dateRange]);

  // Listen to changes in search query
  useEffect(() => {
    if (query.length > 0) {
      queryParams.delete("search");
      queryParams.append("search", query);
    } else {
      queryParams.delete("search");
    }
    history.replace({ search: queryParams.toString() });
  }, [query]);

  const updateFilters = () => {
    // Clear current params
    let currentParams = [...queryParams.keys()];
    for (let key of currentParams) {
      if (key !== "search") {
        queryParams.delete(key);
      }
    }
    history.replace({ search: "" });

    if (typeFilter !== "0") {
      queryParams.append("type", `${typeFilter}`);
    }
    if (statusFilter) {
      queryParams.append("status", `${statusFilter}`);
    }

    if (dateRange[0] && dateRange[1]) {
      queryParams.append("from", startOfDay(dateRange[0]).toDateString());
      queryParams.append("to", endOfDay(dateRange[1]).toDateString());
    }

    if (processedFilter) {
      queryParams.append("processed", `${processedFilter}`);
    }

    if (sortByNumber) {
      queryParams.append("orderByEmployeeNumber", `${sortByNumber}`);
    }

    history.replace({ search: queryParams.toString() });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  interface FilterIndicator {
    clear: () => void;
    data: string;
    label: string;
  }

  const generateFilterIndicators = () => {
    let filterParams = getFilterParams();
    let filters: FilterIndicator[] = [];
    for (const [key, value] of Object.entries(filterParams)) {
      if (value === null || value === 0 || value === "") {
        // Clear filter
        continue;
      }

      let label: string = "";
      let callback: () => void = () => null;

      switch (key) {
        case "state":
          label = AbsenceStatus[value];
          callback = () => setStatusFilter("");
          break;
        case "type":
          label = getAbsenceType(value);
          callback = () => setTypeFilter("0");
          break;
        case "fromDate":
          label = `${format(dateRange[0]!, "dd/MM/YYY")} -> ${format(
            dateRange[1]!,
            "dd/MM/YYY"
          )}`;
          callback = () => setTempDateRange([null, null]);
          break;
        case "toDate":
          continue;
        case "processed":
          label = "Ej behandlade av ekonomi";
          callback = () => setProcessedFilter(false);
          break;
        case "orderByEmployeeNumber":
          label = "Sortera efter anställningsnummer";
          callback = () => setSortByNumber(false);
          break;
        case "searchQuery":
          continue;
      }
      filters.push({
        clear: callback,
        data: value,
        label: label,
      });
    }
    setFiltersIndicator(filters);
  };

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const getFilterParams = (): FilterTimeEntryParams => {
    const params: FilterTimeEntryParams = {
      page: 0,
      pageSize: 0,
      searchQuery: "",
    };
    // Add statusFilter
    if (statusFilter !== "") {
      params.state = statusFilter;
    }
    // Add typefilter
    if (typeFilter !== "") {
      params.type = parseInt(typeFilter);
    }
    // Add date range
    if (dateRange[0] && dateRange[1]) {
      params.fromDate = new Date(
        Date.UTC(
          dateRange[0].getFullYear(),
          dateRange[0].getMonth(),
          dateRange[0].getDate(),
          dateRange[0].getHours(),
          dateRange[0].getMinutes(),
          dateRange[0].getSeconds(),
          dateRange[0].getMilliseconds()
        )
      ).toISOString();
      params.toDate = new Date(
        Date.UTC(
          dateRange[1].getFullYear(),
          dateRange[1].getMonth(),
          dateRange[1].getDate(),
          23,
          59,
          59,
          999
        )
      ).toISOString();
    }

    // Add processed filter
    if (processedFilter) {
      params.processed = true;
    }

    // Add sort param
    if (sortByNumber) {
      params.orderByEmployeeNumber = true;
    }

    // Add searchQuery
    params.searchQuery = query;

    return params;
  };

  // Listening for changes in filters
  useEffect(() => {
    // Set counter
    let counter = 0;
    if (statusFilter !== "") counter++;
    if (dateRange[0] && dateRange[1]) counter++;
    if (Number.parseInt(typeFilter, 10) !== 0) counter++;
    if (processedFilter) counter++;
    if (sortByNumber) counter++;

    setActiveFiltersCounter(counter);

    // Check if filterparams are set, if so, dispatch action to fetch entries with filterparams.
    const filterParams: FilterTimeEntryParams = getFilterParams();

    updateFilters();
  }, [
    typeFilter,
    statusFilter,
    processedFilter,
    dateRange,
    query,
    sortByNumber,
  ]);

  const onSearch = (value) => {
    if (queryTimer) {
      clearTimeout(queryTimer);
    }
    // Wait to determine if user is done typing, if so fetch
    queryTimer = setTimeout(() => {
      setQuery(value);
    }, 500);
  };

  const renderElements = () => {
    if (!allEntries) {
      return [];
    } else {
      let _entries = [...allEntries];
      const list = allEntries?.map((entry) => {
        return (
          <TimeEntryItem
            key={entry.id}
            expanded={expanded === entry.id}
            onClick={handleChange(entry.id)}
            timeEntry={entry}
            filterWithinRange={dateRange[0] && dateRange[1] ? dateRange : null}
          />
        );
      });

      return list;
    }
  };

  const getData = (): Promise<CSVRow[]> => {
    return getCSVData(getFilterParams(), token || "");
  };

  return (
    <MainContainer title={name}>
      <Row className="px-2">
        <Col xs={12} lg={5} xl={4} xxl={3} className="p-1">
          <SearchBar label="Sök" onChange={onSearch} value={query} />
        </Col>

        <Col xs={12} lg={7} xl={8} xxl={9} className="p-1">
          <TimeFilter
            activeFiltersCounter={activeFiltersCounter}
            tempDateRange={tempDateRange}
            setTempDateRange={setTempDateRange}
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            processedFilter={processedFilter}
            setProcessedFilter={setProcessedFilter}
            sortByNumber={sortByNumber}
            setSortByNumber={setSortByNumber}
          />
        </Col>
      </Row>
      {!entriesLoading && activeFiltersCounter > 0 && (
        <Row className="mb-1 justify-content-end">
          {dateRange[0] &&
            dateRange[1] &&
            allEntries &&
            allEntries.length > 0 && (
              <Col sm="auto" className="m-3">
                <CsvDownloader
                  filename={generateFileName(dateRange[0], dateRange[1])}
                  extension=".csv"
                  separator=";"
                  wrapColumnChar=""
                  columns={columns}
                  datas={getData}
                >
                  <Button variant="contained" sx={{ textTransform: "none" }}>
                    Ladda ner tidrapport som CSV-fil
                  </Button>
                </CsvDownloader>
              </Col>
            )}
        </Row>
      )}
      {!entriesLoading && (
        <>
          {query.length > 1 && (
            <p className="fw-bold mt-4">{`Sökresultat för ${query}`}</p>
          )}

          {filtersIndicator && filtersIndicator.length > 0 && (
            <p className="mb-0 mt-4 fw-bold">Valda filter</p>
          )}
          <Row className="mb-2">
            {filtersIndicator?.map((item) => (
              <Col sm="auto" className="my-1" key={item.label}>
                <Chip
                  label={item.label}
                  variant="outlined"
                  onDelete={item.clear}
                />
              </Col>
            ))}
          </Row>
          <p className="mt-2">{filteredResultsCounter} resultat hittades</p>
        </>
      )}
      <Row>
        <InfiniteScroll
          scrollableTarget="scrollContainer"
          hasMore={hasMorePages}
          onScroll={() => null}
          dataLength={allEntries ? allEntries.length : 0}
          next={() => {
            if (initialParamsSet && !loading) {
              dispatch(filterTimeEntries(getFilterParams()));
            }
          }}
          loader={
            <div className="d-flex flex-column align-items-center justify-content-center my-5">
              <BeatLoader color="#5f5f5f" className="mb-3" />
            </div>
          }
        >
          {allEntries && allEntries.length > 0 && renderElements()}
        </InfiniteScroll>
      </Row>
    </MainContainer>
  );
};
export default TimeManagement;

const getAbsenceType = (type: number): string => {
  switch (type) {
    case 1:
      return "Sjuk";
    case 2:
      return "Betald semester";
    case 3:
      return "VAB";
    case 4:
      return "Tjänstledigt";
    case 5:
      return "Övertid";
    case 6:
      return "Vård av anhörig";
    case 7:
      return "Föräldraledig";
    default:
      return "";
  }
};

enum AbsenceStatus {
  PENDING = "Ej behandlad",
  APPROVED = "Godkänd",
  REJECTED = "Ej godkänd",
}
