import { MouseEvent, ReactNode, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Chip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Col, Row } from "react-bootstrap";
import styles from "./filterbar.module.scss";

export interface FilterbarProps {
  clearFilters: () => void;
  numberOfFiltersActive: number;
  children: ReactNode;
}

const Filterbar: React.FunctionComponent<FilterbarProps> = (props) => {
  const { children, clearFilters, numberOfFiltersActive } = props;
  const [filterExpanded, setFilterExpanded] = useState<string>("");

  const toggleFilter = (panel) => (event, newExpanded) => {
    setFilterExpanded(newExpanded ? panel : false);
  };

  const handleOnClear = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    clearFilters();
  };

  return (
    <div className={styles.filterBar}>
      <Accordion
        expanded={filterExpanded === "filterpanel"}
        onChange={toggleFilter("filterpanel")}
        className={styles.customAccordion}
      >
        <AccordionSummary
          aria-controls="filterpanel-content"
          id="filterpanel-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Badge badgeContent={numberOfFiltersActive} color="primary">
            <h5 className="m-1">Filtrera</h5>
          </Badge>
          {numberOfFiltersActive > 0 && (
            <Col className="mx-4">
              <Chip
                label="Rensa alla"
                variant="outlined"
                onClick={handleOnClear}
              />
            </Col>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Row className="gy-2">{children}</Row>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filterbar;
