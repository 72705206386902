import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "app/store";
import { BE_URL } from "config/api";
import { GenericError } from "interfaces/errorTypes";
import { SerialEntity } from "models/Checklist";

interface ProductsState {
  productLoading: boolean;
  error: GenericError | undefined | null;
  products: SerialEntity[];
  productWasAdded: boolean;
  productWasEdited: boolean;
  productWasDeleted: boolean;
}

const initialState: ProductsState = {
  productLoading: false,
  error: null,
  products: [],
  productWasAdded: false,
  productWasEdited: false,
  productWasDeleted: false,
};

export const addProduct = createAsyncThunk<
  SerialEntity,
  SerialEntity,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/addProduct", async (createProduct, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = createProduct;

  const response = await axios
    .post<SerialEntity>(`${BE_URL}/checklist/serialEntity`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response as SerialEntity;
});

export const deleteProduct = createAsyncThunk<
  {},
  string,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/deleteProduct", async (productId, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const response = await axios
    .delete<string>(`${BE_URL}/checklist/serialEntity`, {
      data: {
        id: productId,
      },
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return productId;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod.",
          status: e.response.status,
        });
      }
    });
  return response;
});

export const editProduct = createAsyncThunk<
  SerialEntity,
  SerialEntity,
  { rejectValue: GenericError; state: RootState }
>("viewChecklist/editProduct", async (editProduct, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState();

  const body = editProduct;

  const response = await axios
    .patch<SerialEntity>(`${BE_URL}/checklist/serialEntity`, body, {
      headers: {
        token: state.auth.token || "",
      },
    })
    .then(async (res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 404) {
        return thunkApi.rejectWithValue({
          message: "ChecklistId existerar inte",
          status: e.response.status,
        });
      } else {
        // Some other error occured
        return thunkApi.rejectWithValue({
          message: "ett okänt fel uppstod",
          status: e.response.status,
        });
      }
    });
  return response as SerialEntity;
});

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // Populate the products array in productsState with serialEntities from viewChecklistState
    updateProductsFromChecklist: (state, action) => {
      state.products = action.payload;
    },
    resetProductsAfterUpdate: (state) => {
      state.productWasAdded = false;
      state.productWasDeleted = false;
      state.productWasEdited = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // add product
      .addCase(addProduct.pending, (state) => {
        state.productLoading = true;
        state.productWasAdded = false;

        state.productWasEdited = false;
        state.productWasDeleted = false;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productWasAdded = true; // technically it not added, but update state for toastify function
        state.error = action.payload;
      })
      .addCase(
        addProduct.fulfilled,
        (state, action: PayloadAction<SerialEntity>) => {
          state.productLoading = false;
          state.productWasAdded = true;

          if (state.products) {
            state.products = [action.payload, ...state.products];
          }
        }
      )

      // delete product
      .addCase(deleteProduct.pending, (state) => {
        state.productLoading = true;
        state.productWasDeleted = false;

        state.productWasAdded = false;
        state.productWasEdited = false;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productWasDeleted = true; // technically it's not deleted, but update state for toastify function

        state.error = action.payload;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.productLoading = false;
        state.productWasDeleted = true;

        if (state.products) {
          state.products = state.products.filter(
            (product) => product.id !== action.payload
          );
        }
      })

      // edit product
      .addCase(editProduct.pending, (state) => {
        state.productLoading = true;
        state.productWasEdited = false;

        state.productWasAdded = false;
        state.productWasDeleted = false;
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
        state.productWasEdited = true; // technically not edited, but update state for toastify function
      })
      .addCase(
        editProduct.fulfilled,
        (state, action: PayloadAction<SerialEntity>) => {
          state.productLoading = false;
          state.productWasEdited = true;

          if (state.products) {
            state.products = state.products.map((product) => {
              if (product.id === action.payload.id) {
                return action.payload;
              }
              return product;
            });
          }
        }
      );
  },
});

export const { updateProductsFromChecklist, resetProductsAfterUpdate } =
  productsSlice.actions;

export const selectProductsState = (state: RootState) => state.products;

export default productsSlice.reducer;
