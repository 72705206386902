import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { createAccountRequest } from "features/auth/authAPI";
import { RegisterResponse } from "features/auth/types/authResponseTypes";
import { SignUpData } from "interfaces/formTypes";
import { GenericError } from "interfaces/errorTypes";

interface SignUpState {
  status: "idle" | "done" | "loading" | "error";
  error: null | GenericError | undefined;
}

const initialState: SignUpState = {
  error: null,
  status: "idle",
};

export const registerUser = createAsyncThunk<
  RegisterResponse,
  SignUpData,
  { rejectValue: GenericError }
>("auth/register", async (signUpData, thunkApi) => {
  try {
    const response = await createAccountRequest(signUpData);
    return response.data as RegisterResponse;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      return thunkApi.rejectWithValue({
        message: "Lösenorden stämmer inte överens.",
      });
    } else if (error.response && error.response.status === 403) {
      return thunkApi.rejectWithValue({
        message: "Registreringskoden du angav är ej giltig.",
      });
    } else if (error.response && error.response.status === 409) {
      return thunkApi.rejectWithValue({
        message: "E-postadressen är redan registrerad.",
      });
    } else {
      return thunkApi.rejectWithValue({
        message: "Någonting gick fel med registreringen.",
      });
    }
  }
});

export const signUpSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.error = null;
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "done";
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "error";
      });
  },
});

// export const {  } = signUpSlice.actions;
export const selectError = (state: RootState) => state.signUp.error;
export const selectStatus = (state: RootState) => state.signUp.status;

export default signUpSlice.reducer;
