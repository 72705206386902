import { Container } from "react-bootstrap";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const DoneComponent = ({ message }) => {
  return (
    <Container>
      <div className="my-4 mx-2">
        <h4 className="text-center">Klart! {message}</h4>
        <div className="d-flex justify-content-center">
          <CheckCircleOutlineIcon sx={{ fontSize: 100, color: "#66bb6a" }} />
        </div>
      </div>
    </Container>
  );
};

export default DoneComponent;
