import React, { useState } from "react";
import { useAppSelector } from "app/hooks";
import { selectUser, selectUserIsAdmin } from "features/auth/authSlice";
import MainContainer from "features/containers/MainContainer/MainContainer";
import IPage from "interfaces/page";
import ProfileUserData from "./ProfileUserData";
import ChangePasswordModal from "./ChangePasswordModal";
import EditProfile from "./EditProfile";
import { DataContainer } from "features/containers/dataContainer/DataContainer";
import { Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import iconStyles from "../../utilities/styles/iconStyles.module.scss";

const Profile: React.FunctionComponent<IPage> = (props) => {
  const { name } = props;
  const user = useAppSelector(selectUser);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const [editProfileOpen, setEditProfileOpen] = useState<boolean>(false);

  const openChangePasswordModal = () => {
    setChangePasswordOpen(true);
  };

  return (
    <MainContainer title={name}>
      <DataContainer>
        <div className="d-flex justify-content-end">
          {isAdmin && (
            <Tooltip arrow title="Redigera dina uppgifter">
              <EditOutlinedIcon
                className={iconStyles.editIcon}
                onClick={() => setEditProfileOpen(true)}
              />
            </Tooltip>
          )}

          <Tooltip arrow title="Ändra lösenord">
            <VpnKeyIcon
              className={iconStyles.editIcon}
              onClick={openChangePasswordModal}
              sx={{ marginLeft: 1 }}
            />
          </Tooltip>
        </div>

        <div className="d-flex flex-column w-100">
          <ProfileUserData
            name={name}
            user={user}
          />

          <EditProfile
            handleClose={() => setEditProfileOpen(false)}
            show={editProfileOpen}
          />
        </div>

        <ChangePasswordModal
          handleClose={() => setChangePasswordOpen(false)}
          handleConfirm={() => setChangePasswordOpen(false)}
          show={changePasswordOpen}
          message={""}
          confirmLabel={""}
          dismissLabel={""}
          isLoading={false}
        />
      </DataContainer>
    </MainContainer>
  );
};

export default Profile;
