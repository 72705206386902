export type SimpleUser = {
  userName: string;
  userId: string;
  userCompany: string;
  status: UserStatus;

  id: string | null;
};

export enum UserStatus {
  CREATED,
  PENDING,
  ACTIVE,
  INACTIVE,
  RECOVERY,
}

export type FunctionCheckBy = {
  userName: string;
  userId: string;
}